<template>
  <div class="intro-y mt-8 flex items-center">
    <h2 class="mr-auto text-lg font-medium">Profile Layout</h2>
  </div>
  <TabGroup>
    <!-- BEGIN: Profile Info -->
    <div class="intro-y box mt-5 px-5 pt-5">
      <div
        class="-mx-5 flex flex-col border-b border-slate-200/60 pb-5 dark:border-darkmode-400 lg:flex-row"
      >
        <div
          class="flex flex-1 items-center justify-center px-5 lg:justify-start"
        >
          <div
            class="image-fit relative h-20 w-20 flex-none sm:h-24 sm:w-24 lg:h-32 lg:w-32"
          >
            <img
              alt="Gromura - SCM"
              class="rounded-full"
              :src="$f()[0].users[0].image"
            />
          </div>
          <div class="ml-5">
            <div
              class="w-24 truncate text-lg font-medium sm:w-40 sm:whitespace-normal"
            >
              {{ $f()[0].users[0].name }}
            </div>
            <div class="text-slate-500">{{ $f()[0].jobs[0] }}</div>
          </div>
        </div>
        <div
          class="mt-6 flex-1 border-l border-r border-t border-slate-200/60 px-5 pt-5 dark:border-darkmode-400 lg:mt-0 lg:border-t-0 lg:pt-0"
        >
          <div class="text-center font-medium lg:mt-3 lg:text-left">
            Contact Details
          </div>
          <div
            class="mt-4 flex flex-col items-center justify-center lg:items-start"
          >
            <div class="flex items-center truncate sm:whitespace-normal">
            <MailIcon class="mr-2 h-4 w-4" />
              {{ $f()[0].users[0].email }}
            </div>
            <div class="mt-3 flex items-center truncate sm:whitespace-normal">
              <InstagramIcon class="mr-2 h-4 w-4" /> Instagram
              {{ $f()[0].users[0].name }}
            </div>
            <div class="mt-3 flex items-center truncate sm:whitespace-normal">
              <TwitterIcon class="mr-2 h-4 w-4" /> Twitter
              {{ $f()[0].users[0].name }}
            </div>
          </div>
        </div>
        <div
          class="mt-6 flex flex-1 items-center justify-center border-t border-slate-200/60 px-5 pt-5 dark:border-darkmode-400 lg:mt-0 lg:border-0 lg:pt-0"
        >
          <div class="w-20 rounded-md py-3 text-center">
            <div class="text-xl font-medium text-primary">201</div>
            <div class="text-slate-500">Orders</div>
          </div>
          <div class="w-20 rounded-md py-3 text-center">
            <div class="text-xl font-medium text-primary">1k</div>
            <div class="text-slate-500">Purchases</div>
          </div>
          <div class="w-20 rounded-md py-3 text-center">
            <div class="text-xl font-medium text-primary">492</div>
            <div class="text-slate-500">Reviews</div>
          </div>
        </div>
      </div>
      <TabList
        class="nav-link-tabs flex-col justify-center text-center sm:flex-row lg:justify-start"
      >
        <Tab :fullWidth="false" class="flex cursor-pointer items-center py-4">
          <UserIcon class="mr-2 h-4 w-4" /> Profile
        </Tab>
        <Tab :fullWidth="false" class="flex cursor-pointer items-center py-4">
          <ShieldIcon class="mr-2 h-4 w-4" /> Account
        </Tab>
        <Tab :fullWidth="false" class="flex cursor-pointer items-center py-4">
          <LockIcon class="mr-2 h-4 w-4" /> Change Password
        </Tab>
        <Tab :fullWidth="false" class="flex cursor-pointer items-center py-4">
          <SettingsIcon class="mr-2 h-4 w-4" /> Settings
        </Tab>
      </TabList>
    </div>
    <!-- END: Profile Info -->
    <TabPanels class="mt-5">
      <TabPanel>
        <div class="grid grid-cols-12 gap-6">
          <!-- BEGIN: Latest Uploads -->
          <div class="intro-y box col-span-12 lg:col-span-6">
            <div
              class="flex items-center border-b border-slate-200/60 px-5 py-5 dark:border-darkmode-400 sm:py-3"
            >
              <h2 class="mr-auto text-base font-medium">Latest Uploads</h2>
              <Dropdown class="ml-auto sm:hidden">
                <DropdownToggle
                  tag="a"
                  class="block h-5 w-5"
                  href="javascript:;"
                >
                  <MoreHorizontalIcon class="h-5 w-5 text-slate-500" />
                </DropdownToggle>
                <DropdownMenu class="w-40">
                  <DropdownContent>
                    <DropdownItem>All Files</DropdownItem>
                  </DropdownContent>
                </DropdownMenu>
              </Dropdown>
              <button class="btn btn-outline-secondary hidden sm:flex">
                All Files
              </button>
            </div>
            <div class="p-5">
              <div class="flex items-center">
                <div class="file">
                  <a href="" class="file__icon file__icon--directory w-12"></a>
                </div>
                <div class="ml-4">
                  <a class="font-medium" href="">Dokumentasi</a>
                  <div class="mt-0.5 text-xs text-slate-500">40 KB</div>
                </div>
                <Dropdown class="ml-auto">
                  <DropdownToggle
                    tag="a"
                    class="block h-5 w-5"
                    href="javascript:;"
                  >
                    <MoreHorizontalIcon class="h-5 w-5 text-slate-500" />
                  </DropdownToggle>
                  <DropdownMenu class="w-40">
                    <DropdownContent>
                      <DropdownItem>
                        <UsersIcon class="mr-2 h-4 w-4" /> Share File
                      </DropdownItem>
                      <DropdownItem>
                        <TrashIcon class="mr-2 h-4 w-4" /> Delete
                      </DropdownItem>
                    </DropdownContent>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div class="mt-5 flex items-center">
                <div class="file">
                  <a href="" class="file__icon file__icon--file w-12">
                    <div class="file__icon__file-name text-xs">CSV</div>
                  </a>
                </div>
                <div class="ml-4">
                  <a class="font-medium" href="">Laporan Penjualan Agustus</a>
                  <div class="mt-0.5 text-xs text-slate-500">40 KB</div>
                </div>
                <Dropdown class="ml-auto">
                  <DropdownToggle
                    tag="a"
                    class="block h-5 w-5"
                    href="javascript:;"
                  >
                    <MoreHorizontalIcon class="h-5 w-5 text-slate-500" />
                  </DropdownToggle>
                  <DropdownMenu class="w-40">
                    <DropdownContent>
                      <DropdownItem>
                        <UsersIcon class="mr-2 h-4 w-4" /> Share File
                      </DropdownItem>
                      <DropdownItem>
                        <TrashIcon class="mr-2 h-4 w-4" /> Delete
                      </DropdownItem>
                    </DropdownContent>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div class="mt-5 flex items-center">
                <div class="file">
                  <a
                    href=""
                    class="file__icon file__icon--empty-directory w-12"
                  ></a>
                </div>
                <div class="ml-4">
                  <a class="font-medium" href="">Data Karyawan</a>
                  <div class="mt-0.5 text-xs text-slate-500">0 KB</div>
                </div>
                <Dropdown class="ml-auto">
                  <DropdownToggle
                    tag="a"
                    class="block h-5 w-5"
                    href="javascript:;"
                  >
                    <MoreHorizontalIcon class="h-5 w-5 text-slate-500" />
                  </DropdownToggle>
                  <DropdownMenu class="w-40">
                    <DropdownContent>
                      <DropdownItem>
                        <UsersIcon class="mr-2 h-4 w-4" /> Share File
                      </DropdownItem>
                      <DropdownItem>
                        <TrashIcon class="mr-2 h-4 w-4" /> Delete
                      </DropdownItem>
                    </DropdownContent>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
          <!-- END: Latest Uploads -->
          <!-- BEGIN: Work In Progress -->
          <TabGroup class="intro-y box col-span-12 lg:col-span-6">
            <div
              class="flex items-center border-b border-slate-200/60 px-5 py-5 dark:border-darkmode-400 sm:py-0"
            >
              <h2 class="mr-auto text-base font-medium">Work In Progress</h2>
              <Dropdown class="ml-auto sm:hidden">
                <DropdownToggle
                  tag="a"
                  class="block h-5 w-5"
                  href="javascript:;"
                >
                  <MoreHorizontalIcon class="h-5 w-5 text-slate-500" />
                </DropdownToggle>
                <DropdownMenu class="w-40">
                  <DropdownContent tag="div">
                    <TabList class="block">
                      <Tab
                        :fullWidth="false"
                        class="dropdown-item cursor-pointer"
                        >New</Tab
                      >
                      <Tab
                        :fullWidth="false"
                        class="dropdown-item cursor-pointer"
                        >Last Week</Tab
                      >
                    </TabList>
                  </DropdownContent>
                </DropdownMenu>
              </Dropdown>
              <TabList class="nav-link-tabs ml-auto hidden w-auto sm:flex">
                <Tab :fullWidth="false" class="cursor-pointer py-5">New</Tab>
                <Tab :fullWidth="false" class="cursor-pointer py-5"
                  >Last Week</Tab
                >
              </TabList>
            </div>
            <div class="p-5">
              <TabPanels>
                <TabPanel>
                  <div>
                    <div class="flex">
                      <div class="mr-auto">Pending Tasks</div>
                      <div>20%</div>
                    </div>
                    <div class="progress mt-2 h-1">
                      <div
                        class="progress-bar w-1/2 bg-primary"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div class="mt-5">
                    <div class="flex">
                      <div class="mr-auto">Completed Tasks</div>
                      <div>2 / 20</div>
                    </div>
                    <div class="progress mt-2 h-1">
                      <div
                        class="progress-bar w-1/4 bg-primary"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div class="mt-5">
                    <div class="flex">
                      <div class="mr-auto">Tasks In Progress</div>
                      <div>42</div>
                    </div>
                    <div class="progress mt-2 h-1">
                      <div
                        class="progress-bar w-3/4 bg-primary"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <a href="" class="btn btn-secondary mx-auto mt-5 block w-40"
                    >View More Details</a
                  >
                </TabPanel>
              </TabPanels>
            </div>
          </TabGroup>
          <!-- END: Work In Progress -->
          <!-- BEGIN: Daily Sales -->
          <div class="intro-y box col-span-12 lg:col-span-6">
            <div
              class="flex items-center border-b border-slate-200/60 px-5 py-5 dark:border-darkmode-400 sm:py-3"
            >
              <h2 class="mr-auto text-base font-medium">Daily Sales</h2>
              <Dropdown class="ml-auto sm:hidden">
                <DropdownToggle
                  tag="a"
                  class="block h-5 w-5"
                  href="javascript:;"
                >
                  <MoreHorizontalIcon class="h-5 w-5 text-slate-500" />
                </DropdownToggle>
                <DropdownMenu class="w-40">
                  <DropdownContent>
                    <DropdownItem>
                      <FileIcon class="mr-2 h-4 w-4" /> Download Excel
                    </DropdownItem>
                  </DropdownContent>
                </DropdownMenu>
              </Dropdown>
              <button class="btn btn-outline-secondary hidden sm:flex">
                <FileIcon class="mr-2 h-4 w-4" /> Download Excel
              </button>
            </div>
            <div class="p-5">
              <div class="relative flex items-center">
                <div class="image-fit h-12 w-12 flex-none">
                  <img
                    alt="Gromura - SCM"
                    class="rounded-full"
                    :src="$f()[0].users[0].image"
                  />
                </div>
                <div class="ml-4 mr-auto">
                  <a href="" class="font-medium">{{ $f()[0].users[0].name }}</a>
                  <div class="mr-5 text-slate-500 sm:mr-5">
                    CAP PANDA CINCAU SELASIH
                  </div>
                </div>
                <div class="font-medium text-slate-600 dark:text-slate-500">
                  +Rp19
                </div>
              </div>
              <div class="relative mt-5 flex items-center">
                <div class="image-fit h-12 w-12 flex-none">
                  <img
                    alt="Gromura - SCM"
                    class="rounded-full"
                    :src="$f()[1].users[0].image"
                  />
                </div>
                <div class="ml-4 mr-auto">
                  <a href="" class="font-medium">{{ $f()[1].users[0].name }}</a>
                  <div class="mr-5 text-slate-500 sm:mr-5">
                    OVALE NATURAL H2O MICELLAR WATER
                  </div>
                </div>
                <div class="font-medium text-slate-600 dark:text-slate-500">
                  +Rp25
                </div>
              </div>
              <div class="relative mt-5 flex items-center">
                <div class="image-fit h-12 w-12 flex-none">
                  <img
                    alt="Gromura - SCM"
                    class="rounded-full"
                    :src="$f()[2].users[0].image"
                  />
                </div>
                <div class="ml-4 mr-auto">
                  <a href="" class="font-medium">{{ $f()[2].users[0].name }}</a>
                  <div class="mr-5 text-slate-500 sm:mr-5">
                    ELLIPS 5IN1 HAIR VITAMIN MILKSHAKE
                  </div>
                </div>
                <div class="font-medium text-slate-600 dark:text-slate-500">
                  +Rp21
                </div>
              </div>
            </div>
          </div>
          <!-- END: Daily Sales -->
          <!-- BEGIN: Latest Tasks -->
          <TabGroup class="intro-y box col-span-12 lg:col-span-6">
            <div
              class="flex items-center border-b border-slate-200/60 px-5 py-5 dark:border-darkmode-400 sm:py-0"
            >
              <h2 class="mr-auto text-base font-medium">Latest Tasks</h2>
              <Dropdown class="ml-auto sm:hidden">
                <DropdownToggle
                  tag="a"
                  class="block h-5 w-5"
                  href="javascript:;"
                >
                  <MoreHorizontalIcon class="h-5 w-5 text-slate-500" />
                </DropdownToggle>
                <DropdownMenu class="w-40">
                  <DropdownContent tag="div">
                    <TabList class="block">
                      <Tab
                        :fullWidth="false"
                        class="dropdown-item cursor-pointer"
                        >New</Tab
                      >
                      <Tab
                        :fullWidth="false"
                        class="dropdown-item cursor-pointer"
                        >Last Week</Tab
                      >
                    </TabList>
                  </DropdownContent>
                </DropdownMenu>
              </Dropdown>
              <TabList class="nav-link-tabs ml-auto hidden w-auto sm:flex">
                <Tab :fullWidth="false" class="cursor-pointer py-5">New</Tab>
                <Tab :fullWidth="false" class="cursor-pointer py-5"
                  >Last Week</Tab
                >
              </TabList>
            </div>
            <div class="p-5">
              <TabPanels>
                <TabPanel>
                  <div class="flex items-center">
                    <div
                      class="border-l-2 border-primary pl-4 dark:border-primary"
                    >
                      <a href="" class="font-medium">Create New Campaign</a>
                      <div class="text-slate-500">10:00 AM</div>
                    </div>
                    <div class="form-check form-switch ml-auto">
                      <input class="form-check-input" type="checkbox" />
                    </div>
                  </div>
                  <div class="mt-5 flex items-center">
                    <div
                      class="border-l-2 border-primary pl-4 dark:border-primary"
                    >
                      <a href="" class="font-medium">Meeting With Client</a>
                      <div class="text-slate-500">02:00 PM</div>
                    </div>
                    <div class="form-check form-switch ml-auto">
                      <input class="form-check-input" type="checkbox" />
                    </div>
                  </div>
                  <div class="mt-5 flex items-center">
                    <div
                      class="border-l-2 border-primary pl-4 dark:border-primary"
                    >
                      <a href="" class="font-medium">Create New Repository</a>
                      <div class="text-slate-500">04:00 PM</div>
                    </div>
                    <div class="form-check form-switch ml-auto">
                      <input class="form-check-input" type="checkbox" />
                    </div>
                  </div>
                </TabPanel>
              </TabPanels>
            </div>
          </TabGroup>
          <!-- END: Latest Tasks -->
          <!-- BEGIN: New Products -->
          <div class="intro-y box col-span-12">
            <div
              class="flex items-center border-b border-slate-200/60 px-5 py-3 dark:border-darkmode-400"
            >
              <h2 class="mr-auto text-base font-medium">New Products</h2>
              <button
                class="tiny-slider-navigator btn btn-outline-secondary mr-2 px-2"
                @click="prevNewProducts"
              >
                <ChevronLeftIcon class="h-4 w-4" />
              </button>
              <button
                class="tiny-slider-navigator btn btn-outline-secondary px-2"
                @click="nextNewProducts"
              >
                <ChevronRightIcon class="h-4 w-4" />
              </button>
            </div>
            <div id="new-products" class="tiny-slider py-5">
              <TinySlider ref-key="newProductsRef">
                <div
                  v-for="(faker, fakerKey) in $_.take($f(), 5)"
                  :key="fakerKey"
                  class="px-5"
                >
                  <div class="flex flex-col items-center pb-5 lg:flex-row">
                    <div
                      class="flex flex-col items-center border-slate-200/60 pr-5 dark:border-darkmode-400 sm:flex-row lg:border-r"
                    >
                      <div class="sm:mr-5">
                        <div class="image-fit h-20 w-20">
                          <img
                            alt="Gromura - SCM"
                            class="rounded-full"
                            :src="faker.products[0].images[0]"
                          />
                        </div>
                      </div>
                      <div
                        class="mr-auto mt-3 text-center sm:mt-0 sm:text-left"
                      >
                        <a href="" class="text-lg font-medium">{{
                          faker.products[0].name
                        }}</a>
                        <div class="mt-1 text-slate-500 sm:mt-0">
                          {{ faker.news[0].shortContent }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="mt-6 flex w-full flex-1 items-center justify-center border-t border-slate-200/60 px-5 pt-4 dark:border-darkmode-400 lg:mt-0 lg:w-auto lg:border-t-0 lg:pt-0"
                    >
                      <div class="w-20 rounded-md py-3 text-center">
                        <div class="text-xl font-medium text-primary">
                          {{ faker.totals[0] }}
                        </div>
                        <div class="text-slate-500">Orders</div>
                      </div>
                      <div class="w-20 rounded-md py-3 text-center">
                        <div class="text-xl font-medium text-primary">
                          {{ faker.totals[1] }}k
                        </div>
                        <div class="text-slate-500">Purchases</div>
                      </div>
                      <div class="w-20 rounded-md py-3 text-center">
                        <div class="text-xl font-medium text-primary">
                          {{ faker.totals[0] }}
                        </div>
                        <div class="text-slate-500">Reviews</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex flex-col items-center border-t border-slate-200/60 pt-5 dark:border-darkmode-400 sm:flex-row"
                  >
                    <div
                      class="flex w-full items-center justify-center border-b border-slate-200/60 pb-5 dark:border-darkmode-400 sm:w-auto sm:justify-start sm:border-b-0 sm:pb-0"
                    >
                      <div
                        class="mr-3 rounded bg-primary/10 px-3 py-2 font-medium text-primary dark:bg-darkmode-400 dark:text-slate-300"
                      >
                        {{ faker.dates[0] }}
                      </div>
                      <div class="text-slate-500">Date of Release</div>
                    </div>
                    <div class="mt-5 flex sm:ml-auto sm:mt-0">
                      <button class="btn btn-secondary ml-auto w-20">
                        Preview
                      </button>
                      <button class="btn btn-secondary ml-2 w-20">
                        Details
                      </button>
                    </div>
                  </div>
                </div>
              </TinySlider>
            </div>
          </div>
          <!-- END: New Products -->
          <!-- BEGIN: New Authors -->
          <div class="intro-y box col-span-12">
            <div
              class="flex items-center border-b border-slate-200/60 px-5 py-3 dark:border-darkmode-400"
            >
              <h2 class="mr-auto text-base font-medium">New Authors</h2>
              <button
                class="tiny-slider-navigator btn btn-outline-secondary mr-2 px-2"
                @click="prevNewAuthors"
              >
                <ChevronLeftIcon class="h-4 w-4" />
              </button>
              <button
                class="tiny-slider-navigator btn btn-outline-secondary px-2"
                @click="nextNewAuthors"
              >
                <ChevronRightIcon class="h-4 w-4" />
              </button>
            </div>
            <div id="new-authors" class="tiny-slider py-5">
              <TinySlider ref-key="newAuthorsRef">
                <div
                  v-for="(faker, fakerKey) in $_.take($f(), 5)"
                  :key="fakerKey"
                  class="px-5"
                >
                  <div class="flex flex-col items-center pb-5 lg:flex-row">
                    <div
                      class="flex flex-1 flex-col items-center border-slate-200/60 pr-5 dark:border-darkmode-400 sm:flex-row lg:border-r"
                    >
                      <div class="sm:mr-5">
                        <div class="image-fit h-20 w-20">
                          <img
                            alt="Gromura - SCM"
                            class="rounded-full"
                            :src="faker.users[0].image"
                          />
                        </div>
                      </div>
                      <div
                        class="mr-auto mt-3 text-center sm:mt-0 sm:text-left"
                      >
                        <a href="" class="text-lg font-medium">{{
                          faker.users[0].name
                        }}</a>
                        <div class="mt-1 text-slate-500 sm:mt-0">
                          {{ faker.jobs[0] }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="mt-6 flex w-full flex-1 flex-col items-center justify-center border-t border-slate-200/60 px-5 pt-4 dark:border-darkmode-400 lg:mt-0 lg:w-auto lg:items-start lg:border-t-0 lg:pt-0"
                    >
                      <div class="flex items-center">
                        <a
                          href=""
                          class="mr-2 flex h-8 w-8 items-center justify-center rounded-full border text-slate-400"
                        >
                          <FacebookIcon class="h-3 w-3 fill-current" />
                        </a>
                        {{ faker.users[0].email }}
                      </div>
                      <div class="mt-2 flex items-center">
                        <a
                          href=""
                          class="mr-2 flex h-8 w-8 items-center justify-center rounded-full border text-slate-400"
                        >
                          <TwitterIcon class="h-3 w-3 fill-current" />
                        </a>
                        {{ faker.users[0].name }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex flex-col items-center border-t border-slate-200/60 pt-5 dark:border-darkmode-400 sm:flex-row"
                  >
                    <div
                      class="flex w-full items-center justify-center border-b border-slate-200/60 pb-5 dark:border-darkmode-400 sm:w-auto sm:justify-start sm:border-b-0 sm:pb-0"
                    >
                      <div
                        class="mr-3 rounded bg-primary/10 px-3 py-2 font-medium text-primary dark:bg-darkmode-400 dark:text-slate-300"
                      >
                        {{ faker.dates[0] }}
                      </div>
                      <div class="text-slate-500">Joined Date</div>
                    </div>
                    <div class="mt-5 flex sm:ml-auto sm:mt-0">
                      <button class="btn btn-secondary ml-auto w-20">
                        Message
                      </button>
                      <button class="btn btn-secondary ml-2 w-20">
                        Profile
                      </button>
                    </div>
                  </div>
                </div>
              </TinySlider>
            </div>
          </div>
          <!-- END: New Authors -->
        </div>
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

<script setup>
import { ref, provide } from 'vue'

const newProductsRef = ref()
const newAuthorsRef = ref()

provide('bind[newProductsRef]', (el) => {
  newProductsRef.value = el
})

provide('bind[newAuthorsRef]', (el) => {
  newAuthorsRef.value = el
})

const prevNewProducts = () => {
  const el = newProductsRef.value
  el.tns.goTo('prev')
}
const nextNewProducts = () => {
  const el = newProductsRef.value
  el.tns.goTo('next')
}
const prevNewAuthors = () => {
  const el = newAuthorsRef.value
  el.tns.goTo('prev')
}
const nextNewAuthors = () => {
  const el = newAuthorsRef.value
  el.tns.goTo('next')
}
</script>
