<template>
  <Chart
    :type="chartType"
    :width="width"
    :height="height"
    :data="chartData"
    :options="options"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useDarkModeStore } from '@/stores/dark-mode'
import { useColorSchemeStore } from '@/stores/color-scheme'
import { colors } from '@/utils/colors'

const props = defineProps({
  chartType: {
    type: String,
    default: 'line',
  },
  width: {
    type: [Number, String],
    default: 'auto',
  },
  height: {
    type: [Number, String],
    default: 'auto',
  },
  itemName: {
    type: [Array, String],
    default: '',
  },
  labels: {
    type: Array,
    default: () => {
      return []
    },
  },
  customColor: {
    type: Array,
    default: () => {
      return []
    },
  },
  data: {
    type: Array,
    default: () => {
      return []
    },
  },
  showLegend: {
    type: Boolean,
    default: false,
  },
  showCurrencyPrefix: {
    type: Boolean,
    default: false,
  },
})

const darkMode = computed(() => useDarkModeStore().darkMode)
const colorScheme = computed(() => useColorSchemeStore().colorScheme)

function getColor(index) {
  switch (index) {
    case 0:
      return colors.info(0.9)
    case 1:
      return colors.success(0.9)
    case 2:
      return colors.danger(0.9)
    case 3:
      return colors.primary()
    case 4:
      return colors.warning()
    case 5:
      return colors.pending()
    case 6:
      return colors.secondary()
    case 7:
      return colors.light()
    case 8:
      return colors.dark()
    default:
      return colors.primary()
  }
}

function generateDataset() {
  let dataset = []
  if (Array.isArray(props.itemName)) {
    props.itemName.forEach((item, index) =>
      dataset.push({
        label: item,
        data: props.data[index],
        borderWidth: 0,
        borderColor: colorScheme.value
          ? props.customColor.length > 0
            ? props.customColor[index]
            : getColor(index)
          : '',
        borderRadius: 100,
        borderSkipped: false,
        backgroundColor:
          props.chartType !== 'bar' ? 'transparent' : getColor(index),
        pointBorderColor:
          props.customColor.length > 0
            ? props.customColor[index]
            : getColor(index),
        tension: 0.4,
      })
    )
  } else {
    dataset.push({
      label: props.itemName,
      data: props.data,
      borderWidth: 0,
      borderColor: colorScheme.value ? colors.primary(0.8) : '',
      borderRadius: 100,
      borderSkipped: false,
      backgroundColor:
        props.chartType !== 'bar' ? 'transparent' : colors.primary(),
      pointBorderColor: colors.primary(),
      tension: 0.4,
    })
  }

  return dataset
}

const chartType = computed(() => {
  return props.chartType;
})

const chartData = computed(() => {
  return {
    labels: props.labels,
    datasets: generateDataset(),
  }
})

const options = computed(() => {
  return {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: props.showLegend,
      },
      tooltip: {
          callbacks: {
              label: function(context) {
                  let total = context.dataset.label || '';
                  if (total) {
                    total += ': ';
                  }
                  if (context.parsed.y !== null) {
                    // total += new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(context.parsed.y);
                    const newTotal = context.parsed.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    total += props.showCurrencyPrefix ? 'Rp' + newTotal : newTotal;
                  }
                  return total;
              }
          }
      },
    },
    
    scales: {
      x: {
        // stacked: true,
        ticks: {
          autoSkip: false,
          font: {
            size: 10,
          },
          color: colors.slate['500'](1),
        },
        grid: {
          display: true,
          drawBorder: true,
        },
      },
      y: {
        ticks: {
          font: {
            size: 12,
          },
          color: colors.slate['500'](1),
          callback: function(value, index, values) {
            let total = 0;
            if (parseInt(value) >= 1000) {
                total = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                total = value;
            }
            return props.showCurrencyPrefix ? 'Rp' + total : total;
          }
        },
        grid: {
          color: darkMode.value
            ? colors.slate['500'](0.3)
            : colors.slate['300'](),
          borderDash: [2, 2],
          drawBorder: true,
        },
      },
    },
  }
})
</script>
