<template>
  <span
    v-if="status === 'PENDING'"
    class="rounded-full border border-amber-400 bg-amber-50 text-amber-600 text-xs md:text-sm px-3 py-1"
  >
    Tertunda
  </span>
  <span
    v-else-if="status === 'PROCESS'"
    class="rounded-full border border-lime-400 bg-lime-50 text-lime-600 text-xs md:text-sm px-3 py-1"
  >
    Diproses
  </span>
  <span
    v-else-if="status === 'DELIVERY'"
    class="rounded-full border border-purple-400 bg-purple-50 text-purple-600 text-xs md:text-sm px-3 py-1"
  >
    Dikirim
  </span>
  <span
    v-else-if="status === 'PAID'"
    class="rounded-full border border-teal-400 bg-teal-50 text-teal-600 text-xs md:text-sm px-3 py-1"
  >
    Dibayar
  </span>
  <span
    v-else-if="status === 'RECEIVED'"
    class="rounded-full border border-sky-400 bg-sky-50 text-sky-600 text-xs md:text-sm px-3 py-1"
  >
    Diterima
  </span>
  <span
    v-else-if="status === 'COMPLETED'"
    class="rounded-full border border-green-400 bg-green-50 text-green-600 text-xs md:text-sm px-3 py-1"
  >
    Selesai
  </span>
  <span
    v-else-if="status === 'CANCELLED'"
    class="rounded-full border border-red-400 bg-red-50 text-red-600 text-xs md:text-sm px-3 py-1"
  >
    Dibatalkan
  </span>
</template>

<script>
export default {
  name: 'StatusTrx',
  props: {
    status: {
      type: String,
      default: 'PENDING',
      required: false,
      validator: function (value) {
        return [
          'PENDING',
          'PROCESS',
          'DELIVERY',
          'PAID',
          'RECEIVED',
          'COMPLETED',
          'CANCELLED',
        ].includes(value)
      },
    },
  },
}
</script>
