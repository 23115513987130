<template>
  <div class="mr-2 rounded-full border font-semibold bg-orange-50 text-orange-600 border-orange-200 pl-2 pr-3 py-1 text-xs flex items-center mb-2">
    <BuildingIcon class="h-3 w-3 mr-1" />{{ name }}
  </div>
</template>

<script>
export default {
  name: 'Brand',
  props: {
    name: {
      type: String,
      default: null,
      required: true,
    },
  },
}
</script>
