<template>
  <Modal :slide-over="true" :show="showModal" @hidden="onClose" class="modal-cart modal-side">
    <a
      class="top-0 right-0 right-auto mt-4 -ml-12 hidden md:fixed md:flex"
      href="javascript:;"
      @click="onClose"
    >
      <XIcon class="h-8 w-8 text-white" />
    </a>
    <ModalHeader v-if="transaction.code !== undefined" class="px-4">
      <div class="flex w-full flex-col justify-between md:flex-row">
        <div class="flex justify-between">
          <div class="flex items-center">
            <span class="fake-receipt text-base md:text-lg">{{ transaction.code }} </span
            >&nbsp
            <Status :status="transaction.status" />
          </div>
          <div class="flex items-center">
            <a
              class="flex md:hidden"
              href="javascript:;"
              @click="onClose"
            >
              <XIcon class="h-6 w-6 text-red-500" />
            </a>
          </div>
        </div>
        <div class="mt-2 flex md:mt-0">
          <button
            class="btn bg-slate-600 px-4 text-white rounded-lg text-xs"
            @click="$h.printReceipt(transaction)"
          >
            <PrinterIcon class="mr-2 h-3 w-3" /> Print
          </button>
        </div>
      </div>
    </ModalHeader>
    <ModalBody v-if="transaction.code !== undefined" class="p-0">
      <div class="flex w-full flex-col">
        <div class="flex px-4 mt-4 mb-2 w-full">
          <div v-if="transaction.store !== undefined" class="flex flex-col w-1/2">
            <span
              class="flex text-xs text-gray-500"
            >
              Outlet
            </span>
            <span class="text-sm">
              {{ transaction.store.name }}
            </span>
          </div>
          <div v-if="transaction.payment_method !== undefined" class="flex flex-col w-1/2 pl-2 md-pl-0">
            <span
              class="flex text-xs text-gray-500"
            >
              {{ $t('formLabel.paymentMethod') }}
            </span>
            <span class="text-sm">
              {{ transaction.payment_method.name }}
            </span>
          </div>
        </div>

        <div class="flex px-4 mt-2 mb-4 w-full">
          <div v-if="transaction.pic !== undefined" class="flex flex-col w-1/2">
            <span
              v-if="
                ['pos-web', 'pos-android', 'pos-ios'].includes(
                  transaction.channel
                )
              "
              class="flex text-xs text-gray-500"
            >
              Outlet
            </span>
            <span
              v-else
              class="flex text-xs text-gray-500"
            >
              PIC
            </span>
            <span class="text-sm">
              {{ transaction.pic.full_name }}
            </span>
          </div>
          <div v-if="transaction.created_by !== undefined" class="flex flex-col w-1/2 pl-2 md-pl-0">
            <span
              class="flex text-xs text-gray-500"
            >
              Dibuat Oleh
            </span>
            <span v-if="transaction.created_by !== undefined" class="text-sm">
              {{ transaction.created_by.full_name }}
            </span>
          </div>
        </div>

        <div
          v-if="transaction.customer !== undefined"
          class="flex flex-col mb-2 w-full"
        >
          <!-- <div class="w-full border border-dashed border-gray-100 mb-4"></div> -->
          <div class="px-4">
            <div class="border border-gray-200 rounded-lg p-3">
              <Customer :customer="transaction.customer" :show-address="true" />
            </div>
          </div>
        </div>

        <div class="mt-2 grid grid-cols-12">
          <div
            v-for="(item, index) in transaction.items"
            :key="item.key"
            class="relative col-span-12"
          >
            <div :class="index === 0 ? 'h-3': 'h-2'" class="w-full bg-gray-100"></div>
            <div class="flex flex-col">
              <div class="flex w-full">
                <div class="flex w-20 pl-4 pt-4 pb-2">
                  <div class="w-full">
                    <ImageLoader
                      :image="
                        item.product.images !== undefined &&
                        item.product.images.length > 0
                          ? item.product.images[0].image
                          : null
                      "
                      :alt="item.product.name"
                      class="w-16"
                    rounded-class="rounded-xl"
                    />
                  </div>
                </div>
                <div class="flex w-full flex-col pl-4 md:px-4 pt-4 pb-2">
                  <div class="text-md flex w-full font-medium">
                    {{ item.product.name }}
                  </div>
                  <div class="flex">
                    <Brand v-if="item.product.brand !== undefined" :name="item.product.brand.name" />
                  </div>
                  <div v-if="showBasePrice" class="flex text-xs">
                    HPP:
                    <Currency
                      class="ml-2 text-teal-800"
                      :total-in-string="
                        item.base_price.toString()
                      "
                    />
                  </div>

                  <div v-if="canEdit" class="flex mt-4">
                    <button
                      type="button"
                      class="btn mr-1 h-8 w-8 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                      @click="changeQuantity(item, false, false)"
                    >
                      -
                    </button>
                    <input
                      id="pos-form-4"
                      v-model="item.quantity"
                      type="text"
                      class="form-control w-16 text-center text-xs"
                      placeholder="0"
                      @input="calculateTotalPriceItem(item)"
                    />
                    <button
                      type="button"
                      class="btn ml-1 h-8 w-8 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                      @click="changeQuantity(item, false, true)"
                    >
                      +
                    </button>
                  </div>

                  <div v-if="canEdit" class="flex pl-2 md:pl-6">
                    <input
                      id="pos-form-4"
                      v-model="item.sale_price"
                      type="text"
                      class="form-control w-32 md:w-36 text-center text-xs"
                      placeholder="Harga Beli"
                      @input="calculateTotalPriceItem(item)"
                    />
                  </div>
                </div>
                <div class="flex w-12 justify-end py-2 pr-4">
                  <a
                    v-if="canEdit"
                    class="flex mt-2 text-danger"
                    href="javascript:;"
                    @click="removeItem(item)"
                  >
                    <Trash2Icon class="h-5 w-5" />
                  </a>
                </div>
              </div>

              <div v-if="canEdit === false" class="flex flex-col mt-2 w-full px-4">
                <div class="flex w-full justify-between text-xs">
                  <span class="text-gray-600">Qty</span>
                  <div class="flex items-center">
                    x<Currency
                      :with-prefix="false"
                      class="text-gray-900 ml-1"
                      :total-in-string="item.quantity.toString()"
                    />
                  </div>
                </div>
                <div class="flex w-full justify-between text-xs mt-1">
                  <span class="text-gray-600">Harga Jual</span>
                  <template v-if="item.price_discount !== undefined && item.price_discount !== 0">
                    <div class="flex">
                      <Currency
                        class="text-gray-900"
                        :total-in-string="
                          Math.round(
                            ((item.quantity * item.sale_price) - item.price_discount) / item.quantity
                          ).toString()
                        "
                      />
                      <Currency
                        class="ml-2 text-red-600 line-through"
                        :total-in-string="item.sale_price.toString()"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <Currency
                      class="text-gray-900"
                      :total-in-string="
                        item.sale_price.toString()
                      "
                    />
                  </template>
                </div>
                <!-- <div class="flex w-full justify-between text-xs mt-1">
                  <span class="text-gray-600">Biaya Lainnya</span>
                  <Currency
                    class="text-gray-900"
                    :total-in-string="Number(item.other_fees).toString()"
                  />
                </div>
                <div class="flex w-full justify-between text-xs mt-1">
                  <span class="text-gray-600">Discount</span>
                  <span class="flex text-red-600">
                    -<Currency
                      :total-in-string="item.price_discount.toString()"
                    />
                  </span>
                </div> -->
              </div>

              <div class="mt-2 flex w-full flex-col border-dashed border-t p-2 py-3 pl-4">
                <div class="flex w-full justify-between text-xs">
                  <div class="flex items-center font-medium">Total Harga</div>
                  <div class="flex items-end pr-2">
                    <Currency
                      v-if="item.price_discount !== undefined && item.price_discount !== 0"
                      class="mr-2 text-red-500 line-through"
                      :total-in-string="
                        ((item.quantity * item.sale_price) - item.price_discount).toString()
                      "
                    />
                    <Currency
                      v-else
                      class="font-medium"
                      :total-in-string="
                        (item.quantity * item.sale_price).toString()
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="
            transaction.documents !== undefined &&
            transaction.documents.length > 0
          "
          class="intro-y col-span-12 border-t-2 pt-4"
        >
        <div class="font-medium px-4">
            <span class="text-lg">Dokumen</span>
          </div>
        </div>
        <div
          v-if="
            transaction.documents !== undefined &&
            transaction.documents.length > 0
          "
          class="mb-4 grid gap-4 px-4"
        >
          <div
            v-for="item in transaction.documents"
            :key="item.id"
            class="rounded-lg border border-green-100 px-2 py-2"
          >
            <Document :document="item.document" />
          </div>
        </div>

        <div class="h-3 w-full bg-gray-100"></div>

        <div class="flex flex-col pb-36 md:pb-24 px-4">
          <div class="border mt-4 rounded-lg bg-teal-50 border-teal-200 p-4">
            <div class="flex">
              <span class="mr-auto text-gray-600">Subtotal</span>
              <span class="font-medium">
                <Currency
                  :total-in-string="
                    (
                      transaction.total -
                      Math.round((transaction.total / 100) * tax)
                    ).toString()
                  "
                />
              </span>
            </div>
            <div class="mt-3 flex">
              <span class="mr-auto text-gray-600">Tax ({{ tax }}%)</span>
              <span class="font-medium">
                <Currency
                  :total-in-string="
                    Math.round((transaction.total / 100) * tax).toString()
                  "
                />
              </span>
            </div>
            <div
              v-if="transaction.total_discount !== undefined"
              class="mt-3 flex"
            >
              <span class="mr-auto text-gray-600">
                Discount
              </span>
              <span class="flex medium text-red-600">
                -<Currency
                  :total-in-string="transaction.total_discount.toString()"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter v-if="transaction.code !== undefined" class="w-470 fixed bottom-0 z-20 bg-white p-0 px-4 py-4">
      <div class="flex justify-between">
        <div class="flex flex-col">
          <div class="mr-auto text-gray-600 text-xs font-semibold">Total Harga</div>
          <div class="flex text-base md:text-xl text-teal-600 font-semibold">
            <Currency :total-in-string="transaction.total.toString()" />
          </div>
        </div>
        <div class="flex">
          <button
            v-if="canEdit"
            type="button"
            class="btn flex w-32 bg-gradient-to-r from-teal-700 to-primary text-white"
          >
            <!-- @click="updateTransaction" -->
            <LoadingIcon
              v-if="isFormLoading"
              icon="oval"
              color="white"
              class="mr-2 h-5 w-5"
            />

            Update
          </button>
        </div>
      </div>
    </ModalFooter>
  </Modal>
</template>

<script>
import { computed, onMounted, provide, ref, watch, defineProps } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helper as $h } from '@/utils/helper'
import ImageLoader from '@/components/image-loader/Main.vue'
import Document from '@/components/document/Main.vue'
import Currency from '@/components/Currency.vue'
import Status from '@/components/status/Main.vue'
import Brand from '@/components/detail-product/Brand.vue'

export default {
  name: 'TransactionDetail',
  components: {
    ImageLoader,
    Document,
    Currency,
    Status,
    Brand,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    showBasePrice: {
      type: Boolean,
      required: false,
      default: false,
    },
    transaction: {
      type: Object,
      required: true,
      default: '',
    },
    canEdit: {
      type: Boolean,
      required: true,
      default: false,
    },
    autoPrint: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['onClose', 'callback'],
  setup(props, { emit }) {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const tax = ref(11)
    const isFormLoading = ref(false)
    
    watch(
      computed(() => props.showModal),
      () => {
        if (props.showModal === true && props.autoPrint === true) {
          setTimeout(() => {
            $h.printReceipt(props.transaction)
          }, 1000)
        }
      }
    )

    const onClose = () => {
      emit('onClose')
    }

    const changeQuantity = (product, bonus, added) => {
      product = $h.changeQuantity(product, bonus, added)
      calculateTotalPriceItem(product)
    }
    
    const calculateTotalPriceItem = (product) => {
      product = $h.calculateTotalPriceItem(product)
    
      const result = $h.calculateTotalPrice(props.transaction)
      props.transaction.total = result.total
    }

    const removeItem = (product) => {
      if (props.transaction.items.length === 1) {
        emit('callback', {
          status: 'Gagal',
          message: 'Tidak dapat menghapus, minimal satu item tersisa dalam satu transaksi',
          type: 'failed'
        })
        return
      }

      props.transaction.items = props.transaction.items.filter(
        (item) => item.id != product.id
      )

      const result = $h.calculateTotalPrice(props.transaction)
      props.transaction.total = result.total
    }

    return {
      t,
      store,
      route,
      router,
      
      tax,
      isFormLoading,

      onClose,
      changeQuantity,
      calculateTotalPriceItem,
      removeItem
    }
  },
}
</script>
