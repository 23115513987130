<template>
  <h2 class="intro-y mt-2 md:mt-6 text-lg font-medium">Data Warung</h2>
  <ButtonAdd 
    v-if="$h.roleCanManage($h.commissionerRoles(), roleAccess) === false"
    :is-loading="isButtonCreateLoading" @click="addForm" />
  <div class="mt-0 grid grid-cols-12 gap-6 md:mt-5">
    <div class="intro-y col-span-12 mt-2 flex flex-wrap items-center">
      <div class="flex w-full md:w-auto">
        <SearchFilter
          :value="filterData.search"
          placeholder="Cari warung..."
          @search-data="searchFilter"
        />

        <ButtonFilter
          :show-length="true"
          :filter-length="0"
          class="ml-2 md:ml-10"
        />
        <!-- <ButtonFilter
          :show-length="true"
          :filter-length="filterLength"
          @click="openFilter"
          class="ml-2 md:ml-10"
        /> -->
      </div>
      <div class="mx-auto hidden text-slate-500 md:block"></div>
      <div
        v-if="$h.roleCanManage($h.superRoles(), roleAccess)"
        class="flex mt-3 items-center md:mt-0 xl:mt-0 xl:w-auto"
      >
        <ExportCSVComponent @export="download" />
      </div>
    </div>
    <!-- BEGIN: Order Detail Table -->
    <TableComponent
      :show-loading="isLoading"
      :show-title="false"
      :show-search="false"
      :header="tableHeader"
      :meta-data="metaData"
      :is-not-empty="tableData.length > 0"
      class="-mt-4 md:mt-0"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="(user, index) in tableData" :key="user.id" class="intro-x">
        <td class="!py-3.5">
          <div class="bg-gray-50 font-bold p-2 text-emerald-700 rounded-xl w-12 text-center">
            {{ (metaData.page !== 1 ? (metaData.page - 1) * metaData.take : 0) + index + 1 }}
          </div>
        </td>
        <!-- <td class="w-10">
            <input class="form-check-input" type="checkbox" />
          </td> -->
        <td class="!py-3.5">
          <div class="flex items-center">
            <div class="image-fit zoom-in h-9 w-9">
              <ImageLoader
                :image="user.avatar"
                :alt="`${user.first_name} ${user.last_name}`"
                rounded-class="rounded-full"
              />
            </div>
          </div>
        </td>
        <td class="cursor-pointer" @click="customerPreview(user)">
          <div class="flex">
            <a href="#" class="whitespace-nowrap font-medium"
              >{{ user.first_name }} {{ user.last_name }}</a
            >
            <div class="mt-0.5 whitespace-nowrap text-xs text-slate-500">
              {{ user.username }}
            </div>
          </div>
        </td>
        <td class="">
          {{ user.phone !== '' ? user.phone : '-' }}
        </td>
        <td class="text-xs text-slate-600 dark:text-slate-500">
          {{ user.email !== '' ? user.email : '-' }}
        </td>
        <td class="text-center capitalize">
          <span>{{ user.type }}</span>
        </td>
        <!-- <td class="w-40">
            <div
              class="flex items-center justify-center"
              :class="{
                'text-success': faker.trueFalse[0],
                'text-danger': !faker.trueFalse[0],
              }"
            >
              <CheckSquareIcon class="mr-2 h-4 w-4" />
              {{ faker.trueFalse[0] ? 'Active' : 'Inactive' }}
            </div>
          </td> -->
        <td class="table-report__action w-32">
          <div class="flex items-center justify-center text-success">
            <!-- <a
              class="flex items-center text-secondary"
              href="javascript:;"
              @click="customerPreview(user)"
            >
              <EyeIcon class="mr-1 h-4 w-4" />
            </a> -->
            <a
              v-if="$h.roleCanManage($h.commissionerRoles(), roleAccess) === false"
              class="ml-3 flex items-center"
              href="javascript:;"
              @click="editForm(user)"
            >
              <LoadingIcon
                v-if="isButtonEditLoading && activeDataId === user.id"
                icon="ball-triangle"
                class="h-5 w-5"
              />
              <div v-else class="flex items-center">
                <CheckSquareIcon class="mr-1 h-5 w-5" />
              </div>
            </a>
            <!-- <a
              class="flex items-center text-danger"
              href="javascript:;"
              @click="deleteConfirmationModal = true"
            >
              <Trash2Icon class="mr-1 h-4 w-4" /> Delete
            </a> -->
          </div>
        </td>
      </tr>
    </TableComponent>
    <!-- END: Order Detail Table -->
  </div>

  <!-- DETAIL MODAL -->
  <Modal :show="customerPreviewModal" @hidden="customerPreviewModal = false">
    <ModalBody class="p-0">
      <DetailUser
        v-if="activeCustomer !== undefined"
        :active-user="activeCustomer"
      />
    </ModalBody>
  </Modal>

  <!-- ADD & EDIT MODAL -->
  <Modal size="modal-lg" :show="addEditModal" @hidden="addEditModal = false">
    <ModalHeader>
      <h2 class="mr-auto text-base font-medium">{{ titleModal }}</h2>
    </ModalHeader>
    <ModalBody>
      <div class="w-full">
        <div class="image-fit relative h-20 w-20 flex-none lg:h-24 lg:w-24">
          <template v-if="customerImagePreview != ''">
            <ImageLoader
              :image="customerImagePreview"
              :alt="formData.name"
              rounded-class="rounded-full"
            />
          </template>
          <template v-else>
            <ImageLoader
              :image="formData.image"
              :alt="formData.name"
              rounded-class="rounded-full"
            />
          </template>

          <div
            class="absolute bottom-0 right-0 mb-1 flex cursor-pointer items-center justify-center rounded-full bg-primary p-1 lg:p-2"
            @click="selectFile()"
          >
            <input
              id="hidden-input"
              ref="imageUploader"
              type="file"
              accept="image/*"
              class="hidden"
            />
            <CameraIcon class="h-3 w-3 text-white lg:h-4 lg:w-4" />
          </div>
        </div>
        <div class="mt-8 grid grid-cols-12 gap-4">
          <div class="input-form col-span-6">
            <label
              for="form-firstName"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.firstName') }} (*)
            </label>
            <input
              id="form-firstName"
              v-model.trim="validate.first_name.$model"
              type="text"
              name="firstName"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.first_name.$error }"
              :placeholder="$t('formInput.enterName')"
              :readonly="isEditForm && !$h.roleCanManage([...$h.managementRoles(), ...$h.managementStoreRoles()], roleAccess)"
            />
            <template v-if="validate.first_name.$error">
              <div
                v-for="(error, index) in validate.first_name.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form col-span-6">
            <label
              for="form-lastName"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.lastName') }}
            </label>
            <input
              id="form-lastName"
              v-model.trim="validate.last_name.$model"
              type="text"
              name="lastName"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.last_name.$error }"
              :placeholder="$t('formInput.enterName')"
              :readonly="isEditForm && !$h.roleCanManage([...$h.managementRoles(), ...$h.managementStoreRoles()], roleAccess)"
            />
            <template v-if="validate.last_name.$error">
              <div
                v-for="(error, index) in validate.last_name.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
        </div>

        <div class="input-form mt-3 w-full">
          <label
            for="form-phone"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.phone') }} (*)
          </label>
          <input
            id="form-phone"
            v-model="validate.phone.$model"
            type="text"
            name="phone"
            class="intro-x login__input form-control block py-3 px-4"
            :class="{ 'border-danger': validate.phone.$error }"
            :placeholder="$t('formLabel.phone')"
          />
          <template v-if="validate.phone.$error">
            <div
              v-for="(error, index) in validate.phone.$errors"
              :key="index"
              class="mt-2 text-danger"
            >
              {{ $t(error.$message) }}
            </div>
          </template>
        </div>

        <div class="input-form mt-3 w-full">
          <label
            for="form-email"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.email') }}
          </label>
          <input
            id="form-email"
            v-model.trim="validate.email.$model"
            type="email"
            name="email"
            class="intro-x login__input form-control block py-3 px-4"
            :class="{ 'border-danger': validate.email.$error }"
            :placeholder="$t('formLabel.email')"
          />
          <template v-if="validate.email.$error">
            <div
              v-for="(error, index) in validate.email.$errors"
              :key="index"
              class="mt-2 text-danger"
            >
              {{ $t(error.$message) }}
            </div>
          </template>
        </div>

        <div class="input-form mt-3 w-full">
          <label
            for="form-phone"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            Tipe (*)
          </label>
          <div class="mt-2">
            <Multiselect
              v-model="validate.type.$model"
              value="id"
              value-prop="id"
              label="name"
              track-by="name"
              placeholder="Pilih Tipe"
              :options="typeOptions"
              :searchable="true"
              :classes="multiSelectClasses"
              :class="{ 'border-danger': validate.type.$error }"
            />
          </div>
          <template v-if="validate.type.$error">
            <div
              v-for="(error, index) in validate.type.$errors"
              :key="index"
              class="mt-2 text-danger"
            >
              {{ $t(error.$message) }}
            </div>
          </template>
        </div>

        <div
          class="mt-4 mt-2 border-t border-slate-200/60 pt-4 dark:border-darkmode-400"
        >
          <div class="flex truncate sm:whitespace-normal">
            <p class="text-md font-bold">Informasi Alamat</p>
          </div>
        </div>

        <div class="flex w-full flex-col lg:flex-row">
          <div class="input-form mt-3 w-full">
            <label
              for="form-address-province"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.province') }}
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="validate.province_id.$model"
                value="code"
                value-prop="code"
                label="name"
                track-by="name"
                :placeholder="$t('formLabel.select.province')"
                :options="provinceList"
                :searchable="true"
                :classes="multiSelectClasses"
                :class="{ 'border-danger': validate.province_id.$error }"
                @change="changeAddress('province')"
              />
            </div>
            <template v-if="validate.province_id.$error">
              <div
                v-for="(error, index) in validate.province_id.$errors"
                :key="index"
                class="mt-2 text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form mt-3 w-full lg:ml-4">
            <label
              for="form-address-city"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.city') }}
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="validate.city_id.$model"
                value="code"
                value-prop="code"
                label="name"
                track-by="name"
                :placeholder="$t('formLabel.select.city')"
                :options="cityList"
                :searchable="true"
                :classes="multiSelectClasses"
                :class="{ 'border-danger': validate.city_id.$error }"
                :disabled="cityDisabled === true"
                @change="changeAddress('city')"
              />
            </div>
            <template v-if="validate.city_id.$error">
              <div
                v-for="(error, index) in validate.city_id.$errors"
                :key="index"
                class="mt-2 text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
        </div>

        <div class="flex w-full flex-col lg:flex-row">
          <div class="input-form mt-3 w-full">
            <label
              for="form-address-district"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.district') }}
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="validate.district_id.$model"
                value="code"
                value-prop="code"
                label="name"
                track-by="name"
                :placeholder="$t('formLabel.select.district')"
                :options="districtList"
                :searchable="true"
                :classes="multiSelectClasses"
                :class="{ 'border-danger': validate.district_id.$error }"
                :disabled="districtDisabled === true"
                @change="changeAddress('district')"
              />
            </div>
            <template v-if="validate.district_id.$error">
              <div
                v-for="(error, index) in validate.district_id.$errors"
                :key="index"
                class="mt-2 text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form mt-3 w-full lg:ml-4">
            <label
              for="form-address-village"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.village') }}
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="validate.village_id.$model"
                value="code"
                value-prop="code"
                label="name"
                track-by="name"
                :placeholder="$t('formLabel.select.village')"
                :options="villageList"
                :searchable="true"
                :classes="multiSelectClasses"
                :class="{ 'border-danger': validate.village_id.$error }"
                :disabled="villageDisabled === true"
                @change="changeAddress('village')"
              />
            </div>
            <template v-if="validate.village_id.$error">
              <div
                v-for="(error, index) in validate.village_id.$errors"
                :key="index"
                class="mt-2 text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
        </div>

        <div class="flex w-full flex-col lg:flex-row">
          <div class="input-form mt-3 w-full">
            <label
              for="form-address-postal-code"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.postalCode') }}
            </label>
            <div class="mt-2">
              <input
                id="form-postalCode"
                v-model.trim="validate.postal_code.$model"
                type="number"
                name="postalCode"
                class="intro-x login__input form-control block py-3 px-4"
                :class="{ 'border-danger': validate.postal_code.$error }"
                :placeholder="$t('formLabel.postalCode')"
              />
              <template v-if="validate.postal_code.$error">
                <div
                  v-for="(error, index) in validate.postal_code.$errors"
                  :key="index"
                  class="mt-2 text-danger"
                >
                  {{ $t(error.$message) }}
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="mt-3 grid grid-cols-12 gap-4">
          <div class="input-form col-span-6">
            <label
              for="form-longitude"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.longitude') }}
            </label>
            <input
              id="form-longitude"
              v-model.trim="validate.longitude.$model"
              type="text"
              name="longitude"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.longitude.$error }"
              :placeholder="$t('formInput.enterName')"
            />
            <template v-if="validate.longitude.$error">
              <div
                v-for="(error, index) in validate.longitude.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form col-span-6">
            <label
              for="form-latitude"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.latitude') }}
            </label>
            <input
              id="form-latitude"
              v-model.trim="validate.latitude.$model"
              type="text"
              name="latitude"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.latitude.$error }"
              :placeholder="$t('formInput.enterName')"
            />
            <template v-if="validate.latitude.$error">
              <div
                v-for="(error, index) in validate.latitude.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
        </div>

        <div class="input-form mt-3 w-full">
          <label
            for="form-address"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.address') }} (*)
          </label>

          <textarea
            id="form-address"
            v-model.trim="validate.detail_address.$model"
            type="text"
            name="address"
            class="intro-x login__input form-control block py-3 px-4"
            :class="{ 'border-danger': validate.detail_address.$error }"
            :placeholder="$t('formLabel.address')"
          ></textarea>

          <template v-if="validate.detail_address.$error">
            <div
              v-for="(error, index) in validate.detail_address.$errors"
              :key="index"
              class="mt-2 text-danger"
            >
              {{ $t(error.$message) }}
            </div>
          </template>
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <button type="button" class="btn bg-gradient-to-r from-teal-700 to-primary text-white px-8" @click="addData">
        <template v-if="isFormLoading === true">
          <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
        </template>
        Simpan
      </button>
    </ModalFooter>
  </Modal>

  <!-- DELETE MODAL -->
  <Modal :show="deleteModal" @hidden="cancelDelete">
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5">Apakah anda yakin ingin menghapus data ini ?</div>
        <div class="mt-2 text-lg text-slate-600">
          {{ activeData.first_name }} {{ activeData.last_name }}
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          class="btn btn-outline-secondary mr-4 w-24"
          @click="cancelDelete"
        >
          Batal
        </button>
        <button type="button" class="btn btn-danger w-32" @click="deleteData">
          <template v-if="isFormLoading === true">
            <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
          </template>
          Hapus
        </button>
      </div>
    </ModalBody>
  </Modal>

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">
        {{ message }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
  helpers,
  required,
  minLength,
  maxLength,
  email,
  integer,
  url,
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import { resizeImg } from '@/plugins/pica'
import globalMixin from '@/mixins/global.js'
import useEmitter from '@/composables/useEmitter'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import Multiselect from '@vueform/multiselect'
import ButtonAdd from '@/components/button/ButtonAdd.vue'
import DetailUser from '@/components/detail-user/Main.vue'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'
import SearchFilter from '@/components/filter/Search.vue'
import ButtonFilter from '@/components/button/ButtonFilter.vue'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Currency,
    ButtonAdd,
    Multiselect,
    DetailUser,
    ExportCSVComponent,
    SearchFilter,
    ButtonFilter
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const emitter = useEmitter()

    const activeUser = reactive({})
    const roleAccess = ref('')
    const superRoles = ref([
      'SUPERADMIN',
      'CEO',
      'HEAD_TECHNOLOGY',
      'HEAD_OPERATION',
      'HEAD_SALES',
      'HEAD_FINANCE',
    ])

    const customerPreviewModal = ref(false)
    const activeCustomer = reactive({})

    const isFormLoading = ref(false)
    const isLoading = ref(false)
    const isButtonCreateLoading = ref(false)
    const isButtonEditLoading = ref(false)

    const provincePage = ref(1)
    const cityPage = ref(1)
    const districtPage = ref(1)
    const villagePage = ref(1)

    const cityDisabled = ref(true)
    const districtDisabled = ref(true)
    const villageDisabled = ref(true)

    const provinceList = ref([])
    const cityList = ref([])
    const districtList = ref([])
    const villageList = ref([])

    const timer = ref(undefined)

    const customerImagePreview = ref('')
    const imageFile = ref(null)

    const imagePreviewModal = ref(false)
    const addEditModal = ref(false)
    const deleteModal = ref(false)
    const isEditForm = ref(false)

    const status = ref('')
    const message = ref('')

    const typeOptions = ref([
      {
        id: 'R1',
        name: 'RI',
      },
      {
        id: 'R2',
        name: 'R2',
      },
      {
        id: 'R3',
        name: 'R3',
      },
      {
        id: 'MODERN_MARKET',
        name: 'Modern Market',
      },
    ])

    const titleModal = ref('')

    const tableHeader = ref([
      {
        item: 'NO',
        customClass: '',
      },
      {
        item: 'AVATAR',
        customClass: '',
      },
      {
        item: 'NAMA LENGKAP',
        customClass: '',
      },
      {
        item: 'NO. TELEPON',
        customClass: '',
      },
      {
        item: 'EMAIL',
        customClass: '',
      },
      {
        item: 'TYPE',
        customClass: 'text-center',
      },
      // {
      //   item: 'USERNAME',
      //   customClass: '',
      // },
      {
        item: 'AKSI',
        customClass: 'text-center',
      },
    ])

    let activeDataId = ref(null)

    let tableData = ref([])

    let metaData = reactive({
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 10,
      search: '',
      order: 'ASC',
      type: null,
    })

    const activeData = reactive({
      first_name: '',
      last_name: '',
      type: '',
      email: '',
      phone: '',
      // username: '',
      gender: '',
      province_id: '',
      city_id: '',
      district_id: '',
      village_id: '',
      postal_code: '',
      longitude: '',
      latitude: '',
      detail_address: '',
    })

    const formData = reactive({
      first_name: '',
      last_name: '',
      type: '',
      email: '',
      phone: '',
      // username: '',
      gender: '',
      province_id: '',
      city_id: '',
      district_id: '',
      village_id: '',
      postal_code: '',
      longitude: '',
      latitude: '',
      detail_address: '',
    })

    const firstNameRequired = helpers.withMessage(
      'formError.firstName.required',
      required
    )
    const emailRequired = helpers.withMessage(
      'formError.email.required',
      required
    )
    const emailValid = helpers.withMessage('formError.email.validEmail', email)
    const phoneRequired = helpers.withMessage(
      'formError.phone.required',
      required
    )
    const phoneValid = helpers.withMessage(
      'formError.phone.validPhone',
      integer
    )
    const phoneMinLength = helpers.withMessage(
      'formError.phone.minLength',
      minLength(9)
    )
    const phoneMaxLength = helpers.withMessage(
      'formError.phone.maxLength',
      maxLength(13)
    )
    const typeRequired = helpers.withMessage(
      'formError.customer_type.required',
      required
    )
    const addressRequired = helpers.withMessage(
      'formError.address.required',
      required
    )
    const postalCodeRequired = helpers.withMessage(
      'formError.postalCode.required',
      required
    )

    let rules = reactive({
      first_name: {
        firstNameRequired,
      },
      last_name: {},
      type: { typeRequired },
      email: {
        // emailRequired,
        emailValid,
      },
      phone: {
        phoneRequired,
        phoneMinLength,
        phoneMaxLength,
        phoneValid,
      },
      // username: {},
      gender: {},
      province_id: {},
      city_id: {},
      district_id: {},
      village_id: {},
      longitude: {},
      latitude: {},
      postal_code: {
        // postalCodeRequired,
      },
      detail_address: {
        addressRequired,
      },
    })

    const validate = useVuelidate(rules, toRefs(formData))

    onMounted(async () => {
      // clearing all event
      emitter.all.clear()

      getAddressProvince()

      getAllData()

      Object.assign(activeUser, store.getters['auth/activeUser'])

      roleAccess.value = activeUser.role_codes
    })

    const getAddressProvince = async () => {
      const queryParameters = {
        order: 'ASC',
        page: provincePage.value,
        take: 20,
        q: '',
      }
      await store
        .dispatch('address/province', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const provinceTemporary = []
            response.data.data.forEach((item) => {
              provinceTemporary.push({
                value: item.code,
                ...item,
              })
            })
            provinceList.value = [...provinceList.value, ...provinceTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('province')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressCity = async () => {
      const queryParameters = {
        order: 'ASC',
        page: cityPage.value,
        take: 50,
        q: '',
        province_code: formData.province_id,
      }
      await store
        .dispatch('address/city', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const cityTemporary = []
            response.data.data.forEach((item) => {
              cityTemporary.push({
                value: item.code,
                ...item,
              })
            })
            if (cityPage.value === 1) {
              cityList.value = []
              cityList.value = [...cityTemporary]
            } else {
              cityList.value = [...cityList.value, ...cityTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('city')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressDistrict = async () => {
      const queryParameters = {
        order: 'ASC',
        page: 1,
        take: 50,
        q: '',
        city_code: formData.city_id,
      }
      await store
        .dispatch('address/district', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const districtTemporary = []
            response.data.data.forEach((item) => {
              districtTemporary.push({
                value: item.code,
                ...item,
              })
            })
            if (districtPage.value === 1) {
              districtList.value = []
              districtList.value = [...districtTemporary]
            } else {
              districtList.value = [...districtList.value, ...districtTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('district')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressVillage = async () => {
      const queryParameters = {
        order: 'ASC',
        page: 1,
        take: 50,
        q: '',
        district_code: formData.district_id,
      }
      await store
        .dispatch('address/village', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const villageTemporary = []
            response.data.data.forEach((item) => {
              villageTemporary.push({
                value: item.code,
                ...item,
              })
            })
            if (villagePage.value === 1) {
              villageList.value = []
              villageList.value = [...villageTemporary]
            } else {
              villageList.value = [...villageList.value, ...villageTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('village')
            }
          }
        })
        .catch((error) => {})
    }

    const loadMores = async (type) => {
      if (type === 'province') {
        provincePage.value += 1
        getAddressProvince()
      } else if (type === 'city') {
        cityPage.value += 1
        getAddressCity()
      } else if (type === 'district') {
        districtPage.value += 1
        getAddressDistrict()
      } else if (type === 'village') {
        villagePage.value += 1
        getAddressVillage()
      }
    }

    const changeAddress = async (type) => {
      setTimeout(() => {
        if (type === 'province') {
          cityPage.value = 1
          formData.city_id = ''
          cityList.value = []
          cityDisabled.value = false

          formData.district_id = ''
          districtList.value = []
          districtDisabled.value = true

          formData.village_id = ''
          villageList.value = []
          villageDisabled.value = true

          if (formData.province_id !== '') {
            getAddressCity()
          }
        } else if (type === 'city') {
          districtPage.value = 1
          formData.district_id = ''
          districtList.value = []
          districtDisabled.value = false

          formData.village_id = ''
          villageList.value = []
          villageDisabled.value = true

          if (formData.city_id !== '') {
            getAddressDistrict()
          }
        } else if (type === 'district') {
          villagePage.value = 1
          formData.village_id = ''
          villageList.value = []
          villageDisabled.value = false

          if (formData.district_id !== '') {
            getAddressVillage()
          }
        }
      }, 200)
    }

    const addData = async () => {
      validate.value.$touch()

      if (validate.value.$invalid == false) {
        isFormLoading.value = true

        const storeId = store.getters['store/storeId']

        const phone =
          formData.phone.slice(0, 1) === '0'
            ? `+62${formData.phone.substring(1)}`
            : formData.phone.slice(0, 1) !== '+'
            ? `+62${formData.phone}`
            : formData.phone

        const payload = new FormData()
        payload.append('store_id', storeId)
        payload.append('avatar', imageFile.value)
        payload.append('first_name', formData.first_name)
        payload.append('last_name', formData.last_name)
        payload.append('email', formData.email)
        payload.append('phone', phone)
        payload.append('type', formData.type)
        payload.append('province_id', formData.province_id)
        payload.append('city_id', formData.city_id)
        payload.append('district_id', formData.district_id)
        payload.append('village_id', formData.village_id)
        payload.append('postal_code', formData.postal_code)
        payload.append('longitude', formData.longitude)
        payload.append('latitude', formData.latitude)
        payload.append('detail_address', formData.detail_address)

        let endpoint = 'storeCustomer/create'

        if (activeDataId.value !== null && activeDataId.value !== '') {
          payload.id = activeDataId.value

          endpoint = 'storeCustomer/update'
        }

        await store
          .dispatch(endpoint, payload)
          .then((response) => {
            console.log('-response', response)
            if (response.statusCode === 200) {
              status.value = response.status
              message.value = response.message

              addEditModal.value = false
              validate.value.$reset()

              // show toast
              setTimeout(() => {
                showToast({
                  success: true,
                })
              }, 200)

              setTimeout(() => {
                getAllData()
              }, 500)
            } else {
              status.value = response.message.status
              message.value = response.message.detail
              // show toast
              setTimeout(() => {
                showToast()
              }, 200)
            }
            isFormLoading.value = false
          })
          .catch((error) => {
            console.log('-error ', error)
            console.log('[-error.response ]', error.response)
            const responseData = error.response.data
            console.log('[-error.responseData ]', responseData)
            isFormLoading.value = false
            status.value = responseData.message.status
            message.value = responseData.message.detail
            // show toast
            setTimeout(() => {
              showToast(false)
            }, 200)
          })
      }
    }

    const deleteData = async () => {
      isFormLoading.value = true
      await store
        .dispatch('storeCustomer/delete', activeDataId.value)
        .then((response) => {
          if (response.statusCode === 200) {
            status.value = response.status
            message.value = response.message

            // show toast
            setTimeout(() => {
              showToast({
                success: true,
              })
            }, 200)

            validate.value.$reset()

            getAllData(true)
          } else {
            status.value = response.message.status
            message.value = response.message.detail
            // show toast
            setTimeout(() => {
              showToast()
            }, 200)
          }
          isFormLoading.value = false
          deleteModal.value = false
        })
        .catch((error) => {
          const responseData = error.response.data
          deleteModal.value = false
          isFormLoading.value = false
          status.value = responseData.message.status
          message.value = responseData.message.detail
          // show toast
          setTimeout(() => {
            showToast(false)
          }, 200)
        })
    }

    const download = async (fileType) => {
      const storeId = store.getters['store/storeId']

      let queryParameters = {
        store_id: storeId,
        file_type: fileType,
        order: filterData.order,
      }

      await store
        .dispatch('storeCustomer/download', queryParameters)
        .then((response) => {
          const filename = response.dispotition.split('"')[1]
          saveAs(response.data, filename)
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const resetForm = () => {
      validate.value.$reset()

      isEditForm.value = false

      activeDataId.value = null

      customerImagePreview.value = ''
      imageFile.value = null

      formData.first_name = ''
      formData.last_name = ''
      formData.email = ''
      formData.phone = ''
      // formData.username = ''
      formData.type = ''
      formData.gender = ''
      formData.province_id = ''
      formData.city_id = ''
      formData.district_id = ''
      formData.village_id = ''
      formData.longitude = ''
      formData.latitude = ''
      formData.postal_code = ''
      formData.detail_address = ''

      cityDisabled.value = true
      districtDisabled.value = true
      villageDisabled.value = true
    }

    const addForm = async () => {
      isButtonCreateLoading.value = true

      resetForm()

      await getAddressProvince()

      titleModal.value = 'Tambah Warung'
      addEditModal.value = true
      isButtonCreateLoading.value = false
    }

    const editForm = async (data) => {
      isButtonEditLoading.value = true

      resetForm()

      activeDataId.value = data.id

      isEditForm.value = true

      if (data.avatar !== null && data.avatar !== '') {
        customerImagePreview.value = data.avatar
      }

      formData.first_name = data.first_name
      formData.last_name = data.last_name
      formData.email = data.email

      formData.phone = data.phone.replace('+62', '0')
      // formData.username = data.username
      formData.gender = data.gender
      formData.type = data.type

      if (data.address !== undefined && data.address !== null) {
        const address = data.address
        console.log('---address ', address)
        formData.province_id = address.province_code
        formData.city_id = address.city_code
        formData.district_id = address.district_code
        formData.village_id = address.village_code
        formData.longitude = address.longitude
        formData.latitude = address.latitude
        formData.postal_code = address.postal_code
        formData.detail_address = address.detail_address

        if (address.province_code !== null) {
          await getAddressProvince()

          cityDisabled.value = false
          await getAddressCity()
        }

        if (address.city_code !== null) {
          districtDisabled.value = false
          await getAddressDistrict()
        }

        if (address.district_code !== null) {
          villageDisabled.value = false
          await getAddressVillage()
        }
      }

      titleModal.value = 'Ubah Warung'
      addEditModal.value = true

      isButtonEditLoading.value = false
    }

    const deleteForm = (data) => {
      activeData.first_name = data.first_name
      activeData.last_name = data.last_name

      activeDataId.value = data.id

      deleteModal.value = true
    }

    const cancelDelete = (data) => {
      activeData.first_name = ''
      activeData.last_name = ''

      activeDataId.value = null

      deleteModal.value = false
    }

    const previewImage = (file) => {
      // no need resize
      if (file.size < 1024) {
        customerImagePreview.value = window.URL.createObjectURL(file)
        imageFile.value = file
      }
      const targetWidth = 1000
      resizeImg(file, targetWidth, (resized) => {
        customerImagePreview.value = window.URL.createObjectURL(resized)
        imageFile.value = resized
      })
    }

    const selectFile = () => {
      const hidden = document.getElementById('hidden-input')
      hidden.click()
      hidden.onchange = (e) => {
        previewImage(e.target.files[0])
      }
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const getAllData = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        tableData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }

      const storeId = store.getters['store/storeId']

      await store
        .dispatch('storeCustomer/getAll', {
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          store_id: storeId,
          q: filterData.search,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            tableData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const changeFilter = (event) => {
      Object.assign(filterData, {
        ...filterData,
        page: Number(event.filterData.page),
        take: Number(event.filterData.take),
      })
      getAllData()
    }

    const searchFilter = (val) => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        filterData.search = val
        getAllData(true)
      }, 500)
    }

    const customerPreview = async (customer) => {
      Object.assign(activeCustomer, customer)
      customerPreviewModal.value = true
    }

    const imagePreview = async (customer) => {
      Object.assign(activeCustomer, customer)
      imagePreviewModal.value = true
    }

    return {
      t,
      n,
      store,
      route,
      router,

      activeUser,

      customerPreviewModal,
      activeCustomer,

      roleAccess,
      superRoles,

      customerImagePreview,
      imageFile,
      selectFile,
      previewImage,

      addEditModal,
      deleteModal,
      imagePreviewModal,

      customerPreview,
      imagePreview,

      isFormLoading,
      isLoading,
      isButtonCreateLoading,
      isButtonEditLoading,
      timer,

      status,
      message,

      titleModal,
      tableHeader,
      tableData,
      metaData,
      filterData,

      formData,
      validate,
      addData,
      resetForm,
      addForm,
      editForm,
      deleteForm,
      isEditForm,

      cityDisabled,
      districtDisabled,
      villageDisabled,

      provinceList,
      cityList,
      districtList,
      villageList,

      changeAddress,

      cancelDelete,
      deleteData,
      download,
      // editData,
      
      activeDataId,
      activeData,
      getAllData,
      typeOptions,

      showToast,
      changeFilter,
      searchFilter,
    }
  },
}
</script>
