<template>
  <div class="report-box rounded-tl-xl rounded-tr-lg rounded-bl-xl rounded-br-3xl shadow-sm" :class="{'md:transform md:duration-200 md:hover:scale-110 md:hover:transform-gpu md:hover:cursor-pointer' : total !== null}">
    <ShimmerCardReport v-if="loading" />
    <div
      v-else
      class="rounded-tl-xl rounded-tr-lg rounded-bl-xl rounded-br-3xl" :class="boxClasses"
    >
      <div class="flex w-full items-center rounded-tl-xl rounded-tr-lg pl-3 pr-1 md:pr-3 md:pl-4" :class="[boxTitleClasses, percentage !== '' ? 'py-[3px] md:py-1.5' : 'py-1.5']">
        <div class="flex min-w-max text-xs md:text-sm font-semibold" :class="[titleClasses, percentage !== '' ? `text-gray-600 md:${titleClasses}` : '']">{{ title }}</div>
        <div v-if="percentage !== ''" class="w-full flex justify-end">
          <Tippy
            tag="div"
            class="report-box__indicator cursor-pointer !font-bold !pl-0 md:!px-2 bg-transparent md:!text-white "
            :class="$h.tippyGetClass(isUp)"
            :content="$h.tippyGetContent(isUp, percentage, gapDate)"
          >
            {{ percentage }}
            <ChevronUpIcon
              v-if="isUp === true"
              class="ml-0.5 h-4 w-4"
            />
            <ChevronDownIcon v-if="isUp === false" class="ml-0.5 h-4 w-4" />
          </Tippy>
        </div>
      </div>
      <div class="text-lg font-medium leading-8 md:text-2xl py-2 px-3 md:px-4 md:py-5">
        <Currency
          :total-in-string="withPercentage ? `${total.toString()}%` : total.toString()"
          :with-prefix="withPrefix"
          class="text-base font-medium leading-8 md:text-2xl"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { helper as $h } from '@/utils/helper'
import Currency from '@/components/Currency.vue'
import ShimmerCardReport from '@/components/shimmer/ShimmerCardReport.vue'

export default {
  name: 'CardReportPercentage',
  components: {
    Currency,
    ShimmerCardReport,
  },
  props: {
    title: {
      type: String,
      default: null,
      required: true,
    },
    total: {
      total: Number,
      default: "",
      required: true,
    },
    percentage: {
      total: String,
      default: "",
      required: false,
    },
    isUp: {
      total: Boolean,
      default: '',
      required: false,
    },
    gapDate: {
      total: String,
      default: '',
      required: false,
    },
    loading: {
      total: Boolean,
      default: false,
      required: false,
    },
    withPrefix: {
      total: Boolean,
      default: false,
      required: false,
    },
    withPercentage: {
      total: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    boxClasses: 'bg-white',
    boxTitleClasses: '',
    titleClasses: 'text-light',
  }),
  watch: {
    isUp(val) {
      this.getClassCard(val)
    },
  },
  mounted() {
    this.getClassCard(this.isUp)
  },
  methods: {
    getClassCard(isUp) {
      switch (isUp) {
        case true:
          // this.boxClasses =
          //   'bg-green-50'
          this.boxTitleClasses = 'bg-gradient-to-r from-green-50/50 to-green-200/70'
          this.titleClasses = 'text-green-500'
          break 
        case false:
          // this.boxClasses =
          //   'bg-red-50'
          this.boxTitleClasses = 'bg-gradient-to-r from-red-50/50 to-red-200/70'
          this.titleClasses = 'text-red-500'
          break
        default:
          // this.boxClasses =
          //   'bg-white'
          this.boxTitleClasses = 'bg-gradient-to-r from-gray-50/50 to-gray-200/70'
          this.titleClasses = 'text-gray-500'
          break
      }
    },
  },
}
</script>
