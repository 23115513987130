<template>
  <div class="mt-0 grid grid-cols-12 gap-6 md:mt-5">
    <div class="intro-y col-span-12 mt-4 md:mt-2 flex flex-wrap items-center">
      <div class="flex w-full md:w-auto">
        <p class="intro-y text-base md:text-lg font-medium">Ringkasan Transaksi</p>
      </div>
      <div class="mx-auto hidden text-slate-500 md:block"></div>
      <div class="relative w-full md:w-max mt-3 text-slate-500 md:mt-0">
        <CalendarIcon
          class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
        />
        <Litepicker
          v-model="filterDate"
          :options="$h.litePickerConfig()"
          class="form-control box pl-10 w-full md:w-56 litepicker-right"
          @change="getSummaryData"
        />
      </div>
    </div>
  </div>
  <div class="mt-3 grid grid-cols-12 gap-3 md:gap-6 md:mt-5">
    <div class="intro-y col-span-12 flex flex-wrap items-center">
      <div class="-mb-2 md:-mb-4 flex w-full md:w-auto">
        <p class="intro-y text-xs md:text-sm italic text-slate-500">Penjualan</p>
      </div>
    </div>
    <div class="intro-y col-span-6 lg:col-span-3">
      <CardReport
        title="Semua"
        :total="reportData.sales_total.total"
        :loading="isSummaryLoading"
        :with-prefix="true"
      />
    </div>
    <div class="intro-y col-span-6 lg:col-span-3">
      <CardReport
        title="Berlangsung"
        :total="reportData.sales_active_total.total"
        :loading="isSummaryLoading"
        :with-prefix="true"
        color="info"
      />
    </div>
    <div class="intro-y col-span-6 lg:col-span-3">
      <CardReport
        title="Selesai"
        :total="reportData.sales_completed_total.total"
        :loading="isSummaryLoading"
        :with-prefix="true"
        color="success"
      />
    </div>
    <div class="intro-y col-span-6 lg:col-span-3">
      <CardReport
        title="Dibatalkan"
        :total="reportData.sales_canceled_total.total"
        :loading="isSummaryLoading"
        :with-prefix="true"
        color="danger"
      />
    </div>

    <div class="intro-y col-span-12 flex flex-wrap items-center">
      <div class="-mb-2 md:-mb-4 flex w-full md:w-auto">
        <p class="intro-y text-xs md:text-sm italic text-slate-500">Transaksi</p>
      </div>
    </div>
    <div class="intro-y col-span-6 lg:col-span-3">
      <CardReport
        title="Semua"
        :total="reportData.transaction_total.total"
        :loading="isSummaryLoading"
      />
    </div>
    <div class="intro-y col-span-6 lg:col-span-3">
      <CardReport
        title="Berlangsung"
        :total="reportData.transaction_active_total.total"
        :loading="isSummaryLoading"
        color="info"
      />
    </div>
    <div class="intro-y col-span-6 lg:col-span-3">
      <CardReport
        title="Selesai"
        :total="reportData.transaction_completed_total.total"
        :loading="isSummaryLoading"
        color="success"
      />
    </div>
    <div class="intro-y col-span-6 lg:col-span-3">
      <CardReport
        title="Dibatalkan"
        :total="reportData.transaction_canceled_total.total"
        :loading="isSummaryLoading"
        color="danger"
      />
    </div>
  </div>

  <div class="mt-4 grid grid-cols-12 gap-2 md:mt-8">
    <div class="intro-y col-span-12 md:flex md:border border-dashed md:mb-4"> </div>
    <div class="intro-y col-span-12 flex">
      <p class="intro-y text-base md:text-lg font-medium">List Transaksi</p>
    </div>
    <div class="intro-y col-span-12 mt-2 flex flex-wrap items-center">
      <div class="flex w-full md:w-auto">
        <SearchFilter
          :value="filterData.search"
          placeholder="Kode transaksi atau warung"
          @search-data="searchFilter"
        />

        <ButtonFilter
          :show-length="true"
          :filter-length="filterLength"
          @click="openFilter"
          class="ml-2 md:ml-10"
        />
      </div>
      <div class="mx-auto hidden text-slate-500 md:block"></div>
      <div class="mt-3 flex items-center md:mt-0 xl:mt-0 xl:w-auto">
        <ExportCSVComponent @export="download" />
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <TableComponent
      :show-loading="isLoading"
      :show-title="false"
      :show-search="false"
      :header="tableHeader"
      :meta-data="metaData"
      :is-not-empty="tableData.length > 0"
      @change-filter="changeFilter($event)"
    >
      <tr
        v-for="(transaction, index) in tableData"
        :key="transaction.id"
        class="intro-x"
      >
        <td class="cursor-pointer" @click="showDetailModal(transaction)">
          <Number
            :index="index"
            :page="metaData.page"
            :take="metaData.take"
          />
        </td>
        <td class="cursor-pointer" @click="showDetailModal(transaction)">
          <Date :date="transaction.created" />
        </td>
        <td class="cursor-pointer text-teal-900 !py-3.5" @click="showDetailModal(transaction)">
          {{ transaction.code }}
        </td>
        <td>
          <Customer :customer="transaction.customer" />
        </td>
        <td class="text-teal-600 font-semibold">
          <Currency :total-in-string="transaction.total.toString()" />
        </td>
        <td class="cursor-pointer" @click="showDetailModal(transaction)">
          <div class="flex justify-center">
            <Status :status="transaction.status" />
          </div>
        </td>
        <td class="table-report__action">
          <div class="flex items-center justify-center">
            <template
              v-if="
                salesOrderChannel.includes(transaction.channel) &&
                ['PAID', 'DELIVERY', 'RECEIVED'].includes(transaction.status)
              "
            >
              <Tippy
                tag="div"
                content="Upload Bukti Pembayaran"
                class="mr-4 cursor-pointer flex items-center text-purple-600"
                :permanent="false"
                @click="uploadDocForm(transaction)"
              >
                <FilePlusIcon class="h-5 w-5" />
              </Tippy>
              <span class="text-gray-300">|</span>
            </template>
            <template
              v-if="
                ['COMPLETED', 'CANCELLED'].includes(transaction.status) ===
                false || (isSuperAdmin && transaction.status !== 'CANCELLED')
              "
            >
              <Tippy
                tag="div"
                content="Ubah Status"
                class="flex cursor-pointer px-0 ml-4 text-teal-800"
                :permanent="false"
                @click="showChangeStatusModal(transaction)"
              >
                <ArrowLeftRightIcon class="h-5 w-5" />
              </Tippy>
            </template>
            <template v-else>-</template>
          </div>
        </td>
      </tr>
    </TableComponent>
    <!-- END: Data List -->

    <!-- <DetailTransaction :show-modal="showDetail" :can-edit="['PENDING', 'PROCESS'].includes(transaction.status) &&
                        roleCanManage($h.superRoles(), roleAccess)" :transaction="transaction" @on-close="showDetail = false" @callback="onCallback" /> -->
    <DetailTransaction
      :show-modal="showDetail"
      :show-base-price="roleCanManage($h.superRoles(), roleAccess)"
      :transaction="transaction"
      :can-edit="false"
      @on-close="showDetail = false"
      @callback="onCallback"
    />

    <!-- UDATE STATUS MODAL -->
    <ChangeStatusTransaction
      :show-modal="changeStatus"
      :is-super-admin="isSuperAdmin"
      :transaction="transaction"
      @on-close="changeStatus = false"
      @callback="onCallback"
      @refresh-data="getAllData()"
    />

    <!-- UDATE DOC MODAL -->
    <Modal
      size="modal-lg"
      :show="uploadDocModal"
      @hidden="uploadDocModal = false"
    >
      <ModalHeader>
        <div class="flex w-full justify-between">
          <div class="flex">
            <h2 class="text-base font-medium">Upload Bukti Pembayaran</h2>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="flex w-full flex-col">
          <div class="w-full">
            <label class="form-label">Dokumen</label>
            <div
              class="rounded-md border-2 border-dashed dark:border-darkmode-400"
              :class="files.length === 0 ? 'py-6' : 'pt-4'"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <div class="flex flex-wrap px-4">
                <div
                  v-for="(file, i) in files"
                  :key="i"
                  class="image-fit zoom-in relative mb-5 mr-5 h-24 w-24 cursor-pointer"
                >
                  <img
                    v-if="file.image !== undefined"
                    class="rounded-md"
                    alt="Gromura - SCM"
                    :src="file.image"
                  />
                  <div
                    v-else-if="file.type === 'application/pdf'"
                    class="flex h-full w-full flex-col justify-center rounded-md bg-orange-100 px-2 text-center text-orange-600"
                  >
                    <span class="text-xs">{{ file.name }}</span>
                    <!-- <span class="text-sm">{{ file.name }}</span> -->
                  </div>
                  <img
                    v-else
                    class="rounded-md"
                    alt="Gromura - SCM"
                    :src="generateThumbnail(file)"
                  />
                  <Tippy
                    tag="div"
                    content="Hapus gambar ini?"
                    class="absolute right-0 top-0 -mr-2 -mt-2 flex h-5 w-5 items-center justify-center rounded-full bg-danger text-white"
                    @click="remove(file)"
                  >
                    <xIcon class="h-4 w-4" />
                  </Tippy>
                </div>
              </div>
              <div
                class="relative flex cursor-pointer flex-col px-4"
                :class="files.length === 0 ? 'py-6' : 'pb-4'"
              >
                <div class="flex items-center">
                  <ImageIcon class="mr-2 h-4 w-4" />
                  <div v-if="isDragging">Lepaskan file disini.</div>
                  <div v-else>
                    <span class="mr-1 text-primary">Unggah file</span>
                    <span class="text-gray-500">atau jatuhkan file disini</span>
                  </div>
                </div>
                <span
                  class="mt-2 block text-xs font-medium italic text-gray-400"
                >
                  Hanya mendukung pdf & gambar
                </span>
                <input
                  id="fileInput"
                  ref="file"
                  multiple
                  type="file"
                  name="file"
                  class="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
                  accept="application/pdf,image/*"
                  @change="onChange"
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn bg-gradient-to-r from-teal-700 to-primary text-white px-8"
          @click="uploadDocData"
        >
          <template v-if="isFormLoading === true">
            <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
          </template>
          Simpan
        </button>
      </ModalFooter>
    </Modal>

    <!-- FILTER MODAL -->
    <Modal :show="filterModal" @hidden="filterModal = false">
      <ModalHeader>
        <div class="flex justify-between w-full items-center">
          <h2 class="mr-auto text-lg font-bold">Filter</h2>
          <XIcon class="h-5 w-5 text-red-500 cursor-pointer" @click="filterModal = false" />
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="flex w-full flex-col">
          <span class="text-sm font-bold">Urutkan</span>
          <div class="mt-4 flex w-full flex-col">
            <span class="text-xs">Waktu Dibuat</span>
            <div
              v-for="sort in sortingOptions"
              :key="sort.id"
              class="form-check mt-2 flex justify-between border-b py-3 cursor-pointer"
              @click="checkFilter(sort.value, 'order')"
            >
              <div class="flex">
                <ArrowsUpDownIcon class="h-4" />
                <label class="form-check-label ml-2" :for="sort.id">{{
                  sort.label
                }}</label>
              </div>
              <input
                :id="sort.id"
                v-model="filterData.order"
                :value="sort.value"
                class="form-check-input"
                type="radio"
                name="filter-sorting"
                />
                <!-- @input="checkFilter($event, 'order')" -->
            </div>
          </div>

          <span class="mt-6 text-sm font-bold">Berdasarkan</span>
          <div class="mt-4 flex w-full flex-col">
            <span class="text-xs">Rentang Waktu</span>
            <div class="relative mt-1 w-full text-slate-500 sm:ml-auto">
              <CalendarIcon
                class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
              />
              <Litepicker
                v-model="filterData.filter_date"
                :options="$h.litePickerConfig()"
                class="form-control box w-full border border-gray-300 pl-8"
              />
            </div>
          </div>
          <div
            v-if="isCashierSales === false"
            class="mt-4 flex w-full flex-col"
          >
            <span class="text-xs">Sumber</span>
            <div class="mt-1 flex w-full flex-wrap">
              <StatusOption
                v-for="item in sourceOptions"
                :id="item.id"
                :key="item.id"
                :name="item.name"
                :selected="item.selected"
                class="mt-1"
                @click="selectSource(item)"
              />
            </div>
          </div>
          <div class="mt-4 flex w-full flex-col">
            <span class="text-xs">Status</span>
            <div class="mt-1 flex w-full flex-wrap">
              <StatusOption
                v-for="item in statusOptions"
                :id="item.id"
                :key="item.id"
                :name="item.status"
                :selected="item.selected"
                class="mt-1"
                @click="selectStatus(item)"
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          title="Terapkan Filter"
          color="gradient"
          title-classes="w-full"
          @click="applyFilter"
        />
      </ModalFooter>
    </Modal>
  </div>

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">
        {{ message }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import { resizeImg } from '@/plugins/pica'
import dayjs from 'dayjs'
import globalMixin from '@/mixins/global.js'
import useEmitter from '@/composables/useEmitter'
import { ArrowsUpDownIcon } from '@heroicons/vue/24/solid'
import Currency from '@/components/Currency.vue'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Status from '@/components/status/Main.vue'
import StatusOption from '@/components/status-option/Main.vue'
import Document from '@/components/document/Main.vue'
import Multiselect from '@vueform/multiselect'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'
import CardReport from '@/components/card/CardReport.vue'
import SearchFilter from '@/components/filter/Search.vue'
import ButtonFilter from '@/components/button/ButtonFilter.vue'
import DetailTransaction from '@/components/transaction/Detail.vue'
import ChangeStatusTransaction from '@/components/transaction/ChangeStatus.vue'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Status,
    StatusOption,
    Currency,
    ArrowsUpDownIcon,
    Document,
    Multiselect,
    ExportCSVComponent,
    DetailTransaction,
    ChangeStatusTransaction,
    CardReport,
    ButtonFilter,
    SearchFilter,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const emitter = useEmitter()

    const activeUser = reactive({})
    const roleAccess = ref('')

    let isSuperAdmin = ref(false)
    let isCashierSales = ref(false)
    let isCashier = ref(false)
    let isSales = ref(false)

    // const dropzoneValidationRef = ref()

    let reportData = reactive({
      sales_total: {
        total: 0,
      },
      sales_active_total: {
        total: 0,
      },
      sales_completed_total: {
        total: 0,
      },
      sales_canceled_total: {
        total: 0,
      },
      transaction_total: {
        total: 0,
      },
      transaction_active_total: {
        total: 0,
      },
      transaction_completed_total: {
        total: 0,
      },
      transaction_canceled_total: {
        total: 0,
      },
    })
    let transaction = reactive({})

    const tax = ref(11)
    const subTotal = ref(0)
    const total = ref(0)

    const isDragging = ref(false)
    let files = ref([])

    const isFormLoading = ref(false)
    const isLoading = ref(false)
    const isSummaryLoading = ref(false)
    const timer = ref(undefined)

    const changeStatus = ref(false)
    const uploadDocModal = ref(false)
    const filterModal = ref(false)
    const showDetail = ref(false)

    const status = ref('')
    const message = ref('')

    const titleModal = ref('')

    const tableHeader = ref([
      {
        item: 'NO',
        customClass: '',
      },
      {
        item: 'DIBUAT',
        customClass: 'w-created',
      },
      {
        item: 'KODE',
        customClass: '',
      },
      {
        item: 'CUSTOMER',
        customClass: '',
      },
      {
        item: 'TOTAL HARGA',
        customClass: '',
      },
      {
        item: 'STATUS',
        customClass: 'text-center',
      },
      {
        item: 'AKSI',
        customClass: 'text-center',
      },
    ])

    const sortingOptions = ref([
      {
        id: 1,
        label: 'A-Z',
        value: 'ASC',
      },
      {
        id: 2,
        label: 'Z-A',
        value: 'DESC',
      },
    ])

    const sourceOptions = ref([
      {
        id: 'POS',
        name: 'Point of Sale',
        selected: false,
      },
      {
        id: 'SO',
        name: 'Sales Order',
        selected: false,
      },
    ])

    const statusOptions = ref([
      // {
      //   id: '',
      //   status: 'Semua',
      //   selected: false
      // },
      {
        id: 'PENDING',
        status: 'Tertunda',
        selected: false,
      },
      {
        id: 'PROCESS',
        status: 'Diproses',
        selected: false,
      },
      {
        id: 'DELIVERY',
        status: 'Dikirim',
        selected: false,
      },
      // {
      //   id: 'PAID',
      //   status: 'Dibayar',
      //   selected: false
      // },
      {
        id: 'RECEIVED',
        status: 'Diterima',
        selected: false
      },
      {
        id: 'COMPLETED',
        status: 'Selesai',
        selected: false,
      },
      {
        id: 'CANCELLED',
        status: 'Dibatalkan',
        selected: false,
      },
    ])

    let tableData = ref([])

    let metaData = reactive({
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 10,
      search: '',
      order: sortingOptions.value[0].value,
      source: null,
      status: null,
      filter_date: '',
    })

    let filterLength = ref(1);
    const filterDate = ref()

    watch(filterDate, (currentValue, oldValue) => {
      getSummaryData()
    })

    onMounted(async () => {
      // clearing all event
      emitter.all.clear()

      Object.assign(activeUser, store.getters['auth/activeUser'])

      roleAccess.value = activeUser.role_codes

      isSuperAdmin.value = $h.roleCanManage(
        ['SUPERADMIN'],
        roleAccess.value
      )
      isCashierSales.value = $h.roleCanManage(
        ['CASHIER', 'SALES'],
        roleAccess.value
      )
      isCashier.value = $h.roleCanManage(['CASHIER'], roleAccess.value)
      isSales.value = $h.roleCanManage(['SALES'], roleAccess.value)

      emitter.on('update-data', (store) => {
        getSummaryData()
        getAllData(true)
      })

      const storeId = store.getters['store/storeId']

      if (storeId === null) {
        setTimeout(() => {
          getSummaryData()
          getAllData()
        }, 2000)
      } else {
        getSummaryData()
        getAllData()
      }
    })

    const getSummaryData = async () => {
      isSummaryLoading.value = true
      const storeId = store.getters['store/storeId']

      let queryParameters = {
        store_id: storeId,
      }
      const dateRange = filterDate.value.split(' - ')
      if (dateRange.hasOwnProperty(0)) queryParameters.start_date = dateRange[0]
      if (dateRange.hasOwnProperty(1)) queryParameters.end_date = dateRange[1]

      await store
        .dispatch('transaction/summary', queryParameters)
        .then((response) => {
          const responseData = response.data

          Object.assign(reportData, responseData)
        
          isSummaryLoading.value = false
        })
        .catch((e) => {
          isSummaryLoading.value = false
          console.log(e)
        })
    }

    const getAllData = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        tableData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }

      const storeId = store.getters['store/storeId']
      const dateRange = filterData.filter_date.split(' - ')

      let queryParameters = {
        store_id: storeId,
        order: filterData.order,
        page: filterData.page,
        take: filterData.take,
      }
      if (isCashierSales.value === true) queryParameters.created_by = activeUser.id
      if (isCashier.value === true) queryParameters.pos_only = true
      if (isSales.value === true) queryParameters.sales_only = true
      if (filterData.source && filterData.source !== null)
        queryParameters.source = filterData.source
      if (filterData.status && filterData.status !== null)
        queryParameters.status = filterData.status
      if (dateRange.hasOwnProperty(0))
        queryParameters.start_date = dateRange[0]
      if (dateRange.hasOwnProperty(1)) queryParameters.end_date = dateRange[1]
      
      if (filterData.search) {
        queryParameters.q = filterData.search
        delete queryParameters.start_date
        delete queryParameters.end_date
      }

      await store
        .dispatch('transaction/getByStore', queryParameters)
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            tableData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const download = async (fileType) => {
      const storeId = store.getters['store/storeId']

      const dateRange = filterData.filter_date.split(' - ')

      let queryParameters = {
        store_id: storeId,
        file_type: fileType,
        order: filterData.order,
      }
      if (isCashierSales.value === true) queryParameters.created_by = activeUser.id
      if (isCashier.value === true) queryParameters.pos_only = true
      if (isSales.value === true) queryParameters.sales_only = true
      if (filterData.source && filterData.source !== null)
        queryParameters.source = filterData.source
      if (filterData.status && filterData.status !== null)
        queryParameters.status = filterData.status
      if (filterData.search) queryParameters.q = filterData.search
      if (dateRange.hasOwnProperty(0)) queryParameters.start_date = dateRange[0]
      if (dateRange.hasOwnProperty(1)) queryParameters.end_date = dateRange[1]

      await store
        .dispatch('transaction/download', queryParameters)
        .then((response) => {
          const filename = response.dispotition.split('"')[1]
          saveAs(response.data, filename)
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const openFilter = () => {
      filterModal.value = true
    }

    const checkFilter = (value, _target) => {
      if (_target === 'order') {
        filterData.order = value
      }
    }

    const applyFilter = () => {
      filterModal.value = false
      getAllData()
    }

    const changeFilter = (event) => {
      Object.assign(filterData, {
        ...filterData,
        page: Number(event.filterData.page),
        take: Number(event.filterData.take),
      })
      getAllData()
    }

    const searchFilter = (val) => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        filterData.search = val
        getAllData(true)
      }, 500)
    }

    const selectSource = (option) => {
      option.selected = !option.selected

      let sources = []
      if (filterData.source !== null) {
        sources = filterData.source.split(',')
      }

      if (option.selected === false && sources.length > 0) {
        sources = sources.filter((e) => e !== option.id)
      } else {
        sources.push(option.id)
      }

      if (sources.length > 0) {
        filterData.source = sources.toString()
      } else {
        filterData.source = null
      }

      calculateFilterTotal()
    }

    const selectStatus = (option) => {
      option.selected = !option.selected

      let statuses = []
      if (filterData.status !== null) {
        statuses = filterData.status.split(',')
      }

      if (option.selected === false && statuses.length > 0) {
        statuses = statuses.filter((e) => e !== option.id)
      } else {
        statuses.push(option.id)
      }

      if (statuses.length > 0) {
        filterData.status = statuses.toString()
      } else {
        filterData.status = null
      }

      calculateFilterTotal()
    }

    const calculateFilterTotal = () => {
      const sortByTotal = 1;
      const sourceTotal = filterData.source === null ? 0 : filterData.source.split(",").length
      const statusTotal = filterData.status === null ? 0 : filterData.status.split(",").length

      filterLength.value = sortByTotal + sourceTotal + statusTotal;
    }

    const showDetailModal = async (trx) => {
      showDetail.value = true
      Object.assign(transaction, trx)
    }

    const showChangeStatusModal = async (trx) => {
      changeStatus.value = true
      Object.assign(transaction, trx)
    }

    const onChange = (e) => {
      files.value = [...files.value, ...e.target.files]
    }

    const generateThumbnail = (file) => {
      let fileSrc = URL.createObjectURL(file)
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc)
      }, 1000)
      return fileSrc
    }

    const remove = (file) => {
      const index = files.value.indexOf(file)
      files.value.splice(index, 1)
    }
    const dragover = (e) => {
      e.preventDefault()
      isDragging.value = true
    }
    const dragleave = () => {
      isDragging.value = false
    }
    const drop = (e) => {
      e.preventDefault()

      files.value = [...files.value, ...e.dataTransfer.files]

      isDragging.value = false
    }

    const uploadDocForm = async (data) => {
      resetForm()
      transaction.value = data

      uploadDocModal.value = true
    }

    const resetForm = () => {
      transaction.value = {}
      files.value = []
    }

    const uploadDocData = async () => {
      if (files.value.length > 0) {
        isFormLoading.value = true

        let totalUploaded = 0
        files.value.map(async (file) => {
          const payloadDoc = {
            transaction_id: transaction.value.id,
            type: 'PAYMENT', // INVOICE, PAYMENT, DOCUMENT, DRAFT
            document: file,
          }
          await store
            .dispatch('transaction/upload', payloadDoc)
            .then((response) => {
              totalUploaded += 1
            })
            .catch((error) => {
              const responseData = error.response.data

              status.value = responseData.message.status
              message.value = responseData.message.detail

              // show toast
              setTimeout(() => {
                showToast()
              }, 200)
            })
        })

        setTimeout(() => {
          isFormLoading.value = false
          uploadDocModal.value = false
        }, 1000 * files.value.length)

        if (files.value.length === totalUploaded.length) {
          isFormLoading.value = false
          uploadDocModal.value = false
        }
      }
    }

    const onCallback = (callback) => {
      status.value = callback.status
      message.value = callback.message
      // show toast
      setTimeout(() => {
        showToast(callback.type)
      }, 200)
    }

    const showToast = (type) => {
      let toastId = '#failed-notification'
      if (type === 'success') {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    return {
      t,
      n,
      store,
      route,
      router,

      roleAccess,

      showDetail,
      showDetailModal,
      changeStatus,
      showChangeStatusModal,

      uploadDocForm,
      uploadDocModal,
      uploadDocData,

      isFormLoading,
      isLoading,
      isSummaryLoading,
      timer,

      resetForm,
      status,
      message,

      titleModal,

      tableHeader,
      tableData,
      metaData,

      openFilter,
      checkFilter,
      applyFilter,
      searchFilter,
      changeFilter,
      selectSource,
      selectStatus,

      isSuperAdmin,
      isCashierSales,

      filterModal,
      sortingOptions,
      sourceOptions,
      statusOptions,

      filterData,
      filterDate,
      filterLength,

      getSummaryData,
      getAllData,
      download,

      isDragging,
      files,

      onChange,
      generateThumbnail,
      remove,
      dragover,
      dragleave,
      drop,

      showToast,

      tax,
      subTotal,
      total,

      transaction,
      reportData,
      onCallback
    }
  },
}
</script>
