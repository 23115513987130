<template>
  <button class="btn box shadow-sm" @click="onClick">
    <FilterIcon class="h-4 w-4" />
    <div
      v-if="showLength"
      class="flex absolute z-50 -mr-8 mb-9 h-4 w-4 cursor-pointer items-center justify-center rounded-full bg-red-500 text-white"
    >
      <span class="text-xs">{{ filterLength }}</span>
    </div>
  </button>
</template>

<script>
export default {
  name: 'ButtonFilter',
  props: {
    showLength: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterLength: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>
