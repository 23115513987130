<template>
  <div class="box p-5 w-full">
    <div class="flex w-full mb-4">
      <span class="text-sm font-semibold">{{  title }}</span>
    </div>
    <div>
      <!-- v-if="type === 'line'" -->
      <LineChart
        :height="275"
        :chart-type="chartType"
        :item-name="prefix"
        :labels="chartLabels"
        :data="chartValues"
        :show-legend="showLegend"
        :show-currency-prefix="showCurrencyPrefix"
      />
      <!-- <BarChart
        v-else
        :height="275"
        :item-name="prefix"
        :labels="chartLabels"
        :data="chartValues"
        :show-legend="showLegend"
      /> -->
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import { helper as $h } from '@/utils/helper'
import BarChart from '@/components/vertical-bar-chart/Main.vue'
import LineChart from '@/components/line-chart/Main.vue'
import { colors } from '@/utils/colors'

export default {
  name: 'CardReportChart',
  components: {
    BarChart,
    LineChart,
  },
  props: {
    chartType: {
      type: String,
      default: "bar",
      required: false,
    },
    title: {
      type: String,
      default: null,
      required: true,
    },
    prefix: {
      type: [Array, String],
      default: '',
    },
    labels: {
      type: Array,
      default: () => {
        return []
      },
    },
    data: {
      type: Array,
      default: () => {
        return []
      },
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    showCurrencyPrefix: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),
  setup(props, { emit }) {
    // let chartLabels = ref([])
    // let chartValues = ref([])

    // onMounted(async () => {
    //   if (props.labels !== undefined && props.labels.length > 0) {
    //     chartLabels.value = props.labels
    //     chartValues.value = props.data
    //   } else {
    //     chartLabels.value = props.data.map((item) => item.name)
    //     chartValues.value = props.data.map((item) => item.total)
    //   }
    // })

    const chartType = computed(() => {
      return props.chartType;
    })

    const chartLabels = computed(() => {
      if (props.labels !== undefined && props.labels.length > 0) {
        return props.labels
      }
      return props.data.map((item) => item.name);
    })

    const chartValues = computed(() => {
      if (props.labels !== undefined && props.labels.length > 0) {
        return  props.data
      }
      return props.data.map((item) => item.total);
    })

    return {
      chartType,
      chartLabels,
      chartValues
    }
  }
}
</script>
