// initial state
const state = () => ({
  isAuthenticated: false,
  user: {
    fullName: null,
    instituteId: null,
    instituteName: '',
    roleId: '',
    roleName: '',
  }
})

// getters
const getters = {
  headerMode(state) {
    return state.headerMode
  },
  headerData(state) {
    return state.headerData
  },
}

// actions
const actions = {}

// mutations
const mutations = {
  setHeaderMode(state, val) {
    state.headerMode = val
  },
  setHeaderData(state, val) {
    state.headerData = val
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
