<template>
  <h2 class="intro-y mt-2 md:mt-6 text-lg font-medium">Kelurahan | Desa</h2>
  <div class="mt-0 grid grid-cols-12 gap-6 md:mt-5">
    <div class="intro-y col-span-12 mt-2 flex flex-wrap items-center">
      <div class="flex w-full md:w-auto">
        <div class="relative w-full text-slate-500 md:w-48 h-9.5">
          <input
            v-model="filterData.search"
            type="text"
            class="form-control box w-full pr-8 md:w-56 h-10"
            placeholder="Cari kelurahan atau desa..."
            @input="searchFilter()"
          />
          <SearchIcon
            class="absolute inset-y-0 right-0 my-auto mr-4 h-4 w-4 md:-mr-4"
          />
        </div>
        <button class="btn box ml-2 shadow-sm md:ml-10" @click="openFilter">
          <FilterIcon class="h-4 w-4" />
        </button>
      </div>
      <div class="mx-auto hidden text-slate-500 md:block"></div>
      <div class="mt-3 flex items-center md:mt-0 xl:mt-0 xl:w-auto">
        <!-- <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white mr-2 text-white">
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button> -->
        <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white  text-white" disabled @click="download">
          <template v-if="isDownloadLoading === true">
            <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
          </template>
          <FileTextIcon class="mr-2 h-4 w-4" /> Export ke Excel
        </button>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <TableComponent
      :show-loading="isLoading"
      :show-title="false"
      :show-search="false"
      :header="tableHeader"
      :meta-data="metaData"
      :is-not-empty="tableData.length > 0"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="item in tableData" :key="item.id" class="intro-x">
        <!-- <td class="w-10">
            <input class="form-check-input" type="checkbox" />
          </td> -->
        <td class="capitalize">
          {{ item.code }}
        </td>
        <td>
          {{ item.name }}
        </td>
        <td class="text-xs text-slate-600 dark:text-slate-500">
          {{ item.longitude }}
        </td>
        <td class="text-xs text-slate-600 dark:text-slate-500">
          {{ item.latitude }}
        </td>
        <td class="text-xs text-slate-600 dark:text-slate-500">
          {{ item.alt_name }}
        </td>
        <td class="text-xs text-slate-600 dark:text-slate-500">
          {{ item.postal_code }}
        </td>
        <td class="table-report__action w-32">
          <div class="flex items-center justify-center text-success">
            <a class="mr-3 flex items-center" href="javascript:;">
              <CheckSquareIcon class="mr-1 h-4 w-4" /> Edit
            </a>
            <!-- <a
              class="flex items-center text-danger"
              href="javascript:;"
              @click="deleteConfirmationModal = true"
            >
              <Trash2Icon class="mr-1 h-4 w-4" /> Delete
            </a> -->
          </div>
        </td>
      </tr>
    </TableComponent>
    <!-- END: Data List -->
  </div>

  <!-- ADD & EDIT MODAL -->
  <Modal :show="filterModal" @hidden="filterModal = false">
    <ModalHeader>
      <div class="flex w-full justify-between">
        <div class="flex">
          <h2 class="mr-auto text-lg font-bold">Filter</h2>
        </div>
        <div class="flex">
          <h2
            class="ml-auto cursor-pointer text-lg font-bold text-danger"
            @click="resetFilter"
          >
            Reset
          </h2>
        </div>
      </div>
    </ModalHeader>
    <ModalBody>
      <div class="flex w-full flex-col">
        <span class="text-sm font-bold">Urutkan</span>
        <div class="mt-4 flex w-full flex-col">
            <span class="text-xs">Waktu Dibuat</span>
            <div
              v-for="sort in sortingOptions"
              :key="sort.id"
              class="form-check mt-2 flex justify-between border-b py-3 cursor-pointer"
              @click="checkFilter(sort.value, 'order')"
            >
              <div class="flex">
                <ArrowsUpDownIcon class="h-4" />
                <label class="form-check-label ml-2" :for="sort.id">{{
                  sort.label
                }}</label>
              </div>
              <input
                :id="sort.id"
                v-model="filterData.order"
                :value="sort.value"
                class="form-check-input"
                type="radio"
                name="filter-sorting"
              />
            </div>
          </div>

        <span class="mt-6 text-sm font-bold">Berdasarkan</span>
        <div class="input-form mt-3 w-full">
          <label
            for="form-address-province"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.province') }}
          </label>
          <div class="mt-2">
            <Multiselect
              v-model="filterData.province_code"
              value="code"
              label="name"
              track-by="name"
              :placeholder="$t('formLabel.select.province')"
              :options="provinceList"
              :classes="multiSelectClasses"
              :searchable="true"
              @change="changeAddress('province')"
            />
          </div>
        </div>
        <div class="input-form mt-3 w-full">
          <label
            for="form-address-city"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.city') }}
          </label>
          <div class="mt-2">
            <Multiselect
              v-model="filterData.city_code"
              value="code"
              label="name"
              track-by="name"
              :placeholder="$t('formLabel.select.city')"
              :options="cityList"
              :classes="multiSelectClasses"
              :searchable="true"
              :disabled="cityDisabled === true"
              @change="changeAddress('city')"
            />
          </div>
        </div>
        <div class="input-form mt-3 w-full">
          <label
            for="form-address-district"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.district') }}
          </label>
          <div class="mt-2">
            <Multiselect
              v-model="filterData.district_code"
              value="code"
              label="name"
              track-by="name"
              :placeholder="$t('formLabel.select.district')"
              :options="districtList"
              :classes="multiSelectClasses"
              :searchable="true"
              :disabled="districtDisabled === true"
              @change="changeAddress('district')"
            />
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <button
        type="button"
        class="btn bg-gradient-to-r from-teal-700 to-primary text-white my-2 w-full"
        :disabled="applyButtonDisabled"
        @click="applyFilter"
      >
        Terapkan Filter
      </button>
    </ModalFooter>
  </Modal>

  <!-- BEGIN: Delete Confirmation Modal -->
  <!-- <Modal
    :show="deleteConfirmationModal"
    @hidden="deleteConfirmationModal = false"
  >
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5 text-3xl">Are you sure?</div>
        <div class="mt-2 text-slate-500">
          Do you really want to delete these records? <br />This process cannot
          be undone.
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="deleteConfirmationModal = false"
          class="btn btn-outline-secondary mr-1 w-24"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-danger w-24">Delete</button>
      </div>
    </ModalBody>
  </Modal> -->
  <!-- END: Delete Confirmation Modal -->
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import globalMixin from '@/mixins/global.js'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import { ArrowsUpDownIcon } from '@heroicons/vue/24/solid'
import Multiselect from '@vueform/multiselect'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Currency,
    ArrowsUpDownIcon,
    Multiselect,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    onMounted(async () => {
      Object.assign(activeUser, store.getters['auth/activeUser'])
      roleAccess.value = activeUser.role_codes

      getAllData()
    })

    const activeUser = reactive({})
    const roleAccess = ref('')

    const isLoading = ref(false)
    const isDownloadLoading = ref(false)
    const timer = ref(undefined)
    const filterModal = ref(false)

    const applyButtonDisabled = ref(false)

    const tableHeader = ref([
      {
        item: 'KODE',
        customClass: '',
      },
      {
        item: 'NAMA',
        customClass: '',
      },
      {
        item: 'LONGITUDE',
        customClass: '',
      },
      {
        item: 'LATITUDE',
        customClass: '',
      },
      {
        item: 'ALT NAME',
        customClass: '',
      },
      {
        item: 'KODE POS',
        customClass: '',
      },
      {
        item: 'AKSI',
        customClass: 'text-center',
      },
    ])

    let tableData = ref([])

    let metaData = reactive({
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    const sortingOptions = ref([
      {
        id: 1,
        label: 'A-Z',
        value: 'ASC',
      },
      {
        id: 2,
        label: 'Z-A',
        value: 'DESC',
      },
    ])

    const filterDefault = {
      page: 1,
      take: 10,
      order: sortingOptions.value[0].value,
      province_code: null,
      city_code: null,
      district_code: null,
      search: '',
    }

    let filterData = reactive({})
    Object.assign(filterData, filterDefault)

    const download = async () => {
      isDownloadLoading.value = true
      await store
        .dispatch('address/villageDownload')
        .then((response) => {
          // const filename = $h.extractFilename(
          //   response.dispotition,
          //   'village_list.xlsx'
          // )

          isDownloadLoading.value = false

          const filename = response.dispotition.split('"')[1]
          saveAs(response.data, filename)
        })
        .catch((e) => {
          isDownloadLoading.value = false
          console.log(e)
        })
    }

    const getAllData = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        tableData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }

      await store
        .dispatch('address/village', {
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          district_code: filterData.district_code,
          q: filterData.search,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            tableData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const provincePage = ref(1)
    const provinceList = ref([])

    const getAddressProvince = async () => {
      const queryParameters = {
        order: 'ASC',
        page: provincePage.value,
        take: 20,
        q: '',
      }
      await store
        .dispatch('address/province', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const provinceTemporary = []
            response.data.data.forEach((item) => {
              provinceTemporary.push({
                value: item.code,
                ...item,
              })
            })
            provinceList.value = [...provinceList.value, ...provinceTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('province')
            }
          }
        })
        .catch((error) => {})
    }

    const cityPage = ref(1)
    const cityList = ref([])
    const cityDisabled = ref(true)

    const getAddressCity = async () => {
      const queryParameters = {
        order: 'ASC',
        page: cityPage.value,
        take: 20,
        province_code: filterData.province_code,
        q: '',
      }
      await store
        .dispatch('address/city', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const cityTemporary = []
            response.data.data.forEach((item) => {
              cityTemporary.push({
                value: item.code,
                ...item,
              })
            })
            cityList.value = [...cityList.value, ...cityTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('city')
            }
          }
        })
        .catch((error) => {})
    }

    const changeAddress = async (type) => {
      setTimeout(() => {
        if (type === 'province') {
          applyButtonDisabled.value = true

          filterData.city_code = null
          filterData.district_code = null

          cityPage.value = 1
          cityList.value = []
          cityDisabled.value = false

          districtPage.value = 1
          districtList.value = []
          districtDisabled.value = true

          if (filterData.province_code !== '') {
            getAddressCity()
          }
        } else if (type === 'city') {
          applyButtonDisabled.value = true

          filterData.district_code = null

          districtPage.value = 1
          districtList.value = []
          districtDisabled.value = false

          if (filterData.city_code !== '') {
            getAddressDistrict()
          }
        } else if (type === 'district') {
          applyButtonDisabled.value = false
        }
      }, 200)
    }

    const districtPage = ref(1)
    const districtList = ref([])
    const districtDisabled = ref(true)

    const getAddressDistrict = async () => {
      const queryParameters = {
        order: 'ASC',
        page: districtPage.value,
        take: 20,
        city_code: filterData.city_code,
        q: '',
      }
      await store
        .dispatch('address/district', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const districtTemporary = []
            response.data.data.forEach((item) => {
              districtTemporary.push({
                value: item.code,
                ...item,
              })
            })
            districtList.value = [...districtList.value, ...districtTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('district')
            }
          }
        })
        .catch((error) => {})
    }

    const loadMores = async (type) => {
      if (type === 'province') {
        provincePage.value += 1
        getAddressProvince()
      } else if (type === 'city') {
        cityPage.value += 1
        getAddressCity()
      } else if (type === 'district') {
        districtPage.value += 1
        getAddressDistrict()
      }
    }

    const openFilter = () => {
      filterModal.value = true
      getAddressProvince(true)
    }

    const resetFilter = () => {
      Object.assign(filterData, filterDefault)

      applyButtonDisabled.value = false

      cityPage.value = 1
      cityList.value = []
      cityDisabled.value = true

      districtPage.value = 1
      districtList.value = []
      districtDisabled.value = true

      getAllData(true)
    }

    const checkFilter = (value, _target) => {
      if (_target === 'order') {
        filterData.order = value
      }
    }

    const applyFilter = () => {
      filterModal.value = false
      getAllData()
    }

    const changeFilter = (event) => {
      Object.assign(filterData, {
        ...filterData,
        page: Number(event.filterData.page),
        take: Number(event.filterData.take),
      })
      getAllData()
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getAllData(true)
      }, 500)
    }

    return {
      t,
      n,
      store,
      route,
      router,

      isLoading,
      isDownloadLoading,
      timer,
      filterModal,

      tableHeader,
      tableData,
      metaData,
      filterData,
      sortingOptions,

      getAllData,

      getAddressProvince,
      provincePage,
      provinceList,

      getAddressCity,
      cityPage,
      cityList,
      cityDisabled,

      getAddressDistrict,
      districtPage,
      districtList,
      districtDisabled,

      changeAddress,
      loadMores,

      openFilter,
      resetFilter,
      applyFilter,
      changeFilter,
      searchFilter,
      checkFilter,
      applyButtonDisabled,

      download,
    }
  },
}
</script>
