import axios, { routePathAPI } from '@/plugins/axios.js'
import { helper as $h } from '@/utils/helper'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  getAll({ commit }, payload) {
    let queryParameters = {
      order: payload.order ?? 'ASC',
      page: payload.page ?? 1,
      take: payload.take ?? 50,
      // store_id: payload.store_id ?? null,
      // is_accessories: payload.is_accessories ?? null,
    }
    if (payload.q) queryParameters.q = payload.q
    if (payload.sub_category_ids) queryParameters.sub_category_ids = payload.sub_category_ids

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}/product?` +
            $h.serializeObjectToQueryParam({ ...queryParameters })
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSingle({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/product/` + id)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}/product/create`, payload)
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${routePathAPI()}/product/update/` + payload.id, payload)
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${routePathAPI()}/product/delete/` + id)
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  uploadImage({ commit }, payload) {
    const formData = new FormData()
    formData.append("image", payload.file);
    
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}/product/image/upload/` + payload.id, formData, config)
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteImage({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${routePathAPI()}/product/image/delete/` + payload.id)
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
