<template>
  <div class="intro-y mt-8 flex items-center">
    <h2 class="mr-auto text-lg font-medium">Add Product</h2>
  </div>
  <div class="mt-5 grid grid-cols-11 gap-x-6 pb-20">
    <!-- BEGIN: Notification -->
    <!-- <Alert
        class="intro-y alert-primary alert-dismissible col-span-11 mb-6"
        v-slot="{ dismiss }"
        role="alert"
      >
        <div class="flex items-center">
          <span><InfoIcon class="mr-2 h-4 w-4" /></span>
          <span
            >Starting May 10, 2021, there will be changes to the Terms &
            Conditions regarding the number of products that may be added by the
            Seller.
            <a
              href="https://themeforest.net/item/midone-jquery-tailwindcss-html-admin-template/26366820"
              class="ml-1 underline"
              target="blank"
              >Learn More</a
            ></span
          >
          <button
            type="button"
            class="btn-close text-white"
            @click="dismiss"
            aria-label="Close"
          >
            <XIcon class="h-4 w-4" />
          </button>
        </div>
      </Alert> -->
    <!-- BEGIN: Notification -->
    <div class="intro-y col-span-11 2xl:col-span-9">
      <!-- BEGIN: Uplaod Product -->
      <div class="intro-y box p-5">
        <div
          class="rounded-md border border-slate-200/60 p-5 dark:border-darkmode-400"
        >
          <div
            class="flex items-center border-b border-slate-200/60 pb-5 text-base font-medium dark:border-darkmode-400"
          >
            <ChevronDownIcon class="mr-2 h-4 w-4" /> Upload Product
          </div>
          <div class="mt-5">
            <div class="flex items-center text-slate-500">
              <span><LightbulbIcon class="h-5 w-5 text-warning" /></span>
              <div class="ml-2">
                <span class="mr-1"
                  >Avoid selling counterfeit products / violating Intellectual
                  Property Rights, so that your products are not deleted.</span
                >
                <a
                  href="https://themeforest.net/item/midone-jquery-tailwindcss-html-admin-template/26366820"
                  class="font-medium text-primary"
                  target="blank"
                  >Learn More</a
                >
              </div>
            </div>
            <div class="form-inline mt-10 flex-col items-start xl:flex-row">
              <div class="form-label w-full xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Product Photos</div>
                    <div
                      class="ml-2 rounded-md bg-slate-200 px-2 py-0.5 text-xs text-slate-600 dark:bg-darkmode-300 dark:text-slate-400"
                    >
                      Required
                    </div>
                  </div>
                  <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    <div>
                      The image format is .jpg .jpeg .png and a minimum size of
                      300 x 300 pixels (For optimal images use a minimum size of
                      700 x 700 pixels).
                    </div>
                    <div class="mt-2">
                      Select product photos or drag and drop up to 5 photos at
                      once here. Include min. 3 attractive photos to make the
                      product more attractive to buyers.
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="mt-3 w-full flex-1 rounded-md border-2 border-dashed pt-4 dark:border-darkmode-400 xl:mt-0"
              >
                <div class="grid grid-cols-10 gap-5 pl-4 pr-5">
                  <div
                    v-for="(faker, fakerKey) in $_.take($f(), 5)"
                    :key="fakerKey"
                    class="image-fit zoom-in relative col-span-5 h-28 cursor-pointer md:col-span-2"
                  >
                    <img
                      class="rounded-md"
                      alt="Gromura - SCM"
                      :src="faker.users[0].image"
                    />
                    <Tippy
                      content="Remove this image?"
                      class="tooltip absolute right-0 top-0 -mr-2 -mt-2 flex h-5 w-5 items-center justify-center rounded-full bg-danger text-white"
                    >
                      <XIcon class="h-4 w-4" />
                    </Tippy>
                  </div>
                </div>
                <div
                  class="relative mt-5 flex cursor-pointer items-center justify-center px-4 pb-4"
                >
                  <ImageIcon class="mr-2 h-4 w-4" />
                  <span class="mr-1 text-primary">Upload a file</span> or drag
                  and drop
                  <input
                    id="horizontal-form-1"
                    type="file"
                    class="absolute top-0 left-0 h-full w-full opacity-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Uplaod Product -->
      <!-- BEGIN: Product Information -->
      <div class="intro-y box mt-5 p-5">
        <div
          class="rounded-md border border-slate-200/60 p-5 dark:border-darkmode-400"
        >
          <div
            class="flex items-center border-b border-slate-200/60 pb-5 text-base font-medium dark:border-darkmode-400"
          >
            <ChevronDownIcon class="mr-2 h-4 w-4" /> Product Information
          </div>
          <div class="mt-5">
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Barcode</div>
                    <div
                      class="ml-2 rounded-md bg-slate-200 px-2 py-0.5 text-xs text-slate-600 dark:bg-darkmode-300 dark:text-slate-400"
                    >
                      Required
                    </div>
                  </div>
                  <!-- <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    Include min. 40 characters to make it more attractive and
                    easy for buyers to find, consisting of product type, brand,
                    and information such as color, material, or type.
                  </div> -->
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <input
                  id="product-name"
                  type="text"
                  class="form-control"
                  placeholder="Product barcode"
                />
                <div class="form-help text-right">Maximum character 0/70</div>
              </div>
            </div>
            <div
              class="form-inline mt-3 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Product Name</div>
                    <div
                      class="ml-2 rounded-md bg-slate-200 px-2 py-0.5 text-xs text-slate-600 dark:bg-darkmode-300 dark:text-slate-400"
                    >
                      Required
                    </div>
                  </div>
                  <!-- <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    Include min. 40 characters to make it more attractive and
                    easy for buyers to find, consisting of product type, brand,
                    and information such as color, material, or type.
                  </div> -->
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <input
                  id="product-name"
                  type="text"
                  class="form-control"
                  placeholder="Product name"
                />
                <div class="form-help text-right">Maximum character 0/70</div>
              </div>
            </div>
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Category</div>
                    <div
                      class="ml-2 rounded-md bg-slate-200 px-2 py-0.5 text-xs text-slate-600 dark:bg-darkmode-300 dark:text-slate-400"
                    >
                      Required
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <select id="category" class="form-select">
                  <option
                    v-for="(faker, fakerKey) in $_.take($f(), 9)"
                    :key="fakerKey"
                    :value="faker.categories[0].name"
                  >
                    {{ faker.categories[0].name }}
                  </option>
                </select>
              </div>
            </div>
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Subcategory</div>
                  </div>
                  <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    You can add a new subcategory or choose from the existing
                    subcategory list.
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <TomSelect
                  v-model="subcategory"
                  :options="{
                    placeholder: 'Etalase',
                  }"
                  class="w-full"
                  multiple
                >
                  <option
                    v-for="(faker, fakerKey) in $_.take($f(), 2)"
                    :key="fakerKey"
                    :value="faker.categories[0].name"
                  >
                    {{ faker.categories[0].name }}
                  </option>
                </TomSelect>
              </div>
            </div>
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Supplier</div>
                    <div
                      class="ml-2 rounded-md bg-slate-200 px-2 py-0.5 text-xs text-slate-600 dark:bg-darkmode-300 dark:text-slate-400"
                    >
                      Required
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <select id="category" class="form-select">
                  <option
                    v-for="(faker, fakerKey) in $_.take($f(), 9)"
                    :key="fakerKey"
                    :value="faker.suppliers[0].name"
                  >
                    {{ faker.suppliers[0].name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Product Information -->
      <!-- BEGIN: Product Detail -->
      <!-- <div class="intro-y box mt-5 p-5">
        <div
          class="rounded-md border border-slate-200/60 p-5 dark:border-darkmode-400"
        >
          <div
            class="flex items-center border-b border-slate-200/60 pb-5 text-base font-medium dark:border-darkmode-400"
          >
            <ChevronDownIcon class="mr-2 h-4 w-4" /> Product Detail
          </div>
          <div class="mt-5">
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Condition</div>
                    <div
                      class="ml-2 rounded-md bg-slate-200 px-2 py-0.5 text-xs text-slate-600 dark:bg-darkmode-300 dark:text-slate-400"
                    >
                      Required
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <div class="flex flex-col sm:flex-row">
                  <div class="form-check mr-4">
                    <input
                      id="condition-new"
                      class="form-check-input"
                      type="radio"
                      name="horizontal_radio_button"
                      value="horizontal-radio-chris-evans"
                    />
                    <label class="form-check-label" for="condition-new"
                      >New</label
                    >
                  </div>
                  <div class="form-check mr-4 mt-2 sm:mt-0">
                    <input
                      id="condition-second"
                      class="form-check-input"
                      type="radio"
                      name="horizontal_radio_button"
                      value="horizontal-radio-liam-neeson"
                    />
                    <label class="form-check-label" for="condition-second"
                      >Second</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Product Description</div>
                    <div
                      class="ml-2 rounded-md bg-slate-200 px-2 py-0.5 text-xs text-slate-600 dark:bg-darkmode-300 dark:text-slate-400"
                    >
                      Required
                    </div>
                  </div>
                  <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    <div>
                      Make sure the product description provides a detailed
                      explanation of your product so that it is easy to
                      understand and find your product.
                    </div>
                    <div class="mt-2">
                      It is recommended not to enter info on mobile numbers,
                      e-mails, etc. into the product description to protect your
                      personal data.
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <ClassicEditor v-model="editorData" />
                <div class="form-help text-right">Maximum character 0/2000</div>
              </div>
            </div>
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Product Video</div>
                  </div>
                  <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    Add a video so that buyers are more interested in your
                    product.
                    <a
                      href="https://themeforest.net/item/midone-jquery-tailwindcss-html-admin-template/26366820"
                      class="font-medium text-primary"
                      target="blank"
                      >Learn more.</a
                    >
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <button class="btn btn-outline-secondary w-40">
                  <PlusIcon class="mr-2 h-4 w-4" /> Add Video URL
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- END: Product Detail -->
      <!-- BEGIN: Product Variant -->
      <!-- <div class="intro-y box mt-5 p-5">
        <div
          class="rounded-md border border-slate-200/60 p-5 dark:border-darkmode-400"
        >
          <div
            class="flex items-center border-b border-slate-200/60 pb-5 text-base font-medium dark:border-darkmode-400"
          >
            <ChevronDownIcon class="mr-2 h-4 w-4" /> Product Variant
          </div>
          <div class="mt-5">
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label sm:!mr-10">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Product Variant</div>
                  </div>
                  <div class="mt-2 text-xs leading-relaxed text-slate-500">
                    Add variants such as color, size, or more. Choose a maximum
                    of 2 variant types.
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0 xl:text-right">
                <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white w-44">
                  <PlusIcon class="mr-2 h-4 w-4" /> Add Variant
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- END: Product Variant -->
      <!-- BEGIN: Product Variant (Details) -->
      <!-- <div class="intro-y box mt-5 p-5">
        <div
          class="rounded-md border border-slate-200/60 p-5 dark:border-darkmode-400"
        >
          <div
            class="flex items-center border-b border-slate-200/60 pb-5 text-base font-medium dark:border-darkmode-400"
          >
            <ChevronDownIcon class="mr-2 h-4 w-4" /> Product Variant (Details)
          </div>
          <div class="mt-5">
            <div
              class="form-inline mt-2 flex-col items-start pt-2 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Variant 1</div>
                  </div>
                  <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    Add the types of variants and options, you can add up to 5
                    options.
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <div
                  class="relative rounded-md bg-slate-50 py-10 pl-5 pr-5 dark:border dark:bg-transparent xl:pr-10"
                >
                  <a
                    href=""
                    class="absolute top-0 right-0 mr-4 mt-4 text-slate-500"
                  >
                    <XIcon class="h-5 w-5" />
                  </a>
                  <div>
                    <div class="form-inline mt-5 first:mt-0">
                      <label class="form-label sm:w-20">Name</label>
                      <div class="flex flex-1 items-center xl:pr-20">
                        <div class="input-group flex-1">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Size"
                          />
                          <div class="input-group-text">6/14</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-inline mt-5 items-start first:mt-0">
                      <label class="form-label mt-2 sm:w-20">Options</label>
                      <div class="flex-1">
                        <div class="mt-5 items-center first:mt-0 xl:flex">
                          <div class="input-group flex-1">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Small"
                            />
                            <div class="input-group-text">6/14</div>
                          </div>
                          <div class="mt-3 flex w-20 text-slate-500 xl:mt-0">
                            <a href="" class="xl:ml-5">
                              <MoveIcon class="h-4 w-4" />
                            </a>
                            <a href="" class="ml-3 xl:ml-5">
                              <Trash2Icon class="h-4 w-4" />
                            </a>
                          </div>
                        </div>
                        <div class="mt-5 items-center first:mt-0 xl:flex">
                          <div class="input-group flex-1">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Medium"
                            />
                            <div class="input-group-text">6/14</div>
                          </div>
                          <div class="mt-3 flex w-20 text-slate-500 xl:mt-0">
                            <a href="" class="xl:ml-5">
                              <MoveIcon class="h-4 w-4" />
                            </a>
                            <a href="" class="ml-3 xl:ml-5">
                              <Trash2Icon class="h-4 w-4" />
                            </a>
                          </div>
                        </div>
                        <div class="mt-5 items-center first:mt-0 xl:flex">
                          <div class="input-group flex-1">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Large"
                            />
                            <div class="input-group-text">6/14</div>
                          </div>
                          <div class="mt-3 flex w-20 text-slate-500 xl:mt-0">
                            <a href="" class="xl:ml-5">
                              <MoveIcon class="h-4 w-4" />
                            </a>
                            <a href="" class="ml-3 xl:ml-5">
                              <Trash2Icon class="h-4 w-4" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-5 first:mt-0 xl:ml-20 xl:pl-5 xl:pr-20">
                      <button
                        class="btn btn-outline-primary w-full border-dashed"
                      >
                        <PlusIcon class="mr-2 h-4 w-4" /> Add New Option
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-inline mt-2 flex-col items-start pt-2 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Variant 2</div>
                  </div>
                  <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    Add the types of variants and options, you can add up to 5
                    options.
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <div
                  class="relative rounded-md bg-slate-50 py-10 pl-5 pr-5 dark:border dark:bg-transparent xl:pr-10"
                >
                  <a
                    href=""
                    class="absolute top-0 right-0 mr-4 mt-4 text-slate-500"
                  >
                    <XIcon class="h-5 w-5" />
                  </a>
                  <div>
                    <div class="form-inline mt-5 first:mt-0">
                      <label class="form-label sm:w-20">Name</label>
                      <div class="flex flex-1 items-center xl:pr-20">
                        <div class="input-group flex-1">
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Color"
                          />
                          <div class="input-group-text">6/14</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-inline mt-5 items-start first:mt-0">
                      <label class="form-label mt-2 sm:w-20">Options</label>
                      <div class="flex-1">
                        <div class="mt-5 items-center first:mt-0 xl:flex">
                          <div class="input-group flex-1">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Black"
                            />
                            <div class="input-group-text">6/14</div>
                          </div>
                          <div class="mt-3 flex w-20 text-slate-500 xl:mt-0">
                            <a href="" class="xl:ml-5">
                              <MoveIcon class="h-4 w-4" />
                            </a>
                            <a href="" class="ml-3 xl:ml-5">
                              <Trash2Icon class="h-4 w-4" />
                            </a>
                          </div>
                        </div>
                        <div class="mt-5 items-center first:mt-0 xl:flex">
                          <div class="input-group flex-1">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="White"
                            />
                            <div class="input-group-text">6/14</div>
                          </div>
                          <div class="mt-3 flex w-20 text-slate-500 xl:mt-0">
                            <a href="" class="xl:ml-5">
                              <MoveIcon class="h-4 w-4" />
                            </a>
                            <a href="" class="ml-3 xl:ml-5">
                              <Trash2Icon class="h-4 w-4" />
                            </a>
                          </div>
                        </div>
                        <div class="mt-5 items-center first:mt-0 xl:flex">
                          <div class="input-group flex-1">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Gray"
                            />
                            <div class="input-group-text">6/14</div>
                          </div>
                          <div class="mt-3 flex w-20 text-slate-500 xl:mt-0">
                            <a href="" class="xl:ml-5">
                              <MoveIcon class="h-4 w-4" />
                            </a>
                            <a href="" class="ml-3 xl:ml-5">
                              <Trash2Icon class="h-4 w-4" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-5 first:mt-0 xl:ml-20 xl:pl-5 xl:pr-20">
                      <button
                        class="btn btn-outline-primary w-full border-dashed"
                      >
                        <PlusIcon class="mr-2 h-4 w-4" /> Add New Option
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2 pt-2 first:mt-0 first:pt-0 xl:ml-64 xl:pl-10">
              <button
                class="btn btn-outline-secondary w-full border-dashed py-3"
              >
                <PlusIcon class="mr-2 h-4 w-4" /> Add New Variant
              </button>
            </div>
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Variant Information</div>
                  </div>
                  <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    Apply price and stock on all variants or based on certain
                    variant codes.
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <div class="grid-cols-4 gap-2 sm:grid">
                  <div class="input-group">
                    <div class="input-group-text">$</div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Price"
                    />
                  </div>
                  <input
                    type="text"
                    class="form-control mt-2 sm:mt-0"
                    placeholder="Stock"
                  />
                  <input
                    type="text"
                    class="form-control mt-2 sm:mt-0"
                    placeholder="Variant Code"
                  />
                  <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white mt-2 sm:mt-0">
                    Apply To All
                  </button>
                </div>
              </div>
            </div>
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Variant List</div>
                  </div>
                  <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    Set the price and stock for each variant.
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <div class="overflow-x-auto">
                  <table class="table border">
                    <thead>
                      <tr>
                        <th
                          class="whitespace-nowrap bg-slate-50 text-slate-500 dark:bg-darkmode-800"
                        >
                          Size
                        </th>
                        <th
                          class="whitespace-nowrap bg-slate-50 text-slate-500 dark:bg-darkmode-800"
                        >
                          <div class="flex items-center">
                            Color <HelpCircleIcon class="ml-2 h-4 w-4" />
                          </div>
                        </th>
                        <th
                          class="whitespace-nowrap bg-slate-50 !px-2 text-slate-500 dark:bg-darkmode-800"
                        >
                          Price
                        </th>
                        <th
                          class="whitespace-nowrap bg-slate-50 !px-2 text-slate-500 dark:bg-darkmode-800"
                        >
                          <div class="flex items-center">
                            <div
                              class="relative mr-2 -mt-0.5 h-4 w-4 before:absolute before:h-4 before:w-4 before:rounded-full before:bg-primary/20 before:content-[''] after:absolute after:h-4 after:w-4 after:rounded-full after:border-4 after:border-white/60 after:bg-primary after:content-[''] after:dark:border-darkmode-300 lg:before:animate-ping"
                            ></div>
                            Stock <HelpCircleIcon class="ml-2 h-4 w-4" />
                          </div>
                        </th>
                        <th
                          class="whitespace-nowrap bg-slate-50 !pl-2 text-slate-500 dark:bg-darkmode-800"
                        >
                          Variant Code
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="3" class="border-r">Small</td>
                        <td>Black</td>
                        <td class="!px-2">
                          <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                              type="text"
                              class="form-control min-w-[6rem]"
                              placeholder="Price"
                            />
                          </div>
                        </td>
                        <td class="!px-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Stock"
                          />
                        </td>
                        <td class="!pl-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Variant Code"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>White</td>
                        <td class="!px-2">
                          <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                              type="text"
                              class="form-control min-w-[6rem]"
                              placeholder="Price"
                            />
                          </div>
                        </td>
                        <td class="!px-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Stock"
                          />
                        </td>
                        <td class="!pl-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Variant Code"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Gray</td>
                        <td class="!px-2">
                          <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                              type="text"
                              class="form-control min-w-[6rem]"
                              placeholder="Price"
                            />
                          </div>
                        </td>
                        <td class="!px-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Stock"
                          />
                        </td>
                        <td class="!pl-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Variant Code"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="3" class="border-r">Medium</td>
                        <td>Black</td>
                        <td class="!px-2">
                          <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                              type="text"
                              class="form-control min-w-[6rem]"
                              placeholder="Price"
                            />
                          </div>
                        </td>
                        <td class="!px-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Stock"
                          />
                        </td>
                        <td class="!pl-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Variant Code"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>White</td>
                        <td class="!px-2">
                          <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                              type="text"
                              class="form-control min-w-[6rem]"
                              placeholder="Price"
                            />
                          </div>
                        </td>
                        <td class="!px-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Stock"
                          />
                        </td>
                        <td class="!pl-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Variant Code"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Gray</td>
                        <td class="!px-2">
                          <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                              type="text"
                              class="form-control min-w-[6rem]"
                              placeholder="Price"
                            />
                          </div>
                        </td>
                        <td class="!px-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Stock"
                          />
                        </td>
                        <td class="!pl-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Variant Code"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="3" class="border-r">Large</td>
                        <td>Black</td>
                        <td class="!px-2">
                          <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                              type="text"
                              class="form-control min-w-[6rem]"
                              placeholder="Price"
                            />
                          </div>
                        </td>
                        <td class="!px-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Stock"
                          />
                        </td>
                        <td class="!pl-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Variant Code"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>White</td>
                        <td class="!px-2">
                          <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                              type="text"
                              class="form-control min-w-[6rem]"
                              placeholder="Price"
                            />
                          </div>
                        </td>
                        <td class="!px-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Stock"
                          />
                        </td>
                        <td class="!pl-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Variant Code"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Gray</td>
                        <td class="!px-2">
                          <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                              type="text"
                              class="form-control min-w-[6rem]"
                              placeholder="Price"
                            />
                          </div>
                        </td>
                        <td class="!px-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Stock"
                          />
                        </td>
                        <td class="!pl-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Variant Code"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Wholesale</div>
                  </div>
                  <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    Add wholesale price for certain quantity purchases.
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <div class="overflow-x-auto">
                  <table class="table border">
                    <thead>
                      <tr>
                        <th class="bg-slate-50 !pr-2 dark:bg-darkmode-800"></th>
                        <th class="bg-slate-50 dark:bg-darkmode-800"></th>
                        <th
                          class="whitespace-nowrap bg-slate-50 !px-2 text-slate-500 dark:bg-darkmode-800"
                        >
                          Min.
                        </th>
                        <th
                          class="whitespace-nowrap bg-slate-50 !px-2 text-slate-500 dark:bg-darkmode-800"
                        >
                          Max.
                        </th>
                        <th
                          class="whitespace-nowrap bg-slate-50 !px-2 text-slate-500 dark:bg-darkmode-800"
                        >
                          Unit Price
                        </th>
                        <th class="bg-slate-50 !px-2 dark:bg-darkmode-800"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="!pr-2">1.</td>
                        <td class="whitespace-nowrap">Wholesale Price 1</td>
                        <td class="!px-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Min Qty"
                          />
                        </td>
                        <td class="!px-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Max Qty"
                          />
                        </td>
                        <td class="!px-2">
                          <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                              type="text"
                              class="form-control min-w-[6rem]"
                              placeholder="Price"
                            />
                          </div>
                        </td>
                        <td class="!pl-4 text-slate-500">
                          <a href="">
                            <Trash2Icon class="h-4 w-4" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td class="!pr-2">2.</td>
                        <td class="whitespace-nowrap">Wholesale Price 2</td>
                        <td class="!px-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Min Qty"
                          />
                        </td>
                        <td class="!px-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Max Qty"
                          />
                        </td>
                        <td class="!px-2">
                          <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                              type="text"
                              class="form-control min-w-[6rem]"
                              placeholder="Price"
                            />
                          </div>
                        </td>
                        <td class="!pl-4 text-slate-500">
                          <a href="">
                            <Trash2Icon class="h-4 w-4" />
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td class="!pr-2">3.</td>
                        <td class="whitespace-nowrap">Wholesale Price 3</td>
                        <td class="!px-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Min Qty"
                          />
                        </td>
                        <td class="!px-2">
                          <input
                            type="text"
                            class="form-control min-w-[6rem]"
                            placeholder="Max Qty"
                          />
                        </td>
                        <td class="!px-2">
                          <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                              type="text"
                              class="form-control min-w-[6rem]"
                              placeholder="Price"
                            />
                          </div>
                        </td>
                        <td class="!pl-4 text-slate-500">
                          <a href="">
                            <Trash2Icon class="h-4 w-4" />
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button
                  class="btn btn-outline-primary mt-4 w-full border-dashed"
                >
                  <PlusIcon class="mr-2 h-4 w-4" /> Add New Wholesale Price
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- END: Product Variant (Details) -->
      <!-- BEGIN: Product Management -->
      <!-- <div class="intro-y box mt-5 p-5">
        <div
          class="rounded-md border border-slate-200/60 p-5 dark:border-darkmode-400"
        >
          <div
            class="flex items-center border-b border-slate-200/60 pb-5 text-base font-medium dark:border-darkmode-400"
          >
            <ChevronDownIcon class="mr-2 h-4 w-4" /> Product Management
          </div>
          <div class="mt-5">
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Product Status</div>
                    <div
                      class="ml-2 rounded-md bg-slate-200 px-2 py-0.5 text-xs text-slate-600 dark:bg-darkmode-300 dark:text-slate-400"
                    >
                      Required
                    </div>
                  </div>
                  <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    If the status is active, your product can be searched for by
                    potential buyers.
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <div class="form-check form-switch">
                  <input
                    id="product-status-active"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label class="form-check-label" for="product-status-active"
                    >Active</label
                  >
                </div>
              </div>
            </div>
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Product Stock</div>
                    <div
                      class="ml-2 rounded-md bg-slate-200 px-2 py-0.5 text-xs text-slate-600 dark:bg-darkmode-300 dark:text-slate-400"
                    >
                      Required
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <input
                  id="product-stock"
                  type="text"
                  class="form-control"
                  placeholder="Input Product Stock"
                />
              </div>
            </div>
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">SKU (Stock Keeping Unit)</div>
                    <div
                      class="ml-2 rounded-md bg-slate-200 px-2 py-0.5 text-xs text-slate-600 dark:bg-darkmode-300 dark:text-slate-400"
                    >
                      Required
                    </div>
                  </div>
                  <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    Use a unique SKU code if you want to mark your product.
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <input
                  id="sku"
                  type="text"
                  class="form-control"
                  placeholder="Input SKU"
                />
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- END: Product Management -->
      <!-- BEGIN: Weight & Shipping -->
      <!-- <div class="intro-y box mt-5 p-5">
        <div
          class="rounded-md border border-slate-200/60 p-5 dark:border-darkmode-400"
        >
          <div
            class="flex items-center border-b border-slate-200/60 pb-5 text-base font-medium dark:border-darkmode-400"
          >
            <ChevronDownIcon class="mr-2 h-4 w-4" /> Weight & Shipping
          </div>
          <div class="mt-5">
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Product Weight</div>
                    <div
                      class="ml-2 rounded-md bg-slate-200 px-2 py-0.5 text-xs text-slate-600 dark:bg-darkmode-300 dark:text-slate-400"
                    >
                      Required
                    </div>
                  </div>
                  <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    Enter the weight by weighing the product after it is
                    <span class="font-medium text-slate-600 dark:text-slate-300"
                      >packaged</span
                    >.
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <div class="grid-cols-4 gap-2 sm:grid">
                  <select class="form-select">
                    <option value="Gram (g)">Gram (g)</option>
                    <option value="Kilogram (kg)">Kilogram (kg)</option>
                  </select>
                  <input
                    type="text"
                    id="product-weight"
                    class="form-control mt-2 sm:mt-0"
                    placeholder="Stock"
                  />
                </div>
                <Alert
                  class="alert-outline-warning alert-dismissible mt-5 bg-warning/20 dark:border-darkmode-400 dark:bg-darkmode-400"
                  v-slot="{ dismiss }"
                  role="alert"
                >
                  <div class="flex items-center">
                    <span><AlertTriangleIcon class="mr-3 h-6 w-6" /></span>
                    <span class="text-slate-800 dark:text-slate-500"
                      >Pay close attention to the weight of the product so that
                      there is no difference in data with the shipping courier.
                      <a class="font-medium text-primary" href=""
                        >Learn More</a
                      ></span
                    >
                    <button
                      type="button"
                      class="btn-close dark:text-white"
                      @click="dismiss"
                      aria-label="Close"
                    >
                      <XIcon class="h-4 w-4" />
                    </button>
                  </div>
                </Alert>
              </div>
            </div>
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Product Size</div>
                    <div
                      class="ml-2 rounded-md bg-slate-200 px-2 py-0.5 text-xs text-slate-600 dark:bg-darkmode-300 dark:text-slate-400"
                    >
                      Required
                    </div>
                  </div>
                  <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    Enter the product size after packing to calculate the volume
                    weight.
                    <a class="font-medium text-primary" href=""
                      >Learn Volume Weight</a
                    >
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <div class="grid-cols-3 gap-2 sm:grid">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Width"
                    />
                    <div class="input-group-text">cm</div>
                  </div>
                  <div class="input-group mt-2 sm:mt-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Height"
                    />
                    <div class="input-group-text">cm</div>
                  </div>
                  <div class="input-group mt-2 sm:mt-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Length"
                    />
                    <div class="input-group-text">cm</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Shipping Insurance</div>
                  </div>
                  <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    Refund product & postage for the seller and buyer in case of
                    damage / loss during shipping.
                    <a class="font-medium text-primary" href="">Learn More</a>
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <div class="flex flex-col sm:flex-row">
                  <div class="form-check mr-4">
                    <input
                      id="shipping-insurance-required"
                      class="form-check-input"
                      type="radio"
                      name="horizontal_radio_button"
                      value="horizontal-radio-chris-evans"
                    />
                    <div class="form-check-label">
                      <div>Required</div>
                      <div
                        class="mt-1 w-56 text-xs leading-relaxed text-slate-500"
                      >
                        You
                        <span
                          class="font-medium text-slate-600 dark:text-slate-300"
                          >require</span
                        >
                        the buyer to activate shipping insurance
                      </div>
                    </div>
                  </div>
                  <div class="form-check mr-4 mt-2 sm:mt-0">
                    <input
                      id="shipping-insurance-optional"
                      class="form-check-input"
                      type="radio"
                      name="horizontal_radio_button"
                      value="horizontal-radio-liam-neeson"
                    />
                    <div class="form-check-label">
                      <div>Optional</div>
                      <div
                        class="mt-1 w-56 text-xs leading-relaxed text-slate-500"
                      >
                        You
                        <span
                          class="font-medium text-slate-600 dark:text-slate-300"
                          >give the buyer the option</span
                        >
                        to activate shipping insurance
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">Shipping Service</div>
                  </div>
                  <div class="mt-3 text-xs leading-relaxed text-slate-500">
                    Configure shipping services according to your product type.
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <div class="flex flex-col sm:flex-row">
                  <div class="form-check mr-4">
                    <input
                      id="shipping-service-standard"
                      class="form-check-input"
                      type="radio"
                      name="horizontal_radio_button"
                      value="horizontal-radio-chris-evans"
                    />
                    <label
                      class="form-check-label"
                      for="shipping-service-standard"
                      >Standard</label
                    >
                  </div>
                  <div class="form-check mr-4 mt-2 sm:mt-0">
                    <input
                      id="shipping-service-custom"
                      class="form-check-input"
                      type="radio"
                      name="horizontal_radio_button"
                      value="horizontal-radio-liam-neeson"
                    />
                    <label
                      class="form-check-label"
                      for="shipping-service-custom"
                      >Custom</label
                    >
                  </div>
                </div>
                <div class="mt-3 text-xs leading-relaxed text-slate-500">
                  The delivery service for this product will be the same as in
                  the
                  <a class="font-medium text-primary" href=""
                    >Shipping Settings.</a
                  >
                </div>
              </div>
            </div>
            <div
              class="form-inline mt-5 flex-col items-start pt-5 first:mt-0 first:pt-0 xl:flex-row"
            >
              <div class="form-label xl:!mr-10 xl:w-64">
                <div class="text-left">
                  <div class="flex items-center">
                    <div class="font-medium">PreOrder</div>
                  </div>
                </div>
              </div>
              <div class="mt-3 w-full flex-1 xl:mt-0">
                <div class="form-check form-switch">
                  <input
                    id="preorder-active"
                    class="form-check-input"
                    type="checkbox"
                  />
                  <label
                    class="form-check-label text-xs leading-relaxed text-slate-500"
                    for="preorder-active"
                  >
                    Activate PreOrder if you need a longer shipping process.
                    <a class="font-medium text-primary" href="">Learn more.</a>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- END: Weight & Shipping -->
      <div class="mt-5 flex flex-col justify-end gap-2 md:flex-row">
        <button
          type="button"
          class="btn w-full border-slate-300 py-3 text-slate-500 dark:border-darkmode-400 md:w-52"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn w-full border-slate-300 py-3 text-slate-500 dark:border-darkmode-400 md:w-52"
        >
          Save & Add New Product
        </button>
        <button type="button" class="btn bg-gradient-to-r from-teal-700 to-primary text-white w-full rounded-xl py-3 md:w-52">
          Save
        </button>
      </div>
    </div>
    <div class="intro-y col-span-2 hidden 2xl:block">
      <div class="sticky top-0 pt-10">
        <ul
          class="relative text-slate-500 before:absolute before:left-0 before:z-[-1] before:h-full before:w-[2px] before:bg-slate-200 before:content-[''] before:dark:bg-darkmode-600"
        >
          <li
            class="mb-4 border-l-2 border-primary pl-5 font-medium text-primary dark:border-primary"
          >
            <a href="">Upload Product</a>
          </li>
          <li
            class="mb-4 border-l-2 border-transparent pl-5 dark:border-transparent"
          >
            <a href="">Product Information</a>
          </li>
          <li
            class="mb-4 border-l-2 border-transparent pl-5 dark:border-transparent"
          >
            <a href="">Product Detail</a>
          </li>
          <li
            class="mb-4 border-l-2 border-transparent pl-5 dark:border-transparent"
          >
            <a href="">Product Variant</a>
          </li>
          <li
            class="mb-4 border-l-2 border-transparent pl-5 dark:border-transparent"
          >
            <a href="">Product Variant (Details)</a>
          </li>
          <li
            class="mb-4 border-l-2 border-transparent pl-5 dark:border-transparent"
          >
            <a href="">Product Management</a>
          </li>
          <li
            class="mb-4 border-l-2 border-transparent pl-5 dark:border-transparent"
          >
            <a href="">Weight & Shipping</a>
          </li>
        </ul>
        <div
          class="relative mt-10 rounded-md border border-warning bg-warning/20 p-5 dark:border-0 dark:bg-darkmode-600"
        >
          <LightbulbIcon
            class="absolute top-0 right-0 mt-5 mr-3 h-12 w-12 text-warning/80"
          />
          <h2 class="text-lg font-medium">Tips</h2>
          <div class="mt-5 font-medium">Price</div>
          <div
            class="mt-2 text-xs leading-relaxed text-slate-600 dark:text-slate-500"
          >
            <div>
              The image format is .jpg .jpeg .png and a minimum size of 300 x
              300 pixels (For optimal images use a minimum size of 700 x 700
              pixels).
            </div>
            <div class="mt-2">
              Select product photos or drag and drop up to 5 photos at once
              here. Include min. 3 attractive photos to make the product more
              attractive to buyers.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const subcategory = ref([])
const editorData = ref('<p>Content of the editor.</p>')
</script>
