import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import printJS from 'print-js'

dayjs.extend(duration)

const helpers = {
  serializeObjectToQueryParam(obj) {
    var str = []
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
      }
    return str.join('&')
  },
  cutText(text, length) {
    if (text.split(' ').length > 1) {
      const string = text.substring(0, length)
      const splitText = string.split(' ')
      splitText.pop()
      return splitText.join(' ') + '...'
    } else {
      return text
    }
  },
  formatDate(date, format) {
    return dayjs(date).format(format)
  },
  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ''
    }
  },
  onlyNumber(string) {
    if (string) {
      return string.replace(/\D/g, '')
    } else {
      return ''
    }
  },
  formatCurrency(number) {
    if (number) {
      const formattedNumber = number.toString().replace(/\D/g, '')
      const rest = formattedNumber.length % 3
      let currency = formattedNumber.substr(0, rest)
      const thousand = formattedNumber.substr(rest).match(/\d{3}/g)
      let separator

      if (thousand) {
        separator = rest ? '.' : ''
        currency += separator + thousand.join('.')
      }

      return currency
    } else {
      return ''
    }
  },
  timeAgo(time) {
    const date = new Date((time || '').replace(/-/g, '/').replace(/[TZ]/g, ' '))
    const diff = (new Date().getTime() - date.getTime()) / 1000
    const dayDiff = Math.floor(diff / 86400)

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
      return dayjs(time).format('MMMM DD, YYYY')
    }

    return (
      (dayDiff === 0 &&
        ((diff < 60 && 'just now') ||
          (diff < 120 && '1 minute ago') ||
          (diff < 3600 && Math.floor(diff / 60) + ' minutes ago') ||
          (diff < 7200 && '1 hour ago') ||
          (diff < 86400 && Math.floor(diff / 3600) + ' hours ago'))) ||
      (dayDiff === 1 && 'Yesterday') ||
      (dayDiff < 7 && dayDiff + ' days ago') ||
      (dayDiff < 31 && Math.ceil(dayDiff / 7) + ' weeks ago')
    )
  },
  diffTimeByNow(time) {
    const startDate = dayjs(dayjs().format('YYYY-MM-DD HH:mm:ss').toString())
    const endDate = dayjs(dayjs(time).format('YYYY-MM-DD HH:mm:ss').toString())

    const duration = dayjs.duration(endDate.diff(startDate))
    const milliseconds = Math.floor(duration.asMilliseconds())

    const days = Math.round(milliseconds / 86400000)
    const hours = Math.round((milliseconds % 86400000) / 3600000)
    let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000)
    const seconds = Math.round(
      (((milliseconds % 86400000) % 3600000) % 60000) / 1000
    )

    if (seconds < 30 && seconds >= 0) {
      minutes += 1
    }

    return {
      days: days.toString().length < 2 ? '0' + days : days,
      hours: hours.toString().length < 2 ? '0' + hours : hours,
      minutes: minutes.toString().length < 2 ? '0' + minutes : minutes,
      seconds: seconds.toString().length < 2 ? '0' + seconds : seconds,
    }
  },
  isset(obj) {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === 'object' || Array.isArray(obj)) {
        return Object.keys(obj).length
      } else {
        return obj.toString().length
      }
    }

    return false
  },
  toRaw(obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  randomNumbers(from, to, length) {
    const numbers = [0]
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to))
    }

    return numbers
  },
  toRGB(colors) {
    const tempColors = Object.assign({}, colors)
    const rgbColors = Object.entries(tempColors)
    for (const [key, value] of rgbColors) {
      if (typeof value === 'string') {
        if (value.replace('#', '').length == 6) {
          const aRgbHex = value.replace('#', '').match(/.{1,2}/g)
          tempColors[key] = (opacity = 1) =>
            `rgb(${parseInt(aRgbHex[0], 16)} ${parseInt(
              aRgbHex[1],
              16
            )} ${parseInt(aRgbHex[2], 16)} / ${opacity})`
        }
      } else {
        tempColors[key] = helpers.toRGB(value)
      }
    }
    return tempColors
  },
  extractFilename(dispotition, defaultName) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(dispotition)
    let filename = defaultName
    if (matches && matches[1]) {
      filename = matches[1].replace(/['"]/g, '')
    }
    return filename
  },
  isNumber: function(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
      evt.preventDefault();;
    } else {
      return true;
    }
  },
  roleCanManage(roles, targets) {
    let managed = false
    for (const role of roles) {
      for (const target of targets) {
        if (role === target) {
          managed = true
        }
      }
    }
    return managed
  },
  changeQuantity: (product, bonus, added) => {
    if (bonus === true) {
      if (added === true) {
        if (product.quantity_bonus === null) {
          product.quantity_bonus = 1
        } else {
          product.quantity_bonus = product.quantity_bonus + 1
        }
      } else {
        if (product.quantity_bonus !== 0) {
          product.quantity_bonus = product.quantity_bonus - 1
        }
      }
    } else {
      if (added === true) {
        if (product.quantity === null) {
          product.quantity = 1
        } else {
          product.quantity = product.quantity + 1
        }
      } else {
        if (product.quantity !== 0) {
          product.quantity = product.quantity - 1
        }
      }
    }

    // calculateTotalPriceItem(product)
    return product
  },
  calculateTotalPriceItem: (product) => {
    if (product.quantity > product.quantity_fixed) {
      product.quantity = product.quantity_fixed
    }
    // if (product.quantity_bonus > product.quantity_bonus_fixed) {
    //   product.quantity_bonus = product.quantity_bonus_fixed
    // }
    product.total_price =
      product.quantity * product.price - product.price_discount
    
    // calculateTotalPrice()
    return product
  },
  calculateTotalPrice: (transaction) => {
    let totalPrice = 0
    transaction.items.forEach((item) => {
      totalPrice += item.quantity * item.sale_price
    })
    // if (transaction.tax.toString() !== '0') {
    //   subTotal.value = totalPrice - Math.round(totalPrice / transaction.tax)
    // } else {
    //   subTotal.value = totalPrice
    // }
    // total.value = totalPrice

    const subTotal = totalPrice;
    const total = totalPrice;

    return { subTotal, total }
  },
  formatPrice: (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  },
  customRanges: () => {
    const ranges = {
      'Hari ini': [new Date(), new Date()],
      'Kemarin': [
        new Date(dayjs().add(-1, 'days')),
        new Date(dayjs().add(-1, 'days')),
      ],
      'Minggu ini': [
        new Date(dayjs().startOf('week').add(1, 'days')),
        new Date(dayjs().endOf('week').add(1, 'days')),
      ],
      'Minggu lalu': [
        new Date(dayjs().add(-1, 'week').startOf('week').add(1, 'days')),
        new Date(dayjs().add(-1, 'week').endOf('week').add(1, 'days')),
      ],
      'Bulan ini': [
        new Date(dayjs().startOf('month')),
        new Date(dayjs().endOf('month')),
      ],
      'Bulan lalu': [
        new Date(dayjs().add(-1, 'month').startOf('month')),
        new Date(dayjs().add(-1, 'month').endOf('month')),
      ],
      '3 Bulan': [
        new Date(dayjs().add(-2, 'month').startOf('month')),
        new Date(dayjs().endOf('month')),
      ]
    }
    return ranges
  },
  litePickerConfig: () => {
    return {
      lang: 'id',
      autoApply: false,
      singleMode: false,
      showWeekNumbers: true,
      dropdowns: {
        minYear: 2020,
        maxYear: null,
        months: true,
        years: true,
      },
      format: 'YYYY-MM-DD',
      buttonText: { apply: 'Terapkan', cancel: 'Batal' },
      plugins: ['ranges'],
      ranges: {
        position: 'bottom',
        customRanges: {
          'Hari ini': [new Date(), new Date()],
          'Kemarin': [
            new Date(dayjs().add(-1, 'days')),
            new Date(dayjs().add(-1, 'days')),
          ],
          'Minggu ini': [
            new Date(dayjs().startOf('week').add(1, 'days')),
            new Date(dayjs().endOf('week').add(1, 'days')),
          ],
          'Minggu lalu': [
            new Date(dayjs().add(-1, 'week').startOf('week').add(1, 'days')),
            new Date(dayjs().add(-1, 'week').endOf('week').add(1, 'days')),
          ],
          'Bulan ini': [
            new Date(dayjs().startOf('month')),
            new Date(dayjs().endOf('month')),
          ],
          'Bulan lalu': [
            new Date(dayjs().add(-1, 'month').startOf('month')),
            new Date(dayjs().add(-1, 'month').endOf('month')),
          ],
          '3 Bulan': [
            new Date(dayjs().add(-2, 'month').startOf('month')),
            new Date(dayjs().endOf('month')),
          ]
        },
      },
    }
  },
  formattedDate(date, format) {

    if (date === undefined && date === null && date === '') return ''
    
    let defaultFormat = 'DD MMMM YYYY'
    if (format !== undefined && format !== null && format !== '') {
      defaultFormat = format
    }

    const newDate = dayjs(date).format(defaultFormat)
    return newDate
  },
  tippyGetClass (isUp) {
    return isUp === true ? 'md:bg-success !text-success ' : isUp === false ? 'md:bg-danger !text-danger' : 'md:bg-gray-500 !text-gray-500'
  },
  tippyGetContent (isUp, percentage, previous) {
    return isUp === null ? '' : `${isUp === true ? 'Naik' : 'Turun'} ${percentage.replace("-", "")} ${previous !== undefined ? `dari ${previous} sebelumnya` : 'dari periode sebelumnya'}`
  },
  toRupiah (text) {
    return text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  },
  superRoles() {
    return [
      'SUPERADMIN',
      'COMMISSIONER',
      'CEO',
      'HEAD_TECHNOLOGY',
      'HEAD_OPERATION',
      'HEAD_SALES',
      'HEAD_FINANCE',
    ]
  },
  commissionerRoles() {
    return [
      'COMMISSIONER',
    ]
  },
  managementRoles() {
    return [
      'SUPERADMIN',
      'CEO',
      'HEAD_TECHNOLOGY',
      'HEAD_OPERATION',
      'HEAD_SALES',
      'HEAD_FINANCE',
    ]
  },
  managementStoreRoles() {
    return [
      'HEAD_STORE',
      'ADMIN_STORE',
    ]
  },
  
  titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
  },
  printReceipt (transaction) {
    const border = `<tr><td height="0.3" colspan="100" style="border-bottom:1.8px solid black;"></td></tr>`
    const space = `<tr><td style="padding-bottom: 3px; "></td></tr>`

    let address = ''
    let village = ''
    let district = ''
    let city = ''
    const storeAddress = transaction.store.address
    if (storeAddress !== undefined && storeAddress !== null) {
      address = storeAddress.address
      village = storeAddress.village.name
      district = storeAddress.district.name
      city = storeAddress.city.name
    }

    let addressMore = ''
    if (village !== '' && district !== '' && city !== '') {
      addressMore = this.titleCase(`${village}, ${district}, ${city}`)
    }

    let customer = ``
    let customerAddress = ``
    let pic = ``
    if (
      ['agent-web', 'agent-android', 'agent-ios'].includes(
        transaction.channel
      )
    ) {
      if (
        transaction.customer !== undefined &&
        transaction.customer !== null
      ) {
        customer = `
        <tr>
          <td style="vertical-align: top; width: 27%;">
            Customer
          </td>
          <td style="vertical-align: top;">
            : ${transaction.customer.full_name}
          </td>
        </tr>`

        if (
          transaction.customer.address !== undefined &&
          transaction.customer.address !== null &&
          transaction.customer.address.detail_address !== null 
        ) {
          customerAddress = `
          <tr>
            <td style="vertical-align: top; width: 27%;">
              Alamat
            </td>
            <td style="vertical-align: top;">
              : ${transaction.customer.address.detail_address}
            </td>
          </tr>
        `
        }
      }

      if (transaction.pic !== undefined &&
        transaction.pic !== null) {
        pic = `<tr>
                  <td style="vertical-align: top; width: 27%;">
                    Sales
                  </td>
                  <td style="vertical-align: top;">
                    : ${transaction.pic.full_name}
                  </td>
                </tr>`
      }
    } else {
      if (
        transaction.customer !== undefined &&
        transaction.customer !== null
      ) {
        customer = `
        <tr>
          <td style="vertical-align: top; width: 27%;">
            Customer
          </td>
          <td style="vertical-align: top;">
            : ${transaction.customer.full_name}
          </td>
        </tr>`

        if (
          transaction.customer.address !== undefined &&
          transaction.customer.address !== null &&
          transaction.customer.address.detail_address !== null  
        ) {
          customerAddress = `
          <tr>
            <td style="vertical-align: top; width: 27%;">
              Alamat
            </td>
            <td style="vertical-align: top;">
              : ${transaction.customer.address.detail_address}
            </td>
          </tr>
        `
        }
      }

      pic = `<tr>
              <td style="vertical-align: top; width: 27%;">
                Kasir
              </td>
              <td style="vertical-align: top;">
                : ${transaction.created_by.full_name}
              </td>
            </tr>`
    }

    

    let productPrice = ''
    let productName = ''
    let index = 1

    let items = ''

    transaction.items.map((item) => {
      if (index === 1) {
        productName = `${item.quantity} ${item.product.name}`
      } else {
        productName = `${productName} <br> ${item.quantity} ${item.product.name}`
      }

      productPrice = `${productPrice}<br> ${item.quantity * item.sale_price}`

      const totalPrice = this.formatCurrency(item.quantity * item.sale_price)

      const disc =
        item.quantity === 20
          ? `<br> Disc &nbsp (${this.formatCurrency(4000)})`
          : ''
      items += `
        <tr>
          <td style="text-align: left; vertical-align: top;" width="50%">
            ${item.product.name}
            ${disc}
          </td>
          <td style="text-align: center; vertical-align: top;" width="10%">
            ${item.quantity}
          </td>
          <td style="text-align: right; vertical-align: top;" width="20%">
            Rp${this.formatCurrency(item.sale_price)}
          </td>
          <td style="text-align: right; vertical-align: top;" width="20%">
            Rp${totalPrice}
          </td>
        </tr>
        `

      if (transaction.items.length !== index) {
        items += space
      }

      index++
    })

    printJS({
      type: 'raw-html',
      // @font-face {
      //     font-family: 'Fake Receipt';
      //     font-style: normal;
      //     font-weight: 400;
      //     src: local('Fake Receipt'), url('https://fonts.cdnfonts.com/s/6086/fake receipt.woff') format('woff');
      // }
      style: `
        @media print {
          html, body {
              font-family: monospace, sans-serif;;
          }
        }

        // @page {
        //   size: 21.59cm 13.97cm;
        // }

        body{
          width: 145mm;
          font-size: 0.85em;
          margin: 0;
          background: #ffffff;
          background-color: #ffffff;
          -webkit-font-smoothing: antialiased;
          font-family: monospace, sans-serif;
        }
        table {
          border-collapse: collapse;
          border-spacing: 0px;
        }
      `,
      printable: `
        <table width="100%" border="0" align="center" bgcolor="#ffffff">
          <tbody>
            <tr>
              <td style="vertical-align: top; width: 45%;">
                <table width="100%" border="0" align="left" bgcolor="#ffffff">
                  <tbody>
                    <tr>
                      <td style="vertical-align: top;">
                        <strong>${transaction.store.name}</strong>
                        <br>
                        ${transaction.store.phone}
                      </td>
                    </tr>
                    <tr><td style="padding-bottom: 4px;"></td></tr>
                    <tr>
                      <td style="vertical-align: top;">
                        ${address}, ${addressMore}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style="vertical-align: top; width: 5%;">
                <table width="100%" border="0" align="left" bgcolor="#ffffff">
                </table>
              </td>
              <td style="vertical-align: top; width: 50%;">
                <table width="100%" border="0" align="left" bgcolor="#ffffff">
                  <tbody>
                    <tr>
                      <td style="vertical-align: top; width: 27%;">
                        No Nota
                      </td>
                      <td style="vertical-align: top;">
                        : #${transaction.code}
                      </td>
                    </tr>
                    <tr>
                      <td style="vertical-align: top; width: 27%;">
                        Tgl.Order
                      </td>
                      <td style="vertical-align: top;">
                        : ${this.formattedDate(transaction.created, 'YYYY-MM-DD HH:mm:ss')}
                      </td>
                    </tr>
                    ${pic}
                    ${customer}
                    ${customerAddress}
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table width="100%" border="0" bgcolor="#ffffff">
          <tbody>
            ${space}
            ${space}
            ${border}
            <tr>
              <td style="text-align: left; vertical-align: top;" width="50%">
                Produk
              </td>
              <td style="text-align: center; vertical-align: top;" width="10%">
                Qty
              </td>
              <td style="text-align: right; vertical-align: top;" width="20%">
                Harga Produk
              </td>
              <td style="text-align: right; vertical-align: top;" width="20%">
                Total
              </td>
            </tr>
            ${border}
            ${space}
            ${space}
            ${items}
            ${space}
            ${space}
            ${border}
          </tbody>
        </table>
        <table width="100%" border="0" bgcolor="#ffffff">
          <tbody>
            ${space}
            <tr>
              <td style="text-align: left; vertical-align: top;">
                KARYA KREASI NASIONAL PT (BCA)
              </td>
              <td style="text-align: right; vertical-align: top;">
                Subtotal ${transaction.items.length} Produk:
              </td>
              <td style="text-align: right; vertical-align: top;" width="20%">
                Rp${this.formatCurrency(transaction.total)}
              </td>
            </tr>
            <tr>
              <td style="text-align: left; vertical-align: top;">
                7295755091
              </td>
              <td style="text-align: right; vertical-align: top;">
                Diskon:
              </td>
              <td style="text-align: right; vertical-align: top;" width="20%">
                Rp${this.formatCurrency('0')}
              </td>
            </tr>
          </tbody>
        </table>
        <table width="100%" border="0" cellpadding="0">
          <tbody>
            ${space}
            <tr>
              <td style="text-align: left;">
                Harga sudah termasuk PPN 11%
              </td>
              <td style="text-align: right;">
                <b>Total:</b>
              </td>
              <td style="text-align:right; white-space:nowrap; " width="20%">
                <b>Rp${this.formatCurrency(transaction.total)}</b>
              </td>
            </tr>
          </tbody>
        </table>
      `,
    })
  }
}

const install = (app) => {
  app.config.globalProperties.$h = helpers
}

export { install as default, helpers as helper }
