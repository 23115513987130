import axios, { routePathAPI } from '@/plugins/axios.js'
import { helper as $h } from '@/utils/helper'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  summaryCustomerTurnOver({ commit }, queryParameters) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}/analytic/customer/turnover/summary?` +
            $h.serializeObjectToQueryParam({ ...queryParameters })
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCustomerTurnOver({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}/analytic/customer/turnover?` +
            $h.serializeObjectToQueryParam({ ...params })
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  downloadCustomerTurnOver({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}/analytic/customer/turnover/download?` +
            $h.serializeObjectToQueryParam({ ...params }),
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              dispotition: response.headers['content-disposition'],
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
