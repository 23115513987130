<template>
  <div class="flex flex-col">
    <div class="intro-y col-span-12">
      <div class="box">
        <div class="flex w-full px-5 pt-5">
          <div class="flex">
            <div class="image-fit zoom-in h-16 w-16">
              <ImageLoader
                :image="activeUser.avatar"
                :alt="activeUser.first_name"
                rounded-class="rounded-full"
                @click="imagePreview(activeUser)"
              />
            </div>
          </div>
          <div
            class="ml-5 flex w-full flex-col text-slate-600 dark:text-slate-500"
          >
            <div class="flex">
              <span class="text-base font-bold md:text-lg">
                {{ activeUser.first_name }} {{ activeUser.last_name }}
              </span>
            </div>
            <div class="flex">
              <span v-if="activeUser.type" class="text-success">
                {{ activeUser.type }}
              </span>
              <span v-if="activeUser.role_names" class="text-success">
                {{ roleNames(activeUser.role_names) }}
              </span>
              <span
                v-if="
                  activeUser.area_district !== undefined &&
                  activeUser.area_district !== null
                "
                class="text-success"
              >
                &nbsp({{ activeUser.area_district.name }})
              </span>
            </div>
          </div>
        </div>
        <div
          class="flex w-full flex-col px-5 pb-5 text-slate-600 dark:text-slate-500"
        >
          <div class="mt-4 flex flex-wrap">
            <div class="flex w-1/3 md:w-1/4">
              <LinkIcon class="mr-2 h-4 w-4" />
              Bergabung
            </div>
            <div class="flex w-2/3 md:w-3/4">
              <span
                >: {{ new Date(activeUser.created).toLocaleDateString() }}</span
              >
            </div>
          </div>
          <!-- <div class="mt-2 flex">
            <div class="flex w-1/3 md:w-1/4">
              <UsersIcon class="mr-2 h-4 w-4" /> Tipe
            </div>
            <div class="flex w-2/3 md:w-3/4">
              <span>: {{ activeUser.type }}</span>
            </div>
          </div> -->
          <div class="mt-2 flex">
            <div class="flex w-1/3 md:w-1/4">
              <MailIcon class="mr-2 h-4 w-4" /> Email
            </div>
            <div class="flex w-2/3 md:w-3/4">
              <span>: {{ activeUser.email }}</span>
            </div>
          </div>
          <div class="mt-2 flex">
            <div class="flex w-1/3 md:w-1/4">
              <PhoneIcon class="mr-2 h-4 w-4" /> No Telpon
            </div>
            <div class="flex w-2/3 md:w-3/4">
              <span>: {{ activeUser.phone }}</span>
            </div>
          </div>
          <div v-if="'address' in activeUser" class="mt-2 flex">
            <div class="flex w-1/3 md:w-1/4">
              <MapPinIcon class="mr-2 h-4 w-4" /> Alamat
            </div>
            <div class="flex w-2/3 md:w-3/4">
              <span
                >: {{ activeUser.address.detail_address }},
                {{ activeUser.address.postal_code }}</span
              >
            </div>
          </div>
          <div v-if="'address' in activeUser" class="mt-1 flex">
            <div class="flex w-1/3 md:w-1/4"></div>
            <div class="flex w-2/3 md:w-3/4">
              <span
                >{{
                  activeUser.address.village !== undefined &&
                  activeUser.address.village !== null
                    ? activeUser.address.village.name
                    : null
                }},
                {{
                  activeUser.address.district !== undefined &&
                  activeUser.address.district !== null
                    ? activeUser.address.district.name
                    : null
                }},
                {{
                  activeUser.address.city !== undefined &&
                  activeUser.address.city !== null
                    ? activeUser.address.city.name
                    : null
                }},
                {{
                  activeUser.address.province !== undefined &&
                  activeUser.address.province !== null
                    ? activeUser.address.province.name
                    : null
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Currency from '@/components/Currency.vue'
import ImageLoader from '@/components/image-loader/Main.vue'
import globalMixin from '@/mixins/global.js'

export default {
  name: 'DetailUser',
  components: {
    Currency,
    ImageLoader,
  },
  mixins: [globalMixin],
  props: {
    activeUser: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data: () => ({}),
  mounted() {},
  methods: {},
}
</script>
