import axios, { routePathAPI } from '@/plugins/axios.js'
import { helper as $h } from '@/utils/helper'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  getAll({ commit }, payload) {
    let queryParameters = {
      order: payload.order ?? 'ASC',
      page: payload.page ?? 1,
      take: payload.take ?? 50,
      // is_accessories: payload.is_accessories,
    }
    if (payload.q) queryParameters.q = payload.q

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}/product/category?` +
            $h.serializeObjectToQueryParam({ ...queryParameters })
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSingle({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/product/category/` + id)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  download({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/product/category/download`, {
          // params: {
          //   start_date: payload.start_date,
          //   end_date: payload.end_date,
          // },
          // headers: {
          //   'x-language-code': currentLanguageCode,
          // },
          responseType: 'blob',
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              dispotition: response.headers['content-disposition'],
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  create({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}/product/category/create`, payload)
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${routePathAPI()}/product/category/update/` + payload.id, {
          code: payload.code,
          name: payload.name,
        })
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${routePathAPI()}/product/category/delete/` + id)
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
