<template>
  <div class="bg-gray-50 font-bold p-2 text-emerald-700 rounded-xl w-12 text-center">
    {{ (page !== 1 ? (page - 1) * take : 0) + index + 1 }}
  </div>
</template>

<script>
export default {
  name: 'Number',
  props: {
    index: {
      type: Number,
      default: 0,
      required: true,
    },
    page: {
      type: Number,
      default: 0,
      required: true,
    },
    take: {
      type: Number,
      default: 0,
      required: true,
    },
  },
}
</script>
