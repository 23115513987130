<template>
  <div class="rounded-full border font-semibold bg-cyan-50 text-cyan-600 border-cyan-200 pl-2 pr-3 flex items-center py-1 text-xs mb-2">
    <ChevronRightIcon class="h-3 w-3 mr-1" />{{ name }}
  </div>
</template>

<script>
export default {
  name: 'SubCategory',
  props: {
    name: {
      type: String,
      default: null,
      required: true,
    },
  },
}
</script>
