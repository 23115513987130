<template>
  <div
    class="group z-50 fixed bottom-6 right-4 flex h-20 w-20 items-end justify-end md:bottom-10 md:right-6"
    @click="onClick()"
  >
    <div
      class="absolute z-50 flex cursor-pointer items-center justify-center rounded-full bg-gradient-to-r from-teal-700 to-primary p-3 text-white shadow-xl"
    >
      <LoadingIcon v-if="isLoading" icon="oval" color="white" class="h-8 w-8" />
      <PlusIcon
        v-else
        class="h-8 w-8 transition transition-all duration-[0.6s] group-hover:rotate-90"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonAdd',
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
</script>
