<template>
  <div class="intro-y mt-8 flex flex-col items-center sm:flex-row">
    <h2 class="mr-auto text-lg font-medium">Add New Post</h2>
    <div class="mt-4 flex w-full sm:mt-0 sm:w-auto">
      <Dropdown class="mr-2">
        <DropdownToggle class="btn box flex items-center">
          English <ChevronDownIcon class="ml-2 h-4 w-4" />
        </DropdownToggle>
        <DropdownMenu class="w-40">
          <DropdownContent>
            <DropdownItem>
              <ActivityIcon class="mr-2 h-4 w-4" />
              <span class="truncate">English</span>
            </DropdownItem>
            <DropdownItem>
              <ActivityIcon class="mr-2 h-4 w-4" />
              <span class="truncate">Indonesian</span>
            </DropdownItem>
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>
      <button
        type="button"
        class="btn box mr-2 ml-auto flex items-center sm:ml-0"
      >
        <EyeIcon class="mr-2 h-4 w-4" /> Preview
      </button>
      <Dropdown>
        <DropdownToggle class="btn bg-gradient-to-r from-teal-700 to-primary text-white flex items-center shadow-md">
          Save <ChevronDownIcon class="ml-2 h-4 w-4" />
        </DropdownToggle>
        <DropdownMenu class="w-40">
          <DropdownContent>
            <DropdownItem>
              <FileTextIcon class="mr-2 h-4 w-4" /> As New Post
            </DropdownItem>
            <DropdownItem>
              <FileTextIcon class="mr-2 h-4 w-4" /> As Draft
            </DropdownItem>
            <DropdownItem>
              <FileTextIcon class="mr-2 h-4 w-4" /> Export to PDF
            </DropdownItem>
            <DropdownItem>
              <FileTextIcon class="mr-2 h-4 w-4" /> Export to Word
            </DropdownItem>
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>
    </div>
  </div>
  <div class="pos intro-y mt-5 grid grid-cols-12 gap-5">
    <!-- BEGIN: Post Content -->
    <div class="intro-y col-span-12 lg:col-span-8">
      <input
        type="text"
        class="intro-y form-control box py-3 px-4 pr-10"
        placeholder="Title"
      />
      <TabGroup class="post intro-y box mt-5 overflow-hidden">
        <TabList
          class="post__tabs nav-tabs flex-col bg-slate-200 dark:bg-darkmode-800 sm:flex-row"
        >
          <Tab :fullWidth="false" class="w-full py-0 px-0 sm:w-40" tag="button">
            <Tippy
              content="Fill in the article content"
              class="tooltip flex w-full items-center justify-center py-4"
              aria-controls="content"
              aria-selected="true"
            >
              <FileTextIcon class="mr-2 h-4 w-4" /> Content
            </Tippy>
          </Tab>
          <Tab :fullWidth="false" class="w-full py-0 px-0 sm:w-40" tag="button">
            <Tippy
              content="Adjust the meta title"
              class="tooltip flex w-full items-center justify-center py-4"
              aria-selected="false"
            >
              <CodeIcon class="mr-2 h-4 w-4" /> Meta Title
            </Tippy>
          </Tab>
          <Tab :fullWidth="false" class="w-full py-0 px-0 sm:w-40" tag="button">
            <Tippy
              content="Use search keywords"
              class="tooltip flex w-full items-center justify-center py-4"
              aria-selected="false"
            >
              <AlignLeftIcon class="mr-2 h-4 w-4" /> Keywords
            </Tippy>
          </Tab>
        </TabList>
        <TabPanels class="post__content">
          <TabPanel class="p-5">
            <div
              class="rounded-md border border-slate-200/60 p-5 dark:border-darkmode-400"
            >
              <div
                class="flex items-center border-b border-slate-200/60 pb-5 font-medium dark:border-darkmode-400"
              >
                <ChevronDownIcon class="mr-2 h-4 w-4" /> Text Content
              </div>
              <div class="mt-5">
                <ClassicEditor v-model="editorData" />
              </div>
            </div>
            <div
              class="mt-5 rounded-md border border-slate-200/60 p-5 dark:border-darkmode-400"
            >
              <div
                class="flex items-center border-b border-slate-200/60 pb-5 font-medium dark:border-darkmode-400"
              >
                <ChevronDownIcon class="mr-2 h-4 w-4" /> Caption & Images
              </div>
              <div class="mt-5">
                <div>
                  <label for="post-form-7" class="form-label">Caption</label>
                  <input
                    id="post-form-7"
                    type="text"
                    class="form-control"
                    placeholder="Write caption"
                  />
                </div>
                <div class="mt-3">
                  <label class="form-label">Upload Image</label>
                  <div
                    class="rounded-md border-2 border-dashed pt-4 dark:border-darkmode-400"
                  >
                    <div class="flex flex-wrap px-4">
                      <div
                        v-for="(faker, fakerKey) in $_.take($f(), 4)"
                        :key="fakerKey"
                        class="image-fit zoom-in relative mb-5 mr-5 h-24 w-24 cursor-pointer"
                      >
                        <img
                          class="rounded-md"
                          alt="Gromura - SCM"
                          :src="faker.images[0]"
                        />
                        <Tippy
                          tag="div"
                          content="Remove this image?"
                          class="absolute right-0 top-0 -mr-2 -mt-2 flex h-5 w-5 items-center justify-center rounded-full bg-danger text-white"
                        >
                          <xIcon class="h-4 w-4" />
                        </Tippy>
                      </div>
                    </div>
                    <div
                      class="relative flex cursor-pointer items-center px-4 pb-4"
                    >
                      <ImageIcon class="mr-2 h-4 w-4" />
                      <span class="mr-1 text-primary">Upload a file</span> or
                      drag and drop
                      <input
                        type="file"
                        class="absolute top-0 left-0 h-full w-full opacity-0"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
    <!-- END: Post Content -->
    <!-- BEGIN: Post Info -->
    <div class="col-span-12 lg:col-span-4">
      <div class="intro-y box p-5">
        <div>
          <label class="form-label">Written By</label>
          <Dropdown>
            <DropdownToggle
              tag="div"
              class="btn btn-outline-secondary flex w-full items-center justify-start dark:border-darkmode-800 dark:bg-darkmode-800"
              role="button"
            >
              <div class="image-fit mr-3 h-6 w-6">
                <img
                  class="rounded"
                  alt="Gromura - SCM"
                  :src="$f()[0].users[0].image"
                />
              </div>
              <div class="truncate">{{ $f()[0].users[0].name }}</div>
              <ChevronDownIcon class="ml-auto h-4 w-4" />
            </DropdownToggle>
            <DropdownMenu class="w-full">
              <DropdownContent>
                <DropdownItem
                  v-for="(faker, fakerKey) in $_.take($f(), 5)"
                  :key="fakerKey"
                >
                  <div class="image-fit absolute mr-3 h-6 w-6">
                    <img
                      class="rounded"
                      alt="Gromura - SCM"
                      :src="faker.users[0].image"
                    />
                  </div>
                  <div class="ml-8 pl-1">{{ faker.users[0].name }}</div>
                </DropdownItem>
              </DropdownContent>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div class="mt-3">
          <label for="post-form-2" class="form-label">Post Date</label>
          <Litepicker
            id="post-form-2"
            v-model="salesReportFilter"
            :options="{
              autoApply: false,
              showWeekNumbers: true,
              dropdowns: {
                minYear: 1990,
                maxYear: null,
                months: true,
                years: true,
              },
            }"
            class="form-control"
          />
        </div>
        <div class="mt-3">
          <label for="post-form-3" class="form-label">Categories</label>
          <TomSelect
            id="post-form-3"
            v-model="categories"
            class="w-full"
            multiple
          >
            <option value="1">Horror</option>
            <option value="2">Sci-fi</option>
            <option value="3">Action</option>
            <option value="4">Drama</option>
            <option value="5">Comedy</option>
          </TomSelect>
        </div>
        <div class="mt-3">
          <label for="post-form-4" class="form-label">Tags</label>
          <TomSelect id="post-form-4" v-model="tags" class="w-full" multiple>
            <option value="1">Leonardo DiCaprio</option>
            <option value="2">Johnny Deep</option>
            <option value="3">Robert Downey, Jr</option>
            <option value="4">Samuel L. Jackson</option>
            <option value="5">Morgan Freeman</option>
          </TomSelect>
        </div>
        <div class="form-check form-switch mt-3 flex flex-col items-start">
          <label for="post-form-5" class="form-check-label ml-0 mb-2"
            >Published</label
          >
          <input id="post-form-5" class="form-check-input" type="checkbox" />
        </div>
        <div class="form-check form-switch mt-3 flex flex-col items-start">
          <label for="post-form-6" class="form-check-label ml-0 mb-2"
            >Show Author Name</label
          >
          <input id="post-form-6" class="form-check-input" type="checkbox" />
        </div>
      </div>
    </div>
    <!-- END: Post Info -->
  </div>
</template>

<script setup>
import { ref } from 'vue'

const categories = ref(['1', '2'])
const tags = ref(['1', '2'])
const salesReportFilter = ref('')
const editorData = ref('<p>Content of the editor.</p>')
</script>
