<template>
  <div class="mt-0 grid grid-cols-12 gap-6 md:mt-5">
    <div class="intro-y col-span-12 mt-2 flex flex-wrap items-center">
      <div class="flex w-full md:w-auto">
        <p class="intro-y text-base md:text-lg font-medium">Ringkasan Turnover</p>
      </div>
      <div class="mx-auto hidden text-slate-500 md:block"></div>
      <div class="relative w-full md:w-max mt-3 text-slate-500 md:mt-0">
        <CalendarIcon
          class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
        />
        <Litepicker
          v-model="filterDate"
          :options="$h.litePickerConfig()"
          class="form-control box pl-10 sm:w-56 litepicker-right"
          @change="getSummaryData"
        />
      </div>
    </div>
  </div>
  <div class="mt-3 grid grid-cols-12 gap-3 md:gap-6 md:mt-5">
    <!-- <div class="intro-y col-span-12 flex flex-wrap items-center">
      <div class="-mb-2 md:-mb-4 flex w-full md:w-auto">
        <p class="intro-y text-xs md:text-sm italic text-slate-500">Turnover</p>
      </div>
    </div> -->

    <div class="intro-y col-span-12 lg:col-span-6">
      <div class="box p-5">
        <div class="flex w-full mb-4">
          <span class="text-sm font-semibold">Customer Turnover</span>
        </div>
        <div class="flex flex-col md:flex-row items-center justify-between">
          <div class="relative">
            <DonutChart
              :data="[Number(reportData.turnover_average_r1), Number(reportData.turnover_average_r2), Number(reportData.turnover_average_r3)]"
              :labels="['R1', 'R2', 'R3']"
              :colors="[colors.info(0.9), colors.success(0.9), colors.danger(0.9)]"
              :height="200"
            />
            <div
              class="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-center"
            >
              <div class="text-xl font-medium 2xl:text-2xl">{{ reportData.turnover_average }}x</div>
              <div class="mt-0.5 text-slate-500">Rata - Rata</div>
            </div>
          </div>
          <div class="w-full mt-4 md:mt-0 justify-center">
            <div class="flex justify-between md:justify-start items-center">
              <div class="flex w-20 items-center">
                <div class="mr-3 h-2 w-2 rounded-full bg-sky-600"></div>
                <span class="truncate">R1</span>
              </div>
              <p><span class="font-semibold">{{ reportData.turnover_average_r1 }}</span>x</p>
            </div>
            <div class="mt-4 flex justify-between md:justify-start items-center">
              <div class="flex w-20 items-center">
                <div class="mr-3 h-2 w-2 rounded-full bg-green-600"></div>
                <span class="truncate">R2</span>
              </div>
              <p><span class="font-semibold">{{ reportData.turnover_average_r2 }}</span>x</p>
            </div>
            <div class="mt-4 flex justify-between md:justify-start items-center">
              <div class="flex w-20 items-center">
                <div class="mr-3 h-2 w-2 rounded-full bg-red-600"></div>
                <span class="truncate">R3</span>
              </div>
              <p><span class="font-semibold">{{ reportData.turnover_average_r3 }}</span>x</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="intro-y col-span-12 lg:col-span-6">
      <div class="box p-5">
        <div class="flex w-full mb-4">
          <span class="text-sm font-semibold">Kategori Transaksi</span>
        </div>
        <div class="flex flex-col md:flex-row items-center justify-between">
          <div class="relative">
            <DonutChart
              :data="[Number(reportData.transaction_r1), Number(reportData.transaction_r2), Number(reportData.transaction_r3)]"
              :labels="['R1', 'R2', 'R3']"
              :colors="[colors.info(0.9), colors.success(0.9), colors.danger(0.9)]"
              :height="200"
            />
            <div
              class="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-center"
            >
              <div class="text-xl font-medium 2xl:text-2xl">{{ reportData.transaction_total }}</div>
              <div class="mt-0.5 text-slate-500">Total Semua</div>
            </div>
          </div>
          <div class="w-full mt-4 md:mt-0 justify-center">
            <div class="flex justify-between md:justify-start items-center">
              <div class="flex w-20 items-center">
                <div class="mr-3 h-2 w-2 rounded-full bg-sky-600"></div>
                <span class="truncate">R1</span>
              </div>
              <span class="font-semibold">{{ reportData.transaction_r1 }}</span>
            </div>
            <div class="mt-4 flex justify-between md:justify-start items-center">
              <div class="flex w-20 items-center">
                <div class="mr-3 h-2 w-2 rounded-full bg-green-600"></div>
                <span class="truncate">R2</span>
              </div>
              <span class="font-semibold">{{ reportData.transaction_r2 }}</span>
            </div>
            <div class="mt-4 flex justify-between md:justify-start items-center">
              <div class="flex w-20 items-center">
                <div class="mr-3 h-2 w-2 rounded-full bg-red-600"></div>
                <span class="truncate">R3</span>
              </div>
              <span class="font-semibold">{{ reportData.transaction_r3 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="intro-y col-span-6 lg:col-span-3">
      <CardReport
        title="Rata - Rata"
        :total="reportData.turnover_average"
        :loading="isSummaryLoading"
      />
    </div>
    <div class="intro-y col-span-6 lg:col-span-3">
      <CardReport
        title="R1"
        :total="reportData.turnover_average_r1"
        :loading="isSummaryLoading"
        color="info"
      />
    </div>
    <div class="intro-y col-span-6 lg:col-span-3">
      <CardReport
        title="R2"
        :total="reportData.turnover_average_r2"
        :loading="isSummaryLoading"
        color="success"
      />
    </div>
    <div class="intro-y col-span-6 lg:col-span-3">
      <CardReport
        title="R3"
        :total="reportData.turnover_average_r3"
        :loading="isSummaryLoading"
        color="danger"
      />
    </div> -->

    <!-- <div class="intro-y col-span-12 flex flex-wrap items-center">
      <div class="-mb-2 md:-mb-4 flex w-full md:w-auto">
        <p class="intro-y text-xs md:text-sm italic text-slate-500">Transaksi</p>
      </div>
    </div>
    <div class="intro-y col-span-6 lg:col-span-3">
      <CardReport
        title="Total"
        :total="reportData.transaction_total"
        :loading="isSummaryLoading"
      />
    </div>
    <div class="intro-y col-span-6 lg:col-span-3">
      <CardReport
        title="R1"
        :total="reportData.transaction_r1"
        :loading="isSummaryLoading"
        title-classes="!text-cyan-500"
        color="info"
      />
    </div>
    <div class="intro-y col-span-6 lg:col-span-3">
      <CardReport
        title="R2"
        :total="reportData.transaction_r2"
        :loading="isSummaryLoading"
        title-classes="!text-green-500"
        color="success"
      />
    </div>
    <div class="intro-y col-span-6 lg:col-span-3">
      <CardReport
        title="R3"
        :total="reportData.transaction_r3"
        :loading="isSummaryLoading"
        title-classes="!text-red-500"
        color="danger"
      />
    </div> -->
  </div>
  <div class="mt-8 grid grid-cols-12 gap-2 md:mt-8">
    <!-- <div class="intro-y col-span-12 md:flex md:border border-dashed md:mb-4"> </div> -->
    <div class="intro-y col-span-12 flex">
      <p class="intro-y text-base md:text-lg font-medium">List Turnover</p>
    </div>
    <div class="intro-y col-span-12 mt-2 flex flex-wrap items-center">
      <div class="flex w-full md:w-auto">
        <SearchFilter
          :value="filterData.search"
          placeholder="Cari warung..."
          @search-data="searchFilter"
        />
        <ButtonFilter
          :show-length="true"
          :filter-length="filterLength"
          @click="openFilter"
          class="ml-2 md:ml-10"
        />
      </div>
      <div class="mx-auto hidden text-slate-500 md:block"></div>
      <div
        v-if="$h.roleCanManage($h.superRoles(), roleAccess)"
        class="flex mt-3 items-center md:mt-0 xl:mt-0 xl:w-auto"
      >
        <ExportCSVComponent @export="download" />
      </div>
    </div>
    <!-- BEGIN: Order Detail Table -->
    <TableComponent
      :show-loading="isLoading"
      :show-title="false"
      :show-search="false"
      :header="tableHeader"
      :meta-data="metaData"
      :is-not-empty="tableData.length > 0"
      class="-mt-4 md:mt-0"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="(user, index) in tableData" :key="user.id" class="intro-x">
        <td class="!py-3.5">
          <div class="bg-gray-50 font-bold p-2 text-emerald-700 rounded-xl w-12 text-center">
            {{ (metaData.page !== 1 ? (metaData.page - 1) * metaData.take : 0) + index + 1 }}
          </div>
        </td>
        <!-- <td class="w-10">
            <input class="form-check-input" type="checkbox" />
          </td> -->
        <td class="!py-3.5">
          <div class="flex items-center">
            <div class="image-fit zoom-in h-9 w-9">
              <ImageLoader
                :image="user.avatar"
                :alt="`${user.first_name} ${user.last_name}`"
                rounded-class="rounded-full"
              />
            </div>
          </div>
        </td>
        <td class="cursor-pointer" @click="customerPreview(user)">
          <div class="flex">
            <a href="#" class="whitespace-nowrap font-medium"
              >{{ user.first_name }} {{ user.last_name }}</a
            >
            <div class="mt-0.5 whitespace-nowrap text-xs text-slate-500">
              {{ user.username }}
            </div>
          </div>
        </td>
        <td class="">
          {{ user.phone !== '' ? user.phone : '-' }}
        </td>
        <td class="text-center capitalize">
          <span>{{ user.type }}</span>
        </td>
        <td class="text-center capitalize">
          <span>{{ user.turnover.total }}</span>
        </td>
        <td class="w-96">
          <div class="flex">
            <div
              v-for="date in user.turnover.dates"
              :key="date"
            >
              <div class="m-0.5 rounded-full border border-lime-400 bg-lime-50 text-lime-600 text-xs md:text-sm px-3 py-1 w-max">
                {{ date }}
              </div>
            </div>
          </div>
        </td>
      </tr>
    </TableComponent>
    <!-- END: Order Detail Table -->
  </div>

  <!-- DETAIL MODAL -->
  <Modal :show="customerPreviewModal" @hidden="customerPreviewModal = false">
    <ModalBody class="p-0">
      <DetailUser
        v-if="activeCustomer !== undefined"
        :active-user="activeCustomer"
      />
    </ModalBody>
  </Modal>

  <!-- FILTER MODAL -->
  <Modal :show="filterModal" @hidden="filterModal = false">
      <ModalHeader>
        <div class="flex justify-between w-full items-center">
          <h2 class="mr-auto text-lg font-bold">Filter</h2>
          <XIcon class="h-5 w-5 text-red-500 cursor-pointer" @click="filterModal = false" />
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="flex w-full flex-col">
          <span class="text-sm font-bold">Urutkan</span>
          <div class="mt-4 flex w-full flex-col">
            <span class="text-xs">Turnover</span>
            <div
              v-for="sort in sortingOptions"
              :key="sort.id"
              class="form-check mt-2 flex justify-between border-b py-3 cursor-pointer"
              @click="checkFilter(sort.value, 'order')"
            >
              <div class="flex">
                <ArrowsUpDownIcon class="h-4" />
                <label class="form-check-label ml-2" :for="sort.id">{{
                  sort.label
                }}</label>
              </div>
              <input
                :id="sort.id"
                v-model="filterData.order"
                :value="sort.value"
                class="form-check-input"
                type="radio"
                name="filter-sorting"
                />
                <!-- @input="checkFilter($event, 'order')" -->
            </div>
          </div>

          <span class="mt-6 text-sm font-bold">Berdasarkan</span>
          <div class="mt-4 flex w-full flex-col">
            <span class="text-xs">Rentang Waktu</span>
            <div class="relative mt-1 w-full text-slate-500 sm:ml-auto">
              <CalendarIcon
                class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
              />
              <Litepicker
                v-model="filterData.filter_date"
                :options="$h.litePickerConfig()"
                class="form-control box w-full border border-gray-300 pl-8"
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          title="Terapkan Filter"
          color="gradient"
          title-classes="w-full"
          @click="applyFilter"
        />
      </ModalFooter>
    </Modal>

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">
        {{ message }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import Toastify from 'toastify-js'
import globalMixin from '@/mixins/global.js'
import useEmitter from '@/composables/useEmitter'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import DetailUser from '@/components/detail-user/Main.vue'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'
import SearchFilter from '@/components/filter/Search.vue'
import ButtonFilter from '@/components/button/ButtonFilter.vue'
import CardReport from '@/components/card/CardReport.vue'
import DonutChart from '@/components/donut-chart/Main.vue'
import { ArrowsUpDownIcon } from '@heroicons/vue/24/solid'
import { colors } from '@/utils/colors'

export default {
  components: {
    ImageLoader,
    TableComponent,
    DetailUser,
    ExportCSVComponent,
    SearchFilter,
    ButtonFilter,
    CardReport,
    ArrowsUpDownIcon,
    DonutChart
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const emitter = useEmitter()

    const activeUser = reactive({})
    const roleAccess = ref('')

    const filterModal = ref(false)
    const customerPreviewModal = ref(false)
    const activeCustomer = reactive({})

    const isLoading = ref(false)
    const isSummaryLoading = ref(false)
    const timer = ref(undefined)

    const status = ref('')
    const message = ref('')


    let reportData = reactive({
        turnover_average: 0,
        turnover_average_r1: 0,
        turnover_average_r2: 0,
        turnover_average_r3: 0,
        transaction_total: 0,
        transaction_r1: 0,
        transaction_r2: 0,
        transaction_r3: 0,
    })

    const sortingOptions = ref([
      {
        id: 1,
        label: 'TERBARU-TERLAMA',
        value: 'DESC',
      },
      {
        id: 2,
        label: 'TERLAMA-TERBARU',
        value: 'ASC',
      },
    ])

    const tableHeader = ref([
      {
        item: 'NO',
        customClass: '',
      },
      {
        item: 'AVATAR',
        customClass: '',
      },
      {
        item: 'TOKO',
        customClass: '',
      },
      {
        item: 'NO. TELEPON',
        customClass: '',
      },
      {
        item: 'TYPE',
        customClass: 'text-center',
      },
      {
        item: 'TURNOVER',
        customClass: 'text-center',
      },
      {
        item: 'DETAIL',
        customClass: '!w-96',
      },
    ])

    let activeDataId = ref(null)

    let tableData = ref([])

    let metaData = reactive({
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    const starDateOfMonth = dayjs().startOf('month').format("YYYY-MM-DD")
    const endDateOfMonth = dayjs().endOf('month').format("YYYY-MM-DD")

    let filterData = reactive({
      page: 1,
      take: 10,
      search: '',
      order: sortingOptions.value[0].value,
      source: null,
      status: null,
      filter_date: `${starDateOfMonth} - ${endDateOfMonth}`,
    })

    let filterLength = ref(1);
    const filterDate = ref(`${starDateOfMonth} - ${endDateOfMonth}`)

    watch(filterDate, (currentValue, oldValue) => {
      getSummaryData()
    })

    onMounted(async () => {
      // clearing all event
      emitter.all.clear()

      Object.assign(activeUser, store.getters['auth/activeUser'])

      roleAccess.value = activeUser.role_codes

      emitter.on('update-data', (store) => {
        getSummaryData()
        getAllData(true)
      })

      const storeId = store.getters['store/storeId']

      if (storeId === null) {
        setTimeout(() => {
          getSummaryData()
          getAllData()
        }, 2000)
      } else {
        getSummaryData()
        getAllData()
      }
    })

    const getSummaryData = async () => {
      isSummaryLoading.value = true
      const storeId = store.getters['store/storeId']

      let queryParameters = {
        store_id: storeId,
      }
      const dateRange = filterDate.value.split(' - ')
      if (dateRange.hasOwnProperty(0)) queryParameters.start_date = dateRange[0]
      if (dateRange.hasOwnProperty(1)) queryParameters.end_date = dateRange[1]

      await store
        .dispatch('analytic/summaryCustomerTurnOver', queryParameters)
        .then((response) => {
          const responseData = response.data

          Object.assign(reportData, responseData)
        
          isSummaryLoading.value = false
        })
        .catch((e) => {
          isSummaryLoading.value = false
          console.log(e)
        })
    }

    const getAllData = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        tableData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }

      const storeId = store.getters['store/storeId']
      const dateRange = filterData.filter_date.split(' - ')

      let queryParameters = {
        store_id: storeId,
        order: filterData.order,
        page: filterData.page,
        take: filterData.take,
      }
      if (dateRange.hasOwnProperty(0))
        queryParameters.start_date = dateRange[0]
      if (dateRange.hasOwnProperty(1)) queryParameters.end_date = dateRange[1]
      if (filterData.search) queryParameters.q = filterData.search

      await store
        .dispatch('analytic/getCustomerTurnOver', queryParameters)
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            tableData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const download = async (fileType) => {
      const storeId = store.getters['store/storeId']
      const dateRange = filterData.filter_date.split(' - ')

      let queryParameters = {
        store_id: storeId,
        file_type: fileType,
        order: filterData.order,
      }

      if (dateRange.hasOwnProperty(0))
        queryParameters.start_date = dateRange[0]
      if (dateRange.hasOwnProperty(1)) queryParameters.end_date = dateRange[1]
      if (filterData.search) queryParameters.q = filterData.search


      await store
        .dispatch('analytic/downloadCustomerTurnOver', queryParameters)
        .then((response) => {
          const filename = response.dispotition.split('"')[1]
          saveAs(response.data, filename)
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const openFilter = () => {
      filterModal.value = true
    }

    const checkFilter = (value, _target) => {
      if (_target === 'order') {
        filterData.order = value
      }
    }

    const applyFilter = () => {
      filterModal.value = false
      getAllData()
    }

    const changeFilter = (event) => {
      Object.assign(filterData, {
        ...filterData,
        page: Number(event.filterData.page),
        take: Number(event.filterData.take),
      })
      getAllData()
    }

    const searchFilter = (val) => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        filterData.search = val
        getAllData(true)
      }, 500)
    }

    const calculateFilterTotal = () => {
      const sortByTotal = 1;
      const sourceTotal = filterData.source === null ? 0 : filterData.source.split(",").length
      const statusTotal = filterData.status === null ? 0 : filterData.status.split(",").length

      filterLength.value = sortByTotal + sourceTotal + statusTotal;
    }

    const customerPreview = async (customer) => {
      Object.assign(activeCustomer, customer)
      customerPreviewModal.value = true
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    return {
      t,
      n,
      store,
      route,
      router,

      activeUser,

      filterModal,
      customerPreviewModal,
      customerPreview,
      activeCustomer,

      roleAccess,

      isLoading,
      isSummaryLoading,
      timer,

      status,
      message,

      tableHeader,
      tableData,
      metaData,

      reportData,

      openFilter,
      checkFilter,
      applyFilter,
      searchFilter,
      changeFilter,

      sortingOptions,

      filterData,
      filterDate,
      filterLength,
      
      activeDataId,
      getSummaryData,
      getAllData,
      download,

      showToast,
      colors
    }
  },
}
</script>
