<template>
  <button
    class="inline-flex w-full justify-center rounded-xl transition duration-150 ease-in-out px-4 py-2"
    :class="[buttonClass, classes !== '' ? classes : '']"
    @click="onClick()"
    :disabled="loading"
  >
    <svg
      v-if="loading"
      class="mr-3 -ml-1 h-5 w-5 animate-spin"
      :class="svgClass"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <p class="flex items-center">
      <slot></slot>{{ title }}
    </p>
  </button>
</template>

<script>
export default {
  name: 'ButtonDefault',
  components: {},
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    classes: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['click'],
  data: () => ({
    buttonClass: '',
    svgClass: '',
  }),
  watch: {
    color(val) {
      this.getClassButton(val)
    },
  },
  mounted() {
    this.getClassButton(this.color)
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
    getClassButton(color) {
      switch (color) {
        case 'gradient':
          this.buttonClass =
            'bg-gradient-to-r from-teal-700 to-primary hover:opacity-90 text-light -darker'
          this.svgClass = 'text-light'
          break
        case 'primary-dark':
          this.buttonClass =
            'bg-teal-600 hover:opacity-90 text-light -darker'
          this.svgClass = 'text-light'
          break
        case 'primary':
          this.buttonClass =
            'bg-primary opacity-100 hover:opacity-90 text-light -darker'
          this.svgClass = 'text-light'
          break
        case 'secondary':
          this.buttonClass =
            'vs-button vs-button-filled vs-button-secondary text-light'
          this.svgClass = 'text-light'
          break
        case 'danger':
          this.buttonClass =
            'bg-danger opacity-100 hover:opacity-90 text-light'
          this.svgClass = 'text-light'
          break
        case 'primary-outline':
          this.buttonClass = 'border-primary text-primary'
          this.svgClass = 'text-primary'
          break
        case 'secondary-outline':
          this.buttonClass = 'border-secondary text-secondary'
          this.svgClass = 'text-secondary'
          break
        case 'danger-outline':
          this.buttonClass = 'border-danger text-danger'
          this.svgClass = 'text-danger'
          break
        default:
          this.buttonClass = 'bg-gray-400 text-white'
          this.svgClass = 'text-white'
          break
      }
    },
  },
}
</script>
