<template>
  <h2 class="intro-y mt-2 md:mt-6 text-lg font-medium">Reviews</h2>
  <div class="mt-5 grid grid-cols-12 gap-6">
    <div
      class="intro-y col-span-12 mt-2 flex flex-wrap items-center xl:flex-nowrap"
    >
      <div class="flex w-full sm:w-auto">
        <div class="relative w-48 text-slate-500">
          <input
            type="text"
            class="form-control box w-48 pr-10"
            placeholder="Cari by name..."
          />
          <SearchIcon class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4" />
        </div>
        <select class="box form-select ml-2 w-48 xl:w-auto">
          <option>Status</option>
          <option>Active</option>
          <option>Removed</option>
        </select>
      </div>
      <div class="mx-auto hidden text-slate-500 xl:block">
        Showing 1 to 10 of 150 entries
      </div>
      <div
        class="mt-3 flex w-full flex-wrap items-center gap-y-3 xl:mt-0 xl:w-auto xl:flex-nowrap"
      >
        <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white mr-2 shadow-md">
          <FileTextIcon class="mr-2 h-4 w-4" /> Export to Excel
        </button>
        <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white mr-2 shadow-md">
          <FileTextIcon class="mr-2 h-4 w-4" /> Export to PDF
        </button>
        <Dropdown>
          <DropdownToggle class="btn box px-2">
            <span class="flex h-5 w-5 items-center justify-center">
              <PlusIcon class="h-4 w-4" />
            </span>
          </DropdownToggle>
          <DropdownMenu class="w-40">
            <DropdownContent>
              <DropdownItem>
                <ArrowLeftRightIcon class="mr-2 h-4 w-4" /> Change Status
              </DropdownItem>
              <DropdownItem>
                <BookmarkIcon class="mr-2 h-4 w-4" /> Bookmark
              </DropdownItem>
            </DropdownContent>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
      <table class="table-report -mt-2 table">
        <thead>
          <tr>
            <th class="whitespace-nowrap">
              <input class="form-check-input" type="checkbox" />
            </th>
            <th class="whitespace-nowrap">PRODUCT</th>
            <th class="whitespace-nowrap">NAME</th>
            <th class="whitespace-nowrap">RATING</th>
            <th class="whitespace-nowrap text-center">POSTED TIME</th>
            <th class="whitespace-nowrap text-center">STATUS</th>
            <th class="whitespace-nowrap text-center">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(faker, fakerKey) in $_.take($f(), 9)"
            :key="fakerKey"
            class="intro-x"
          >
            <td class="w-10">
              <input class="form-check-input" type="checkbox" />
            </td>
            <td class="!py-4">
              <div class="flex items-center">
                <div class="image-fit zoom-in h-10 w-10">
                  <Tippy
                    tag="img"
                    alt="Gromura - SCM"
                    class="border-1 rounded-lg border-white shadow-md"
                    :src="faker.products[0].images[0]"
                    :content="`Uploaded at ${faker.dates[0]}`"
                  />
                </div>
                <a href="" class="ml-4 whitespace-nowrap font-medium">{{
                  faker.products[0].name
                }}</a>
              </div>
            </td>
            <td class="whitespace-nowrap">
              <a
                class="flex items-center underline decoration-dotted"
                href="javascript:;"
                >{{ faker.users[0].name }}</a
              >
            </td>
            <td class="text-center">
              <div class="flex items-center">
                <div class="flex items-center">
                  <StarIcon class="mr-1 h-4 w-4 fill-pending/30 text-pending" />
                  <StarIcon class="mr-1 h-4 w-4 fill-pending/30 text-pending" />
                  <StarIcon class="mr-1 h-4 w-4 fill-pending/30 text-pending" />
                  <StarIcon class="mr-1 h-4 w-4 fill-pending/30 text-pending" />
                  <StarIcon class="fill-slate/30 mr-1 h-4 w-4 text-slate-400" />
                </div>
                <div class="ml-1 text-xs text-slate-500">(4.5+)</div>
              </div>
            </td>
            <td class="whitespace-nowrap text-center">
              {{ faker.formattedTimes[0] }}
            </td>
            <td class="w-40">
              <div
                class="flex items-center justify-center"
                :class="{
                  'text-success': faker.trueFalse[0],
                  'text-danger': !faker.trueFalse[0],
                }"
              >
                <CheckSquareIcon class="mr-2 h-4 w-4" />
                {{ faker.trueFalse[0] ? 'Active' : 'Removed' }}
              </div>
            </td>
            <td class="table-report__action w-56">
              <div class="flex items-center justify-center">
                <a
                  class="flex items-center whitespace-nowrap text-primary"
                  href="javascript:;"
                >
                  <CheckSquareIcon class="mr-1 h-4 w-4" /> View Details
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->
    <!-- BEGIN: Pagination -->
    <div
      class="intro-y col-span-12 flex flex-wrap items-center sm:flex-row sm:flex-nowrap"
    >
      <nav class="w-full sm:mr-auto sm:w-auto">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronsLeftIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronLeftIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">...</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">1</a>
          </li>
          <li class="page-item active">
            <a class="page-link" href="#">2</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">3</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">...</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronRightIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronsRightIcon class="h-4 w-4" />
            </a>
          </li>
        </ul>
      </nav>
      <select class="box form-select mt-3 w-20 sm:mt-0">
        <option>10</option>
        <option>25</option>
        <option>35</option>
        <option>50</option>
      </select>
    </div>
    <!-- END: Pagination -->
  </div>
  <!-- BEGIN: Delete Confirmation Modal -->
  <Modal
    :show="deleteConfirmationModal"
    @hidden="deleteConfirmationModal = false"
  >
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5 text-3xl">Are you sure?</div>
        <div class="mt-2 text-slate-500">
          Do you really want to delete these records? <br />This process cannot
          be undone.
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="deleteConfirmationModal = false"
          class="btn btn-outline-secondary mr-1 w-24"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-danger w-24">Delete</button>
      </div>
    </ModalBody>
  </Modal>
  <!-- END: Delete Confirmation Modal -->
</template>

<script setup>
import { ref } from 'vue'

const deleteConfirmationModal = ref(false)
</script>
