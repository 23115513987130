import axios, { routePathAPI } from '@/plugins/axios.js'
import { helper as $h } from '@/utils/helper'

// initial state
const state = () => ({
  storeId: null,
  storeName: null,
})

// getters
const getters = {
  storeId(state) {
    if (state.storeId === null) {
      return localStorage.getItem('gromura.storeId') !== null ? localStorage.getItem('gromura.storeId') : null
    }
    return state.storeId
  },
  storeName(state) {
    if (state.storeName === null) {
      return localStorage.getItem('gromura.storeName') !== null ? localStorage.getItem('gromura.storeName') : null
    }
    return state.storeName
  },
}

// actions
const actions = {
  getAll({ commit }, payload) {
    let queryParameters = {
      // store_id: payload.store_id,
      order: payload.order ?? 'ASC',
      page: payload.page ?? 1,
      take: payload.take ?? 50,
    }
    if (payload.q) queryParameters.q = payload.q

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}/store?` +
            $h.serializeObjectToQueryParam({ ...queryParameters })
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSingle({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/store/${id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  updateStoreIdState({ commit, state }, storeId) {
    return commit('SET_STORE_ID', storeId)
  },
  updateStoreNameState({ commit, state }, storeName) {
    return commit('SET_STORE_NAME', storeName)
  },
}

// mutations
const mutations = {
  SET_STORE_ID(state, storeId) {
    state.storeId = storeId
    localStorage.setItem('gromura.storeId', storeId)
  },

  SET_STORE_NAME(state, storeName) {
    state.storeName = storeName
    localStorage.setItem('gromura.storeName', storeName)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
