<template>
  <h2 class="intro-y mt-2 md:mt-6 text-lg font-medium">Data Direksi</h2>
  <ButtonAdd
    v-if="$h.roleCanManage($h.commissionerRoles(), roleAccess) === false"
    :is-loading="isButtonLoading" />
  <div class="mt-0 grid grid-cols-12 gap-6 md:mt-5">
    <div class="intro-y col-span-12 mt-2 flex flex-wrap items-center">
      <div class="flex w-full md:w-auto">
        <div class="relative w-full text-slate-500 md:w-48 h-9.5">
          <input
            v-model="filterData.search"
            type="text"
            class="form-control box w-full pr-8 md:w-56 h-10"
            placeholder="Cari direksi..."
            @input="searchFilter()"
          />
          <SearchIcon
            class="absolute inset-y-0 right-0 my-auto mr-4 h-4 w-4 md:-mr-4"
          />
        </div>
        <button class="btn box ml-2 shadow-sm md:ml-10" @click="openFilter">
          <FilterIcon class="h-4 w-4" />
        </button>
      </div>
      <div class="mx-auto hidden text-slate-500 md:block"></div>
      <div class="mt-3 flex items-center md:mt-0 xl:mt-0 xl:w-auto">
        <!-- <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white mr-2 text-white">
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button> -->
        <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white  text-white">
          <FileTextIcon class="mr-2 h-4 w-4" /> Export ke Excel
        </button>
      </div>
    </div>
    <!-- BEGIN: Order Detail Table -->
    <TableComponent
      :show-loading="isLoading"
      :show-title="false"
      :show-search="false"
      :header="tableHeader"
      :meta-data="metaData"
      :is-not-empty="tableData.length > 0"
      class="-mt-4 md:mt-0"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="(user, index) in tableData" :key="user.id" class="intro-x">
        <td class="!py-3.5">
          <div class="bg-gray-50 font-bold p-2 text-emerald-700 rounded-xl w-12 text-center">
            {{ (metaData.page !== 1 ? (metaData.page - 1) * metaData.take : 0) + index + 1 }}
          </div>
        </td>
        <!-- <td class="w-10">
            <input class="form-check-input" type="checkbox" />
          </td> -->
        <td class="!py-3.5">
          <div class="flex items-center">
            <div class="image-fit zoom-in h-9 w-9">
              <!-- <Tippy
                  tag="img"
                  alt="Gromura - SCM"
                  class="rounded-lg border-white shadow-md"
                  :src="faker.users[0].image"
                  :content="`Uploaded at ${faker.dates[0]}`"
                /> -->
              <ImageLoader
                :image="user.avatar"
                :alt="`${user.first_name} ${user.last_name}`"
                rounded-class="rounded-full"
              />
            </div>
            <div class="ml-4">
              <a href="#" class="whitespace-nowrap font-medium"
                >{{ user.first_name }} {{ user.last_name }}</a
              >
              <div class="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                {{ user.username }}
              </div>
            </div>
          </div>
        </td>
        <td class="text-center text-xs text-slate-600 dark:text-slate-500">
          {{ user.email }}
        </td>
        <td class="text-center">
          {{ user.phone ?? '-' }}
        </td>
        <td class="text-center capitalize">
          <Alert
            v-if="user.gender === 'Male'"
            className="alert-success text-white rounded-xl text-xs py-1 px-2"
            >{{ user.gender }}</Alert
          >
          <Alert
            v-else
            className="alert-primary text-white rounded-xl text-xs py-1 px-2"
            >{{ user.gender }}</Alert
          >
        </td>
        <td class="text-center capitalize">
          <!-- <div
            v-if="user.role_codes.includes('SUPERADMIN')"
            className="text-secondary"
          >
            {{ user.role_names.toString() }}
          </div>
          <div
            v-else-if="user.role_codes.includes('CEO')"
            className="text-indigo-600"
          >
            {{ user.role_names.toString() }}
          </div>
          <div
            v-else-if="user.role_codes.includes('HEAD_FINANCE')"
            className="text-warning"
          >
            {{ user.role_names.toString() }}
          </div>
          <div
            v-else-if="user.role_codes.includes('HEAD_SALES')"
            className="text-success"
          >
            {{ user.role_names.toString() }}
          </div>
          <div
            v-else-if="user.role_codes.includes('HEAD_OPERATION')"
            className="text-danger"
          >
            {{ user.role_names.toString() }}
          </div> -->
          <div className="font-bold text-sky-500 text-center text-xs">
            {{ roleNames(user.role_names) }}
          </div>
        </td>
        <!-- <td class="text-center">
          <template v-if="user.store">
            <span
              class="flex items-center justify-center underline decoration-dotted"
              >{{ user.store.name }}</span
            >
          </template>
          <template v-else> - </template>
        </td> -->
        <!-- <td class="w-40">
            <div
              class="flex items-center justify-center"
              :class="{
                'text-success': faker.trueFalse[0],
                'text-danger': !faker.trueFalse[0],
              }"
            >
              <CheckSquareIcon class="mr-2 h-4 w-4" />
              {{ faker.trueFalse[0] ? 'Active' : 'Inactive' }}
            </div>
          </td> -->
        <td class="table-report__action w-32">
          <div 
            v-if="$h.roleCanManage($h.commissionerRoles(), roleAccess) === false"
            class="flex items-center justify-center text-success">
            <a class="mr-3 flex items-center" href="javascript:;">
              <CheckSquareIcon class="mr-1 h-4 w-4" /> Edit
            </a>
            <!-- <a
              class="flex items-center text-danger"
              href="javascript:;"
              @click="deleteConfirmationModal = true"
            >
              <Trash2Icon class="mr-1 h-4 w-4" /> Delete
            </a> -->
          </div>
          <div v-else class="flex items-center justify-center text-success">
            -
          </div>
        </td>
      </tr>
    </TableComponent>
    <!-- END: Order Detail Table -->
  </div>
  <!-- BEGIN: Delete Confirmation Modal -->
  <!-- <Modal
    :show="deleteConfirmationModal"
    @hidden="deleteConfirmationModal = false"
  >
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5 text-3xl">Are you sure?</div>
        <div class="mt-2 text-slate-500">
          Do you really want to delete these records? <br />This process cannot
          be undone.
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="deleteConfirmationModal = false"
          class="btn btn-outline-secondary mr-1 w-24"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-danger w-24">Delete</button>
      </div>
    </ModalBody>
  </Modal> -->
  <!-- END: Delete Confirmation Modal -->
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import globalMixin from '@/mixins/global.js'
import useEmitter from '@/composables/useEmitter'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import ButtonAdd from '@/components/button/ButtonAdd.vue'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Currency,
    ButtonAdd,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const emitter = useEmitter()

    const activeUser = reactive({})
    const roleAccess = ref('')
    const roles = ref('')

    const activeUserStaff = reactive({})

    const isFormLoading = ref(false)
    const isLoading = ref(false)
    const isButtonLoading = ref(false)

    const timer = ref(undefined)

    const userImagePreview = ref('')
    const imageFile = ref(null)

    const userPreviewModal = ref(false)
    const imagePreviewModal = ref(false)
    const addEditModal = ref(false)
    const deleteModal = ref(false)

    const status = ref('')
    const message = ref('')

    const typeOptions = ref([
      {
        id: 'R1',
        name: 'RI',
      },
      {
        id: 'R2',
        name: 'R2',
      },
      {
        id: 'R3',
        name: 'R3',
      },
      {
        id: 'MODERN_MARKET',
        name: 'Modern Market',
      },
    ])

    const titleModal = ref('')

    const tableHeader = ref([
      {
        item: 'NO',
        customClass: '',
      },
      {
        item: 'USER',
        customClass: '',
      },
      {
        item: 'EMAIL',
        customClass: 'text-center',
      },
      {
        item: 'NO. TELEPON',
        customClass: 'text-center',
      },
      {
        item: 'GENDER',
        customClass: 'text-center',
      },
      {
        item: 'ROLE',
        customClass: 'text-center w-36',
      },
      // {
      //   item: 'CABANG',
      //   customClass: 'text-center',
      // },
      {
        item: 'AKSI',
        customClass: 'text-center',
      },
    ])

    let activeDataId = ref(null)

    let tableData = ref([])

    let metaData = reactive({
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 10,
      search: '',
      order: 'ASC',
      type: null,
    })

    const activeData = reactive({
      first_name: '',
      last_name: '',
      type: '',
      email: '',
      phone: '',
      username: '',
      gender: '',
    })

    const formData = reactive({
      first_name: '',
      last_name: '',
      type: '',
      email: '',
      phone: '',
      username: '',
      gender: '',
    })

    onMounted(async () => {
      // clearing all event
      emitter.all.clear()

      Object.assign(activeUser, store.getters['auth/activeUser'])

      roleAccess.value = activeUser.role_codes

      getAllData()
    })

    const previewImage = (file) => {
      // no need resize
      if (file.size < 1024) {
        userImagePreview.value = window.URL.createObjectURL(file)
        imageFile.value = file
      }
      const targetWidth = 1000
      resizeImg(file, targetWidth, (resized) => {
        userImagePreview.value = window.URL.createObjectURL(resized)
        imageFile.value = resized
      })
    }

    const selectFile = () => {
      const hidden = document.getElementById('hidden-input')
      hidden.click()
      hidden.onchange = (e) => {
        previewImage(e.target.files[0])
      }
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const getAllData = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        tableData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }

      await store
        .dispatch('user/getAll', {
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          manager_only: true,
          q: filterData.search,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            tableData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const changeFilter = (event) => {
      Object.assign(filterData, {
        ...filterData,
        page: Number(event.filterData.page),
        take: Number(event.filterData.take),
      })
      getAllData()
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getAllData(true)
      }, 500)
    }

    const userPreview = async (user) => {
      Object.assign(activeUserStaff, user)
      userPreviewModal.value = true
    }

    const imagePreview = async (user) => {
      Object.assign(activeUserStaff, user)
      imagePreviewModal.value = true
    }

    return {
      t,
      n,
      store,
      route,
      router,

      activeUser,
      activeUserStaff,

      roleAccess,
      roles,

      userImagePreview,
      imageFile,
      selectFile,
      previewImage,

      addEditModal,
      deleteModal,
      userPreviewModal,
      imagePreviewModal,

      userPreview,
      imagePreview,

      isFormLoading,
      isLoading,
      isButtonLoading,
      timer,

      status,
      message,

      titleModal,
      tableHeader,
      tableData,
      metaData,
      filterData,

      formData,
      activeData,

      activeDataId,
      getAllData,
      typeOptions,

      showToast,
      changeFilter,
      searchFilter,
    }
  },
}
</script>
