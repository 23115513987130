<template>
  <div class="flex text-xs font-bold text-emerald-700">
    <!-- <WindIcon class="h-4 hidden md:flex" /> -->
    {{ $h.formattedDate(date, "YYYY-MM-DD HH:mm:ss") }}
  </div>
</template>

<script>
import { helper as $h } from '@/utils/helper'

export default {
  name: 'Date',
  props: {
    date: {
      type: String,
      required: true,
    }
  },
}
</script>
