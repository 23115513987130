<template>
  <div class="mt-3 grid grid-cols-12 gap-3 md:gap-6 md:mt-5">
    <div class="intro-y col-span-12">
      <div class="box p-6 overflow-hidden">
        <iframe
          :src="iframeSrc"
          class="w-full h-auto min-h-screen overflow-hidden"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import Toastify from 'toastify-js'
import globalMixin from '@/mixins/global.js'
import useEmitter from '@/composables/useEmitter'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import DetailUser from '@/components/detail-user/Main.vue'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'
import SearchFilter from '@/components/filter/Search.vue'
import ButtonFilter from '@/components/button/ButtonFilter.vue'
import CardReport from '@/components/card/CardReport.vue'
import DonutChart from '@/components/donut-chart/Main.vue'
import { ArrowsUpDownIcon } from '@heroicons/vue/24/solid'
import { colors } from '@/utils/colors'

export default {
  components: {
    ImageLoader,
    TableComponent,
    DetailUser,
    ExportCSVComponent,
    SearchFilter,
    ButtonFilter,
    CardReport,
    ArrowsUpDownIcon,
    DonutChart
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const emitter = useEmitter()

    const storeId = ref('c7bfe8bb-27ce-423c-8fb1-af51136e86a7')
    const noPadding = true
    const noShadow = true
    const baseURL = 'https://predictor.gromura.com/'
    const iframeSrc = ref(`${baseURL}?store_id=${storeId.value}&no_padding=${noPadding}&no_shadow=${noShadow}`);

    const activeUser = reactive({})
    const roleAccess = ref('')

    const filterModal = ref(false)
    const customerPreviewModal = ref(false)
    const activeCustomer = reactive({})

    const isLoading = ref(false)
    const isSummaryLoading = ref(false)
    const timer = ref(undefined)

    const status = ref('')
    const message = ref('')


    let reportData = reactive({
        turnover_average: 0,
        turnover_average_r1: 0,
        turnover_average_r2: 0,
        turnover_average_r3: 0,
        transaction_total: 0,
        transaction_r1: 0,
        transaction_r2: 0,
        transaction_r3: 0,
    })

    const sortingOptions = ref([
      {
        id: 1,
        label: 'TERBARU-TERLAMA',
        value: 'DESC',
      },
      {
        id: 2,
        label: 'TERLAMA-TERBARU',
        value: 'ASC',
      },
    ])

    const tableHeader = ref([
      {
        item: 'NO',
        customClass: '',
      },
      {
        item: 'AVATAR',
        customClass: '',
      },
      {
        item: 'TOKO',
        customClass: '',
      },
      {
        item: 'NO. TELEPON',
        customClass: '',
      },
      {
        item: 'TYPE',
        customClass: 'text-center',
      },
      {
        item: 'TURNOVER',
        customClass: 'text-center',
      },
      {
        item: 'DETAIL',
        customClass: '!w-96',
      },
    ])

    let activeDataId = ref(null)

    let tableData = ref([])

    let metaData = reactive({
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    const starDateOfMonth = dayjs().startOf('month').format("YYYY-MM-DD")
    const endDateOfMonth = dayjs().endOf('month').format("YYYY-MM-DD")

    let filterData = reactive({
      page: 1,
      take: 10,
      search: '',
      order: sortingOptions.value[0].value,
      source: null,
      status: null,
      filter_date: `${starDateOfMonth} - ${endDateOfMonth}`,
    })

    let filterLength = ref(1);
    const filterDate = ref(`${starDateOfMonth} - ${endDateOfMonth}`)

    watch(filterDate, (currentValue, oldValue) => {
      getSummaryData()
    })

    onMounted(async () => {
      // clearing all event
      emitter.all.clear()

      Object.assign(activeUser, store.getters['auth/activeUser'])

      roleAccess.value = activeUser.role_codes

      emitter.on('update-data', (store) => {
        getSummaryData()
        getAllData(true)
      })

      const storeId = store.getters['store/storeId']

      if (storeId === null) {
        setTimeout(() => {
          getSummaryData()
          getAllData()
        }, 2000)
      } else {
        getSummaryData()
        getAllData()
      }
    })

    const getSummaryData = async () => {
      isSummaryLoading.value = true
      const storeId = store.getters['store/storeId']

      let queryParameters = {
        store_id: storeId,
      }
      const dateRange = filterDate.value.split(' - ')
      if (dateRange.hasOwnProperty(0)) queryParameters.start_date = dateRange[0]
      if (dateRange.hasOwnProperty(1)) queryParameters.end_date = dateRange[1]

      await store
        .dispatch('analytic/summaryCustomerTurnOver', queryParameters)
        .then((response) => {
          const responseData = response.data

          Object.assign(reportData, responseData)
        
          isSummaryLoading.value = false
        })
        .catch((e) => {
          isSummaryLoading.value = false
          console.log(e)
        })
    }

    const getAllData = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        tableData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }

      const storeId = store.getters['store/storeId']
      const dateRange = filterData.filter_date.split(' - ')

      let queryParameters = {
        store_id: storeId,
        order: filterData.order,
        page: filterData.page,
        take: filterData.take,
      }
      if (dateRange.hasOwnProperty(0))
        queryParameters.start_date = dateRange[0]
      if (dateRange.hasOwnProperty(1)) queryParameters.end_date = dateRange[1]
      if (filterData.search) queryParameters.q = filterData.search

      await store
        .dispatch('analytic/getCustomerTurnOver', queryParameters)
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            tableData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const download = async (fileType) => {
      const storeId = store.getters['store/storeId']
      const dateRange = filterData.filter_date.split(' - ')

      let queryParameters = {
        store_id: storeId,
        file_type: fileType,
        order: filterData.order,
      }

      if (dateRange.hasOwnProperty(0))
        queryParameters.start_date = dateRange[0]
      if (dateRange.hasOwnProperty(1)) queryParameters.end_date = dateRange[1]
      if (filterData.search) queryParameters.q = filterData.search


      await store
        .dispatch('analytic/downloadCustomerTurnOver', queryParameters)
        .then((response) => {
          const filename = response.dispotition.split('"')[1]
          saveAs(response.data, filename)
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const openFilter = () => {
      filterModal.value = true
    }

    const checkFilter = (value, _target) => {
      if (_target === 'order') {
        filterData.order = value
      }
    }

    const applyFilter = () => {
      filterModal.value = false
      getAllData()
    }

    const changeFilter = (event) => {
      Object.assign(filterData, {
        ...filterData,
        page: Number(event.filterData.page),
        take: Number(event.filterData.take),
      })
      getAllData()
    }

    const searchFilter = (val) => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        filterData.search = val
        getAllData(true)
      }, 500)
    }

    const calculateFilterTotal = () => {
      const sortByTotal = 1;
      const sourceTotal = filterData.source === null ? 0 : filterData.source.split(",").length
      const statusTotal = filterData.status === null ? 0 : filterData.status.split(",").length

      filterLength.value = sortByTotal + sourceTotal + statusTotal;
    }

    const customerPreview = async (customer) => {
      Object.assign(activeCustomer, customer)
      customerPreviewModal.value = true
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    return {
      t,
      n,
      store,
      route,
      router,

      iframeSrc,

      activeUser,

      filterModal,
      customerPreviewModal,
      customerPreview,
      activeCustomer,

      roleAccess,

      isLoading,
      isSummaryLoading,
      timer,

      status,
      message,

      tableHeader,
      tableData,
      metaData,

      reportData,

      openFilter,
      checkFilter,
      applyFilter,
      searchFilter,
      changeFilter,

      sortingOptions,

      filterData,
      filterDate,
      filterLength,
      
      activeDataId,
      getSummaryData,
      getAllData,
      download,

      showToast,
      colors
    }
  },
}
</script>
