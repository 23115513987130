import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import i18n from './plugins/i18n.js'
import axios from './plugins/axios.js'
import globalComponents from './global-components'
import utils from './utils'
import store from '@/stores/vuex'
import VueLoadImage from 'vue-load-image'
import Shimmer from 'vue3-shimmer'
import VueClipboard from 'vue3-clipboard'
import { saveAs } from 'file-saver'
import vSelect from 'vue-select'
import VueBarcode from '@chenfengyuan/vue-barcode'
import VueMeta from 'vue-meta'

import '@vueform/multiselect/themes/default.css'
import './assets/css/app.css'
import 'vue-select/dist/vue-select.css'

import dayjs from 'dayjs'

import VueImageZoomer from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';

import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import 'swiper/css/free-mode'
import 'swiper/css/mousewheel'

import mitt from 'mitt';
const emitter = mitt();

// const app = createApp(App).use(router).use(createPinia())
const app = createApp(App)

app.use(store)
app.use(createPinia())
app.use(i18n)
app.use(router)
app.use(Shimmer)
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})
app.use(VueImageZoomer);
// app.use(VueMeta, {
//   // optional pluginOptions
//   refreshOnceOnNavigation: true
// })

app.provide('axios', axios) // provide axios
app.provide('saveAs', saveAs) // provide axios
app.provide('dayjs', dayjs.locale('id')) // provide dayJS

// app.prototype.$axios = axios
// app.config.globalProperties.$http = axios

app.component('VueLoadImage', VueLoadImage)
// eslint-disable-next-line vue/component-definition-name-casing
app.component('v-select', vSelect)
app.component(VueBarcode.name, VueBarcode)
app.component('Swiper', Swiper)
app.component('SwiperSlide', SwiperSlide)

app.config.globalProperties.emitter = emitter;

globalComponents(app)

utils(app)

app.mount('#app')
