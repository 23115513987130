<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 2xl:col-span-9">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-8 xl:col-span-9">
          <div class="intro-y flex h-10 items-center">
            <h2 class="mr-5 truncate text-lg font-medium">General Report</h2>
            <a href="" class="ml-auto truncate text-primary">Show More</a>
          </div>
          <div class="report-box-2 intro-y mt-5">
            <div class="box grid grid-cols-12">
              <div
                class="col-span-12 flex flex-col justify-center px-8 py-12 lg:col-span-4"
              >
                <PieChartIcon class="h-10 w-10 text-pending" />
                <div
                  class="mt-12 flex items-center justify-start text-slate-600 dark:text-slate-300"
                >
                  My Total Assets
                  <AlertCircleIcon
                    class="tooltip ml-1.5 h-4 w-4"
                    title="Total value of your sales: $158.409.416"
                  />
                </div>
                <div class="mt-4 flex items-center justify-start">
                  <div class="relative ml-0.5 pl-3 text-2xl font-medium">
                    <span
                      class="absolute top-0 left-0 -ml-0.5 text-xl font-medium"
                      >$</span
                    >
                    1,413,102.02
                  </div>
                  <a class="ml-4 text-slate-500" href="">
                    <RefreshCcwIcon class="h-4 w-4" />
                  </a>
                </div>
                <div class="mt-4 text-xs text-slate-500">
                  Last updated 1 hour ago
                </div>
                <button
                  class="btn btn-outline-secondary relative mt-12 justify-start rounded-full"
                >
                  Download Reports
                  <span
                    class="absolute right-0 top-0 bottom-0 my-auto ml-auto mr-0.5 flex h-8 w-8 items-center justify-center rounded-full bg-primary text-white"
                  >
                    <ArrowRightIcon class="h-4 w-4" />
                  </span>
                </button>
              </div>
              <div
                class="col-span-12 border-t border-dashed border-slate-200 p-8 dark:border-darkmode-300 lg:col-span-8 lg:border-t-0 lg:border-l"
              >
                <TabGroup>
                  <TabList
                    class="nav-pills mx-auto mb-8 w-60 rounded-md border border-dashed border-slate-300 p-1 dark:border-darkmode-300"
                  >
                    <Tab class="w-full py-1.5 px-2" tag="button"> Weekly</Tab>
                    <Tab class="w-full py-1.5 px-2" tag="button"> Monthly</Tab>
                  </TabList>
                  <TabPanels class="px-5 pb-5">
                    <TabPanel class="grid grid-cols-12 gap-y-8 gap-x-10">
                      <div class="col-span-6 sm:col-span-6 md:col-span-4">
                        <div class="text-slate-500">Unpaid Loan</div>
                        <div class="mt-1.5 flex items-center">
                          <div class="text-base">4.501</div>
                          <div
                            class="tooltip ml-2 flex cursor-pointer text-xs font-medium text-danger"
                            title="2% Lower than last month"
                          >
                            2%
                            <ChevronDownIcon class="ml-0.5 h-4 w-4" />
                          </div>
                        </div>
                      </div>
                      <div class="col-span-12 sm:col-span-6 md:col-span-4">
                        <div class="text-slate-500">Active Partner</div>
                        <div class="mt-1.5 flex items-center">
                          <div class="text-base">2</div>
                        </div>
                      </div>
                      <div class="col-span-12 sm:col-span-6 md:col-span-4">
                        <div class="text-slate-500">Paid Installment</div>
                        <div class="mt-1.5 flex items-center">
                          <div class="text-base">$72.000</div>
                        </div>
                      </div>
                      <div class="col-span-12 sm:col-span-6 md:col-span-4">
                        <div class="text-slate-500">Disbursement</div>
                        <div class="mt-1.5 flex items-center">
                          <div class="text-base">$54.000</div>
                        </div>
                      </div>
                      <div class="col-span-12 sm:col-span-6 md:col-span-4">
                        <div class="text-slate-500">Success Payment</div>
                        <div class="mt-1.5 flex items-center">
                          <div class="text-base">2.500</div>
                          <div
                            class="tooltip ml-2 flex cursor-pointer text-xs font-medium text-success"
                            title="52% Higher than last month"
                          >
                            52%
                            <ChevronUpIcon class="ml-0.5 h-4 w-4" />
                          </div>
                        </div>
                      </div>
                      <div class="col-span-12 sm:col-span-6 md:col-span-4">
                        <div class="text-slate-500">Unpaid Loan</div>
                        <div class="mt-1.5 flex items-center">
                          <div class="text-base">$72.000</div>
                        </div>
                      </div>
                      <div class="col-span-12 sm:col-span-6 md:col-span-4">
                        <div class="text-slate-500">Posted Campaign</div>
                        <div class="mt-1.5 flex items-center">
                          <div class="text-base">4.501</div>
                        </div>
                      </div>
                      <div class="col-span-12 sm:col-span-6 md:col-span-4">
                        <div class="text-slate-500">Social Media</div>
                        <div class="mt-1.5 flex items-center">
                          <div class="text-base">2</div>
                        </div>
                      </div>
                      <div class="col-span-12 sm:col-span-6 md:col-span-4">
                        <div class="text-slate-500">Net Margin</div>
                        <div class="mt-1.5 flex items-center">
                          <div class="text-base">$72.000</div>
                          <div
                            class="tooltip ml-2 flex cursor-pointer text-xs font-medium text-success"
                            title="49% Higher than last month"
                          >
                            49%
                            <ChevronUpIcon class="ml-0.5 h-4 w-4" />
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
        <!-- BEGIN: Sales Report -->
        <div
          class="col-span-12 row-start-4 mt-6 md:col-span-6 lg:col-span-4 lg:row-start-3 xl:col-span-3 xl:row-start-auto xl:mt-8"
        >
          <div class="intro-y flex h-10 items-center">
            <h2 class="mr-5 truncate text-lg font-medium">Sales Report</h2>
            <a href="" class="ml-auto truncate text-primary">Show More</a>
          </div>
          <div class="report-box-2 intro-y mt-5 before:hidden xl:before:block">
            <div class="box p-5">
              <div class="mt-3">
                <ReportDonutChart :height="196" />
              </div>
              <div class="mx-auto mt-8 w-52 sm:w-auto">
                <div class="flex items-center">
                  <div class="mr-3 h-2 w-2 rounded-full bg-primary"></div>
                  <span class="truncate">17 - 30 Years old</span>
                  <span class="ml-auto font-medium">62%</span>
                </div>
                <div class="mt-4 flex items-center">
                  <div class="mr-3 h-2 w-2 rounded-full bg-pending"></div>
                  <span class="truncate">31 - 50 Years old</span>
                  <span class="ml-auto font-medium">33%</span>
                </div>
                <div class="mt-4 flex items-center">
                  <div class="mr-3 h-2 w-2 rounded-full bg-warning"></div>
                  <span class="truncate">>= 50 Years old</span>
                  <span class="ml-auto font-medium">10%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Sales Report -->
        <!-- BEGIN: Official Store -->
        <div class="col-span-12 mt-6 xl:col-span-8">
          <div class="intro-y block h-10 items-center sm:flex">
            <h2 class="mr-5 truncate text-lg font-medium">Official Store</h2>
            <div class="relative mt-3 text-slate-500 sm:ml-auto sm:mt-0">
              <MapPinIcon
                class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
              />
              <input
                type="text"
                class="form-control box pl-10 sm:w-56"
                placeholder="Filter by city"
              />
            </div>
          </div>
          <div class="intro-y box mt-12 p-5 sm:mt-5">
            <div>
              250 Official stores in 21 countries, click the marker to see
              location details.
            </div>
            <ReportMap class="report-maps mt-5 rounded-md bg-slate-200" />
          </div>
        </div>
        <!-- END: Official Store -->
        <!-- BEGIN: Weekly Best Sellers -->
        <div class="col-span-12 mt-6 lg:col-span-8 xl:col-span-4">
          <div class="intro-y flex h-10 items-center">
            <h2 class="mr-5 truncate text-lg font-medium">
              Weekly Best Sellers
            </h2>
          </div>
          <div class="mt-5">
            <div
              v-for="(faker, fakerKey) in $_.take($f(), 4)"
              :key="fakerKey"
              class="intro-y"
            >
              <div class="box zoom-in mb-3 flex items-center px-4 py-4">
                <div
                  class="image-fit h-10 w-10 flex-none overflow-hidden rounded-md"
                >
                  <img alt="Gromura - SCM" :src="faker.users[0].image" />
                </div>
                <div class="ml-4 mr-auto">
                  <div class="font-medium">
                    {{ faker.users[0].name }}
                  </div>
                  <div class="mt-0.5 text-xs text-slate-500">
                    {{ faker.dates[0] }}
                  </div>
                </div>
                <div
                  class="cursor-pointer rounded-full bg-success py-1 px-2 text-xs font-medium text-white"
                >
                  137 Sales
                </div>
              </div>
            </div>
            <a
              href=""
              class="intro-y block w-full rounded-md border border-dotted border-slate-400 py-4 text-center text-slate-500 dark:border-darkmode-300"
              >View More</a
            >
          </div>
        </div>
        <!-- END: Weekly Best Sellers -->
        <!-- BEGIN: Visitors -->
        <div class="col-span-12 mt-6 md:col-span-6 lg:col-span-4">
          <div class="intro-y block h-10 items-center sm:flex">
            <h2 class="mr-5 truncate text-lg font-medium">Visitors</h2>
            <select class="box form-select mt-3 sm:ml-auto sm:mt-0 sm:w-auto">
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
              <option value="custom-date">Custom Date</option>
            </select>
          </div>
          <div class="intro-y box mt-12 p-5 sm:mt-5">
            <div
              class="mb-3 flex border-b border-dashed border-slate-200 pb-3 text-slate-500 dark:border-darkmode-300"
            >
              <div>Parameters</div>
              <div class="ml-auto">Report Values</div>
            </div>
            <div class="mb-5 flex items-center">
              <div class="flex items-center">
                <div>Site Visits</div>
                <div
                  class="tooltip ml-2 flex cursor-pointer text-xs font-medium text-danger"
                  title="49% Higher than last month"
                >
                  -2% <ChevronDownIcon class="ml-0.5 h-4 w-4" />
                </div>
              </div>
              <div class="ml-auto">4.500</div>
            </div>
            <div class="mb-5 flex items-center">
              <div class="flex items-center">
                <div>Unique Visitors</div>
              </div>
              <div class="ml-auto">21</div>
            </div>
            <div class="mb-5 flex items-center">
              <div class="flex items-center">
                <div>Page Views</div>
                <div
                  class="tooltip ml-2 flex cursor-pointer text-xs font-medium text-success"
                  title="49% Higher than last month"
                >
                  5% <ChevronUpIcon class="ml-0.5 h-4 w-4" />
                </div>
              </div>
              <div class="ml-auto">500</div>
            </div>
            <div class="mb-5 flex items-center">
              <div class="flex items-center">
                <div>Bounce Rate</div>
              </div>
              <div class="ml-auto">3.420</div>
            </div>
            <div class="mb-5 flex items-center">
              <div class="flex items-center">
                <div>% New Visits</div>
                <div
                  class="tooltip ml-2 flex cursor-pointer text-xs font-medium text-danger"
                  title="49% Higher than last month"
                >
                  -9% <ChevronDownIcon class="ml-0.5 h-4 w-4" />
                </div>
              </div>
              <div class="ml-auto">32%</div>
            </div>
            <div class="mb-5 flex items-center">
              <div class="flex items-center">
                <div>Average Tim On Site</div>
              </div>
              <div class="ml-auto">1.5M</div>
            </div>
            <button
              class="btn btn-outline-secondary relative mb-2 w-full justify-start border-dashed border-slate-300 dark:border-darkmode-300"
            >
              <span class="mr-5 truncate">View Full Report</span>
              <span
                class="absolute right-0 top-0 bottom-0 my-auto ml-auto mr-0.5 flex h-8 w-8 items-center justify-center"
              >
                <ArrowRightIcon class="h-4 w-4" />
              </span>
            </button>
          </div>
        </div>
        <!-- END: Visitors -->
        <!-- BEGIN: Most Viewed Pages -->
        <div class="col-span-12 mt-6 md:col-span-6 lg:col-span-4">
          <div class="intro-y block h-10 items-center sm:flex">
            <h2 class="mr-5 truncate text-lg font-medium">Most Viewed Pages</h2>
            <select class="box form-select mt-3 sm:ml-auto sm:mt-0 sm:w-auto">
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
              <option value="custom-date">Custom Date</option>
            </select>
          </div>
          <div class="intro-y box mt-12 p-5 sm:mt-5">
            <div
              class="mb-3 flex border-b border-dashed border-slate-200 pb-3 text-slate-500 dark:border-darkmode-300"
            >
              <div>Page Names</div>
              <div class="ml-auto">Page Views</div>
            </div>
            <div class="mb-5 flex items-center">
              <div>/letz-lara…review/2653</div>
              <div class="ml-auto">472</div>
            </div>
            <div class="mb-5 flex items-center">
              <div>/midone…review/1674</div>
              <div class="ml-auto">294</div>
            </div>
            <div class="mb-5 flex items-center">
              <div>/profile…review/46789</div>
              <div class="ml-auto">500</div>
            </div>
            <div class="mb-5 flex items-center">
              <div>/profile…review/24357</div>
              <div class="ml-auto">3.420</div>
            </div>
            <div class="mb-5 flex items-center">
              <div>/letz-lara…review/2653</div>
              <div class="ml-auto">83</div>
            </div>
            <div class="mb-5 flex items-center">
              <div>/icewall…review/1674</div>
              <div class="ml-auto">21</div>
            </div>
            <button
              class="btn btn-outline-secondary relative mb-2 w-full justify-start border-dashed border-slate-300 dark:border-darkmode-300"
            >
              <span class="mr-5 truncate">View Full Report</span>
              <span
                class="absolute right-0 top-0 bottom-0 my-auto ml-auto mr-0.5 flex h-8 w-8 items-center justify-center"
              >
                <ArrowRightIcon class="h-4 w-4" />
              </span>
            </button>
          </div>
        </div>
        <!-- END: Most Viewed Pages -->
        <!-- BEGIN: Top Search Items -->
        <div class="col-span-12 mt-6 md:col-span-6 lg:col-span-4">
          <div class="intro-y block h-10 items-center sm:flex">
            <h2 class="mr-5 truncate text-lg font-medium">Top Search Items</h2>
            <select class="box form-select mt-3 sm:ml-auto sm:mt-0 sm:w-auto">
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
              <option value="custom-date">Custom Date</option>
            </select>
          </div>
          <div class="intro-y box mt-12 p-5 sm:mt-5">
            <div
              class="mb-3 flex border-b border-dashed border-slate-200 pb-3 text-slate-500 dark:border-darkmode-300"
            >
              <div>Keywords</div>
              <div class="ml-auto">Searched</div>
            </div>
            <div class="mb-5 flex items-center">
              <div>Laravel 8</div>
              <div class="ml-auto">200</div>
            </div>
            <div class="mb-5 flex items-center">
              <div>Eloquent</div>
              <div class="ml-auto">50</div>
            </div>
            <div class="mb-5 flex items-center">
              <div>CKEditor Source Build</div>
              <div class="ml-auto">31</div>
            </div>
            <div class="mb-5 flex items-center">
              <div>Midone Docs</div>
              <div class="ml-auto">405</div>
            </div>
            <div class="mb-5 flex items-center">
              <div>Vue 3 Release Date</div>
              <div class="ml-auto">201</div>
            </div>
            <div class="mb-5 flex items-center">
              <div>Install Vite Vue</div>
              <div class="ml-auto">42</div>
            </div>
            <button
              class="btn btn-outline-secondary relative mb-2 w-full justify-start border-dashed border-slate-300 dark:border-darkmode-300"
            >
              <span class="mr-5 truncate">View Full Report</span>
              <span
                class="absolute right-0 top-0 bottom-0 my-auto ml-auto mr-0.5 flex h-8 w-8 items-center justify-center"
              >
                <ArrowRightIcon class="h-4 w-4" />
              </span>
            </button>
          </div>
        </div>
        <!-- END: Top Search Items -->
        <!-- BEGIN: Weekly Top Products -->
        <div class="col-span-12 mt-6">
          <div class="intro-y block h-10 items-center sm:flex">
            <h2 class="mr-5 truncate text-lg font-medium">
              Weekly Top Products
            </h2>
            <div class="mt-3 flex items-center sm:ml-auto sm:mt-0">
              <button
                class="btn box flex items-center text-slate-600 dark:text-slate-300"
              >
                <FileTextIcon class="mr-2 hidden h-4 w-4 sm:block" />
                Export to Excel
              </button>
              <button
                class="btn box ml-3 flex items-center text-slate-600 dark:text-slate-300"
              >
                <FileTextIcon class="mr-2 hidden h-4 w-4 sm:block" />
                Export to PDF
              </button>
            </div>
          </div>
          <div class="intro-y mt-8 overflow-auto sm:mt-0 lg:overflow-visible">
            <table class="table-report table sm:mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">IMAGES</th>
                  <th class="whitespace-nowrap">PRODUCT NAME</th>
                  <th class="whitespace-nowrap text-center">STOCK</th>
                  <th class="whitespace-nowrap text-center">STATUS</th>
                  <th class="whitespace-nowrap text-center">ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(faker, fakerKey) in $_.take($f(), 4)"
                  :key="fakerKey"
                  class="intro-x"
                >
                  <td class="w-40">
                    <div class="flex">
                      <div class="image-fit zoom-in h-10 w-10">
                        <Tippy
                          tag="img"
                          alt="Gromura - SCM"
                          class="rounded-full"
                          :src="faker.products[0].images[0]"
                          :content="`Uploaded at ${faker.dates[0]}`"
                        />
                      </div>
                      <div class="image-fit zoom-in -ml-5 h-10 w-10">
                        <Tippy
                          tag="img"
                          alt="Gromura - SCM"
                          class="rounded-full"
                          :src="faker.products[0].images[1]"
                          :content="`Uploaded at ${faker.dates[1]}`"
                        />
                      </div>
                      <!-- <div class="image-fit zoom-in -ml-5 h-10 w-10">
                        <Tippy
                          tag="img"
                          alt="Gromura - SCM"
                          class="rounded-full"
                          :src="faker.images[2]"
                          :content="`Uploaded at ${faker.dates[2]}`"
                        />
                      </div> -->
                    </div>
                  </td>
                  <td>
                    <a href="" class="whitespace-nowrap font-medium">{{
                      faker.products[0].name
                    }}</a>
                    <div
                      class="mt-0.5 whitespace-nowrap text-xs text-slate-500"
                    >
                      {{ faker.products[0].category }}
                    </div>
                  </td>
                  <td class="text-center">{{ faker.stocks[0] }}</td>
                  <td class="w-40">
                    <div
                      class="flex items-center justify-center"
                      :class="{
                        'text-success': faker.trueFalse[0],
                        'text-danger': !faker.trueFalse[0],
                      }"
                    >
                      <CheckSquareIcon class="mr-2 h-4 w-4" />
                      {{ faker.trueFalse[0] ? 'Active' : 'Inactive' }}
                    </div>
                  </td>
                  <td class="table-report__action w-56">
                    <div class="flex items-center justify-center">
                      <a class="mr-3 flex items-center" href="">
                        <CheckSquareIcon class="mr-1 h-4 w-4" />
                        Edit
                      </a>
                      <a class="flex items-center text-danger" href="">
                        <Trash2Icon class="mr-1 h-4 w-4" /> Delete
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="intro-y mt-3 flex flex-wrap items-center sm:flex-row sm:flex-nowrap"
          >
            <nav class="w-full sm:mr-auto sm:w-auto">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ChevronsLeftIcon class="h-4 w-4" />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ChevronLeftIcon class="h-4 w-4" />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">...</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">1</a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">2</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">3</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">...</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ChevronRightIcon class="h-4 w-4" />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ChevronsRightIcon class="h-4 w-4" />
                  </a>
                </li>
              </ul>
            </nav>
            <select class="box form-select mt-3 w-20 sm:mt-0">
              <option>10</option>
              <option>25</option>
              <option>35</option>
              <option>50</option>
            </select>
          </div>
        </div>
        <!-- END: Weekly Top Products -->
      </div>
    </div>
    <div class="col-span-12 2xl:col-span-3">
      <div class="-mb-10 pb-10 2xl:border-l">
        <div class="grid grid-cols-12 gap-x-6 gap-y-6 2xl:gap-x-0 2xl:pl-6">
          <!-- BEGIN: Important Notes -->
          <div class="col-span-12 mt-3 md:col-span-6 xl:col-span-12 2xl:mt-8">
            <div class="intro-x flex h-10 items-center">
              <h2 class="mr-auto truncate text-lg font-medium">
                Important Notes
              </h2>
              <button
                data-carousel="important-notes"
                data-target="prev"
                class="tiny-slider-navigator btn mr-2 border-slate-300 px-2 text-slate-600 dark:text-slate-300"
                @click="prevImportantNotes"
              >
                <ChevronLeftIcon class="h-4 w-4" />
              </button>
              <button
                data-carousel="important-notes"
                data-target="next"
                class="tiny-slider-navigator btn mr-2 border-slate-300 px-2 text-slate-600 dark:text-slate-300"
                @click="nextImportantNotes"
              >
                <ChevronRightIcon class="h-4 w-4" />
              </button>
            </div>
            <div class="intro-x mt-5">
              <div class="box zoom-in">
                <TinySlider ref-key="importantNotesRef">
                  <div class="p-5">
                    <div class="truncate text-base font-medium">
                      Lorem Ipsum is simply dummy text
                    </div>
                    <div class="mt-1 text-slate-400">20 Hours ago</div>
                    <div class="mt-1 text-justify text-slate-500">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </div>
                    <div class="mt-5 flex font-medium">
                      <button type="button" class="btn btn-secondary py-1 px-2">
                        View Notes
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary ml-auto ml-auto py-1 px-2"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                  <div class="p-5">
                    <div class="truncate text-base font-medium">
                      Lorem Ipsum is simply dummy text
                    </div>
                    <div class="mt-1 text-slate-400">20 Hours ago</div>
                    <div class="mt-1 text-justify text-slate-500">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </div>
                    <div class="mt-5 flex font-medium">
                      <button type="button" class="btn btn-secondary py-1 px-2">
                        View Notes
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary ml-auto ml-auto py-1 px-2"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                  <div class="p-5">
                    <div class="truncate text-base font-medium">
                      Lorem Ipsum is simply dummy text
                    </div>
                    <div class="mt-1 text-slate-400">20 Hours ago</div>
                    <div class="mt-1 text-justify text-slate-500">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </div>
                    <div class="mt-5 flex font-medium">
                      <button type="button" class="btn btn-secondary py-1 px-2">
                        View Notes
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary ml-auto ml-auto py-1 px-2"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                </TinySlider>
              </div>
            </div>
          </div>
          <!-- END: Important Notes -->
          <!-- BEGIN: Recent Activities -->
          <div
            class="col-span-12 mt-3 md:col-span-6 xl:col-span-4 2xl:col-span-12"
          >
            <div class="intro-x flex h-10 items-center">
              <h2 class="mr-5 truncate text-lg font-medium">
                Recent Activities
              </h2>
              <a href="" class="ml-auto truncate text-primary">Show More</a>
            </div>
            <div
              class="relative mt-5 before:absolute before:ml-5 before:mt-5 before:block before:h-[85%] before:w-px before:bg-slate-200 before:dark:bg-darkmode-400"
            >
              <div class="intro-x relative mb-3 flex items-center">
                <div
                  class="before:absolute before:mt-5 before:ml-5 before:block before:h-px before:w-20 before:bg-slate-200 before:dark:bg-darkmode-400"
                >
                  <div
                    class="image-fit h-10 w-10 flex-none overflow-hidden rounded-full"
                  >
                    <img alt="Gromura - SCM" :src="$f()[9].users[0].image" />
                  </div>
                </div>
                <div class="box zoom-in ml-4 flex-1 px-5 py-3">
                  <div class="flex items-center">
                    <div class="font-medium">
                      {{ $f()[9].users[0].name }}
                    </div>
                    <div class="ml-auto text-xs text-slate-500">07:00 PM</div>
                  </div>
                  <div class="mt-1 text-slate-500">Has joined the team</div>
                </div>
              </div>
              <div class="intro-x relative mb-3 flex items-center">
                <div
                  class="before:absolute before:mt-5 before:ml-5 before:block before:h-px before:w-20 before:bg-slate-200 before:dark:bg-darkmode-400"
                >
                  <div
                    class="image-fit h-10 w-10 flex-none overflow-hidden rounded-full"
                  >
                    <img alt="Gromura - SCM" :src="$f()[8].users[0].image" />
                  </div>
                </div>
                <div class="box zoom-in ml-4 flex-1 px-5 py-3">
                  <div class="flex items-center">
                    <div class="font-medium">
                      {{ $f()[8].users[0].name }}
                    </div>
                    <div class="ml-auto text-xs text-slate-500">07:00 PM</div>
                  </div>
                  <div class="text-slate-500">
                    <div class="mt-1">Added 3 new photos</div>
                    <div class="mt-2 flex">
                      <Tippy
                        tag="div"
                        class="image-fit zoom-in mr-1 h-8 w-8"
                        :content="$f()[0].products[0].name"
                      >
                        <img
                          alt="Gromura - SCM"
                          class="rounded-md border border-white"
                          :src="$f()[8].images[0]"
                        />
                      </Tippy>
                      <Tippy
                        tag="div"
                        class="image-fit zoom-in mr-1 h-8 w-8"
                        :content="$f()[1].products[0].name"
                      >
                        <img
                          alt="Gromura - SCM"
                          class="rounded-md border border-white"
                          :src="$f()[8].images[1]"
                        />
                      </Tippy>
                      <Tippy
                        tag="div"
                        class="image-fit zoom-in mr-1 h-8 w-8"
                        :content="$f()[2].products[0].name"
                      >
                        <img
                          alt="Gromura - SCM"
                          class="rounded-md border border-white"
                          :src="$f()[8].images[2]"
                        />
                      </Tippy>
                    </div>
                  </div>
                </div>
              </div>
              <div class="intro-x my-4 text-center text-xs text-slate-500">
                12 November
              </div>
              <div class="intro-x relative mb-3 flex items-center">
                <div
                  class="before:absolute before:mt-5 before:ml-5 before:block before:h-px before:w-20 before:bg-slate-200 before:dark:bg-darkmode-400"
                >
                  <div
                    class="image-fit h-10 w-10 flex-none overflow-hidden rounded-full"
                  >
                    <img alt="Gromura - SCM" :src="$f()[7].users[0].image" />
                  </div>
                </div>
                <div class="box zoom-in ml-4 flex-1 px-5 py-3">
                  <div class="flex items-center">
                    <div class="font-medium">
                      {{ $f()[7].users[0].name }}
                    </div>
                    <div class="ml-auto text-xs text-slate-500">07:00 PM</div>
                  </div>
                  <div class="mt-1 text-slate-500">
                    Has changed
                    <a class="text-primary" href="">{{
                      $f()[7].products[0].name
                    }}</a>
                    price and description
                  </div>
                </div>
              </div>
              <div class="intro-x relative mb-3 flex items-center">
                <div
                  class="before:absolute before:mt-5 before:ml-5 before:block before:h-px before:w-20 before:bg-slate-200 before:dark:bg-darkmode-400"
                >
                  <div
                    class="image-fit h-10 w-10 flex-none overflow-hidden rounded-full"
                  >
                    <img alt="Gromura - SCM" :src="$f()[6].users[0].image" />
                  </div>
                </div>
                <div class="box zoom-in ml-4 flex-1 px-5 py-3">
                  <div class="flex items-center">
                    <div class="font-medium">
                      {{ $f()[6].users[0].name }}
                    </div>
                    <div class="ml-auto text-xs text-slate-500">07:00 PM</div>
                  </div>
                  <div class="mt-1 text-slate-500">
                    Has changed
                    <a class="text-primary" href="">{{
                      $f()[6].products[0].name
                    }}</a>
                    description
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Recent Activities -->
          <!-- BEGIN: Transactions -->
          <div
            class="col-span-12 mt-3 md:col-span-6 xl:col-span-4 2xl:col-span-12"
          >
            <div class="intro-x flex h-10 items-center">
              <h2 class="mr-5 truncate text-lg font-medium">Transactions</h2>
            </div>
            <div class="mt-5">
              <div
                v-for="(faker, fakerKey) in $_.take($f(), 5)"
                :key="fakerKey"
                class="intro-x"
              >
                <div class="box zoom-in mb-3 flex items-center px-5 py-3">
                  <div
                    class="image-fit h-10 w-10 flex-none overflow-hidden rounded-full"
                  >
                    <img alt="Gromura - SCM" :src="faker.users[0].image" />
                  </div>
                  <div class="ml-4 mr-auto">
                    <div class="font-medium">
                      {{ faker.users[0].name }}
                    </div>
                    <div class="mt-0.5 text-xs text-slate-500">
                      {{ faker.dates[0] }}
                    </div>
                  </div>
                  <div
                    :class="{
                      'text-success': faker.trueFalse[0],
                      'text-danger': !faker.trueFalse[0],
                    }"
                  >
                    {{ faker.trueFalse[0] ? '+' : '-' }}Rp{{ faker.totals[0] }}
                  </div>
                </div>
              </div>
              <a
                href=""
                class="intro-x block w-full rounded-md border border-dotted border-slate-400 py-3 text-center text-slate-500 dark:border-darkmode-300"
                >View More</a
              >
            </div>
          </div>
          <!-- END: Transactions -->
          <!-- BEGIN: Schedules -->
          <div
            class="col-span-12 mt-3 md:col-span-6 xl:col-span-4 xl:col-start-1 xl:row-start-2 2xl:col-span-12 2xl:col-start-auto 2xl:row-start-auto"
          >
            <div class="intro-x flex h-10 items-center">
              <h2 class="mr-5 truncate text-lg font-medium">Schedules</h2>
              <a
                href=""
                class="ml-auto flex items-center truncate text-primary"
              >
                <PlusIcon class="mr-1 h-4 w-4" /> Add New Schedules
              </a>
            </div>
            <div class="mt-5">
              <div class="intro-x box">
                <div class="p-5">
                  <div class="flex">
                    <ChevronLeftIcon class="h-5 w-5 text-slate-500" />
                    <div class="mx-auto text-base font-medium">April</div>
                    <ChevronRightIcon class="h-5 w-5 text-slate-500" />
                  </div>
                  <div class="mt-5 grid grid-cols-7 gap-4 text-center">
                    <div class="font-medium">Su</div>
                    <div class="font-medium">Mo</div>
                    <div class="font-medium">Tu</div>
                    <div class="font-medium">We</div>
                    <div class="font-medium">Th</div>
                    <div class="font-medium">Fr</div>
                    <div class="font-medium">Sa</div>
                    <div class="relative rounded py-0.5 text-slate-500">29</div>
                    <div class="relative rounded py-0.5 text-slate-500">30</div>
                    <div class="relative rounded py-0.5 text-slate-500">31</div>
                    <div class="relative rounded py-0.5">1</div>
                    <div class="relative rounded py-0.5">2</div>
                    <div class="relative rounded py-0.5">3</div>
                    <div class="relative rounded py-0.5">4</div>
                    <div class="relative rounded py-0.5">5</div>
                    <div
                      class="relative rounded bg-success/20 py-0.5 dark:bg-success/30"
                    >
                      6
                    </div>
                    <div class="relative rounded py-0.5">7</div>
                    <div class="relative rounded bg-primary py-0.5 text-white">
                      8
                    </div>
                    <div class="relative rounded py-0.5">9</div>
                    <div class="relative rounded py-0.5">10</div>
                    <div class="relative rounded py-0.5">11</div>
                    <div class="relative rounded py-0.5">12</div>
                    <div class="relative rounded py-0.5">13</div>
                    <div class="relative rounded py-0.5">14</div>
                    <div class="relative rounded py-0.5">15</div>
                    <div class="relative rounded py-0.5">16</div>
                    <div class="relative rounded py-0.5">17</div>
                    <div class="relative rounded py-0.5">18</div>
                    <div class="relative rounded py-0.5">19</div>
                    <div class="relative rounded py-0.5">20</div>
                    <div class="relative rounded py-0.5">21</div>
                    <div class="relative rounded py-0.5">22</div>
                    <div
                      class="relative rounded bg-pending/20 py-0.5 dark:bg-pending/30"
                    >
                      23
                    </div>
                    <div class="relative rounded py-0.5">24</div>
                    <div class="relative rounded py-0.5">25</div>
                    <div class="relative rounded py-0.5">26</div>
                    <div
                      class="relative rounded bg-primary/10 py-0.5 dark:bg-primary/50"
                    >
                      27
                    </div>
                    <div class="relative rounded py-0.5">28</div>
                    <div class="relative rounded py-0.5">29</div>
                    <div class="relative rounded py-0.5">30</div>
                    <div class="relative rounded py-0.5 text-slate-500">1</div>
                    <div class="relative rounded py-0.5 text-slate-500">2</div>
                    <div class="relative rounded py-0.5 text-slate-500">3</div>
                    <div class="relative rounded py-0.5 text-slate-500">4</div>
                    <div class="relative rounded py-0.5 text-slate-500">5</div>
                    <div class="relative rounded py-0.5 text-slate-500">6</div>
                    <div class="relative rounded py-0.5 text-slate-500">7</div>
                    <div class="relative rounded py-0.5 text-slate-500">8</div>
                    <div class="relative rounded py-0.5 text-slate-500">9</div>
                  </div>
                </div>
                <div class="border-t border-slate-200/60 p-5">
                  <div class="flex items-center">
                    <div class="mr-3 h-2 w-2 rounded-full bg-pending"></div>
                    <span class="truncate">Sales Workshop</span>
                    <span class="font-medium xl:ml-auto">23th</span>
                  </div>
                  <div class="mt-4 flex items-center">
                    <div class="mr-3 h-2 w-2 rounded-full bg-primary"></div>
                    <span class="truncate">Launching Toko Baru</span>
                    <span class="font-medium xl:ml-auto">10th</span>
                  </div>
                  <div class="mt-4 flex items-center">
                    <div class="mr-3 h-2 w-2 rounded-full bg-warning"></div>
                    <span class="truncate">Perayaan 1 Tahun Gromura</span>
                    <span class="font-medium xl:ml-auto">31th</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Schedules -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, provide } from 'vue'
import ReportDonutChart from '@/components/report-donut-chart/Main.vue'
import ReportMap from '@/components/report-map/Main.vue'

const importantNotesRef = ref()

provide('bind[importantNotesRef]', (el) => {
  importantNotesRef.value = el
})

const prevImportantNotes = () => {
  const el = importantNotesRef.value
  el.tns.goTo('prev')
}

const nextImportantNotes = () => {
  const el = importantNotesRef.value
  el.tns.goTo('next')
}
</script>
