<template>
  <div v-if="images !== null && images.length > 1">
    <swiper
      :modules="modulesImage"
      :loop="true"
      :slides-per-view="1"
      :pagination="{ clickable: true }"
      :scrollbar="{ draggable: true }"
    >
      <swiper-slide
        v-for="item in images"
        :key="item.id"
      >
        <!-- <vue-image-zoomer :regular="item.image" :zoom="item.image" /> -->
        <ImageLoader
          :image="item.image"
          :rounded-class="roundedClass"
        />
      </swiper-slide>
    </swiper>
  </div>
  <div v-else-if="images !== null" class="image-fit top-0 left-0 h-full w-full">
    <ImageLoader
      :image="images.length > 0 ? images[0].image : null"
      :alt="name"
      :rounded-class="roundedClass"
    />
  </div>
  <div v-else class="image-fit top-0 left-0 h-full w-full">
    <ImageLoader
      :image="null"
      :rounded-class="roundedClass"
    />
  </div>
</template>

<script>
import { Pagination } from 'swiper/modules'
import ImageLoader from '@/components/image-loader/Main.vue'

export default {
  name: 'DetailImage',
  components: {
    ImageLoader,
    Pagination,
  },
  props: {
    images: {
      type: Array,
      default: null,
      required: true,
    },
    name: {
      type: String,
      default: '',
      required: false,
    },
    roundedClass: {
      type: String,
      default: 'rounded-md',
      required: false,
    },
  },
  data: () => ({
    modulesImage: [Pagination],
  })
}
</script>
