<template>
  <div class="relative w-full text-slate-500 sm:w-48 h-9.5">
    <input
      v-model="search"
      :placeholder="placeholder"
      type="text"
      class="form-control box w-full pl-9 pr-3 md:w-56 text-ellipsis"
      @input="searchData"
    />
    <SearchIcon
      class="absolute inset-y-0 left-0 my-auto ml-3 h-4 w-4 md:-mr-4"
    />
  </div>
</template>

<script>
export default {
  name: 'Search',
  data() {
    return {
      search: ''
    }
  },
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['search-data'],
  methods: {
    searchData() {
      this.$emit('search-data', this.search)
    },
  },
}
</script>
