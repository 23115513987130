<template>
  <TabGroup class="mt-8">
    <TabList class="nav-boxed-tabs flex w-80">
      <Tab class="w-40 py-2 tab-stock good-stock flex text-center items-center font-bold" tag="button"><CheckCircleIcon class="h-4 w-4 mr-2" /> Good Stock</Tab>
      <Tab class="w-40 py-2 tab-stock bad-stock flex text-center items-center font-bold" tag="button"><AlertTriangleIcon class="h-4 w-4 mr-2" /> Bad Stock</Tab>
    </TabList>
    <TabPanels>
      <TabPanel class="leading-relaxed">
        <div class="mt-0 grid grid-cols-12 gap-6 md:mt-4">
          <div class="intro-y col-span-12 mt-6 flex flex-wrap items-center">
            <div class="flex w-full md:w-auto">
              <p class="intro-y text-base md:text-lg font-medium">Stock Valuation</p>
            </div>
          </div>

          <div class="intro-y col-span-6 xl:col-span-3">
            <CardReportPercentage
              title="Nilai Stock"
              :total="reportData.stock_value_total"
              :loading="isSummaryLoading"
              :with-prefix="true"
            />
          </div>
          <div class="intro-y col-span-6 xl:col-span-3">
            <CardReportPercentage
              title="Potensial Penjualan"
              :total="reportData.potential_sales_total"
              :loading="isSummaryLoading"
              :with-prefix="true"
            />
          </div>
          <div class="intro-y col-span-6 xl:col-span-3">
            <CardReportPercentage
              title="Potensial Margin"
              :total="reportData.potensial_margin_total"
              :loading="isSummaryLoading"
              :with-prefix="true"
            />
          </div>
          <div class="intro-y col-span-6 xl:col-span-3">
            <CardReportPercentage
              title="Potensial Persentase"
              :total="reportData.potential_margin_percentage_total"
              :loading="isSummaryLoading"
              :with-prefix="false"
              :with-percentage="true"
            />
          </div>
        </div>

        <div class="mt-12 grid grid-cols-12 gap-2 md:mt-16">
          <div class="intro-y col-span-12 flex">
            <h2 class="intro-y text-lg font-medium">List Stock</h2>
          </div>
          <div class="intro-y col-span-12 mt-2 flex flex-wrap items-center">
            <div class="flex w-full md:w-auto">
              <div class="relative w-full text-slate-500 md:w-48 h-9.5">
                <input
                  v-model="filterData.search"
                  type="text"
                  class="form-control box w-full pr-10 md:w-56 text-ellipsis"
                  placeholder="Cari produk..."
                  @input="searchFilter()"
                />
                <SearchIcon
                  class="absolute inset-y-0 right-0 my-auto mr-4 h-4 w-4 md:-mr-4"
                />
              </div>
              <button class="btn box ml-2 shadow-sm md:ml-10" @click="openFilter">
                <FilterIcon class="h-4 w-4" />
              </button>
            </div>
            <div class="mx-auto hidden text-slate-500 md:block"></div>
            <div class="mt-3 flex items-center md:mt-0 xl:mt-0 xl:w-auto">
              <ExportCSVComponent />
              <!-- <ExportCSVComponent @export="download" /> -->
            </div>
          </div>
          <!-- BEGIN: Data List -->
          <TableComponent
            :show-loading="isLoading"
            :show-title="false"
            :show-search="false"
            :header="tableHeader"
            :meta-data="metaData"
            :is-not-empty="tableData.length > 0"
            @change-filter="changeFilter($event)"
          >
            <tr v-for="(item, index) in tableData" :key="item.id" class="intro-x">
              <td class="!py-3.5">
                <div class="bg-gray-50 font-bold p-2 text-emerald-700 rounded-xl w-12 text-center">
                  {{ (metaData.page !== 1 ? (metaData.page - 1) * metaData.take : 0) + index + 1 }}
                </div>
              </td>
              <td class="!py-3.5">
                <div class="flex items-center">
                  <div class="image-fit zoom-in h-9 w-9">
                    <ImageLoader
                      :image="
                        item.product.images.length > 0
                          ? item.product.images[0].image
                          : null
                      "
                      :alt="item.product.name"
                      rounded-class="rounded-md"
                      @click="imagePreview(item)"
                    />
                  </div>
                </div>
              </td>
              <td>
                {{ item.product.name }}
              </td>
              <td class="w-40">
                <Currency class="text-teal-600 font-semibold" v-if="roleCanManage($h.superRoles(), roleAccess)" :total-in-string="item.base_price" />
                <NoSymbolIcon v-else class="h-4 w-4 text-red-600" />
              </td>
              <td class="w-40">
                <Currency :class="roleCanManage($h.superRoles(), roleAccess) ? 'text-red-600 font-semibold' : 'text-teal-600'" :total-in-string="item.sale_price" />
              </td>
              <td>
                <Currency class="justify-center" :total-in-string="item.stock" :with-prefix="false" />
                <!-- {{ convertStock(item) }} -->
              </td>
              <td class="text-center">
                <Currency class="justify-center" :total-in-string="item.sold_stock" :with-prefix="false" />
              </td>

              <td class="text-center w-40">
                <Currency class="justify-center" :total-in-string="item.stock_value" />
              </td>

              <td class="table-report__action w-32">
                <div class="flex items-center justify-center">
                  <a
                    class="flex items-center text-secondary"
                    href="javascript:;"
                    @click="productPreview(item)"
                  >
                    <EyeIcon class="mr-1 h-4 w-4" /> Detail
                  </a>
                </div>
              </td>
            </tr>
          </TableComponent>
          <!-- END: Data List -->
        </div>
      </TabPanel>
      <TabPanel class="leading-relaxed p-5">
        Coming Soon
      </TabPanel>
    </TabPanels>
  </TabGroup>
  

  <!-- DETAIL MODAL -->
  <Modal :show="productPreviewModal" @hidden="productPreviewModal = false">
    <ModalBody class="p-0">
      <DetailProduct
        v-if="activeProductStock.product !== undefined"
        :active-product="activeProductStock.product"
        :base-price="activeProductStock.base_price"
        :sale-price="activeProductStock.sale_price"
        :stock="activeProductStock.stock"
        :show-base-price="$h.roleCanManage($h.superRoles(), roleAccess)"
      />
    </ModalBody>
  </Modal>

  <!-- DETAIL IMAGE -->
  <Modal :show="imagePreviewModal" @hidden="imagePreviewModal = false">
    <ModalBody class="p-0">
      <DetailImage
        v-if="activeProductStock.product !== undefined"
        :images="activeProductStock.product.images"
        :name="activeProductStock.product.name"
      />
    </ModalBody>
  </Modal>

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">
        {{ message }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
  helpers,
  required,
  minLength,
  maxLength,
  email,
  integer,
  url,
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import { resizeImg } from '@/plugins/pica'
import globalMixin from '@/mixins/global.js'
import useEmitter from '@/composables/useEmitter'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import Multiselect from '@vueform/multiselect'
import DetailImage from '@/components/detail-image/Main.vue'
import DetailProduct from '@/components/detail-product/Main.vue'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'
import { NoSymbolIcon } from '@heroicons/vue/24/solid'
import CardReportPercentage from '@/components/card/CardReportPercentage.vue'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Currency,
    Multiselect,
    DetailImage,
    DetailProduct,
    ExportCSVComponent,
    NoSymbolIcon,
    CardReportPercentage
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const emitter = useEmitter()

    const activeUser = reactive({})
    const roleAccess = ref('')

    const activeProductStock = reactive({})
    let reportData = reactive({
      stock_value_total: 0,
      potential_sales_total: 0,
      potensial_margin_total: 0,
      potential_margin_percentage_total: 0,
    })

    const isLoading = ref(false)
    const isSummaryLoading = ref(false)
    const timer = ref(undefined)

    const productPreviewModal = ref(false)
    const imagePreviewModal = ref(false)

    const status = ref('')
    const message = ref('')

    const tableHeader = ref([
      {
        item: 'NO',
        customClass: '',
      },
      {
        item: 'GAMBAR',
        customClass: '',
      },
      {
        item: 'PRODUK',
        customClass: '',
      },
      {
        item: 'HARGA BELI',
        customClass: '',
      },
      {
        item: 'HARGA JUAL',
        customClass: '',
      },
      {
        item: 'STOCK ON HAND',
        customClass: 'text-center',
      },
      {
        item: 'TERJUAL',
        customClass: 'text-center',
      },
      {
        item: 'TOTAL VALUE',
        customClass: 'text-center',
      },
      {
        item: 'AKSI',
        customClass: 'text-center',
      },
    ])

    let tableData = ref([])

    let metaData = reactive({
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 10,
      order: 'ASC',
      search: '',
      // type: null,
    })

    onMounted(async () => {
      // clearing all event
      emitter.all.clear()

      Object.assign(activeUser, store.getters['auth/activeUser'])
      roleAccess.value = activeUser.role_codes

      emitter.on("update-data", store => {
        console.log(`---updating page => ${route.name} for store Id ${store.name} `)
        getAllData(true)
      });

      const storeId = store.getters['store/storeId']

      if (storeId === null) {
        setTimeout(() => {
          getSummaryData()
          getAllData()
        }, 2000)
      } else {
        getSummaryData()
        getAllData()
      }

    })

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const getSummaryData = async () => {
      isSummaryLoading.value = true
      const storeId = store.getters['store/storeId']

      await store
        .dispatch('productStock/summary', {
          store_id: storeId,
        })
        .then((response) => {
          Object.assign(reportData, response.data)
          isSummaryLoading.value = false
        })
        .catch((e) => {
          // console.log(e)
          isSummaryLoading.value = false
        })
    }

    const getAllData = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        tableData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }

      const storeId = store.getters['store/storeId']

      await store
        .dispatch('productStock/getByStore', {
          store_id: storeId,
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          q: filterData.search,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            tableData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const changeFilter = (event) => {
      Object.assign(filterData, {
        ...filterData,
        page: Number(event.filterData.page),
        take: Number(event.filterData.take),
      })
      getAllData()
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getAllData()
      }, 500)
    }

    const productPreview = async (product) => {
      Object.assign(activeProductStock, product)
      productPreviewModal.value = true
    }

    const imagePreview = async (product) => {
      Object.assign(activeProductStock, product)
      imagePreviewModal.value = true
    }

    const convertStock = (stock) => {
      const product = stock.product
      const converters = product.product_converters

      if (product.is_accessories === true) {
        let unitBig = {}
        let unitSmall = {}
        let converter = 0

        converters.map((item) => {
          if (item.base === true) {
            unitBig = item.product_unit
          } else {
            unitSmall = item.product_unit
            converter = item.converter
          }
        })

        const divideNumber = stock.stock / converter

        if (converter > stock.stock) {
          return `${stock.stock} ${unitSmall.name}`
        } else if (divideNumber.toString().split('.').length > 0) {
          const roundNumber = Math.floor(divideNumber.toString().split('.')[0])
          const leftNumber = stock.stock - roundNumber * converter

          return `${roundNumber} ${unitBig.name} ${leftNumber} ${unitSmall.name}`
        } else {
          return `${stock.stock} ${unitBig.name}`
        }
      }
      
      // default for unit product
      const unit = converters[0].product_unit
      return `${stock.stock} ${unit.name}`
    }

    return {
      t,
      n,
      store,
      route,
      router,

      activeProductStock,
      reportData,

      roleAccess,

      productPreview,
      imagePreview,

      productPreviewModal,
      imagePreviewModal,

      isLoading,
      isSummaryLoading,
      timer,

      showToast,
      status,
      message,

      tableHeader,
      tableData,

      metaData,
      filterData,

      getSummaryData,
      getAllData,

      changeFilter,
      searchFilter,
      convertStock,
    }
  },
}
</script>
