<template>
  <Modal :slide-over="true" :show="showModal" @hidden="onClose" class="modal-cart modal-side">
    <a
      class="top-0 right-0 right-auto mt-4 -ml-12 hidden md:fixed md:flex"
      href="javascript:;"
      @click="onClose"
    >
      <XIcon class="h-8 w-8 text-white" />
    </a>
    <ModalHeader v-if="purchase.code !== undefined" class="px-4">
      <div class="flex w-full flex-col justify-between md:flex-row">
        <div class="flex justify-between">
          <div class="flex items-center">
            <span class="fake-receipt text-lg">{{ purchase.code }} </span
            >&nbsp
            <Status :status="purchase.status" />
          </div>
          <div class="flex items-center">
            <a
              class="flex md:hidden"
              href="javascript:;"
              @click="onClose"
            >
              <XIcon class="h-6 w-6 text-red-500" />
            </a>
          </div>
        </div>
      </div>
    </ModalHeader>
    <ModalBody v-if="purchase.code !== undefined" class="p-0">
      <div class="flex w-full flex-col">
        <div class="flex flex-col px-4 input-form mt-4 mb-4 w-full">
          <label
            for="form-product-store"
            class="form-label flex w-full flex-col text-xs"
          >
            Outlet
          </label>
          <input
            v-if="purchase.store !== undefined"
            :value="purchase.store.name"
            type="text"
            class="form-control h-10 read-only:bg-white"
            readonly
          />
          <input
            v-else
            type="text"
            class="form-control h-10 read-only:bg-white"
            readonly
          />
        </div>
        <div class="input-form mb-4 w-full px-4">
          <label
            for="form-product-supplier"
            class="form-label flex w-full flex-col text-xs"
          >
            {{ $t('formLabel.supplier') }}
          </label>
          <input
            v-if="purchase.supplier !== undefined"
            v-model="purchase.supplier.name"
            type="text"
            class="form-control h-10 read-only:bg-white"
            readonly
          />
          <input
            v-else
            type="text"
            class="form-control h-10 read-only:bg-white"
            readonly
          />
        </div>

        <div class="input-form mb-4 w-full px-4">
          <label
            for="form-product-paymentTerm"
            class="form-label flex w-full flex-col text-xs"
          >
            {{ $t('formLabel.paymentTerm') }}
          </label>
          <input
            v-if="purchase.payment.payment_term !== undefined"
            v-model="purchase.payment.payment_term.name"
            type="text"
            class="form-control h-10 read-only:bg-white"
            readonly
          />
          <input
            v-else
            type="text"
            class="form-control h-10 read-only:bg-white"
            readonly
          />
        </div>
        <div class="relative mx-auto h-12 w-full px-4 mb-6">
          <div class="mb-2 text-xs"><p>Payment Dateline</p></div>
          <div
            class="flex absolute h-10 w-10 items-center justify-center rounded-l border bg-white bg-slate-100 text-slate-500 dark:border-darkmode-800 dark:bg-darkmode-700 dark:text-slate-400"
          >
            <CalendarIcon class="h-4 w-4" />
          </div>

          <input
            :value="
              $h.formattedDate(
                purchase.payment !== undefined
                  ? purchase.payment.payment_due
                  : new Date()
              )
            "
            type="text"
            class="form-control h-10 pl-12 read-only:bg-white"
            readonly
          />
        </div>

        <div class="mt-2 grid grid-cols-12">
          <div
            v-for="item in purchase.items"
            :key="item.key"
            class="relative col-span-12"
          >
          <div class="h-4 w-full bg-gray-100"></div>

            <div class="flex flex-col">
              <div class="flex w-full">
                <div class="flex w-20 pl-4 pt-4 pb-2">
                  <div class="w-full">
                    <ImageLoader
                      :image="
                        item.product.images !== undefined &&
                        item.product.images.length > 0
                          ? item.product.images[0].image
                          : null
                      "
                      :alt="item.product.name"
                      class="w-16"
                    rounded-class="rounded-md cursor-pointer"
                    />
                  </div>
                </div>
                <div class="flex w-full flex-col pl-4 md:px-4 pt-4 pb-2">
                  <div class="text-md flex w-full font-medium">
                    {{ item.product.name }}
                  </div>
                  <div class="flex w-full text-xs font-medium text-green-500">
                    {{ item.product.brand.name }}
                  </div>

                  <div
                    v-if="item.price_discount !== null || item.other_fees !== null"
                    class="flex mt-2 text-xs"
                  >
                    HPP:
                    <Currency
                      class="ml-2 text-gray-900 italic"
                      :total-in-string="
                        Math.round(
                          (
                            (
                              (item.quantity * item.price) + Number(item.other_fees)
                            )
                            - item.price_discount)
                            / (Number(item.quantity) + Number(item.quantity_bonus)
                          )
                        ).toString()
                      "
                    />
                  </div>

                  <div v-if="canEdit" class="flex mt-4">
                    <div
                      class="flex"
                    >
                      <button
                        type="button"
                        class="btn mr-1 h-8 w-8 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                        @click="changeQuantity(item, false, false)"
                      >
                        -
                      </button>
                      <input
                        id="pos-form-4"
                        v-model="item.quantity"
                        type="text"
                        class="form-control w-16 text-center text-xs"
                        placeholder="0"
                        @input="calculateTotalPriceItem(item)"
                      />
                      <button
                        type="button"
                        class="btn ml-1 h-8 w-8 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                        @click="changeQuantity(item, false, true)"
                      >
                        +
                      </button>
                    </div>
                    <!-- <div v-else class="flex flex-col text-xs">
                      <p class="italic text-gray-600">Qty</p>
                      <Currency
                        :with-prefix="false"
                        class="text-gray-900"
                        :total-in-string="item.quantity.toString()"
                      />
                    </div> -->

                    <div class="flex pl-2 md:pl-6">
                      <input
                        id="pos-form-4"
                        v-model="item.price"
                        type="text"
                        class="form-control w-32 md:w-36 text-center text-xs"
                        placeholder="Harga Beli"
                        @input="calculateTotalPriceItem(item)"
                      />
                    </div>
                    <!-- <div v-else class="flex ml-10 flex-col text-xs">
                      <p class="italic text-gray-600">HPP</p>

                      <template v-if="item.price_discount !== 0">
                        <div class="flex">
                          <Currency
                            class="text-gray-900"
                            :total-in-string="
                              Math.round(
                                item.total_price / item.quantity
                              ).toString()
                            "
                          />
                          <Currency
                            class="ml-2 text-red-600 line-through"
                            :total-in-string="item.price.toString()"
                          />
                        </div>
                      </template>
                      <template v-else>
                        <Currency
                          class="text-gray-900"
                          :total-in-string="
                            Math.round(
                              item.total_price / item.quantity
                            ).toString()
                          "
                        />
                      </template>
                    </div> -->
                  </div>
                </div>
                <div class="flex w-12 justify-end py-2 pr-4">
                  <a
                    v-if="canEdit"
                    class="flex mt-2 text-danger"
                    href="javascript:;"
                    @click="removeItem(item)"
                  >
                    <Trash2Icon class="h-5 w-5" />
                  </a>
                </div>
              </div>

              <div v-if="canEdit === false" class="flex flex-col mt-4 w-full px-4">
                <div class="flex w-full justify-between text-xs">
                  <span class="text-gray-600">Qty</span>
                  <Currency
                    :with-prefix="false"
                    class="text-gray-900"
                    :total-in-string="item.quantity.toString()"
                  />
                </div>
                <div class="flex w-full justify-between text-xs mt-1">
                  <span class="text-gray-600">Bonus Item</span>
                  <span class="flex text-green-600">
                    +<Currency
                      :with-prefix="false"
                      :total-in-string="item.quantity_bonus.toString()"
                    />
                  </span>
                </div>
                <div class="flex w-full justify-between text-xs mt-1">
                  <span class="text-gray-600">Harga Beli</span>
                  <Currency
                    class="text-gray-900"
                    :total-in-string="item.price.toString()"
                  />
                </div>
                <div class="flex w-full justify-between text-xs mt-1">
                  <span class="text-gray-600">Biaya Lainnya</span>
                  <Currency
                    class="text-gray-900"
                    :total-in-string="Number(item.other_fees).toString()"
                  />
                </div>
                <div class="flex w-full justify-between text-xs mt-1">
                  <span class="text-gray-600">Discount</span>
                  <span class="flex text-red-600">
                    -<Currency
                      :total-in-string="item.price_discount.toString()"
                    />
                  </span>
                </div>
              </div>

              <div class="mt-2 flex w-full flex-col border-t p-2 py-3 pl-4">
                <div class="flex w-full justify-between text-xs">
                  <div class="flex items-center">Total Harga</div>
                  <div class="flex items-end pr-2">
                    <Currency
                      v-if="item.price_discount !== undefined && item.price_discount !== 0"
                      :total-in-string="
                        ((item.quantity * item.price) + Number(item.other_fees) - item.price_discount).toString()
                      "
                    />
                    <Currency
                      v-else
                      :total-in-string="
                        ((item.quantity * item.price) + Number(item.other_fees)).toString()
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="h-4 w-full bg-gray-100"></div>

        <div class="col-span-12 px-4 pt-4">
          <div class="mr-auto font-medium">
            <span class="text-sm">History</span>
          </div>
        </div>

        <div class="col-span-12 px-4 pt-4">
          <ol class="pl-3 w-full">
            <li
              :class="
                purchase.status === 'COMPLETED'
                  ? 'border-l-2 border-green-500'
                  : purchase.status === 'CANCELLED'
                  ? 'border-l-2 border-red-500'
                  : ['PENDING', 'PROCESS'].includes(purchase.status) === false
                  ? 'border-l-2 border-sky-500'
                  : ''
              "
            >
              <div class="items-start justify-start flex w-full">
                <div
                  class="flex -ml-3 h-6 w-6 items-center justify-center rounded-full"
                  :class="
                    purchase.status === 'COMPLETED'
                      ? 'bg-green-600'
                      : purchase.status === 'CANCELLED'
                      ? 'bg-red-600'
                      : 'bg-sky-600'
                  "
                >
                  <CalendarIcon class="h-3 w-3 text-white" />
                </div>
                <div
                  class="ml-6 w-full rounded-lg bg-gray-50 mb-4 py-4 px-8"
                >
                  <div class="flex justify-between">
                    <p
                      class="text-xs font-bold transition duration-300 ease-in-out"
                      :class="
                        purchase.status === 'COMPLETED'
                          ? 'text-green-600 hover:text-green-700 focus:text-green-800'
                          : purchase.status === 'CANCELLED'
                          ? 'text-red-600 hover:text-red-700 focus:text-red-800'
                          : 'text-sky-600 hover:text-sky-700 focus:text-sky-800'
                      "
                    >
                      {{ $h.formattedDate(purchase.created) }}
                    </p>
                    <p
                      class="text-xs font-medium text-gray-600 transition duration-300 ease-in-out hover:text-gray-700 focus:text-gray-800"
                    >
                      Pesanan Dibuat
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li
              v-if="
                purchase.status !== 'CANCELLED' &&
                purchase.payment !== undefined &&
                purchase.payment.payment_at !== null
              "
              :class="
                purchase.status === 'COMPLETED'
                  ? 'border-l-2 border-green-500'
                  : purchase.status === 'CANCELLED'
                  ? 'border-l-2 border-red-500'
                  : purchase.status !== 'PAID'
                  ? 'border-l-2 border-sky-500'
                  : ''
              "
            >
              <div class="items-start justify-start flex">
                <div
                  class="flex -ml-3 h-6 w-6 items-center justify-center rounded-full"
                  :class="
                    purchase.status === 'COMPLETED'
                      ? 'bg-green-600'
                      : purchase.status === 'CANCELLED'
                      ? 'bg-red-600'
                      : 'bg-sky-600'
                  "
                >
                  <CalendarIcon class="h-3 w-3 text-white" />
                </div>
                <div
                  class="ml-6 w-full rounded-lg bg-gray-50 mb-4 py-4 px-8"
                >
                  <div class="flex justify-between">
                    <p
                      class="text-xs font-bold transition duration-300 ease-in-out"
                      :class="
                        purchase.status === 'COMPLETED'
                          ? 'text-green-600 hover:text-green-700 focus:text-green-800'
                          : purchase.status === 'CANCELLED'
                          ? 'text-red-600 hover:text-red-700 focus:text-red-800'
                          : 'text-sky-600 hover:text-sky-700 focus:text-sky-800'
                      "
                    >
                      {{ $h.formattedDate(purchase.payment.payment_at) }}
                    </p>
                    <p
                      class="text-xs font-medium text-gray-600 transition duration-300 ease-in-out hover:text-gray-700 focus:text-gray-800"
                    >
                      Pesanan Dibayar
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li
              v-if="
                purchase.status !== 'CANCELLED' && purchase.received_at !== null
              "
              :class="
                purchase.status === 'COMPLETED'
                  ? 'border-l-2 border-green-500'
                  : purchase.status === 'CANCELLED'
                  ? 'border-l-2 border-red-500'
                  : purchase.status !== 'RECEIVED'
                  ? 'border-l-2 border-sky-500'
                  : ''
              "
            >
              <div class="items-start justify-start flex">
                <div
                  class="flex -ml-3 h-6 w-6 items-center justify-center rounded-full"
                  :class="
                    purchase.status === 'COMPLETED'
                      ? 'bg-green-600'
                      : purchase.status === 'CANCELLED'
                      ? 'bg-red-600'
                      : 'bg-sky-600'
                  "
                >
                  <CalendarIcon class="h-3 w-3 text-white" />
                </div>
                <div
                  class="ml-6 w-full rounded-lg bg-gray-50 mb-4 py-4 px-8"
                >
                  <div class="flex justify-between">
                    <p
                      class="text-xs font-bold transition duration-300 ease-in-out"
                      :class="
                        purchase.status === 'COMPLETED'
                          ? 'text-green-600 hover:text-green-700 focus:text-green-800'
                          : purchase.status === 'CANCELLED'
                          ? 'text-red-600 hover:text-red-700 focus:text-red-800'
                          : 'text-sky-600 hover:text-sky-700 focus:text-sky-800'
                      "
                    >
                      {{ $h.formattedDate(purchase.received_at) }}
                    </p>
                    <p
                      class="text-xs font-medium text-gray-600 transition duration-300 ease-in-out hover:text-gray-700 focus:text-gray-800"
                    >
                      Pesanan Diterima
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li
              v-if="['COMPLETED', 'CANCELLED'].includes(purchase.status)"
              class="border-green-500 pb-4"
            >
              <div class="flex items-start justify-start">
                <div
                  class="flex -ml-2.5 h-6 w-6 items-center justify-center rounded-full"
                  :class="
                    purchase.status === 'COMPLETED'
                      ? 'bg-green-600'
                      : purchase.status === 'CANCELLED'
                      ? 'bg-red-600'
                      : 'bg-sky-600'
                  "
                >
                  <CalendarIcon class="h-3 w-3 text-white" />
                </div>
                <div class="ml-6 w-full rounded-lg bg-gray-50 mb-4 py-4 px-8">
                  <div class="flex justify-between">
                    <p
                      class="text-xs font-bold transition duration-300 ease-in-out"
                      :class="
                        purchase.status === 'COMPLETED'
                          ? 'text-green-600 hover:text-green-700 focus:text-green-800'
                          : purchase.status === 'CANCELLED'
                          ? 'text-red-600 hover:text-red-700 focus:text-red-800'
                          : 'text-sky-600 hover:text-sky-700 focus:text-sky-800'
                      "
                    >
                      {{ $h.formattedDate(purchase.modified) }}
                    </p>
                    <div>
                      <p
                        v-if="purchase.status === 'COMPLETED'"
                        class="text-xs font-medium text-gray-600 transition duration-300 ease-in-out hover:text-gray-700 focus:text-gray-800"
                      >
                        Pesanan Selesai
                      </p>
                      <p
                        v-else
                        class="text-xs font-medium text-gray-600 transition duration-300 ease-in-out hover:text-gray-700 focus:text-gray-800"
                      >
                        Pesanan Dibatalkan
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ol>
        </div>

        <template v-if="purchase.documents !== undefined && purchase.documents.length > 0">
          <div class="h-4 w-full bg-gray-100"></div>
          <div
            class="intro-y col-span-12 pt-4"
          >
            <div class="font-medium px-4">
              <span class="text-sm">Dokumen</span>
            </div>
          </div>
          <div
            class="my-4 grid gap-4 px-4"
          >
            <div
              v-for="item in purchase.documents"
              :key="item.id"
              class="rounded-lg border border-green-100 px-2 py-2"
            >
              <Document :document="item.document" />
            </div>
          </div>
        </template>

        <div class="h-4 w-full bg-gray-100"></div>

        <div class="flex flex-col pb-24 px-4">
          <div class="border mt-4 rounded-lg bg-teal-50 border-teal-200 p-4">
            <div class="flex">
              <span class="mr-auto text-gray-600">Subtotal</span>
              <span class="font-medium">
                <Currency
                  :total-in-string="
                    (Number(purchase.total) - Number(purchase.other_fees) - Number(purchase.total_discount)).toString()
                  "
                />
              </span>
            </div>
            <!-- <div class="mt-4 flex">
              <div class="mr-auto text-gray-600">Tax ({{ tax }}%)</div>
              <div class="font-medium">
                <Currency
                  :total-in-string="
                    Math.round((purchase.total / 100) * tax).toString()
                  "
                />
              </div>
            </div> -->
            <div
              v-if="purchase.other_fees !== undefined"
              class="mt-3 flex"
            >
              <span class="mr-auto text-gray-600">Biaya Lainnya</span>
              <span class="font-medium">
                <Currency
                  :total-in-string="Number(purchase.other_fees).toString()"
                />
              </span>
            </div>
            <div
              v-if="purchase.total_discount !== undefined"
              class="mt-3 flex"
            >
              <span class="mr-auto text-gray-600">
                Discount
              </span>
              <span class="flex medium text-red-600">
                -<Currency
                  :total-in-string="purchase.total_discount.toString()"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter v-if="purchase.code !== undefined" class="w-470 fixed bottom-0 z-20 bg-white p-0 px-4 py-4">
      <div class="flex justify-between">
        <div class="flex flex-col">
          <div class="mr-auto text-gray-600 text-xs font-medium">Total Harga</div>
          <div class="flex text-base font-medium">
            <Currency :total-in-string="purchase.total.toString()" />
          </div>
        </div>
        <div class="flex">
          <button
            v-if="canEdit"
            type="button"
            class="btn flex w-32 bg-gradient-to-r from-teal-700 to-primary text-white"
          >
            <!-- @click="updateTransaction" -->
            <LoadingIcon
              v-if="isFormLoading"
              icon="oval"
              color="white"
              class="mr-2 h-5 w-5"
            />

            Update
          </button>
        </div>
      </div>
    </ModalFooter>
  </Modal>
</template>

<script>
import { computed, onMounted, provide, ref, watch, defineProps } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helper as $h } from '@/utils/helper'
import ImageLoader from '@/components/image-loader/Main.vue'
import Document from '@/components/document/Main.vue'
import Currency from '@/components/Currency.vue'
import Status from '@/components/status/Main.vue'

export default {
  name: 'TransactionDetail',
  components: {
    ImageLoader,
    Document,
    Currency,
    Status,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    purchase: {
      type: Object,
      required: true,
      default: '',
    },
    canEdit: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  emits: ['onClose', 'onError'],
  methods: {
    onClose() {
      this.$emit('onClose')
    },
  },
  setup(props, { emit }) {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const tax = ref(11)
    const isFormLoading = ref(false)
    
    const changeQuantity = (product, bonus, added) => {
      product = $h.changeQuantity(product, bonus, added)
      calculateTotalPriceItem(product)
    }
    
    const calculateTotalPriceItem = (product) => {
      product = $h.calculateTotalPriceItem(product)
    
      const result = $h.calculateTotalPrice(props.purchase)
      props.purchase.total = result.total
    }

    const removeItem = (product) => {
      if (props.purchase.items.length === 1) {
        emit('onError', 'Tidak dapat menghapus, minimal satu item tersisa dalam satu transaksi')
        return
      }

      props.purchase.items = props.purchase.items.filter(
        (item) => item.id != product.id
      )

      const result = $h.calculateTotalPrice(props.purchase)
      props.purchase.total = result.total
    }

    return {
      t,
      store,
      route,
      router,
      
      tax,
      isFormLoading,

      changeQuantity,
      calculateTotalPriceItem,
      removeItem
    }
  },
}
</script>
