<template>
  <free-style-shimmer
    :is-loading="true"
    height="100%"
    width="100%"
    class="w-full"
    :class="[height !== '' ? height : 'h-20', roundedClass]"
    color="#eceded"
  />
</template>

<script>
export default {
  name: 'ShimmerCard',
  props: {
    height: {
      type: String,
      default: '',
      required: false,
    },
    roundedClass: {
      type: String,
      default: 'rounded-lg',
      required: false,
    },
  },
}
</script>
