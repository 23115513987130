<template>
  <div class="flex flex-col">
    <span class="text-xs">
      {{
        customer !== undefined
          ? customer.full_name
          : '-'
      }} <span class="text-amber-700">{{
        customer !== undefined
          ? `(${customer.type})`
          : ''
      }}</span>
    </span>
    <span class="text-xs text-gray-500">
      {{
        customer !== undefined
          ? customer.phone
          : '-'
      }}
    </span>
    <template v-if="showAddress">
      <div class="border border-dashed my-2"></div>
      <span class="text-xs text-gray-500 italic">
        {{
          customer !== undefined && customer.address !== undefined
            ? customer.address.detail_address
            : '-'
        }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Customer',
  props: {
    customer: {
      type: Object,
      default: {
        full_name: 'Toko Idin',
        phone: '+62-000-000-000',
        type: 'R1',
      },
      required: true,
    },
    showAddress: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
}
</script>
