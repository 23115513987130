<template>
  <div class="grid grid-cols-12 gap-6">
    <!-- BEGIN: General Report -->

    <div class="col-span-12 mt-4 md:mt-6">
      <div class="intro-y col-span-12 mt-2 flex flex-wrap items-center">
        <div class="flex w-full md:w-auto">
          <h2 class="intro-y text-lg font-medium">Laporan Penjualan Hari Ini</h2>
        </div>
      </div>
      <div class="mt-5 md:mt-8 grid grid-cols-12 gap-2 md:gap-4 lg:gap-6">
        <div
          class="intro-y col-span-6 lg:col-span-3"
        >
          <CardReportPercentage
            title="Penjualan"
            :total="reportData.sales_total.total"
            :percentage="reportData.sales_total.percent"
            :is-up="reportData.sales_total.is_up"
            :gap-date="gapDate"
            :loading="isSummaryLoading"
            :with-prefix="true"
          />
        </div>
        <div
          class="intro-y col-span-6 lg:col-span-3"
        >
          <CardReportPercentage
            title="Margin"
            :total="reportData.margin_total.total"
            :percentage="reportData.margin_total.percent"
            :is-up="reportData.margin_total.is_up"
            :gap-date="gapDate"
            :loading="isSummaryLoading"
            :with-prefix="true"
          />
        </div>
        <div
          class="intro-y col-span-6 lg:col-span-3"
        >
          <CardReportPercentage
            title="Persentase Margin"
            :total="reportData.margin_percentage.total"
            :loading="isSummaryLoading"
            :with-prefix="false"
            :with-percentage="true"
          />
        </div>
        <div
          class="intro-y col-span-6 lg:col-span-3"
        >
          <CardReportPercentage
            title="Transaksi"
            :total="reportData.transaction_total.total"
            :percentage="reportData.transaction_total.percent"
            :is-up="reportData.transaction_total.is_up"
            :gap-date="gapDate"
            :loading="isSummaryLoading"
            :with-prefix="false"
          />
        </div>
      </div>
    </div>

    <div v-if="omzetByDateChartData.length > 0" class="intro-y col-span-12 flex flex-wrap mt-4">
      <div class="flex w-full md:w-auto">
        <h2 class="intro-y text-lg font-medium">Laporan Harian</h2>
      </div>
      <div class="mx-auto hidden text-slate-500 md:block"></div>
      <div class="relative w-full md:w-max mt-3 text-slate-500 md:mt-0">
        <CalendarIcon
          class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
        />
        <Litepicker
          v-model="filterDate"
          :options="$h.litePickerConfig()"
          class="form-control box pl-10 w-full md:w-56"
          @change="getSummaryData"
        />
      </div>
    </div>

    <div v-if="omzetByDateChartData.length > 0" class="intro-y col-span-12" :class="differenceDays <= 7 ? 'md:col-span-6' : differenceDays > 14 ? '' : 'lg:col-span-6'">
      <CardReportChart :data="omzetByDateChartData" title="Omzet Harian" prefix="Total Omzet" :show-currency-prefix="true" />
    </div>

    <div v-if="marginByDateChartData.length > 0" class="intro-y col-span-12" :class="differenceDays <= 7 ? 'md:col-span-6' : differenceDays > 14 ? '' : 'lg:col-span-6'">
      <CardReportChart :data="marginByDateChartData" title="Margin Harian" prefix="Total Margin" :show-currency-prefix="true" />
    </div>

    <div v-if="reportByDateChartLabel.length > 0" class="intro-y col-span-12 lg:col-span-12">
      <CardReportChart :data="reportByDateChartData" :labels="reportByDateChartLabel" :prefix="reportByDateChartPrefix" :show-legend="true" title="Transaksi Harian" />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs, watch, provide } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import dayjs from 'dayjs'
import globalMixin from '@/mixins/global.js'
import useEmitter from '@/composables/useEmitter'
import ReportLineChart from '@/components/report-line-chart/Main.vue'
import ReportDonutChart from '@/components/report-donut-chart/Main.vue'
import ReportDonutChart1 from '@/components/report-donut-chart-1/Main.vue'
import ReportMap from '@/components/report-map/Main.vue'
import SimpleLineChart1 from '@/components/simple-line-chart-1/Main.vue'
import ReportPieChart from '@/components/report-pie-chart/Main.vue'
import Currency from '@/components/Currency.vue'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Status from '@/components/status/Main.vue'
import CardReportPercentage from '@/components/card/CardReportPercentage.vue'
import CardReportChart from '@/components/card/CardReportChart.vue'

export default {
  components: {
    ReportLineChart,
    ReportDonutChart,
    ReportDonutChart1,
    ReportMap,
    SimpleLineChart1,
    ReportPieChart,
    ImageLoader,
    TableComponent,
    Status,
    Currency,
    CardReportPercentage,
    CardReportChart
  },
  mixins: [globalMixin],
  setup(props, { emit }) {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const emitter = useEmitter()

    const activeUser = reactive({})
    const roleAccess = ref('')
    
    let differenceDays = ref(0)
    // const periodic = ref(null)
    // const periodicName = ref(null)

    let reportData = reactive({
      sales_total: {
        total: 0,
        percent: '',
        is_up: false
      },
      margin_total: {
        total: 0,
        percent: '',
        is_up: false
      },
      transaction_total: {
        total: 0,
        percent: '',
        is_up: false
      },
      capital_total: {
        total: 0,
      },
      margin_percentage: {
        total: 0,
      },
      transaction_score: {
        total: 0,
      },
    })
   
    let omzetByDateChartData = ref([])
    let marginByDateChartData = ref([])
    let reportByDateChartData = ref([])
    let reportByDateChartLabel = ref([])
    let reportByDateChartPrefix = ref(["Total R1", "Total R2", "Total R3"])

    let gapDate = ref (null)

    const isSummaryLoading = ref(false)
    const isStockSummaryLoading = ref(false)
    const timer = ref(undefined)

    const starDateOfMonth = dayjs().startOf('month').format("YYYY-MM-DD")
    const endDateOfMonth = dayjs().endOf('month').format("YYYY-MM-DD")
    const filterDate = ref(`${starDateOfMonth} - ${endDateOfMonth}`)

    const importantNotesRef = ref()

    provide('bind[importantNotesRef]', (el) => {
      importantNotesRef.value = el
    })

    const prevImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('prev')
    }

    const nextImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('next')
    }

    watch(filterDate, (currentValue, oldValue) => {
      calculateDiffDays()

      refreshData()
    })

    onMounted(async () => {
      // clearing all event
      emitter.all.clear()

      Object.assign(activeUser, store.getters['auth/activeUser'])

      roleAccess.value = activeUser.role_codes
      // periodic.value = periodicOptions.value[0].id
      // periodicName.value = periodicOptions.value[0].name

      checkAccess(roleAccess.value)
      calculateDiffDays()

      emitter.on('update-data', (store) => {
        refreshData()
      })

      const storeId = store.getters['store/storeId']

      if (storeId === null) {
        setTimeout(() => {
          refreshData()
        }, 2000)
      } else {
        refreshData()
      }
    })

    const calculateDiffDays = async () => {
      let startDateString = ""
      let endDatestring = ""
      const dateRange = filterDate.value.split(' - ')
      if (dateRange.hasOwnProperty(0)) startDateString = dateRange[0]
      if (dateRange.hasOwnProperty(1)) endDatestring = dateRange[1]

      const endDate = dayjs(endDatestring)
      differenceDays.value = endDate.diff(startDateString, 'day');
    }

    const refreshData = async () => {
      await getSummaryData()
      await getTransactionByDateChartData()
    }

    const getSummaryData = async () => {
      // const filterPeriodic = periodicOptions.value.filter(function (item) {
      //   return item.id === periodic.value
      // })
      // periodicName.value = filterPeriodic[0].name

      isSummaryLoading.value = true

      // emit('showGlobalLoadingIndicator', true)

      const storeId = store.getters['store/storeId']

      let queryParameters = {
        store_id: storeId,
        is_dashboard: true,
        // periodic: periodic.value,
      }

      queryParameters.start_date = dayjs().format('YYYY-MM-DD')
      queryParameters.end_date = dayjs().format('YYYY-MM-DD')

      const startDate = dayjs(queryParameters.end_date)
      gapDate.value = `${startDate.diff(queryParameters.start_date, 'day') + 1} hari`
      

      await store
        .dispatch('transaction/summary', queryParameters)
        .then((response) => {
          if (response.data) {
            Object.assign(reportData, response.data)
          }

          isSummaryLoading.value = false
          // emit('showGlobalLoadingIndicator', false)
        })
        .catch((e) => {
          isSummaryLoading.value = false
          // emit('showGlobalLoadingIndicator', false)
          console.log(e)
        })
    }

    const getTransactionByDateChartData = async () => {
      isSummaryLoading.value = true

      const storeId = store.getters['store/storeId']

      let queryParameters = {
        store_id: storeId,
      }

      const dateRange = filterDate.value.split(' - ')

      if (dateRange.hasOwnProperty(0)) queryParameters.start_date = dateRange[0]
      if (dateRange.hasOwnProperty(1)) queryParameters.end_date = dateRange[1]

      await store
        .dispatch('report/byDateChart', queryParameters)
        .then((response) => {
          if (response.data) {
            omzetByDateChartData.value = []
            omzetByDateChartData.value = response.data.omzet

            const notZero = omzetByDateChartData.value.filter(obj=>!Object.values(obj).includes(0));

            marginByDateChartData.value = []
            marginByDateChartData.value = response.data.margin

            reportByDateChartLabel.value = response.data.transaction_r1.map((item) => item.name)
            
            reportByDateChartData.value = []
            reportByDateChartData.value.push(response.data.transaction_r1.map((item) => item.total))
            reportByDateChartData.value.push(response.data.transaction_r2.map((item) => item.total))
            reportByDateChartData.value.push(response.data.transaction_r3.map((item) => item.total))
          }

          isSummaryLoading.value = false
        })
        .catch((e) => {
          isSummaryLoading.value = false
          console.log(e)
        })
    }

    const checkAccess = async (roleCodes) => {
      if (roleCodes.includes('ADMIN_STORE')) {
        setTimeout(() => {
          router.push({
            name: 'transaction',
            params: { slug: route.params.slug },
            query: { lang: route.query.lang },
          })
        }, 2000)
      } else if (roleCodes.includes('CASHIER')) {
        setTimeout(() => {
          router.push({
            name: 'point-of-sale',
            params: { slug: route.params.slug },
            query: { lang: route.query.lang },
          })
        }, 2000)
      } else if (roleCodes.includes('PICKER_PACKER')) {
        setTimeout(() => {
          router.push({
            name: 'transaction',
            params: { slug: route.params.slug },
            query: { lang: route.query.lang },
          })
        }, 2000)
      } else if (roleCodes.includes('DRIVER')) {
        setTimeout(() => {
          router.push({
            name: 'transaction',
            params: { slug: route.params.slug },
            query: { lang: route.query.lang },
          })
        }, 2000)
      } else {
        // getSummaryData()
        // getStockSummaryData()
      }
    }

    return {
      dayjs,
      t,
      n,
      store,
      route,
      router,

      roleAccess,

      // periodic,
      // periodicName,
      // periodicOptions,

      reportData,
      reportByDateChartLabel,
      reportByDateChartPrefix,
      reportByDateChartData,
      omzetByDateChartData,
      marginByDateChartData,

      isSummaryLoading,
      isStockSummaryLoading,
      timer,

      getSummaryData,
      getTransactionByDateChartData,
      
      differenceDays,
      gapDate,

      checkAccess,

      filterDate,
      importantNotesRef,
      prevImportantNotes,
      nextImportantNotes,
    }
  },
}
</script>
