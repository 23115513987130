<template>
  <h2 class="intro-y mt-2 md:mt-6 text-lg font-medium">Analytic Perputaran Stock</h2>
  <div class="mt-5 grid grid-cols-12 gap-6">
    <div class="intro-y col-span-12 sm:col-span-6 xl:col-span-3">
      <div class="report-box zoom-in">
        <div class="box p-5">
          <div class="flex">
            <HardDriveIcon class="report-box__icon text-secondary" />
          </div>
          <div class="mt-6 text-2xl font-medium leading-8">60</div>
          <div class="mt-1 text-sm text-slate-500">Good Stock</div>
        </div>
      </div>
    </div>
    <div class="intro-y col-span-12 sm:col-span-6 xl:col-span-3">
      <div class="report-box zoom-in">
        <div class="box p-5">
          <div class="flex">
            <HardDriveIcon class="report-box__icon text-warning" />
          </div>
          <div class="mt-6 text-2xl font-medium leading-8">20</div>
          <div class="mt-1 text-sm text-slate-500">Bad Stock</div>
        </div>
      </div>
    </div>
    <div class="intro-y col-span-12 sm:col-span-6 xl:col-span-3">
      <div class="report-box zoom-in">
        <div class="box p-5">
          <div class="flex">
            <HardDriveIcon class="report-box__icon text-success" />
          </div>
          <div class="mt-6 text-2xl font-medium leading-8">110</div>
          <div class="mt-1 text-sm text-slate-500">Sold Stock</div>
        </div>
      </div>
    </div>
    <div class="intro-y col-span-12 sm:col-span-6 xl:col-span-3">
      <div class="report-box zoom-in">
        <div class="box p-5">
          <div class="flex">
            <HardDriveIcon class="report-box__icon text-danger" />
          </div>
          <div class="mt-6 text-2xl font-medium leading-8">10</div>
          <div class="mt-1 text-sm text-slate-500">Retur Stock</div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-span-12 mt-8">
    <div class="intro-y box mt-5 flex flex-col p-5 md:flex-row">
      <div class="flex justify-center">
        <ReportDonutChart :height="213" />
      </div>
      <div class="mt-5 flex justify-center md:justify-start">
        <div class="flex flex-col justify-center">
          <div class="flex items-center">
            <div class="mr-3 h-2 w-2 rounded-full bg-secondary"></div>
            <span class="truncate">Good Stock</span>
          </div>
          <div class="mt-4 flex items-center">
            <div class="mr-3 h-2 w-2 rounded-full bg-warning"></div>
            <span class="truncate">Bad Stock</span>
          </div>
          <div class="mt-4 flex items-center">
            <div class="mr-3 h-2 w-2 rounded-full bg-success"></div>
            <span class="truncate">Sold Stock</span>
          </div>
          <div class="mt-4 flex items-center">
            <div class="mr-3 h-2 w-2 rounded-full bg-danger"></div>
            <span class="truncate">Retur Stock</span>
          </div>
        </div>
        <div class="flex flex-col justify-center pl-10">
          <div class="flex items-center">
            <span class="font-medium">30%</span>
          </div>
          <div class="mt-4 flex items-center">
            <span class="font-medium">10%</span>
          </div>
          <div class="mt-4 flex items-center">
            <span class="font-medium">55%</span>
          </div>
          <div class="mt-4 flex items-center">
            <span class="font-medium">5%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-12 grid grid-cols-12 gap-6">
    <div
      class="intro-y col-span-12 mt-2 flex flex-wrap items-center sm:flex-nowrap"
    >
      <div class="flex">
        <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white mr-2 shadow-md">
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button>
        <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white mr-2 shadow-md">
          <FileTextIcon class="mr-2 h-4 w-4" /> Export ke Excel
        </button>
      </div>
      <div class="mx-auto hidden text-slate-500 md:block">
        Showing 1 to 10 of 150 entries
      </div>
      <div class="mt-3 w-full sm:mt-0 sm:ml-auto sm:w-auto md:ml-0">
        <div class="relative w-56 text-slate-500">
          <input
            type="text"
            class="form-control box w-56 pr-10"
            placeholder="Search..."
          />
          <SearchIcon class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4" />
        </div>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <div class="intro-y col-span-12 overflow-auto 2xl:overflow-visible">
      <table class="table-report -mt-2 table">
        <thead>
          <tr>
            <th class="whitespace-nowrap">IMAGES</th>
            <th class="whitespace-nowrap">PRODUCT NAME</th>
            <th class="whitespace-nowrap text-center">GOOD STOCK</th>
            <th class="whitespace-nowrap text-center">BAD STOCK</th>
            <th class="whitespace-nowrap text-center">SOLD STOCK</th>
            <th class="whitespace-nowrap text-center">RETUR STOCK</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(faker, fakerKey) in $_.take($f(), 9)"
            :key="fakerKey"
            class="intro-x"
          >
            <td class="w-40">
              <div class="flex">
                <div class="image-fit zoom-in h-10 w-10">
                  <Tippy
                    tag="img"
                    alt="Gromura - SCM"
                    class="rounded-full"
                    :src="faker.products[0].images[0]"
                    :content="`Uploaded at ${faker.dates[0]}`"
                  />
                </div>
                <div class="image-fit zoom-in -ml-5 h-10 w-10">
                  <Tippy
                    tag="img"
                    alt="Gromura - SCM"
                    class="rounded-full"
                    :src="faker.products[0].images[1]"
                    :content="`Uploaded at ${faker.dates[1]}`"
                  />
                </div>
                <!-- <div class="image-fit zoom-in -ml-5 h-10 w-10">
                    <Tippy
                      tag="img"
                      alt="Gromura - SCM"
                      class="rounded-full"
                      :src="faker.images[2]"
                      :content="`Uploaded at ${faker.dates[2]}`"
                    />
                  </div> -->
              </div>
            </td>
            <td>
              <a href="" class="whitespace-nowrap font-medium">{{
                faker.products[0].name
              }}</a>
              <div class="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                {{ faker.products[0].category }}
              </div>
            </td>
            <td class="text-center">{{ faker.stocks[0] + 300 }}</td>
            <td class="text-center">{{ faker.stocks[0] }}</td>
            <td class="text-center">{{ faker.stocks[0] + 2500 }}</td>
            <td class="text-center">{{ faker.stocks[0] - 30 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- END: Data List -->
    <!-- BEGIN: Pagination -->
    <div
      class="intro-y col-span-12 flex flex-wrap items-center sm:flex-row sm:flex-nowrap"
    >
      <nav class="w-full sm:mr-auto sm:w-auto">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronsLeftIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronLeftIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">...</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">1</a>
          </li>
          <li class="page-item active">
            <a class="page-link" href="#">2</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">3</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">...</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronRightIcon class="h-4 w-4" />
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">
              <ChevronsRightIcon class="h-4 w-4" />
            </a>
          </li>
        </ul>
      </nav>
      <select class="box form-select mt-3 w-20 sm:mt-0">
        <option>10</option>
        <option>25</option>
        <option>35</option>
        <option>50</option>
      </select>
    </div>
    <!-- END: Pagination -->
  </div>
  <!-- BEGIN: Delete Confirmation Modal -->
  <Modal
    :show="deleteConfirmationModal"
    @hidden="deleteConfirmationModal = false"
  >
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5 text-3xl">Are you sure?</div>
        <div class="mt-2 text-slate-500">
          Do you really want to delete these records? <br />This process cannot
          be undone.
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="deleteConfirmationModal = false"
          class="btn btn-outline-secondary mr-1 w-24"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-danger w-24">Delete</button>
      </div>
    </ModalBody>
  </Modal>
  <!-- END: Delete Confirmation Modal -->
</template>

<script setup>
import { ref, provide } from 'vue'
import ReportDonutChart from '@/components/report-donut-chart/Main.vue'
import ReportPieChart from '@/components/report-pie-chart/Main.vue'
import ReportDonutChart1 from '@/components/report-donut-chart-1/Main.vue'

const deleteConfirmationModal = ref(false)
</script>
