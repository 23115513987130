<template>
  <div class="intro-y mt-8 flex flex-col items-center sm:flex-row">
    <h2 class="mr-auto text-lg font-medium">Seller Details</h2>
    <div class="mt-4 flex w-full sm:mt-0 sm:w-auto">
      <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white mr-2 shadow-md">Print</button>
      <Dropdown class="ml-auto sm:ml-0">
        <DropdownToggle class="btn box px-2">
          <span class="flex h-5 w-5 items-center justify-center">
            <PlusIcon class="h-4 w-4" />
          </span>
        </DropdownToggle>
        <DropdownMenu class="w-40">
          <DropdownContent>
            <DropdownItem>
              <FileIcon class="mr-2 h-4 w-4" /> Export Word
            </DropdownItem>
            <DropdownItem>
              <FileIcon class="mr-2 h-4 w-4" /> Export PDF
            </DropdownItem>
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>
    </div>
  </div>
  <!-- BEGIN: Seller Details -->
  <div class="intro-y mt-5 grid grid-cols-11 gap-5">
    <div class="col-span-12 lg:col-span-4 2xl:col-span-3">
      <div class="box rounded-md p-5">
        <div
          class="mb-5 flex items-center border-b border-slate-200/60 pb-5 dark:border-darkmode-400"
        >
          <div class="truncate text-base font-medium">User Details</div>
          <a href="" class="ml-auto flex items-center text-primary">
            <EditIcon class="mr-2 h-4 w-4" /> More Details
          </a>
        </div>
        <div class="flex items-center">
          <ClipboardIcon class="mr-2 h-4 w-4 text-slate-500" />
          Unique ID:
          <a href="" class="ml-1 underline decoration-dotted"
            >SLR-20220217-2053411933</a
          >
        </div>
        <div class="mt-3 flex items-center">
          <UserIcon class="mr-2 h-4 w-4 text-slate-500" /> Name:
          <a href="" class="ml-1 underline decoration-dotted">{{
            $f()[0].users[0].name
          }}</a>
        </div>
        <div class="mt-3 flex items-center">
          <CalendarIcon class="mr-2 h-4 w-4 text-slate-500" />
          Phone Number: +71828273732
        </div>
        <div class="mt-3 flex items-center">
          <MapPinIcon class="mr-2 h-4 w-4 text-slate-500" />
          Address: 260 W. Storm Street New York, NY 10025.
        </div>
        <div
          class="mt-5 flex items-center border-t border-slate-200/60 pt-5 font-medium dark:border-darkmode-400"
        >
          <button
            type="button"
            class="btn btn-outline-secondary w-full py-1 px-2"
          >
            Message User
          </button>
        </div>
      </div>
      <div class="box mt-5 rounded-md p-5">
        <div
          class="mb-5 flex items-center border-b border-slate-200/60 pb-5 dark:border-darkmode-400"
        >
          <div class="truncate text-base font-medium">Store Details</div>
          <a href="" class="ml-auto flex items-center text-primary">
            <EditIcon class="mr-2 h-4 w-4" /> More Details
          </a>
        </div>
        <div class="flex items-center">
          <ClipboardIcon class="mr-2 h-4 w-4 text-slate-500" />
          Unique ID:
          <a href="" class="ml-1 underline decoration-dotted"
            >STR-2053411933-20220217</a
          >
        </div>
        <div class="mt-3 flex items-center">
          <ShoppingBagIcon class="mr-2 h-4 w-4 text-slate-500" />
          Name:
          <a href="" class="ml-1 underline decoration-dotted">TM Pasar Kamis</a>
        </div>
        <div class="mt-3 flex items-center">
          <CalendarIcon class="mr-2 h-4 w-4 text-slate-500" />
          Phone Number: +71828273732
        </div>
        <div class="mt-3 flex items-center">
          <MapPinIcon class="mr-2 h-4 w-4 text-slate-500" />
          Address: 260 W. Storm Street New York, NY 10025.
        </div>
        <div class="mt-3 flex items-center">
          <CalendarIcon class="mr-2 h-4 w-4 text-slate-500" />
          Status:
          <span class="ml-1 rounded bg-success/20 px-2 text-success"
            >Active</span
          >
        </div>
        <div
          class="mt-5 flex items-center border-t border-slate-200/60 pt-5 font-medium dark:border-darkmode-400"
        >
          <button
            type="button"
            class="btn btn-outline-secondary w-full py-1 px-2"
          >
            Change Status
          </button>
        </div>
      </div>
      <div class="box mt-5 rounded-md p-5">
        <div
          class="mb-5 flex items-center border-b border-slate-200/60 pb-5 dark:border-darkmode-400"
        >
          <div class="truncate text-base font-medium">Transaction Reports</div>
          <a href="" class="ml-auto flex items-center text-primary">
            <EditIcon class="mr-2 h-4 w-4" /> More Details
          </a>
        </div>
        <div class="mt-3 flex items-center">
          <ClipboardIcon class="mr-2 h-4 w-4 text-slate-500" />
          Avg. Daily Transactions:
          <div class="ml-auto">$1,500.00</div>
        </div>
        <div class="mt-3 flex items-center">
          <ClipboardIcon class="mr-2 h-4 w-4 text-slate-500" />
          Avg. Monthly Transactions:
          <div class="ml-auto">$42,500.00</div>
        </div>
        <div class="mt-3 flex items-center">
          <ClipboardIcon class="mr-2 h-4 w-4 text-slate-500" />
          Avg. Annually Transactions:
          <div class="ml-auto">$1,012,500.00</div>
        </div>
        <div class="mt-3 flex items-center">
          <StarIcon class="mr-2 h-4 w-4 text-slate-500" /> Average Rating:
          <div class="ml-auto">4.9+</div>
        </div>
        <div class="mt-3 flex items-center">
          <AlbumIcon class="mr-2 h-4 w-4 text-slate-500" /> Total Products:
          <div class="ml-auto">7,120</div>
        </div>
        <div class="mt-3 flex items-center">
          <ArchiveIcon class="mr-2 h-4 w-4 text-slate-500" />
          Total Transactions:
          <div class="ml-auto">1.512.001</div>
        </div>
        <div class="mt-3 flex items-center">
          <MonitorIcon class="mr-2 h-4 w-4 text-slate-500" />
          Active Disputes:
          <div class="ml-auto">1</div>
        </div>
      </div>
    </div>
    <div class="col-span-12 lg:col-span-7 2xl:col-span-8">
      <div class="grid grid-cols-12 gap-5">
        <div
          v-for="(faker, fakerKey) in $_.take($f(), 9)"
          :key="fakerKey"
          class="intro-y col-span-12 sm:col-span-6 2xl:col-span-4"
        >
          <div class="box">
            <div class="p-5">
              <div
                class="image-fit h-40 overflow-hidden rounded-md before:absolute before:top-0 before:left-0 before:z-10 before:block before:h-full before:w-full before:bg-gradient-to-t before:from-black before:to-black/10 2xl:h-56"
              >
                <img
                  alt="Gromura - SCM"
                  class="rounded-md"
                  :src="faker.products[0].images[0]"
                />
                <template v-if="faker.trueFalse[0]">
                  <span
                    class="absolute top-0 z-10 m-5 rounded bg-pending/80 px-2 py-1 text-xs text-white"
                    >Featured</span
                  >
                </template>
                <div class="absolute bottom-0 z-10 px-5 pb-6 text-white">
                  <a href="" class="block text-base font-medium">{{
                    faker.products[0].name
                  }}</a>
                  <span class="mt-3 text-xs text-white/90">{{
                    faker.products[0].category
                  }}</span>
                </div>
              </div>
              <div class="mt-5 text-slate-600 dark:text-slate-500">
                <div class="flex items-center">
                  <LinkIcon class="mr-2 h-4 w-4" /> Price: Rp{{
                    faker.totals[0]
                  }}
                </div>
                <div class="mt-2 flex items-center">
                  <LayersIcon class="mr-2 h-4 w-4" /> Remaining Stock:
                  {{ faker.stocks[0] }}
                </div>
                <div class="mt-2 flex items-center">
                  <CheckSquareIcon class="mr-2 h-4 w-4" />
                  Status: {{ faker.trueFalse[0] ? 'Active' : 'Inactive' }}
                </div>
              </div>
            </div>
            <div
              class="flex items-center justify-center border-t border-slate-200/60 p-5 dark:border-darkmode-400 lg:justify-end"
            >
              <a
                class="mr-auto flex items-center text-primary"
                href="javascript:;"
              >
                <EyeIcon class="mr-1 h-4 w-4" /> Preview
              </a>
              <a class="mr-3 flex items-center" href="javascript:;">
                <CheckSquareIcon class="mr-1 h-4 w-4" /> Edit
              </a>
              <a
                class="flex items-center text-danger"
                href="javascript:;"
                @click="deleteConfirmationModal = true"
              >
                <Trash2Icon class="mr-1 h-4 w-4" /> Delete
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- BEGIN: Pagination -->
      <div
        class="intro-y col-span-11 mt-6 flex flex-wrap items-center sm:flex-row sm:flex-nowrap"
      >
        <nav class="w-full sm:mr-auto sm:w-auto">
          <ul class="pagination">
            <li class="page-item">
              <a class="page-link" href="#">
                <ChevronsLeftIcon class="h-4 w-4" />
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                <ChevronLeftIcon class="h-4 w-4" />
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">...</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">1</a>
            </li>
            <li class="page-item active">
              <a class="page-link" href="#">2</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">3</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">...</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                <ChevronRightIcon class="h-4 w-4" />
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                <ChevronsRightIcon class="h-4 w-4" />
              </a>
            </li>
          </ul>
        </nav>
        <select class="box form-select mt-3 w-20 sm:mt-0">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
  </div>
  <!-- END: Seller Details -->
  <!-- BEGIN: Delete Confirmation Modal -->
  <Modal
    :show="deleteConfirmationModal"
    @hidden="deleteConfirmationModal = false"
  >
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5 text-3xl">Are you sure?</div>
        <div class="mt-2 text-slate-500">
          Do you really want to delete these records? <br />This process cannot
          be undone.
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="deleteConfirmationModal = false"
          class="btn btn-outline-secondary mr-1 w-24"
        >
          Cancel
        </button>
        <button type="button" class="btn btn-danger w-24">Delete</button>
      </div>
    </ModalBody>
  </Modal>
  <!-- END: Delete Confirmation Modal -->
</template>

<script setup>
import { ref } from 'vue'

const deleteConfirmationModal = ref(false)
</script>
