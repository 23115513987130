<template>
  <div
    class="mr-2 rounded-full border font-semibold bg-sky-50 text-sky-600 border-sky-200 pl-2 pr-3 flex items-center py-1 text-xs mb-2"
  >
    <ChevronsRightIcon class="h-3 w-3 mr-1" />{{ name }}
  </div>
</template>

<script>
export default {
  name: 'Category',
  props: {
    name: {
      type: String,
      default: null,
      required: true,
    },
  },
}
</script>
