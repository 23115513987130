<template>
  <Modal size="modal-lg" :show="showModal" @hidden="onClose">
    <ModalHeader>
      <div class="flex w-full justify-between">
        <div class="flex">
          <h2 class="text-base font-medium">Ubah Status</h2>
        </div>
      </div>
    </ModalHeader>
    <ModalBody>
      <div class="flex w-full flex-col">
        <div class="w-full flex-col">
          <label class="form-label">Status</label>
          <select
            v-model="validate.status.$model"
            class="box form-select mt-1 w-full"
            placeholder="Pilih Status"
          >
            <option value="" disabled selected>
              {{ placeholderStatus }}
            </option>
            <option
              v-for="item in statusOption"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
          <template v-if="validate.status.$error">
            <div
              v-for="(error, index) in validate.status.$errors"
              :key="index"
              class="mt-2 text-xs text-danger"
            >
              {{ $t(error.$message) }}
            </div>
          </template>
          <!-- <div v-if="statusError === true" class="mt-2 text-xs text-danger">
            Status harus diisi
          </div> -->
        </div>
        <div
          v-if="['PAID', 'RECEIVED'].includes(formData.status)"
          class="mt-4 w-full"
        >
          <label class="form-label">Upload Bukti Pengiriman</label>
          <div
            class="rounded-xl border-2 border-dashed dark:border-darkmode-400"
            :class="[files.length === 0 ? 'py-6' : 'pt-4', isDragging ? 'border-4' : 'border-2']"
            @dragover="dragoverFile"
            @dragleave="dragleaveFile"
            @drop="dropFile"
          >
            <div class="flex flex-wrap px-4">
              <div
                v-for="(file, i) in files"
                :key="i"
                class="image-fit zoom-in relative mb-5 mr-5 h-24 w-24 cursor-pointer"
              >
                <img
                  v-if="file.image !== undefined"
                  class="rounded-md"
                  alt="Gromura - SCM"
                  :src="file.image"
                />
                <div
                  v-else-if="file.type === 'application/pdf'"
                  class="flex h-full w-full flex-col justify-center rounded-md bg-orange-100 px-2 text-center text-orange-600"
                >
                  <span class="text-xs">{{ file.name }}</span>
                  <!-- <span class="text-sm">{{ file.name }}</span> -->
                </div>
                <img
                  v-else
                  class="rounded-md"
                  alt="Gromura - SCM"
                  :src="generateThumbnail(file)"
                />
                <Tippy
                  tag="div"
                  content="Hapus gambar ini?"
                  class="absolute right-0 top-0 -mr-2 -mt-2 flex h-5 w-5 items-center justify-center rounded-full bg-danger text-white"
                  @click="removeFile(file)"
                >
                  <xIcon class="h-4 w-4" />
                </Tippy>
              </div>
            </div>
            <div
              class="relative flex cursor-pointer flex-col px-4"
              :class="[files.length === 0 ? 'py-6' : 'pb-4', isDragging ? 'py-8': '']"
            >
              <div class="flex items-center">
                <ImageIcon class="mr-2 h-4 w-4" :class="isDragging ? 'text-gray-400' : ''" />
                <div v-if="isDragging" class="text-base text-gray-400">Lepaskan file disini.</div>
                <div v-else>
                  <span class="mr-1 text-primary">Unggah file</span>
                  <span class="text-gray-500">atau jatuhkan file disini</span>
                </div>
              </div>
              <span
                v-if="isDragging === false"
                class="mt-2 block text-xs font-medium italic text-gray-400"
              >
                Hanya mendukung pdf & gambar
              </span>
              <input
                id="fileInput"
                ref="file"
                multiple
                type="file"
                name="file"
                class="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
                accept="application/pdf,image/*"
                @change="browseFile"
              />
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <!-- <button
        type="button"
        class="btn bg-gradient-to-r from-teal-700 to-primary text-white px-8"
        @click="changeStatusData"
      >
        <template v-if="isFormLoading === true">
          <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
        </template>
        Simpan
      </button> -->
      <Button
        :title="isFormLoading ? 'Menyimpan..' : 'Simpan'"
        color="gradient"
        :loading="isFormLoading"
        @click="changeStatusData"
      />
    </ModalFooter>
  </Modal>
</template>

<script>
import { computed, onMounted, provide, ref, reactive, toRefs, watch, watchEffect, defineProps } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { helper as $h } from '@/utils/helper'

export default {
  name: 'ChangeStatusTransaction',
  components: {},
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    isSuperAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
    transaction: {
      type: Object,
      required: true,
      default: '',
    },
  },
  emits: ['onClose', 'callback', 'refreshData'],
  setup(props, { emit }) {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const isFormLoading = ref(false)
    const isDragging = ref(false)
    let files = ref([])
    let placeholderStatus = ref('')
    let statusOption = ref([])

    const formData = reactive({
      status: '',
    })

    const statusRequired = helpers.withMessage('Status harus dipilih', required)

    let rules = reactive({
      status: {
        statusRequired,
      },
    })

    const validate = useVuelidate(rules, toRefs(formData))

    const setForm = async () => {
      resetForm()

      if (props.transaction.status === 'PENDING') {
        placeholderStatus.value = 'Tertunda'
      } else if (props.transaction.status === 'PROCESS') {
        placeholderStatus.value = 'Diproses'
      } else if (props.transaction.status === 'DELIVERY') {
        placeholderStatus.value = 'Dikirim'
      } else if (props.transaction.status === 'PAID') {
        placeholderStatus.value = 'Dibayar'
      } else if (props.transaction.status === 'RECEIVED') {
        placeholderStatus.value = 'Diterima'
      } else if (props.transaction.status === 'COMPLETED') {
        placeholderStatus.value = 'Selesai'
      } else if (props.transaction.status === 'CANCELLED') {
        placeholderStatus.value = 'Dibatalkan'
      }

      if (props.transaction.status === 'PENDING') {
        statusOption.value = [
          {
            id: 'PROCESS',
            name: 'Diproses',
          },
          {
            id: 'CANCELLED',
            name: 'Dibatalkan',
          },
        ]
      } else if (props.transaction.status === 'PROCESS') {
        statusOption.value = [
          {
            id: 'DELIVERY',
            name: 'Dikirim',
          },
          // {
          //   id: 'PAID',
          //   name: 'Dibayar',
          // },
          {
            id: 'CANCELLED',
            name: 'Dibatalkan',
          },
        ]
        // } else if (
        //   props.transaction.status === 'DELIVERY' &&
        //   data.payment.payment_at === null
        // ) {
        //   statusOption.value = [
        //     {
        //       id: 'PAID',
        //       name: 'Dibayar',
        //     },
        //     {
        //       id: 'RECEIVED',
        //       name: 'Diterima',
        //     },
        //   ]
      } else if (props.transaction.status === 'DELIVERY') {
        statusOption.value = [
          {
            id: 'RECEIVED',
            name: 'Diterima',
          },
          {
            id: 'CANCELLED',
            name: 'Dibatalkan',
          },
        ]
        // }
        // else if (props.transaction.status === 'PAID' && data.received_at === null) {
        //   statusOption.value = [
        //     {
        //       id: 'RECEIVED',
        //       name: 'Diterima',
        //     },
        //   ]
      } else if (props.transaction.status === 'PAID' || props.transaction.status === 'RECEIVED') {
        statusOption.value = [
          {
            id: 'COMPLETED',
            name: 'Selesai',
          },
        ]

        if (props.isSuperAdmin) {
          statusOption.value.push(
            {
              id: 'CANCELLED',
              name: 'Dibatalkan',
            },
          )
        }
      } else if (props.transaction.status === 'COMPLETED' && props.isSuperAdmin) {
        statusOption.value = [
          {
            id: 'CANCELLED',
            name: 'Dibatalkan',
          },
        ]
      } else {
        statusOption.value = []
      }
    }

    const resetForm = () => {
      validate.value.$reset()
      formData.status = ''
      files.value = []
    }

    watchEffect(() => {
      if (formData.status === '') {
        setForm()
      }
    })

    const onClose = () => {
      emit('onClose')
      resetForm()
    }

    const browseFile = (e) => {
      files.value = [...files.value, ...e.target.files]
    }

    const removeFile = (file) => {
      const index = files.value.indexOf(file)
      files.value.splice(index, 1)
    }
    const dragoverFile = (e) => {
      e.preventDefault()
      isDragging.value = true
    }
    const dragleaveFile = () => {
      isDragging.value = false
    }
    const dropFile = (e) => {
      e.preventDefault()
      files.value = [...files.value, ...e.dataTransfer.files]
      isDragging.value = false
    }

    const generateThumbnail = (file) => {
      let fileSrc = URL.createObjectURL(file)
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc)
      }, 1000)
      return fileSrc
    }

    const changeStatusData = async () => {
      validate.value.$touch()

      if (validate.value.$invalid == false) {
        isFormLoading.value = true
        const payload = {
          transaction_id: props.transaction.id,
          status: formData.status,
        }

        if (formData.status === 'CANCELLED') {
          const payload = {
            transaction_id: props.transaction.id,
          }

          await store
            .dispatch('transaction/cancel', payload)
            .then((response) => {
              if (response.statusCode === 200) {
                emit('callback', {
                  status: response.status,
                  message: response.message,
                  type: 'success'
                })

                isFormLoading.value = false
                props.showModal = false

                emit('onClose')
              } else {
                emit('callback', {
                  status: response.message.status,
                  message: response.message.detail,
                  type: 'failed'
                })
              }

              resetForm()
            })
            .catch((e) => {
              isFormLoading.value = false
            })
        } else {
          await store
            .dispatch('transaction/changeStatus', payload)
            .then(async (response) => {
              if (response.statusCode === 200) {
                if (['RECEIVED'].includes(formData.status)) {
                  files.value.map(async (file) => {
                    const payloadDoc = {
                      transaction_id: props.transaction.id,
                      type: 'DOCUMENT', // INVOICE, PAYMENT, DOCUMENT, DRAFT
                      document: file,
                    }
                    await store
                      .dispatch('transaction/upload', payloadDoc)
                      .then((response) => {})
                      .catch((error) => {
                        const responseData = error.response.data
                        isFormLoading.value = false

                        emit('callback', {
                          status: responseData.message.status,
                          message: responseData.message.detail,
                          type: 'failed'
                        })
                      })
                  })
                }

                validate.value.$reset()

                emit('callback', {
                  status: response.status,
                  message: response.message,
                  type: 'success'
                })

                isFormLoading.value = false
                props.showModal = false

                emit('onClose')

                setTimeout(() => {
                  emit('refreshData')
                }, 1500)
              } else {
                emit('callback', {
                  status: response.message.status,
                  message: response.message.detail,
                  type: 'failed'
                })
              }
              resetForm()
            })
            .catch((error) => {
              const responseData = error.response.data
              isFormLoading.value = false

              emit('callback', {
                status: responseData.message.status,
                message: responseData.message.detail,
                type: 'failed'
              })
            })
        }
      }
    }

    return {
      t,
      store,
      route,
      router,
      
      onClose,
      isFormLoading,
      placeholderStatus,
      files,
      isDragging,
      browseFile,
      removeFile,
      dragoverFile,
      dragleaveFile,
      dropFile,
      generateThumbnail,
      statusOption,
      validate,
      formData,
      changeStatusData,
    }
  },
}
</script>
