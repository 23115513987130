import { createStore } from 'vuex'
import global from './global'
import auth from './auth'
import menu from './menu'
import master from './master'
import role from './role'
import address from './address'
import user from './user'
import company from './company'
import storeCustomer from './store-customer'
import store from './store'
import staff from './staff'
import supplier from './supplier'
import product from './product'
import productCategory from './product-category'
import productSubCategory from './product-sub-category'
import productBrand from './product-brand'
import productUnit from './product-unit'
import productStock from './product-stock'
import purchase from './purchase'
import transaction from './transaction'
import analytic from './analytic'
import report from './report'
import whatsappTemplate from './whatsapp-template'
import whatsappBot from './whatsapp-bot'
import whatsappBlast from './whatsapp-blast'

export default createStore({
  modules: {
    global,
    auth,
    user,
    master,
    role,
    address,
    company,
    storeCustomer,
    store,
    staff,
    supplier,
    product,
    productCategory,
    productSubCategory,
    productBrand,
    productUnit,
    productStock,
    purchase,
    transaction,
    analytic,
    report,
    whatsappTemplate,
    whatsappBot,
    whatsappBlast
  },
})
