import axios, { routePathAPI } from '@/plugins/axios.js'
import { helper as $h } from '@/utils/helper'

// initial state
const state = () => ({
  storeId: null,
  storeName: null,
})

// getters
const getters = {
}

// actions
const actions = {
  getAll({ commit }, payload) {
    let queryParameters = {
      order: payload.order ?? 'ASC',
      page: payload.page ?? 1,
      take: payload.take ?? 50,
    }
    if (payload.staff_only) queryParameters.staff_only = payload.staff_only
    if (payload.q) queryParameters.q = payload.q

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}/role?` +
            $h.serializeObjectToQueryParam({ ...queryParameters })
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSingle({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/role/${id}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
