<template>
  <div class="mt-8 flex items-center">
    <h2 class="mr-auto text-lg font-medium">CKEditor</h2>
  </div>
  <div class="mt-5 grid grid-cols-12 gap-6">
    <!-- BEGIN: Classic Editor -->
    <div class="col-span-12">
      <PreviewComponent class="box" v-slot="{ toggle }">
        <div
          class="flex flex-col items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400 sm:flex-row"
        >
          <h2 class="mr-auto text-base font-medium">Classic Editor</h2>
          <div
            class="form-check form-switch mt-3 w-full sm:ml-auto sm:mt-0 sm:w-auto"
          >
            <label class="form-check-label ml-0" for="show-example-5"
              >Show example code</label
            >
            <input
              @click="toggle"
              class="form-check-input mr-0 ml-3"
              type="checkbox"
            />
          </div>
        </div>
        <div class="p-5">
          <Preview>
            <ClassicEditor v-model="editorData" />
          </Preview>
          <Source>
            <Highlight>
              {{ `
              <ClassicEditor v-model="editorData" />
              ` }}
            </Highlight>
          </Source>
        </div>
      </PreviewComponent>
    </div>
    <!-- END: Classic Editor -->
  </div>
  <div></div>
</template>

<script setup>
import { ref } from 'vue'

const editorData = ref('<p>Content of the editor.</p>')
</script>
