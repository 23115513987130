<template>
  <span v-for="(item, key) in converters" :key="item.id" class="rounded-full px-3 py-1 font-semibold border"
    :class="
      converters.length === 1 ? 'bg-gray-100 text-gray-600 border-gray-200'
      : key === 0 ? 'bg-green-100 text-green-500 border-green-200'
      : 'bg-amber-100 text-amber-500 border-amber-200 ml-1'"
  >{{ item.product_unit.name }}</span>
</template>

<script>
export default {
  name: 'Uom',
  props: {
    converters: {
      type: Array,
      required: true,
    },
  },
}
</script>
