export default {
  appName: 'Gromura',
  language: {
    id: 'ID',
    en: 'EN',
    languages: 'Bahasa',
    change: 'Ubah Bahasa',
    bahasa: 'Indonesia',
    english: 'Inggris',
  },
  menu: {
    home: 'Beranda',
    account: 'Akun',
    dashboard: 'Dashboard',
    store: 'Toko',
    warehouse: 'Gudang',
    report: {
      index: 'Laporan',
      summary: 'Ringkasan Penjualan',
      orderDetail: 'Detail Pesanan',
      tenantSales: 'Penjualan Tenant',
      dailySales: 'Penjualan Harian',
      productSales: 'Penjualan Produk',
      paymentMethod: 'Metode Pembayaran',
      withdrawal: 'Laporan Withdrawal',
      promotion: 'Laporan Promo',
    },
    analytic: {
      index: 'Analytic',
      averageTransaction: 'Transaksi Rerata',
      growthPercentage: 'Persentase Pertumbuhan',
      stockTurnOver: 'Perputaran Stok',
      ecommerceIndex: 'Indeks E-commerce',
      satisfactionIndex: 'Indeks Kepuasan',
    },
    profile: {
      index: 'Profil',
      account: 'Informasi Akun',
      business: 'Pengaturan Usaha',
      store: 'Toko',
      warehouse: 'Gudang',
    },
  },
  action: {
    profile: 'Profil',
    setting: 'Pengaturan',
    logout: 'Keluar',
    export: 'Ekspor Data',
  },
  page: {
    dashboard: {
      sale: 'Penjualan',
      grossProfit: 'Laba Kotor',
      netProfit: 'Laba Bersih',
      transactionTotal: 'Total Transaksi',
    },
    profile: {
      index: 'Profil',
      account: 'Akun Profil',
      accessInformation: 'Informasi Hak Akses',
      addressInformation: 'Informasi Alamat',
      changePassword: 'Ubah Kata Sandi',
      accessRight: 'Hak Akses',
    },
    business: {
      index: 'Usaha',
      information: 'Informasi Usaha',
      addressInformation: 'Informasi Alamat',
      socialMedia: 'Media Sosial',
    },
    store: {
      index: 'Toko',
      information: 'Informasi Toko',
      addressInformation: 'Informasi Alamat',
    },
    warehouse: {
      index: 'Gudang',
      information: 'Informasi Gudang',
      addressInformation: 'Informasi Alamat',
    },
    setting: 'Pengaturan',
  },
  general: {
    greeting: 'Selamat Datang!',
    hello: 'Halo',
    tnc: 'Syarat & Ketentuan',
    privacyPolicy: 'Kebijakan Pribadi',
    optional: 'opsional',
  },
  info: {
    dataNotFound: 'Data tidak ditemukan',
    loginGreeting: 'Beberapa klik lagi untuk masuk ke akun Anda',
    loginGreetingDetail: 'Kelola akun anda di satu tempat',
    currentVersion: 'Versi Dashboard Saat Ini',
    pageMissing: 'Ups. Halaman ini tidak ditemukan',
    pageMissingInstruction:
      'Anda mungkin salah mengetik alamat atau halaman mungkin telah dipindahkan.',
    thereIsNoData: 'Tidak ada data untuk ditampilkan',
  },
  confirmation: {
    logout: 'Apakah Anda yakin ingin keluar?',
  },
  button: {
    submit: 'Kirim',
    login: 'Masuk',
    logout: 'Keluar',
    cancel: 'Batal',
    register: 'Daftar',
    sendEmail: 'Kirim Email',
    sendOTP: 'Kirim OTP',
    resetPassword: 'Setel Ulang Kata Sandi',
    forgotPassword: 'Lupa Password?',
    saveChanges: 'Simpan Perubahan',
    connect: 'Hubungkan',
    connected: 'Terhubung',
    backToHome: 'Kembali ke Beranda',
  },
  formInfo: {
    notLogin: 'Anda belum masuk',
    registerSuccess: 'Anda telah berhasil mendaftar',
    registerFailed: 'Registrasi gagal',
    loginSuccess: 'Berhasil Masuk',
    loginFailed: 'Gagal Masuk',
    saveChangesSuccess: 'Perubahan Berhasil Disimpan',
    saveChangesFailed: 'Perubahan Gagal Disimpan',
    loginDesc: 'Masukan email dan kata sandi anda',
    loginCheck: 'Dengan mendaftar, Anda setuju dengan ',
  },
  formLabel: {
    optional: '(Opsional)',
    npwp: 'NPWP',
    pic: 'PIC',
    taxId: 'Tax ID',
    code: 'Kode',
    barcode: 'Barcode',
    converter: 'Converter',
    name: 'Nama',
    price: 'Harga',
    salePrice: 'Harga Jual',
    productName: 'Nama Produk',
    fullName: 'Nama Lengkap',
    firstName: 'Nama Depan',
    lastName: 'Nama Belakang',
    businessName: 'Nama Bisnis',
    storeName: 'Nama Toko',
    warehouseName: 'Nama Gudang',
    tenantName: 'Nama Tenant',
    email: 'Email',
    phone: 'Nomor Telepon',
    password: 'Kata Sandi',
    passwordConfirmation: 'Konfirmasi Password',
    oldPassword: 'Kata Sandi Lama',
    newPassword: 'Kata Sandi Baru',
    newPasswordConfirmation: 'Konfirmasi Kata Sandi Baru',
    accessRight: 'Hak Akses',
    pin: 'PIN',
    description: 'Deskripsi',
    website: 'Website',
    province: 'Provinsi',
    city: 'Kota',
    district: 'Kecamatan',
    village: 'Kelurahan',
    postalCode: 'Kode Pos',
    address: 'Alamat',
    longitude: 'Longitude',
    latitude: 'Latitude',
    productCategory: 'Kategori Produk',
    productSubCategory: 'Sub Kategori Produk',
    productPrinciple: 'Principle Produk',
    productBrand: 'Merk Produk',
    productSubBrand: 'Sub Merk Produk',
    productUnit: 'Unit Produk',
    product: 'Produk',
    store: 'Toko',
    warehouse: 'Gudang',
    supplier: 'Supplier',
    paymentTerm: 'Term Pembayaran',
    paymentMethod: 'Metode Pembayaran',
    paymentType: 'Tipe Pembayaran',
    salesAgent: 'Agen Sales',
    pic: 'PIC',
    customer: 'Customer',
    select: {
      supplier: 'Pilih Supplier',
      store: 'Pilih Toko',
      warehouse: 'Pilih Gudang',
      paymentTerm: 'Pilih Term Pembayaran',
      paymentMethod: 'Pilih Metode Pembayaran',
      paymentType: 'Pilih Tipe Pembayaran',
      salesAgent: 'Pilih Agen Sales',
      customer: 'Pilih Customer',
      province: 'Pilih Provinsi',
      city: 'Pilih Kota',
      district: 'Pilih Kecamatan',
      village: 'Pilih Kelurahan',
      productCategory: 'Pilih Kategori Produk',
      productSubCategory: 'Pilih Sub Kategori Produk',
      productPrinciple: 'Pilih Principle Produk',
      productBrand: 'Pilih Merk Produk',
      productSubBrand: 'Pilih Sub Merk Produk',
      productUnit: 'Pilih Unit Produk',
      product: 'Pilih Produk',
    },
  },
  formInput: {
    enterCode: 'Masukan kode',
    enterBarcode: 'Masukan barcode',
    enterConverter: 'Masukan converter',
    enterName: 'Masukan nama',
    enterNpwp: 'Masukan NPWP',
    enterPic: 'Masukan PIC',
    enterPrice: 'Masukan harga',
    enterSalePrice: 'Masukan harga jual',
    enterProductName: 'Masukan nama produk',
    enterFullName: 'Masukan nama lengkap anda',
    enterFirstName: 'Masukan nama depan anda',
    enterLastName: 'Masukan nama belakang anda',
    enterBussinessName: 'Masukan nama usaha',
    enterTenantName: 'Masukan nama tenant',
    enterDescription: 'Masukan deskripsi',
    enterWebsite: 'Masukan website',
    enterEmail: 'Masukan email anda',
    enterPhone: 'Masukan nomor telepon anda',
    enterPassword: 'Masukan password anda',
    enterPIN: 'Masukan PIN anda',
    enterAddress: 'Masukan alamat anda',
    enterPostalCode: 'Masukan kode pos',
  },
  formError: {
    area_id: {
      required: 'Area harus diisi',
    },
    role_id: {
      required: 'ID role harus diisi',
    },
    customer_type: {
      required: 'Tipe warung harus diisi',
    },
    code: {
      required: 'Kode harus diisi',
    },
    barcode: {
      required: 'Barcode harus diisi',
    },
    converter: {
      required: 'Converter harus diisi',
    },
    name: {
      required: 'Nama harus diisi',
    },
    price: {
      required: 'Harga harus diisi',
    },
    salePrice: {
      required: 'Harga jual harus diisi',
    },
    productName: {
      required: 'Nama produk harus diisi',
    },
    fullName: {
      required: 'Nama Lengkap harus diisi',
    },
    firstName: {
      required: 'Nama Depan harus diisi',
    },
    lastName: {
      required: 'Nama Belakang harus diisi',
    },
    businessName: {
      required: 'Nama Usaha harus diisi',
    },
    tenantName: {
      required: 'Nama Tenant harus diisi',
    },
    email: {
      required: 'Email harus diisi',
      validEmail: 'Email harus berupa email yang valid',
    },
    phone: {
      required: 'Nomor Telepon harus diisi',
      validPhone: 'Nomor Telepon harus berupa nomor telepon yang valid',
      minLength: 'Nomor Telepon harus berisi minimal 9 digit',
      maxLength: 'Nomor Telepon maksimal berisi 13 digit',
    },
    pin: {
      required: 'PIN harus diisi',
    },
    address: {
      required: 'Alamat harus diisi',
    },
    postalCode: {
      required: 'Kode Pos harus diisi',
    },
    oldPassword: {
      required: 'Kata sandi lama harus diisi',
    },
    newPassword: {
      required: 'Kata sandi baru harus diisi',
    },
    newPasswordConfirmation: {
      required: 'Konfirmasi kata sandi baru tidak sama',
    },
    password: {
      required: 'Kata sandi harus diisi',
      minLength: 'Kata sandi minimal 6 karakter',
      alphaNumeric: 'Password only allowed alphanumeric and special characters',
    },
    wrongEmailOrPassword: 'Wrong email or password',
    validEmailAddress: 'This field must be valid email address',
    validPhoneNumber:
      'Please enter valid WhatsApp number, min 10 digit numbers',
    fieldAlphabet: 'must only contain alphabetic characters',
    fieldAlphaNumeric: 'must only contain alphanumeric characters',
    fieldNumeric: 'must only contain numeric characters',
  },
}
