import axios, { routePathAPI } from '@/plugins/axios.js'
import { helper as $h } from '@/utils/helper'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  getAll({ commit }, payload) {
    let queryParameters = {
      order: payload.order ?? 'ASC',
      page: payload.page ?? 1,
      take: payload.take ?? 50,
    }
    if (payload.q) queryParameters.q = payload.q

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${routePathAPI()}/company/supplier?` +
            $h.serializeObjectToQueryParam({ ...queryParameters })
        )
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getSingle({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/company/supplier/` + id)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  create({ commit }, payload) {
    console.log('---payload duar ', payload)
    const formData = new FormData()

    formData.append('image', payload.image)
    formData.append('code', payload.code)
    formData.append('name', payload.name)
    formData.append('npwp', payload.npwp)
    formData.append('pic', payload.pic)
    formData.append('email', payload.email)
    formData.append('phone', payload.phone)
    formData.append('province_id', payload.province_id)
    formData.append('city_id', payload.city_id)
    formData.append('district_id', payload.district_id)
    formData.append('village_id', payload.village_id)
    formData.append('address', payload.address)
    formData.append('postal_code', payload.postal_code)

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}/company/supplier/create`, formData, config)
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  update({ commit }, payload) {
    const formData = new FormData()

    formData.append('image', payload.image)
    formData.append('code', payload.code)
    formData.append('name', payload.name)
    formData.append('npwp', payload.npwp)
    formData.append('pic', payload.pic)
    formData.append('email', payload.email)
    formData.append('phone', payload.phone)
    formData.append('province_id', payload.province_id)
    formData.append('city_id', payload.city_id)
    formData.append('district_id', payload.district_id)
    formData.append('village_id', payload.village_id)
    formData.append('address', payload.address)
    formData.append('postal_code', payload.postal_code)

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    return new Promise((resolve, reject) => {
      axios
        .put(
          `${routePathAPI()}/company/supplier/update/` + payload.id,
          formData,
          config
        )
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${routePathAPI()}/company/supplier/delete/` + id)
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
