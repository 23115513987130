import { defineStore } from 'pinia'

export const useSideMenuStore = defineStore('sideMenu', {
  state: () => ({
    menu: [
      {
        general: true,
        icon: 'HomeIcon',
        svg: true,
        pageName: 'dashboard',
        title: 'menu.dashboard',
      },
      {
        icon: 'ReportIcon',
        svg: true,
        pageName: 'report',
        title: 'menu.report.index',
        submenu: [
          {
            icon: '',
            pageName: 'report-day',
            title: 'menu.report.dailySales',
          },
          {
            icon: '',
            pageName: 'report-product',
            title: 'menu.report.productSales',
          },
        ]
      },
      //     // {
      //     //   icon: '',
      //     //   pageName: 'report-order-detail',
      //     //   title: 'menu.report.orderDetail',
      //     // },
      //     // {
      //     //   icon: '',
      //     //   pageName: 'report-tenant-sales',
      //     //   title: 'menu.report.tenantSales',
      //     // },
      //     {
      //       icon: '',
      //       pageName: 'report-daily-sales',
      //       title: 'menu.report.dailySales',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'report-product-sales',
      //       title: 'menu.report.productSales',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'report-payment-method',
      //       title: 'menu.report.paymentMethod',
      //     },
      //     // {
      //     //   icon: '',
      //     //   pageName: 'report-withdrawal',
      //     //   title: 'menu.report.withdrawal',
      //     // },
      //     // {
      //     //   icon: '',
      //     //   pageName: 'report-promotion',
      //     //   title: 'menu.report.promotion',
      //     // },
      //   ],
      // },
      {
        icon: 'AnalyticIcon',
        svg: true,
        pageName: 'analytic',
        title: 'menu.analytic.index',
        submenu: [
          {
            icon: '',
            pageName: 'analytic-turnover',
            title: 'Customer Turnover',
          },
          {
            icon: '',
            pageName: 'analytic-sales-prediction',
            title: 'Sales Prediction',
          },
          // {
          //   icon: '',
          //   pageName: 'analytic-gross-profit',
          //   title: 'Gross & Profit',
          // },
          // {
          //   icon: '',
          //   pageName: 'analytic-transaction-average',
          //   title: 'menu.analytic.averageTransaction',
          // },
          // {
          //   icon: '',
          //   pageName: 'analytic-growth-percentage',
          //   title: 'menu.analytic.growthPercentage',
          // },
          // {
          //   icon: '',
          //   pageName: 'analytic-stock-turnover',
          //   title: 'menu.analytic.stockTurnOver',
          // },
          // {
          //   icon: '',
          //   pageName: 'analytic-ecommerce-index',
          //   title: 'menu.analytic.ecommerceIndex',
          // },
          // {
          //   icon: '',
          //   pageName: 'analytic-satisfaction-index',
          //   title: 'menu.analytic.satisfactionIndex',
          // },
        ],
      },
      'devider',
      {
        icon: 'BanknotesIcon',
        pageName: 'sale-price',
        title: 'Harga Jual',
        hero: true,
      },
      {
        icon: 'BoxIcon',
        pageName: 'product-stock',
        title: 'Stock',
        // submenu: [
        //   {
        //     icon: '',
        //     pageName: 'product-good-stock',
        //     title: 'Good Stock',
        //   },
        //   {
        //     icon: '',
        //     pageName: 'product-bad-stock',
        //     title: 'Bad Stock',
        //   },
        //   // {
        //   //   icon: '',
        //   //   pageName: 'product-pos-stock',
        //   //   title: 'PoS Stock',
        //   // },
        // ],
      },
      {
        icon: 'ServerIcon',
        pageName: 'side-menu-master-inventory',
        title: 'Inventory',
        submenu: [
          {
            icon: '',
            pageName: 'stock-opname',
            title: 'Stock Opname',
          },
          {
            icon: '',
            pageName: 'retur-stock',
            title: 'Retur Stock',
          },
        ],
      },
      // {
      //   icon: 'CreditCardIcon',
      //   pageName: 'point-of-sale',
      //   title: 'Point of Sale',
      // },
      'devider',
      {
        icon: 'ShoppingCartIcon',
        pageName: 'purchase',
        title: 'Pembelian',
      },
      // {
      //   icon: 'FileMinusIcon',
      //   pageName: 'receipt',
      //   title: 'Receipt',
      // },
      {
        icon: 'CreditCardIcon',
        pageName: 'point-of-sale',
        title: 'Point of Sale',
      },
      {
        icon: 'ShoppingBagIcon',
        pageName: 'sales-order',
        title: 'Sales Order',
      },
      {
        icon: 'DollarSignIcon',
        pageName: 'transaction',
        title: 'Transaksi',
      },
      // {
      //   icon: 'DollarsignIcon',
      //   pageName: 'side-menu-transaction',
      //   title: 'Transaksi',
      //   submenu: [
      //     // {
      //     //   icon: '',
      //     //   pageName: 'side-menu-transactions',
      //     //   title: 'Transactions',
      //     // submenu: [
      //     {
      //       icon: '',
      //       pageName: 'transaction',
      //       title: 'Transaksi List',
      //     },
      //     // {
      //     //   icon: '',
      //     //   pageName: 'transaction-detail',
      //     //   title: 'Transaksi Detail',
      //     // },
      //     //   ],
      //     // },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-reviews',
      //       title: 'Reviews',
      //     },
      //   ],
      // },
      'devider',
      {
        icon: 'PackageIcon',
        pageName: 'supplier',
        title: 'Supplier',
      },
      {
        icon: 'StoreIcon',
        svg: true,
        pageName: 'customer-list',
        title: 'Warung',
      },
      'devider',
      {
        icon: 'WarehouseIcon',
        svg: true,
        pageName: 'store-list',
        title: 'Outlet',
      },
      {
        icon: 'UsersIcon',
        pageName: 'user-manager',
        title: 'Direksi',
      },
      {
        icon: 'UsersIcon',
        pageName: 'user-staff',
        title: 'Karyawan',
      },
      // {
      //   icon: 'UsersIcon',
      //   pageName: 'agent-list',
      //   title: 'Agen Sales',
      // },
      'devider',
      {
        icon: 'MessageCircleIcon',
        pageName: 'side-menu-whatsapp',
        title: 'WhatsApp',
        submenu: [
          {
            icon: '',
            pageName: 'whatsapp-template',
            title: 'Template',
          },
          {
            icon: '',
            pageName: 'whatsapp-blast',
            title: 'Blast Message',
          },
        ],
      },
      'devider',
      {
        icon: 'DivideSquareIcon',
        pageName: 'side-menu-master-data',
        title: 'Master Data',
        submenu: [
          {
            icon: '',
            pageName: 'payment-method',
            title: 'Metode Pembayaran',
          },
          {
            icon: '',
            pageName: 'payment-term',
            title: 'Term Pembayaran',
          },
        ],
      },
      {
        icon: 'BoxIcon',
        pageName: 'side-menu-master-product',
        title: 'Master Produk',
        submenu: [
          {
            icon: '',
            pageName: 'product',
            title: 'Produk',
          },
          {
            icon: '',
            pageName: 'product-category',
            title: 'Kategori',
          },
          {
            icon: '',
            pageName: 'product-sub-category',
            title: 'Sub Kategori',
          },
          {
            icon: '',
            pageName: 'product-principle',
            title: 'Merk',
          },
          {
            icon: '',
            pageName: 'product-uom',
            title: 'UoM',
          },
        ],
      },
      {
        icon: 'MapPinIcon',
        pageName: 'side-menu-master-alamat',
        title: 'Master Alamat',
        submenu: [
          {
            icon: '',
            pageName: 'address-province',
            title: 'Provinsi',
          },
          {
            icon: '',
            pageName: 'address-city',
            title: 'Kota',
          },
          {
            icon: '',
            pageName: 'address-district',
            title: 'Kecamatan',
          },
          {
            icon: '',
            pageName: 'address-village',
            title: 'Kelurahan',
          },
        ],
      },
      'devider',
      {
        icon: 'CalendarIcon',
        pageName: 'side-menu-calendar',
        title: 'Kalender',
      },
      {
        icon: 'HardDriveIcon',
        pageName: 'side-menu-file-manager',
        title: 'File Manager',
      },
      // {
      //   icon: 'MessageSquareIcon',
      //   pageName: 'side-menu-chat',
      //   title: 'Chat',
      // },
      {
        icon: 'FileTextIcon',
        pageName: 'side-menu-post',
        title: 'Post',
      },
      'devider',
      {
        icon: 'CodesandboxIcon',
        pageName: 'profile-business',
        title: 'menu.profile.business',
        // submenu: [
        //   {
        //     icon: '',
        //     pageName: 'profile-user',
        //     title: 'menu.profile.account',
        //   },
        //   {
        //     icon: '',
        //     pageName: 'profile-business',
        //     title: 'menu.profile.business',
        //   },
        // ],
      },
      // {
      //   icon: 'UsersIcon',
      //   pageName: 'side-menu-seller',
      //   title: 'Seller',
      //   submenu: [
      //     // {
      //     // icon: '',
      //     // pageName: 'side-menu-sellers',
      //     // title: 'Sellers',
      //     // submenu: [
      //     {
      //       icon: '',
      //       pageName: 'side-menu-seller-list',
      //       title: 'Seller List',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-seller-detail',
      //       title: 'Seller Detail',
      //     },
      //     //   ],
      //     // },
      //   ],
      // },

      // {
      //   icon: 'UsersIcon',
      //   pageName: 'side-menu-users',
      //   title: 'Users',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'side-menu-users-layout-1',
      //       title: 'Layout 1',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-users-layout-2',
      //       title: 'Layout 2',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-users-layout-3',
      //       title: 'Layout 3',
      //     },
      //   ],
      // },
      // {
      //   icon: 'HomeIcon',
      //   pageName: 'side-menu-dashboard',
      //   title: 'Dashboard Samples',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'side-menu-dashboard-overview-1',
      //       title: 'Overview 1',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-dashboard-overview-2',
      //       title: 'Overview 2',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-dashboard-overview-3',
      //       title: 'Overview 3',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-dashboard-overview-4',
      //       title: 'Overview 4',
      //     },
      //   ],
      // },
      // {
      //   icon: 'BoxIcon',
      //   pageName: 'side-menu-menu-layout',
      //   title: 'Menu Layout',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'side-menu-dashboard-overview-1',
      //       title: 'Side Menu',
      //       ignore: true,
      //     },
      //     {
      //       icon: '',
      //       pageName: 'simple-menu-dashboard-overview-1',
      //       title: 'Simple Menu',
      //       ignore: true,
      //     },
      //     {
      //       icon: "",
      //       pageName: "top-menu-dashboard-overview-1",
      //       title: "Top Menu",
      //       ignore: true,
      //     },
      //   ],
      // },
      // 'devider',
      // {
      //   icon: 'InboxIcon',
      //   pageName: 'side-menu-inbox',
      //   title: 'Inbox',
      // },

      // 'devider',
      // {
      //   icon: 'EditIcon',
      //   pageName: 'side-menu-crud',
      //   title: 'Crud',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'side-menu-crud-data-list',
      //       title: 'Data List',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-crud-form',
      //       title: 'Form',
      //     },
      //   ],
      // },

      // {
      //   icon: 'TrelloIcon',
      //   pageName: 'side-menu-profile',
      //   title: 'Profile',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'side-menu-profile-overview-1',
      //       title: 'Overview 1',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-profile-overview-2',
      //       title: 'Overview 2',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-profile-overview-3',
      //       title: 'Overview 3',
      //     },
      //   ],
      // },
      // {
      //   icon: 'LayoutIcon',
      //   pageName: 'side-menu-layout',
      //   title: 'Pages',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'side-menu-wizards',
      //       title: 'Wizards',
      //       submenu: [
      //         {
      //           icon: '',
      //           pageName: 'side-menu-wizard-layout-1',
      //           title: 'Layout 1',
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-wizard-layout-2',
      //           title: 'Layout 2',
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-wizard-layout-3',
      //           title: 'Layout 3',
      //         },
      //       ],
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-blog',
      //       title: 'Blog',
      //       submenu: [
      //         {
      //           icon: '',
      //           pageName: 'side-menu-blog-layout-1',
      //           title: 'Layout 1',
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-blog-layout-2',
      //           title: 'Layout 2',
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-blog-layout-3',
      //           title: 'Layout 3',
      //         },
      //       ],
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-pricing',
      //       title: 'Pricing',
      //       submenu: [
      //         {
      //           icon: '',
      //           pageName: 'side-menu-pricing-layout-1',
      //           title: 'Layout 1',
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-pricing-layout-2',
      //           title: 'Layout 2',
      //         },
      //       ],
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-invoice',
      //       title: 'Invoice',
      //       submenu: [
      //         {
      //           icon: '',
      //           pageName: 'side-menu-invoice-layout-1',
      //           title: 'Layout 1',
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-invoice-layout-2',
      //           title: 'Layout 2',
      //         },
      //       ],
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-faq',
      //       title: 'FAQ',
      //       submenu: [
      //         {
      //           icon: '',
      //           pageName: 'side-menu-faq-layout-1',
      //           title: 'Layout 1',
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-faq-layout-2',
      //           title: 'Layout 2',
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-faq-layout-3',
      //           title: 'Layout 3',
      //         },
      //       ],
      //     },
      //     {
      //       icon: '',
      //       pageName: 'login',
      //       title: 'Login',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'register',
      //       title: 'Register',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'error-page',
      //       title: 'Error Page',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-update-profile',
      //       title: 'Update profile',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-change-password',
      //       title: 'Change Password',
      //     },
      //   ],
      // },
      // 'devider',
      // {
      //   icon: 'InboxIcon',
      //   pageName: 'side-menu-components',
      //   title: 'Components',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'side-menu-table',
      //       title: 'Table',
      //       submenu: [
      //         {
      //           icon: '',
      //           pageName: 'side-menu-regular-table',
      //           title: 'Regular Table',
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-tabulator',
      //           title: 'Tabulator',
      //         },
      //       ],
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-overlay',
      //       title: 'Overlay',
      //       submenu: [
      //         {
      //           icon: '',
      //           pageName: 'side-menu-modal',
      //           title: 'Modal',
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-slide-over',
      //           title: 'Slide Over',
      //         },
      //         {
      //           icon: '',
      //           pageName: 'side-menu-notification',
      //           title: 'Notification',
      //         },
      //       ],
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-tab',
      //       title: 'Tab',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-accordion',
      //       title: 'Accordion',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-button',
      //       title: 'Button',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-alert',
      //       title: 'Alert',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-progress-bar',
      //       title: 'Progress Bar',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-tooltip',
      //       title: 'Tooltip',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-dropdown',
      //       title: 'Dropdown',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-typography',
      //       title: 'Typography',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-icon',
      //       title: 'Icon',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-loading-icon',
      //       title: 'Loading Icon',
      //     },
      //   ],
      // },
      // {
      //   icon: 'SidebarIcon',
      //   pageName: 'side-menu-forms',
      //   title: 'Forms',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'side-menu-regular-form',
      //       title: 'Regular Form',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-datepicker',
      //       title: 'Datepicker',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-tom-select',
      //       title: 'Tom Select',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-file-upload',
      //       title: 'File Upload',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-wysiwyg-editor',
      //       title: 'Wysiwyg Editor',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-validation',
      //       title: 'Validation',
      //     },
      //   ],
      // },
      // {
      //   icon: 'HardDriveIcon',
      //   pageName: 'side-menu-widgets',
      //   title: 'Widgets',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'side-menu-chart',
      //       title: 'Chart',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-slider',
      //       title: 'Slider',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-image-zoom',
      //       title: 'Image Zoom',
      //     },
      //   ],
      // },
    ],
  }),
})

export const useSideMenuCommissionerStore = defineStore('sideMenuCommissioner', {
  state: () => ({
    menu: [
      {
        general: true,
        icon: 'HomeIcon',
        svg: true,
        pageName: 'dashboard',
        title: 'menu.dashboard',
      },
      // {
      //   icon: 'ReportIcon',
      //   svg: true,
      //   pageName: 'report',
      //   title: 'menu.report.index',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'report-daily-sales',
      //       title: 'menu.report.dailySales',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'report-product-sales',
      //       title: 'menu.report.productSales',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'report-payment-method',
      //       title: 'menu.report.paymentMethod',
      //     },
      //   ],
      // },
      {
        icon: 'AnalyticIcon',
        svg: true,
        pageName: 'analytic',
        title: 'menu.analytic.index',
        submenu: [
          {
            icon: '',
            pageName: 'analytic-turnover',
            title: 'Customer Turnover',
          },
          {
            icon: '',
            pageName: 'analytic-sales-prediction',
            title: 'Sales Prediction',
          },
        ]
      },
      // {
      //   icon: 'AnalyticIcon',
      //   svg: true,
      //   pageName: 'analytic',
      //   title: 'menu.analytic.index',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'analytic-gross-profit',
      //       title: 'Gross & Profit',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'analytic-transaction-average',
      //       title: 'menu.analytic.averageTransaction',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'analytic-growth-percentage',
      //       title: 'menu.analytic.growthPercentage',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'analytic-stock-turnover',
      //       title: 'menu.analytic.stockTurnOver',
      //     },
      //   ],
      // },
      'devider',
      {
        icon: 'BanknotesIcon',
        pageName: 'sale-price',
        title: 'Harga Jual',
        hero: true,
      },
      {
        icon: 'BoxIcon',
        pageName: 'product-stock',
        title: 'Stock',
      },
      // {
      //   icon: 'ServerIcon',
      //   pageName: 'side-menu-master-inventory',
      //   title: 'Inventory',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'stock-opname',
      //       title: 'Stock Opname',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'retur-stock',
      //       title: 'Retur Stock',
      //     },
      //   ],
      // },
      'devider',
      {
        icon: 'ShoppingCartIcon',
        pageName: 'purchase',
        title: 'Pembelian',
      },
      {
        icon: 'CreditCardIcon',
        pageName: 'point-of-sale',
        title: 'Point of Sale',
      },
      {
        icon: 'ShoppingBagIcon',
        pageName: 'sales-order',
        title: 'Sales Order',
      },
      {
        icon: 'DollarSignIcon',
        pageName: 'transaction',
        title: 'Transaksi',
      },
      'devider',
      {
        icon: 'PackageIcon',
        pageName: 'supplier',
        title: 'Supplier',
      },
      {
        icon: 'StoreIcon',
        svg: true,
        pageName: 'customer-list',
        title: 'Warung',
      },
      'devider',
      {
        icon: 'WarehouseIcon',
        svg: true,
        pageName: 'store-list',
        title: 'Outlet',
      },
      {
        icon: 'UsersIcon',
        pageName: 'user-manager',
        title: 'Direksi',
      },
      {
        icon: 'UsersIcon',
        pageName: 'user-staff',
        title: 'Karyawan',
      },
      // {
      //   icon: 'UsersIcon',
      //   pageName: 'agent-list',
      //   title: 'Agen Sales',
      // },
      // 'devider',
      // {
      //   icon: 'MessageCircleIcon',
      //   pageName: 'side-menu-whatsapp',
      //   title: 'WhatsApp',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'whatsapp-template',
      //       title: 'Template',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'whatsapp-blast',
      //       title: 'Blast Message',
      //     },
      //   ],
      // },
      'devider',
      {
        icon: 'DivideSquareIcon',
        pageName: 'side-menu-master-data',
        title: 'Master Data',
        submenu: [
          {
            icon: '',
            pageName: 'payment-method',
            title: 'Metode Pembayaran',
          },
          {
            icon: '',
            pageName: 'payment-term',
            title: 'Term Pembayaran',
          },
        ],
      },
      {
        icon: 'BoxIcon',
        pageName: 'side-menu-master-product',
        title: 'Master Produk',
        submenu: [
          {
            icon: '',
            pageName: 'product',
            title: 'Produk',
          },
          {
            icon: '',
            pageName: 'product-category',
            title: 'Kategori',
          },
          {
            icon: '',
            pageName: 'product-sub-category',
            title: 'Sub Kategori',
          },
          {
            icon: '',
            pageName: 'product-principle',
            title: 'Merk',
          },
          {
            icon: '',
            pageName: 'product-uom',
            title: 'UoM',
          },
        ],
      },
      {
        icon: 'MapPinIcon',
        pageName: 'side-menu-master-alamat',
        title: 'Master Alamat',
        submenu: [
          {
            icon: '',
            pageName: 'address-province',
            title: 'Provinsi',
          },
          {
            icon: '',
            pageName: 'address-city',
            title: 'Kota',
          },
          {
            icon: '',
            pageName: 'address-district',
            title: 'Kecamatan',
          },
          {
            icon: '',
            pageName: 'address-village',
            title: 'Kelurahan',
          },
        ],
      },
      'devider',
      {
        icon: 'CodesandboxIcon',
        pageName: 'profile-business',
        title: 'menu.profile.business',
        // submenu: [
        //   {
        //     icon: '',
        //     pageName: 'profile-user',
        //     title: 'menu.profile.account',
        //   },
        //   {
        //     icon: '',
        //     pageName: 'profile-business',
        //     title: 'menu.profile.business',
        //   },
        // ],
      },
    ],
  }),
})

export const useSideMenuHeadOperationStore = defineStore(
  'sideMenuHeadOperation',
  {
    state: () => ({
      menu: [
        {
          general: true,
          icon: 'HomeIcon',
          svg: true,
          pageName: 'dashboard',
          title: 'menu.dashboard',
        },
        {
          icon: 'AnalyticIcon',
          svg: true,
          pageName: 'analytic',
          title: 'menu.analytic.index',
          submenu: [
            {
              icon: '',
              pageName: 'analytic-turnover',
              title: 'Customer Turnover',
            },
            {
              icon: '',
              pageName: 'analytic-sales-prediction',
              title: 'Sales Prediction',
            },
          ]
        },
        {
          icon: 'BanknotesIcon',
          pageName: 'sale-price',
          title: 'Harga Jual',
          hero: true,
        },
        {
          icon: 'BoxIcon',
          pageName: 'side-menu-master-product',
          title: 'Master Produk',
          submenu: [
            {
              icon: '',
              pageName: 'product',
              title: 'Produk',
            },
            {
              icon: '',
              pageName: 'product-category',
              title: 'Kategori',
            },
            {
              icon: '',
              pageName: 'product-sub-category',
              title: 'Sub Kategori',
            },
            {
              icon: '',
              pageName: 'product-principle',
              title: 'Principle',
            },
            {
              icon: '',
              pageName: 'product-uom',
              title: 'UoM',
            },
          ],
        },
        {
          icon: 'PackageIcon',
          pageName: 'supplier',
          title: 'Supplier',
        },
        {
          icon: 'StoreIcon',
          svg: true,
          pageName: 'store-list',
          title: 'Warung',
        },
        {
          icon: 'ShoppingCartIcon',
          pageName: 'purchase',
          title: 'Pembelian',
        },
        {
          icon: 'DollarSignIcon',
          pageName: 'transaction',
          title: 'Transaksi',
        },
      ],
    }),
  }
)

export const useSideMenuHeadStoreStore = defineStore('sideMenuHeadStore', {
  state: () => ({
    menu: [
      {
        general: true,
        icon: 'HomeIcon',
        svg: true,
        pageName: 'dashboard',
        title: 'menu.dashboard',
      },
      // 'devider',
      // {
      //   icon: 'ReportIcon',
      //   svg: true,
      //   pageName: 'report',
      //   title: 'menu.report.index',
      //   submenu: [
      //     // {
      //     //   icon: '',
      //     //   pageName: 'report-summary',
      //     //   title: 'menu.report.summary',
      //     // },
      //     // {
      //     //   icon: '',
      //     //   pageName: 'report-order-detail',
      //     //   title: 'menu.report.orderDetail',
      //     // },
      //     // {
      //     //   icon: '',
      //     //   pageName: 'report-tenant-sales',
      //     //   title: 'menu.report.tenantSales',
      //     // },
      //     {
      //       icon: '',
      //       pageName: 'report-daily-sales',
      //       title: 'menu.report.dailySales',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'report-product-sales',
      //       title: 'menu.report.productSales',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'report-payment-method',
      //       title: 'menu.report.paymentMethod',
      //     },
      //     // {
      //     //   icon: '',
      //     //   pageName: 'report-withdrawal',
      //     //   title: 'menu.report.withdrawal',
      //     // },
      //     // {
      //     //   icon: '',
      //     //   pageName: 'report-promotion',
      //     //   title: 'menu.report.promotion',
      //     // },
      //   ],
      // },
      // {
      //   icon: 'AnalyticIcon',
      //   svg: true,
      //   pageName: 'analytic',
      //   title: 'menu.analytic.index',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'analytic-gross-profit',
      //       title: 'Gross & Profit',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'analytic-transaction-average',
      //       title: 'menu.analytic.averageTransaction',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'analytic-growth-percentage',
      //       title: 'menu.analytic.growthPercentage',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'analytic-stock-turnover',
      //       title: 'menu.analytic.stockTurnOver',
      //     },
      //     // {
      //     //   icon: '',
      //     //   pageName: 'analytic-ecommerce-index',
      //     //   title: 'menu.analytic.ecommerceIndex',
      //     // },
      //     // {
      //     //   icon: '',
      //     //   pageName: 'analytic-satisfaction-index',
      //     //   title: 'menu.analytic.satisfactionIndex',
      //     // },
      //   ],
      // },
      {
        icon: 'AnalyticIcon',
        svg: true,
        pageName: 'analytic',
        title: 'menu.analytic.index',
        submenu: [
          {
            icon: '',
            pageName: 'analytic-turnover',
            title: 'Customer Turnover',
          },
          {
            icon: '',
            pageName: 'analytic-sales-prediction',
            title: 'Sales Prediction',
          },
        ]
      },
      'devider',
      {
        icon: 'BoxIcon',
        pageName: 'product-stock',
        title: 'Stock',
        // submenu: [
        //   // {
        //   //   icon: '',
        //   //   pageName: 'product',
        //   //   title: 'Master Produk',
        //   // },
        //   {
        //     icon: '',
        //     pageName: 'product-good-stock',
        //     title: 'Good Stock',
        //     // submenu: [
        //     //   {
        //     //     icon: '',
        //     //     pageName: 'product-good-stock',
        //     //     title: 'Produk List',
        //     //   },
        //     //   {
        //     //     icon: '',
        //     //     pageName: 'product-grid',
        //     //     title: 'Produk Grid',
        //     //   },
        //     // ],
        //   },
        //   {
        //     icon: '',
        //     pageName: 'product-bad-stock',
        //     title: 'Bad Stock',
        //   },
        //   // {
        //   //   icon: '',
        //   //   pageName: 'product-pos-stock',
        //   //   title: 'PoS Stock',
        //   // },
        // ],
      },
      {
        icon: 'ServerIcon',
        pageName: 'side-menu-master-inventory',
        title: 'Inventory',
        submenu: [
          {
            icon: '',
            pageName: 'stock-opname',
            title: 'Stock Opname',
          },
          {
            icon: '',
            pageName: 'retur-stock',
            title: 'Retur Stock',
          },
        ],
      },
      {
        icon: 'BanknotesIcon',
        pageName: 'sale-price',
        title: 'Harga Jual',
        hero: true,
      },
      {
        icon: 'BoxIcon',
        pageName: 'product',
        title: 'Master Produk',
      },
      // {
      //   icon: 'ShoppingCartIcon',
      //   pageName: 'purchase-order',
      //   title: 'Purchase Order',
      // },
      'devider',
      {
        icon: 'ShoppingCartIcon',
        pageName: 'purchase',
        title: 'Request PO',
      },
      {
        icon: 'CreditCardIcon',
        pageName: 'point-of-sale',
        title: 'Point of Sale',
      },
      {
        icon: 'ShoppingBagIcon',
        pageName: 'sales-order',
        title: 'Sales Order',
      },
      {
        icon: 'DollarSignIcon',
        pageName: 'transaction',
        title: 'Transaksi',
        // submenu: [
        //   // {
        //   //   icon: '',
        //   //   pageName: 'side-menu-transactions',
        //   //   title: 'Transactions',
        //   // submenu: [
        //   {
        //     icon: '',
        //     pageName: 'transaction',
        //     title: 'Transaksi List',
        //   },
        //   {
        //     icon: '',
        //     pageName: 'transaction-detail',
        //     title: 'Transaksi Detail',
        //   },
        //   //   ],
        //   // },
        //   // {
        //   //   icon: '',
        //   //   pageName: 'side-menu-reviews',
        //   //   title: 'Reviews',
        //   // },
        // ],
      },
      'devider',
      // {
      //   icon: 'UsersIcon',
      //   pageName: 'agent-list',
      //   title: 'Agen Sales',
      // },
      {
        icon: 'StoreIcon',
        svg: true,
        pageName: 'customer-list',
        title: 'Warung',
      },
      {
        icon: 'UsersIcon',
        pageName: 'user-staff',
        title: 'Karyawan',
      },
      'devider',
      {
        icon: 'StoreIcon',
        svg: true,
        pageName: 'profile-store',
        title: 'menu.profile.store',
      },
      // {
      //   icon: 'HomeIcon',
      //   pageName: 'side-menu-dashboard',
      //   title: 'Dashboard Samples',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'side-menu-dashboard-overview-1',
      //       title: 'Overview 1',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-dashboard-overview-2',
      //       title: 'Overview 2',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-dashboard-overview-3',
      //       title: 'Overview 3',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'side-menu-dashboard-overview-4',
      //       title: 'Overview 4',
      //     },
      //   ],
      // },
      // {
      //   icon: 'BoxIcon',
      //   pageName: 'side-menu-menu-layout',
      //   title: 'Menu Layout',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'side-menu-dashboard-overview-1',
      //       title: 'Side Menu',
      //       ignore: true,
      //     },
      //     {
      //       icon: '',
      //       pageName: 'simple-menu-dashboard-overview-1',
      //       title: 'Simple Menu',
      //       ignore: true,
      //     },
      //     {
      //       icon: "",
      //       pageName: "top-menu-dashboard-overview-1",
      //       title: "Top Menu",
      //       ignore: true,
      //     },
      //   ],
      // },

      // {
      //   general: true,
      //   icon: 'ProfileIcon',
      //   svg: true,
      //   pageName: 'profile',
      //   title: 'menu.profile.index',
      //   submenu: [
      //     {
      //       icon: '',
      //       pageName: 'profile-user',
      //       title: 'menu.profile.account',
      //     },
      //     {
      //       icon: '',
      //       pageName: 'profile-store',
      //       title: 'menu.profile.store',
      //     },
      //   ],
      // },
    ],
  }),
})

export const useSideMenuAdminStoreStore = defineStore('sideMenuAdminStore', {
  state: () => ({
    menu: [
      {
        icon: 'AnalyticIcon',
        svg: true,
        pageName: 'analytic',
        title: 'menu.analytic.index',
        submenu: [
          {
            icon: '',
            pageName: 'analytic-turnover',
            title: 'Customer Turnover',
          },
          {
            icon: '',
            pageName: 'analytic-sales-prediction',
            title: 'Sales Prediction',
          },
        ]
      },
      {
        icon: 'DollarSignIcon',
        pageName: 'transaction',
        title: 'Transaksi',
      },
      {
        icon: 'CreditCardIcon',
        pageName: 'point-of-sale',
        title: 'Point of Sale',
      },
      {
        icon: 'ShoppingBagIcon',
        pageName: 'sales-order',
        title: 'Sales Order',
      },
      'devider',
      {
        icon: 'StoreIcon',
        svg: true,
        pageName: 'customer-list',
        title: 'Warung',
      },
      {
        icon: 'BoxIcon',
        pageName: 'product',
        title: 'Master Produk',
      },
      'devider',
      {
        icon: 'BoxIcon',
        pageName: 'product-stock',
        title: 'Stock',
        // submenu: [
        //   {
        //     icon: '',
        //     pageName: 'product-good-stock',
        //     title: 'Good Stock',
        //   },
        //   {
        //     icon: '',
        //     pageName: 'product-bad-stock',
        //     title: 'Bad Stock',
        //   },
        // ],
      },
      {
        icon: 'ServerIcon',
        pageName: 'side-menu-master-inventory',
        title: 'Inventory',
        submenu: [
          {
            icon: '',
            pageName: 'stock-opname',
            title: 'Stock Opname',
          },
          {
            icon: '',
            pageName: 'retur-stock',
            title: 'Retur Stock',
          },
        ],
      },
    ],
  }),
})

export const useSideMenuCashierStore = defineStore('sideMenuCashierStore', {
  state: () => ({
    menu: [
      // {
      //   general: true,
      //   svg: true,
      //   icon: 'HomeIcon',
      //   pageName: 'dashboard',
      //   title: 'menu.dashboard',
      // },
      {
        icon: 'CreditCardIcon',
        pageName: 'point-of-sale',
        title: 'Point of Sale',
      },
      {
        icon: 'ShoppingBagIcon',
        pageName: 'transaction',
        title: 'Transaksi',
      },
    ],
  }),
})

export const useSideMenuSalesStore = defineStore('sideMenuSales', {
  state: () => ({
    menu: [
      {
        general: true,
        icon: 'HomeIcon',
        svg: true,
        pageName: 'dashboard',
        title: 'menu.dashboard',
      },
      {
        icon: 'AnalyticIcon',
        svg: true,
        pageName: 'analytic',
        title: 'menu.analytic.index',
        submenu: [
          {
            icon: '',
            pageName: 'analytic-turnover',
            title: 'Customer Turnover',
          },
          {
            icon: '',
            pageName: 'analytic-sales-prediction',
            title: 'Sales Prediction',
          },
        ]
      },
      'devider',
      {
        icon: 'ShoppingBagIcon',
        pageName: 'sales-order',
        title: 'Sales Order',
      },
      {
        icon: 'DollarSignIcon',
        pageName: 'transaction',
        title: 'Transaksi',
      },
      'devider',
      {
        icon: 'StoreIcon',
        svg: true,
        pageName: 'customer-list',
        title: 'Warung',
      },
      'devider',
      {
        icon: 'BoxIcon',
        pageName: 'product-stock',
        title: 'Stock',
      },
      // {
      //   icon: 'BoxIcon',
      //   pageName: 'product',
      //   title: 'Master Produk',
      // },
    ],
  }),
})

export const useSideMenuPickerPackerStore = defineStore(
  'sideMenuPickerPacker',
  {
    state: () => ({
      menu: [
        {
          icon: 'DollarSignIcon',
          pageName: 'transaction',
          title: 'Transaksi',
        },
        'devider',
        {
          icon: 'BoxIcon',
          pageName: 'product-stock',
          title: 'Stock',
          // submenu: [
          //   {
          //     icon: '',
          //     pageName: 'product-good-stock',
          //     title: 'Good Stock',
          //   },
          //   {
          //     icon: '',
          //     pageName: 'product-bad-stock',
          //     title: 'Bad Stock',
          //   },
          // ],
        },
        {
          icon: 'ServerIcon',
          pageName: 'side-menu-master-inventory',
          title: 'Inventory',
          submenu: [
            {
              icon: '',
              pageName: 'stock-opname',
              title: 'Stock Opname',
            },
            {
              icon: '',
              pageName: 'retur-stock',
              title: 'Retur Stock',
            },
          ],
        },
        // 'devider',
        // {
        //   icon: 'BoxIcon',
        //   pageName: 'product',
        //   title: 'Master Produk',
        // },
      ],
    }),
  }
)

export const useSideMenuDriverStore = defineStore('sideMenuDriver', {
  state: () => ({
    menu: [
      {
        icon: 'DollarSignIcon',
        pageName: 'transaction',
        title: 'Transaksi',
      },
      'devider',
      {
        icon: 'BoxIcon',
        pageName: 'product-stock',
        title: 'Stock',
      },
    ],
  }),
})
