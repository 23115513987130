<template>
  <div class="col-span-12">
    <div class="intro-y overflow-auto table-scrollbar mb-2">
      <table class="table-report table">
        <thead>
          <tr>
            <th
              v-for="headerItem in header"
              :key="headerItem.item"
              class="whitespace-nowrap"
              :class="headerItem.customClass"
            >
              {{ $t(headerItem.item) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <slot v-if="isNotEmpty"></slot>
          <tr v-else-if="showLoading === true">
            <td :colspan="header.length" class="w-full py-4">
              <div class="grid grid-cols-12 gap-4">
                <div>
                  <ShimmerParagraph height="h-7" width="100%" />
                </div>
                <div class="col-span-8">
                  <ShimmerParagraph height="h-7" width="100%" />
                </div>
                <div class="col-span-2">
                  <ShimmerParagraph height="h-7" width="100%" />
                </div>
                <div>
                  <ShimmerParagraph height="h-7" width="100%" />
                </div>

                <div>
                  <ShimmerParagraph height="h-7" width="100%" />
                </div>
                <div class="col-span-8">
                  <ShimmerParagraph height="h-7" width="100%" />
                </div>
                <div class="col-span-2">
                  <ShimmerParagraph height="h-7" width="100%" />
                </div>
                <div>
                  <ShimmerParagraph height="h-7" width="100%" />
                </div>
              </div>
            </td>
          </tr>
          <tr v-else>
            <td :colspan="header.length" class="!bg-white/50">
              <div class="flex justify-center">
                <NoDataIllustration class="h-[400px]" />
                <!-- {{ $t('info.thereIsNoData') }} -->
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="isNotEmpty"
      class="intro-y flex flex-wrap items-center sm:flex-row sm:flex-nowrap"
    >
      <nav class="flex w-full sm:mr-auto sm:w-auto">
        <ul class="pagination">
          <li
            class="page-item"
            :class="[
              !metaData.hasPreviousPage ? 'cursor-not-allowed opacity-25' : '',
            ]"
          >
            <a
              class="page-link"
              @click="metaData.hasPreviousPage ? changePage(1) : ''"
            >
              <ChevronsLeftIcon class="h-4 w-4" />
            </a>
          </li>
          <li
            class="page-item"
            :class="[
              !metaData.hasPreviousPage ? 'cursor-not-allowed opacity-25' : '',
            ]"
          >
            <a
              class="page-link"
              @click="
                metaData.hasPreviousPage ? changePage(metaData.page - 1) : ''
              "
            >
              <ChevronLeftIcon class="h-4 w-4" />
            </a>
          </li>
          <template v-if="pageCountArr.length > 0">
            <li
              v-for="index in pageCountArr"
              :key="index"
              class="page-item text-center"
            >
              <a
                class="page-link"
                :class="[
                  index === metaData.page
                    ? 'bg-white font-bold !rounded-xl shadow-xl shadow-slate-200 dark:bg-primary'
                    : '',
                ]"
                @click="changePage(index)"
                >{{ index }}</a
              >
            </li>
          </template>
          <template v-else>
            <li
              v-for="index in metaData.pageCount"
              :key="index"
              class="page-item text-center"
            >
              <a
                v-if="index <= 4 || index === metaData.pageCount"
                class="page-link"
                :class="[
                  index === metaData.page
                    ? 'bg-white font-bold !rounded-xl shadow-xl shadow-slate-200 dark:bg-primary'
                    : '',
                ]"
                @click="changePage(index)"
                >{{ index }}</a
              >
            </li>
          </template>

          <li
            class="page-item"
            :class="[
              !metaData.hasNextPage || metaData.pageCount === metaData.page
                ? 'cursor-not-allowed opacity-25'
                : '',
            ]"
          >
            <a
              class="page-link"
              @click="
                metaData.hasNextPage || metaData.pageCount !== metaData.page
                  ? changePage(metaData.page + 1)
                  : ''
              "
            >
              <ChevronRightIcon class="h-4 w-4" />
            </a>
          </li>
          <li
            class="page-item"
            :class="[
              !metaData.hasNextPage || metaData.pageCount === metaData.page
                ? 'cursor-not-allowed opacity-25'
                : '',
            ]"
          >
            <a
              class="page-link"
              @click="
                metaData.hasNextPage || metaData.pageCount !== metaData.page
                  ? changePage(metaData.pageCount)
                  : ''
              "
            >
              <ChevronsRightIcon class="h-4 w-4" />
            </a>
          </li>
        </ul>
        <div class="hidden self-center md:flex">
          <p class="text-xs text-slate-500">
            Menampilkan {{ metaData.take * (metaData.page - 1) + 1 }} sampai
            {{
              metaData.take > metaData.itemCount
                ? metaData.itemCount
                : metaData.take * metaData.page
            }}
            dari {{ metaData.itemCount }} data
          </p>
        </div>
      </nav>
      <nav class="flex w-full mt-2 md:mt-0 md:w-auto justify-between">
        <div class="flex self-center md:hidden">
          <div class="bg-white px-3 py-3 rounded-xl mr-1">
            <p class="text-xs text-gray-600">
              Menampilkan {{ metaData.take * (metaData.page - 1) + 1 }} -
              {{
                metaData.take > metaData.itemCount
                  ? metaData.itemCount
                  : metaData.take * metaData.page
              }}
              dari {{ metaData.itemCount }} data
            </p>
          </div>
        </div>
        <select
          v-if="showOption && isNotEmpty"
          v-model="take"
          class="box form-select w-20 md:ml-auto"
          @change="doFilter('take')"
        >
          <option v-for="option in showOption" :key="option">
            {{ option }}
          </option>
        </select>
      </nav>
    </div>
  </div>
</template>
<script>
// import SaleIcon from '@/assets/svg/sale.svg'
// import ExportCSVComponent from '@/components/ExportCSVComponent.vue'
import ShimmerParagraph from '@/components/shimmer/ShimmerParagraph.vue'
import NoDataIllustration from '@/assets/svg/no-data-illustration.svg'

export default {
  name: 'TableComponent',
  components: {
    // SaleIcon,
    // ExportCSVComponent,
    ShimmerParagraph,
    NoDataIllustration
  },
  props: {
    showLoading: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    dateString: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    filter: {
      type: Object,
      default: () => {
        return {}
      },
    },
    header: {
      type: Array,
      default: () => {
        return []
      },
    },
    vueSelectLabel: {
      type: String,
      default: '',
    },
    vueSelectData: {
      type: Array,
      default: () => {
        return []
      },
    },
    searchOptions: {
      type: Array,
      default: () => {
        return []
      },
    },
    isShowSearch: {
      type: Boolean,
      default: false,
    },
    isNotEmpty: {
      type: Boolean,
      default: true,
    },
    showOption: {
      type: Array,
      default: () => {
        return [10, 25, 35, 50, 75, 100]
      },
    },
    metaData: {
      type: Object,
      default: () => {
        return {
          page: 1,
          take: 10,
          itemCount: 10,
          pageCount: 1,
          hasPreviousPage: false,
          hasNextPage: true,
        }
      },
    },
    isShowExport: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change-filter', 'do-export', 'vue-select-change'],
  data: () => ({
    take: 10,
    page: 1,
    order: 'ASC',
    search: '',
    vueSelectModel: '',
    pageCountArr: [],
  }),
  computed: {
    hasIconSlot() {
      return !!this.$slots.icon
    },
    activePage() {
      return this.metaData.page
    },
  },
  watch: {
    activePage: function () {
      this.paging()
    },
  },
  mounted() {
    this.paging()
  },
  methods: {
    changePage(page) {
      this.page = page
      this.doFilter()
    },
    doFilter(mode = '') {
      if (mode == 'take') this.page = 1
      this.$emit('change-filter', {
        filterData: {
          order: this.order,
          page: this.page,
          take: this.take,
          search: this.search,
        },
      })
    },
    changeSelectModel() {
      this.$emit('vue-select-change', this.vueSelectModel)
    },
    paging() {
      if (this.metaData.pageCount > 1) {
        let pageCountArr = Array.from(
          { length: this.metaData.pageCount },
          (_, i) => i + 1
        )

        let page = this.metaData.page
        let newArr = []
        let counter = 1
        pageCountArr.forEach((item, index, arr) => {
          if (item <= 2) {
            newArr.push(item)
          } else if (item <= page + 1 && item >= page - 1) {
            newArr.push(item)
            counter = 1
          } else if (counter <= 1) {
            counter++
            newArr.push('...')
          } else if (index === arr.length - 1) {
            newArr.push(item)
          }
        })

        this.pageCountArr = newArr
      }
    },
  },
}
</script>
