<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-8">
          <div class="intro-y flex h-10 items-center">
            <h2 class="mr-5 truncate text-lg font-medium">
              Analytic Persentase Pertumbuhan
            </h2>
            <a href="" class="ml-auto flex items-center text-primary">
              <RefreshCcwIcon class="mr-3 h-4 w-4" /> Reload Data
            </a>
          </div>
          <div class="mt-5 grid grid-cols-12 gap-6">
            <div class="intro-y col-span-12 sm:col-span-6 xl:col-span-4">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <DollarSignIcon class="report-box__icon text-primary" />
                    <div class="ml-auto">
                      <Tippy
                        tag="div"
                        class="report-box__indicator cursor-pointer bg-success"
                        content="33% Lebih tinggi dari bulan kemarin"
                      >
                        33%
                        <ChevronUpIcon class="ml-0.5 h-4 w-4" />
                      </Tippy>
                    </div>
                  </div>
                  <div class="mt-6 text-2xl font-medium leading-8 text-success">
                    +Rp 4.710.00
                  </div>
                  <div class="mt-1 text-sm text-slate-500">
                    Penjualan Bulan ini
                  </div>
                </div>
              </div>
            </div>
            <div class="intro-y col-span-12 sm:col-span-6 xl:col-span-4">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <CreditCardIcon class="report-box__icon text-primary" />
                  </div>
                  <div class="mt-6 text-2xl font-medium leading-8">
                    Rp. 23.000.000
                  </div>
                  <div class="mt-1 text-sm text-slate-500">
                    Penjualan Bulan ini
                  </div>
                </div>
              </div>
            </div>
            <div class="intro-y col-span-12 sm:col-span-6 xl:col-span-4">
              <div class="report-box zoom-in">
                <div class="box p-5">
                  <div class="flex">
                    <RewindIcon class="report-box__icon text-warning" />
                  </div>
                  <div class="mt-6 text-2xl font-medium leading-8">
                    Rp. 17.000.000
                  </div>
                  <div class="mt-1 text-sm text-slate-500">
                    Penjualan Bulan lalu
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 lg:col-span-6">
          <div class="intro-y block h-10 items-center sm:flex">
            <!-- <h2 class="mr-5 truncate text-lg font-medium">Sales Report</h2> -->
            <!-- <div class="relative mt-3 text-slate-500 sm:ml-auto sm:mt-0">
              <CalendarIcon
                class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
              />
              <Litepicker
                v-model="salesReportFilter"
                :options="{
                  autoApply: false,
                  singleMode: false,
                  numberOfColumns: 2,
                  numberOfMonths: 2,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true,
                  },
                }"
                class="form-control box pl-10 sm:w-56"
              />
            </div> -->
          </div>
          <div class="intro-y box mt-6 p-5 sm:mt-5">
            <div class="flex flex-col md:flex-row md:items-center">
              <div class="flex">
                <div>
                  <div
                    class="text-lg font-medium text-primary dark:text-slate-300 xl:text-xl"
                  >
                    Pertumbuhan MoM
                  </div>
                </div>
              </div>
              <!-- <Dropdown class="mt-5 md:ml-auto md:mt-0">
                <DropdownToggle class="btn btn-outline-secondary font-normal">
                  Filter by Category
                  <ChevronDownIcon class="ml-2 h-4 w-4" />
                </DropdownToggle>
                <DropdownMenu class="w-40">
                  <DropdownContent class="h-32 overflow-y-auto">
                    <DropdownItem>PC & Laptop</DropdownItem>
                    <DropdownItem>Smartphone</DropdownItem>
                    <DropdownItem>Electronic</DropdownItem>
                    <DropdownItem>Photography</DropdownItem>
                    <DropdownItem>Sport</DropdownItem>
                  </DropdownContent>
                </DropdownMenu>
              </Dropdown> -->
            </div>
            <div class="report-chart">
              <ReportLineChart :height="275" class="mt-6 -mb-6" />
            </div>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-6">
          <div class="intro-y block h-10 items-center sm:flex">
            <!-- <h2 class="mr-5 truncate text-lg font-medium">Sales Report</h2> -->
            <!-- <div class="relative mt-3 text-slate-500 sm:ml-auto sm:mt-0">
              <CalendarIcon
                class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
              />
              <Litepicker
                v-model="salesReportFilter"
                :options="{
                  autoApply: false,
                  singleMode: false,
                  numberOfColumns: 2,
                  numberOfMonths: 2,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 1990,
                    maxYear: null,
                    months: true,
                    years: true,
                  },
                }"
                class="form-control box pl-10 sm:w-56"
              />
            </div> -->
          </div>
          <div class="intro-y box mt-6 p-5 sm:mt-5">
            <div class="flex flex-col md:flex-row md:items-center">
              <div class="flex">
                <div>
                  <div
                    class="text-lg font-medium text-primary dark:text-slate-300 xl:text-xl"
                  >
                    Persentase Pertumbuhan MoM
                  </div>
                </div>
              </div>
              <!-- <Dropdown class="mt-5 md:ml-auto md:mt-0">
                <DropdownToggle class="btn btn-outline-secondary font-normal">
                  Filter by Category
                  <ChevronDownIcon class="ml-2 h-4 w-4" />
                </DropdownToggle>
                <DropdownMenu class="w-40">
                  <DropdownContent class="h-32 overflow-y-auto">
                    <DropdownItem>PC & Laptop</DropdownItem>
                    <DropdownItem>Smartphone</DropdownItem>
                    <DropdownItem>Electronic</DropdownItem>
                    <DropdownItem>Photography</DropdownItem>
                    <DropdownItem>Sport</DropdownItem>
                  </DropdownContent>
                </DropdownMenu>
              </Dropdown> -->
            </div>
            <div class="report-chart">
              <ReportPercentageLineChart :height="275" class="mt-6 -mb-6" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, provide } from 'vue'
import ReportLineChart from '@/components/report-line-chart/Main.vue'
import ReportPercentageLineChart from '@/components/report-line-chart/Percentage.vue'
import ReportDonutChart from '@/components/report-donut-chart/Main.vue'
import ReportPieChart from '@/components/report-pie-chart/Main.vue'
import ReportMap from '@/components/report-map/Main.vue'
import ReportDonutChart1 from '@/components/report-donut-chart-1/Main.vue'
import SimpleLineChart1 from '@/components/simple-line-chart-1/Main.vue'

const salesReportFilter = ref()
const importantNotesRef = ref()

provide('bind[importantNotesRef]', (el) => {
  importantNotesRef.value = el
})

const prevImportantNotes = () => {
  const el = importantNotesRef.value
  el.tns.goTo('prev')
}

const nextImportantNotes = () => {
  const el = importantNotesRef.value
  el.tns.goTo('next')
}
</script>
