<template>
  <div class="grid grid-cols-12 gap-6">
    <!-- BEGIN: General Report -->

    <div class="col-span-12 mt-4 md:mt-6">
      <div class="intro-y col-span-12 mt-2 flex flex-wrap items-center">
        <div class="flex w-full md:w-auto">
          <h2 class="intro-y text-lg font-medium">Ringkasan Laporan</h2>
        </div>
        <div class="mx-auto hidden text-slate-500 md:block"></div>
        <div class="relative w-full md:w-max mt-3 text-slate-500 md:mt-0">
          <CalendarIcon
            class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
          />
          <Litepicker
            v-model="filterDate"
            :options="$h.litePickerConfig()"
            class="form-control box pl-10 w-full md:w-56"
            @change="getSummaryData"
          />
        </div>
      </div>
      <div class="mt-5 md:mt-8 grid grid-cols-12 gap-2 md:gap-4 lg:gap-6">
        <div
          class="intro-y mb-3 md:mb-0 lg:col-span-3 lg:row-span-2 col-span-12"
        >
          <div
            class="report-box flex flex-col md:flex-row lg:flex-col h-full justify-center md:justify-between lg:justify-center rounded-tl-xl rounded-tr-lg rounded-bl-xl rounded-br-3xl shadow-md bg-gradient-to-br from-white via-emerald-100 to-primary/70 px-4 py-4"
          >
            <div class="flex flex-col">
              <div
                class="flex text-sm leading-3 items-center text-slate-600 dark:text-slate-300"
              >
                Total Nilai Stock
                <Tippy
                  tag="div"
                  :content="`Total potensi penjualan Rp ${$h.toRupiah(stockData.potential_sales_total)}`"
                >
                  <AlertCircleIcon class="ml-1.5 mt-0.5 h-5 w-5" />
                </Tippy>
              </div>
              <div class="mt-1 md:mt-2">
                <div class="flex">
                  <Currency
                    :total-in-string="stockData.stock_value_total.toString()"
                    :with-prefix="true"
                    class="text-lg font-medium leading-8 md:text-2xl"
                  />
                </div>
              </div>
            </div>

            <div class="w-full flex md:hidden lg:flex my-2 md:my-4 h-1 bg-gradient-to-r from-emerald-200 to-primary"></div>

            <div class="flex flex-col">
              <div
                class="flex text-sm leading-3 items-center text-slate-600 dark:text-slate-300"
              >
                Total Potensial Margin
                <Tippy
                  tag="div"
                  :content="`Total potensi persentase margin ${stockData.potential_margin_percentage_total}%`"
                >
                  <AlertCircleIcon class="ml-1.5 mt-0.5 h-5 w-5" />
                </Tippy>
              </div>
              <div class="mt-1 md:mt-2">
                <div class="flex">
                  <Currency
                    :total-in-string="stockData.potensial_margin_total.toString()"
                    :with-prefix="true"
                    class="text-lg font-medium leading-8 md:text-2xl"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="intro-y col-span-6 lg:col-span-3"
        >
          <CardReportPercentage
            title="Penjualan"
            :total="reportData.sales_total.total"
            :percentage="reportData.sales_total.percent"
            :is-up="reportData.sales_total.is_up"
            :gap-date="gapDate"
            :loading="isSummaryLoading"
            :with-prefix="true"
          />
        </div>
        <div
          class="intro-y col-span-6 lg:col-span-3"
        >
          <CardReportPercentage
            title="Margin"
            :total="reportData.margin_total.total"
            :percentage="reportData.margin_total.percent"
            :is-up="reportData.margin_total.is_up"
            :gap-date="gapDate"
            :loading="isSummaryLoading"
            :with-prefix="true"
          />
        </div>
        <div
          class="intro-y col-span-6 hidden lg:block lg:col-span-3"
        >
          <CardReportPercentage
            title="Transaksi"
            :total="reportData.transaction_total.total"
            :percentage="reportData.transaction_total.percent"
            :is-up="reportData.transaction_total.is_up"
            :gap-date="gapDate"
            :loading="isSummaryLoading"
            :with-prefix="false"
          />
        </div>
        <div
          class="intro-y col-span-6 lg:col-span-3"
        >
          <CardReportPercentage
            title="Modal"
            :total="reportData.capital_total.total"
            :loading="isSummaryLoading"
            :with-prefix="true"
          />
        </div>
        <div
          class="intro-y col-span-6 lg:col-span-3"
        >
          <CardReportPercentage
            title="Persentase Margin"
            :total="reportData.margin_percentage.total"
            :loading="isSummaryLoading"
            :with-prefix="false"
            :with-percentage="true"
          />
        </div>

        <div
          class="intro-y col-span-6 lg:hidden"
        >
          <CardReportPercentage
            title="Transaksi"
            :total="reportData.transaction_total.total"
            :percentage="reportData.transaction_total.percent"
            :is-up="reportData.transaction_total.is_up"
            :gap-date="gapDate"
            :loading="isSummaryLoading"
            :with-prefix="false"
          />
        </div>
        <div
          class="intro-y col-span-6 lg:col-span-3"
        >
          <CardReportPercentage
            title="Skor Transaksi"
            :total="reportData.transaction_score.total"
            :loading="isSummaryLoading"
            :with-prefix="false"
            :with-percentage="true"
          />
        </div>
      </div>
    </div>

    <div class="intro-y col-span-12 mt-4">
      <div class="flex w-full">
        <h2 class="intro-y text-lg font-medium">Laporan Turnover</h2>
      </div>
    </div>

    <div class="intro-y col-span-12 md:col-span-6">
      <CardReportChartTurnover :data="turnoverData" title="Customer Turnover" />
    </div>
    
    <div class="intro-y col-span-12 md:col-span-6">
      <CardReportChartTransaction :data="turnoverData" title="Customer Kategori Transaksi" />
    </div>

    <div v-if="omzetByDateChartData.length > 0" class="intro-y col-span-12 mt-4">
      <div class="flex w-full">
        <h2 class="intro-y text-lg font-medium">Laporan Harian</h2>
      </div>
    </div>

    <div v-if="omzetByDateChartData.length > 0" class="intro-y col-span-12" :class="differenceDays <= 7 ? 'md:col-span-6' : differenceDays > 14 ? '' : 'lg:col-span-6'">
      <CardReportChart :data="omzetByDateChartData" title="Omzet Harian" prefix="Total Omzet" :show-currency-prefix="true" />
    </div>

    <div v-if="marginByDateChartData.length > 0" class="intro-y col-span-12" :class="differenceDays <= 7 ? 'md:col-span-6' : differenceDays > 14 ? '' : 'lg:col-span-6'">
      <CardReportChart :data="marginByDateChartData" title="Margin Harian" prefix="Total Margin" :show-currency-prefix="true" />
    </div>

    <div v-if="reportByDateChartLabel.length > 0" class="intro-y col-span-12 lg:col-span-12">
      <CardReportChart :data="reportByDateChartData" :labels="reportByDateChartLabel" :prefix="reportByDateChartPrefix" :show-legend="true" title="Transaksi Harian" />
    </div>

    <!-- <div v-if="salesByDateChartData.transaction_r2.length > 0" class="intro-y col-span-12 lg:col-span-12">
      <CardReportChart :data="salesByDateChartData.transaction_r2" type="line" title="Transaksi R2 Harian" prefix="Total Transaksi" />
    </div>

    <div v-if="salesByDateChartData.transaction_r3.length > 0" class="intro-y col-span-12 lg:col-span-12">
      <CardReportChart :data="salesByDateChartData.transaction_r3" type="line" title="Transaksi R3 Harian" prefix="Total Transaksi" />
    </div> -->

    <!-- <div class="col-span-12 md:border border-dashed"></div> -->

    <!-- <div class="intro-y col-span-12 flex flex-wrap items-center">
      <div class="flex w-full md:w-auto">
        <p class="intro-y text-base md:text-lg font-medium">Stock Valuation</p>
      </div>
    </div>

    <div class="intro-y col-span-6 xl:col-span-3">
      <CardReportPercentage
        title="Nilai Stock"
        :total="stockData.stock_value_total"
        :loading="isStockSummaryLoading"
        :with-prefix="true"
      />
    </div>
    <div class="intro-y col-span-6 xl:col-span-3">
      <CardReportPercentage
        title="Potensial Penjualan"
        :total="stockData.potential_sales_total"
        :loading="isStockSummaryLoading"
        :with-prefix="true"
      />
    </div>
    <div class="intro-y col-span-6 xl:col-span-3">
      <CardReportPercentage
        title="Potensial Margin"
        :total="stockData.potensial_margin_total"
        :loading="isStockSummaryLoading"
        :with-prefix="true"
      />
    </div>
    <div class="intro-y col-span-6 xl:col-span-3">
      <CardReportPercentage
        title="Potensial Persentase"
        :total="stockData.potential_margin_percentage_total"
        :loading="isStockSummaryLoading"
        :with-prefix="false"
        :with-percentage="true"
      />
    </div> -->

    <!-- <div class="col-span-12 mt-6 lg:col-span-12">
      <div class="intro-y block h-10 items-center sm:flex">
        <h2 class="mr-5 truncate text-lg font-medium">Chart Penjualan</h2>
      </div>
      <div class="intro-y box mt-4 p-5 sm:mt-5">
        <div class="flex flex-col md:flex-row md:items-center">
          <div class="flex">
            <div>
              <div
                class="text-lg font-medium text-primary dark:text-slate-300 xl:text-xl"
              >
                Rp 1.500.000
              </div>
              <div class="mt-0.5 text-slate-500">Bulan Ini</div>
            </div>
            <div
              class="mx-4 h-12 w-px border border-r border-dashed border-slate-200 dark:border-darkmode-300 xl:mx-5"
            ></div>
            <div>
              <div class="text-lg font-medium text-slate-500 xl:text-xl">
                Rp 1.300.000
              </div>
              <div class="mt-0.5 text-slate-500">Bulan Kemarin</div>
            </div>
          </div>
          <Dropdown class="mt-5 md:ml-auto md:mt-0">
            <DropdownToggle class="btn btn-outline-secondary font-normal">
              Filter by Category
              <ChevronDownIcon class="ml-2 h-4 w-4" />
            </DropdownToggle>
            <DropdownMenu class="w-40">
              <DropdownContent class="h-32 overflow-y-auto">
                <DropdownItem>PC & Laptop</DropdownItem>
                <DropdownItem>Smartphone</DropdownItem>
                <DropdownItem>Electronic</DropdownItem>
                <DropdownItem>Photography</DropdownItem>
                <DropdownItem>Sport</DropdownItem>
              </DropdownContent>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div class="report-chart">
          <ReportLineChart :height="275" class="mt-6 -mb-6" />
        </div>
      </div>
    </div>
    <div class="col-span-12 mt-6 sm:col-span-6 lg:col-span-6">
      <div class="intro-y flex h-10 items-center">
        <h2 class="mr-5 truncate text-lg font-medium">Weekly Top Agen Sales</h2>
        <a href="" class="ml-auto truncate text-primary">Show More</a>
      </div>
      <div class="intro-y box mt-5 p-5">
        <div class="mt-1">
          <ReportPieChart :height="213" />
        </div>
        <div class="mx-auto mt-6 w-52 sm:w-auto">
          <div class="flex items-center">
            <div class="mr-3 h-2 w-2 rounded-full bg-primary"></div>
            <span class="truncate">17 - 30 Years old</span>
            <span class="ml-auto font-medium">62%</span>
          </div>
          <div class="mt-4 flex items-center">
            <div class="mr-3 h-2 w-2 rounded-full bg-pending"></div>
            <span class="truncate">31 - 50 Years old</span>
            <span class="ml-auto font-medium">33%</span>
          </div>
          <div class="mt-4 flex items-center">
            <div class="mr-3 h-2 w-2 rounded-full bg-warning"></div>
            <span class="truncate">>= 50 Years old</span>
            <span class="ml-auto font-medium">10%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 mt-6 sm:col-span-6 lg:col-span-6">
      <div class="intro-y flex h-10 items-center">
        <h2 class="mr-5 truncate text-lg font-medium">Sales Report</h2>
        <a href="" class="ml-auto truncate text-primary">Show More</a>
      </div>
      <div class="intro-y box mt-5 p-5">
        <div class="mt-1">
          <ReportDonutChart :height="213" />
        </div>
        <div class="mx-auto mt-6 w-52 sm:w-auto">
          <div class="flex items-center">
            <div class="mr-3 h-2 w-2 rounded-full bg-primary"></div>
            <span class="truncate">17 - 30 Years old</span>
            <span class="ml-auto font-medium">62%</span>
          </div>
          <div class="mt-4 flex items-center">
            <div class="mr-3 h-2 w-2 rounded-full bg-pending"></div>
            <span class="truncate">31 - 50 Years old</span>
            <span class="ml-auto font-medium">33%</span>
          </div>
          <div class="mt-4 flex items-center">
            <div class="mr-3 h-2 w-2 rounded-full bg-warning"></div>
            <span class="truncate">>= 50 Years old</span>
            <span class="ml-auto font-medium">10%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 mt-6 xl:col-span-8">
      <div class="intro-y block h-10 items-center sm:flex">
        <h2 class="mr-5 truncate text-lg font-medium">Official Store</h2>
        <div class="relative mt-3 text-slate-500 sm:ml-auto sm:mt-0">
          <MapPinIcon
            class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
          />
          <input
            type="text"
            class="form-control box pl-10 sm:w-56"
            placeholder="Filter by city"
          />
        </div>
      </div>
      <div class="intro-y box mt-12 p-5 sm:mt-5">
        <div>
          250 Official stores in 21 countries, click the marker to see
          location details.
        </div>
        <ReportMap class="report-maps mt-5 rounded-md bg-slate-200" />
      </div>
    </div>
    <div class="col-span-12 mt-6">
      <div class="intro-y flex h-10 items-center">
        <h2 class="mr-5 truncate text-lg font-medium">
          Agen Sales Terbaik Minggu Ini
        </h2>
      </div>
      <div class="mt-5">
        <div
          v-for="(faker, fakerKey) in $_.take($f(), 4)"
          :key="fakerKey"
          class="intro-y"
        >
          <div class="box zoom-in mb-3 flex items-center px-4 py-4">
            <div
              class="image-fit h-10 w-10 flex-none overflow-hidden rounded-md"
            >
              <img alt="Gromura - SCM" :src="faker.users[0].image" />
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium">
                {{ faker.users[0].name }}
              </div>
              <div class="mt-0.5 text-xs text-slate-500">
                {{ faker.dates[0] }}
              </div>
            </div>
            <div
              class="cursor-pointer rounded-full bg-success py-1 px-2 text-xs font-medium text-white"
            >
              137 Sales
            </div>
          </div>
        </div>
        <a
              href=""
              class="intro-y block w-full rounded-md border border-dotted border-slate-400 py-4 text-center text-slate-500 dark:border-darkmode-300"
              >View More</a
            >
      </div>
    </div>
    <div class="col-span-12 mt-6 grid grid-cols-12 gap-6">
      <div class="intro-y col-span-12 sm:col-span-6 2lg:col-span-3">
        <div class="box zoom-in p-5">
          <div class="flex items-center">
            <div class="w-2/4 flex-none">
              <div class="truncate text-lg font-medium">Target Sales</div>
              <div class="mt-1 text-slate-500">300 Sales</div>
            </div>
            <div class="relative ml-auto flex-none">
              <ReportDonutChart1 :width="90" :height="90" />
              <div
                class="absolute top-0 left-0 flex h-full w-full items-center justify-center font-medium"
              >
                20%
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="intro-y col-span-12 sm:col-span-6 2lg:col-span-3">
        <div class="box zoom-in p-5">
          <div class="flex">
            <div class="mr-3 truncate text-lg font-medium">
              Social Media
            </div>
            <div
              class="ml-auto flex cursor-pointer items-center truncate rounded-full bg-slate-100 py-1 px-2 text-xs text-slate-500 dark:bg-darkmode-400"
            >
              320 Followers
            </div>
          </div>
          <div class="mt-1">
            <SimpleLineChart1 :height="58" class="-ml-1" />
          </div>
        </div>
      </div>
      <div class="intro-y col-span-12 sm:col-span-6 2lg:col-span-3">
        <div class="box zoom-in p-5">
          <div class="flex items-center">
            <div class="w-2/4 flex-none">
              <div class="truncate text-lg font-medium">New Products</div>
              <div class="mt-1 text-slate-500">1450 Products</div>
            </div>
            <div class="relative ml-auto flex-none">
              <ReportDonutChart1 :width="90" :height="90" />
              <div
                class="absolute top-0 left-0 flex h-full w-full items-center justify-center font-medium"
              >
                45%
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="intro-y col-span-12 sm:col-span-6 2lg:col-span-3">
        <div class="box zoom-in p-5">
          <div class="flex">
            <div class="mr-3 truncate text-lg font-medium">Posted Ads</div>
            <div
              class="ml-auto flex cursor-pointer items-center truncate rounded-full bg-slate-100 py-1 px-2 text-xs text-slate-500 dark:bg-darkmode-400"
            >
              180 Campaign
            </div>
          </div>
          <div class="mt-1">
            <SimpleLineChart1 :height="58" class="-ml-1" />
          </div>
        </div>
      </div>
    </div>
    <div class="col-span-12 mt-6">
      <div class="intro-y block h-10 items-center sm:flex">
        <h2 class="mr-5 truncate text-lg font-medium">
          Produk Terbaik Minggu Ini
        </h2>
        <div class="mt-3 flex items-center sm:ml-auto sm:mt-0">
          <button
            class="btn box flex items-center text-slate-600 dark:text-slate-300"
          >
            <FileTextIcon class="mr-2 hidden h-4 w-4 sm:block" />
            Export to Excel
          </button>
          <button
            class="btn box ml-3 flex items-center text-slate-600 dark:text-slate-300"
          >
            <FileTextIcon class="mr-2 hidden h-4 w-4 sm:block" />
            Export to PDF
          </button>
        </div>
      </div>
      <div class="intro-y mt-6 overflow-auto sm:mt-0 lg:overflow-visible">
        <table class="table-report table sm:mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">IMAGES</th>
              <th class="whitespace-nowrap">PRODUCT NAME</th>
              <th class="whitespace-nowrap text-center">STOCK</th>
              <th class="whitespace-nowrap text-center">STATUS</th>
              <th class="whitespace-nowrap text-center">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(faker, fakerKey) in $_.take($f(), 4)"
              :key="fakerKey"
              class="intro-x"
            >
              <td class="w-40">
                <div class="flex">
                  <div class="image-fit zoom-in h-10 w-10">
                    <Tippy
                      tag="img"
                      alt="Gromura - SCM"
                      class="rounded-full"
                      :src="faker.products[0].images[0]"
                      :content="`Uploaded at ${faker.dates[0]}`"
                    />
                  </div>
                  <div class="image-fit zoom-in -ml-5 h-10 w-10">
                        <Tippy
                          tag="img"
                          alt="Gromura - SCM"
                          class="rounded-full"
                          :src="faker.products[0].images[1]"
                          :content="`Uploaded at ${faker.dates[1]}`"
                        />
                      </div>
                  <div class="image-fit zoom-in -ml-5 h-10 w-10">
                        <Tippy
                          tag="img"
                          alt="Gromura - SCM"
                          class="rounded-full"
                          :src="faker.images[2]"
                          :content="`Uploaded at ${faker.dates[2]}`"
                        />
                      </div>
                </div>
              </td>
              <td>
                <a href="" class="whitespace-nowrap font-medium">{{
                  faker.products[0].name
                }}</a>
                <div class="mt-0.5 whitespace-nowrap text-xs text-slate-500">
                  {{ faker.products[0].category }}
                </div>
              </td>
              <td class="text-center">{{ faker.stocks[0] }}</td>
              <td class="w-40">
                <div
                  class="flex items-center justify-center"
                  :class="{
                    'text-success': faker.trueFalse[0],
                    'text-danger': !faker.trueFalse[0],
                  }"
                >
                  <CheckSquareIcon class="mr-2 h-4 w-4" />
                  {{ faker.trueFalse[0] ? 'Active' : 'Inactive' }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex items-center justify-center">
                  <a class="mr-3 flex items-center" href="">
                    <CheckSquareIcon class="mr-1 h-4 w-4" />
                    Edit
                  </a>
                  <a class="flex items-center text-danger" href="">
                    <Trash2Icon class="mr-1 h-4 w-4" /> Delete
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="intro-y mt-3 flex flex-wrap items-center sm:flex-row sm:flex-nowrap"
      >
        <nav class="w-full sm:mr-auto sm:w-auto">
          <ul class="pagination">
            <li class="page-item">
              <a class="page-link" href="#">
                <ChevronsLeftIcon class="h-4 w-4" />
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                <ChevronLeftIcon class="h-4 w-4" />
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">...</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">1</a>
            </li>
            <li class="page-item active">
              <a class="page-link" href="#">2</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">3</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">...</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                <ChevronRightIcon class="h-4 w-4" />
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                <ChevronsRightIcon class="h-4 w-4" />
              </a>
            </li>
          </ul>
        </nav>
        <select class="box form-select mt-3 w-20 sm:mt-0">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div>
    </div> -->
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs, watch, provide } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import dayjs from 'dayjs'
import globalMixin from '@/mixins/global.js'
import useEmitter from '@/composables/useEmitter'
import ReportLineChart from '@/components/report-line-chart/Main.vue'
import ReportDonutChart from '@/components/report-donut-chart/Main.vue'
import ReportDonutChart1 from '@/components/report-donut-chart-1/Main.vue'
import ReportMap from '@/components/report-map/Main.vue'
import SimpleLineChart1 from '@/components/simple-line-chart-1/Main.vue'
import ReportPieChart from '@/components/report-pie-chart/Main.vue'
import Currency from '@/components/Currency.vue'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Status from '@/components/status/Main.vue'
import CardReportPercentage from '@/components/card/CardReportPercentage.vue'
import CardReportChartTurnover from '@/components/card/CardReportChartTurnover.vue'
import CardReportChartTransaction from '@/components/card/CardReportChartTransaction.vue'
import CardReportChart from '@/components/card/CardReportChart.vue'

export default {
  components: {
    ReportLineChart,
    ReportDonutChart,
    ReportDonutChart1,
    ReportMap,
    SimpleLineChart1,
    ReportPieChart,
    ImageLoader,
    TableComponent,
    Status,
    Currency,
    CardReportPercentage,
    CardReportChartTurnover,
    CardReportChartTransaction,
    CardReportChart
  },
  mixins: [globalMixin],
  setup(props, { emit }) {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const emitter = useEmitter()

    const activeUser = reactive({})
    const roleAccess = ref('')
    
    let differenceDays = ref(0)
    // const periodic = ref(null)
    // const periodicName = ref(null)

    let reportData = reactive({
      sales_total: {
        total: 0,
        percent: '',
        is_up: false
      },
      margin_total: {
        total: 0,
        percent: '',
        is_up: false
      },
      transaction_total: {
        total: 0,
        percent: '',
        is_up: false
      },
      capital_total: {
        total: 0,
      },
      margin_percentage: {
        total: 0,
      },
      transaction_score: {
        total: 0,
      },
    })
    let turnoverData = reactive({
        turnover_average: 0,
        turnover_average_r1: 0,
        turnover_average_r2: 0,
        turnover_average_r3: 0,
        transaction_total: 0,
        transaction_r1: 0,
        transaction_r2: 0,
        transaction_r3: 0,
    })
    let stockData = reactive({
      stock_value_total: 0,
      potential_sales_total: 0,
      potensial_margin_total: 0,
      potential_margin_percentage_total: 0,
    })

    let omzetByDateChartData = ref([])
    let marginByDateChartData = ref([])
    let reportByDateChartData = ref([])
    let reportByDateChartLabel = ref([])
    let reportByDateChartPrefix = ref(["Total R1", "Total R2", "Total R3"])

    let gapDate = ref (null)

    const isSummaryLoading = ref(false)
    const isStockSummaryLoading = ref(false)
    const timer = ref(undefined)

    // const periodicOptions = ref([
    //   {
    //     id: 'DAILY',
    //     name: 'Hari Ini',
    //   },
    //   {
    //     id: 'WEEKLY',
    //     name: 'Minggu Ini',
    //   },
    //   {
    //     id: 'MONTHLY',
    //     name: 'Bulan Ini',
    //   },
    //   {
    //     id: 'QUARTERLY',
    //     name: 'Quarter Ini',
    //   },
    //   {
    //     id: 'YEARLY',
    //     name: 'Tahun Ini',
    //   },
    // ])

    const starDateOfMonth = dayjs().startOf('month').format("YYYY-MM-DD")
    const endDateOfMonth = dayjs().endOf('month').format("YYYY-MM-DD")
    const filterDate = ref(`${starDateOfMonth} - ${endDateOfMonth}`)

    const importantNotesRef = ref()

    provide('bind[importantNotesRef]', (el) => {
      importantNotesRef.value = el
    })

    const prevImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('prev')
    }

    const nextImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('next')
    }

    watch(filterDate, (currentValue, oldValue) => {
      calculateDiffDays()

      refreshData()
    })

    onMounted(async () => {
      // clearing all event
      emitter.all.clear()

      Object.assign(activeUser, store.getters['auth/activeUser'])

      roleAccess.value = activeUser.role_codes
      // periodic.value = periodicOptions.value[0].id
      // periodicName.value = periodicOptions.value[0].name

      checkAccess(roleAccess.value)
      calculateDiffDays()

      emitter.on('update-data', (store) => {
        refreshData()
      })

      const storeId = store.getters['store/storeId']

      if (storeId === null) {
        setTimeout(() => {
          refreshData()
        }, 2000)
      } else {
        refreshData()
      }
    })

    const calculateDiffDays = async () => {
      let startDateString = ""
      let endDatestring = ""
      const dateRange = filterDate.value.split(' - ')
      if (dateRange.hasOwnProperty(0)) startDateString = dateRange[0]
      if (dateRange.hasOwnProperty(1)) endDatestring = dateRange[1]

      const endDate = dayjs(endDatestring)
      differenceDays.value = endDate.diff(startDateString, 'day');
    }

    const refreshData = async () => {
      await getStockSummaryData()
      await getSummaryData()
      await getTurnoverSummaryData()
      await getTransactionByDateChartData()
    }

    const getSummaryData = async () => {
      // const filterPeriodic = periodicOptions.value.filter(function (item) {
      //   return item.id === periodic.value
      // })
      // periodicName.value = filterPeriodic[0].name

      isSummaryLoading.value = true

      // emit('showGlobalLoadingIndicator', true)

      const storeId = store.getters['store/storeId']

      let queryParameters = {
        store_id: storeId,
        is_dashboard: true,
        // periodic: periodic.value,
      }

      const dateRange = filterDate.value.split(' - ')

      if (dateRange.hasOwnProperty(0)) queryParameters.start_date = dateRange[0]
      if (dateRange.hasOwnProperty(1)) queryParameters.end_date = dateRange[1]

      const startDate = dayjs(queryParameters.end_date)
      gapDate.value = `${startDate.diff(queryParameters.start_date, 'day') + 1} hari`

      await store
        .dispatch('transaction/summary', queryParameters)
        .then((response) => {
          if (response.data) {
            Object.assign(reportData, response.data)
          }

          isSummaryLoading.value = false
          // emit('showGlobalLoadingIndicator', false)
        })
        .catch((e) => {
          isSummaryLoading.value = false
          // emit('showGlobalLoadingIndicator', false)
          console.log(e)
        })
    }

    const getStockSummaryData = async () => {
      isStockSummaryLoading.value = true
      const storeId = store.getters['store/storeId']

      await store
        .dispatch('productStock/summary', {
          store_id: storeId,
        })
        .then((response) => {
          Object.assign(stockData, response.data)
          isStockSummaryLoading.value = false
        })
        .catch((e) => {
          // console.log(e)
          isSummaryLoading.value = false
        })
    }

    const getTransactionByDateChartData = async () => {
      isSummaryLoading.value = true

      const storeId = store.getters['store/storeId']

      let queryParameters = {
        store_id: storeId,
      }

      const dateRange = filterDate.value.split(' - ')

      if (dateRange.hasOwnProperty(0)) queryParameters.start_date = dateRange[0]
      if (dateRange.hasOwnProperty(1)) queryParameters.end_date = dateRange[1]

      await store
        .dispatch('report/byDateChart', queryParameters)
        .then((response) => {
          if (response.data) {
            omzetByDateChartData.value = []
            omzetByDateChartData.value = response.data.omzet

            const notZero = omzetByDateChartData.value.filter(obj=>!Object.values(obj).includes(0));

            marginByDateChartData.value = []
            marginByDateChartData.value = response.data.margin

            reportByDateChartLabel.value = response.data.transaction_r1.map((item) => item.name)
            
            reportByDateChartData.value = []
            reportByDateChartData.value.push(response.data.transaction_r1.map((item) => item.total))
            reportByDateChartData.value.push(response.data.transaction_r2.map((item) => item.total))
            reportByDateChartData.value.push(response.data.transaction_r3.map((item) => item.total))
          }

          isSummaryLoading.value = false
        })
        .catch((e) => {
          isSummaryLoading.value = false
          console.log(e)
        })
    }

    const getTurnoverSummaryData = async () => {
      isSummaryLoading.value = true
      const storeId = store.getters['store/storeId']

      let queryParameters = {
        store_id: storeId,
      }
      const dateRange = filterDate.value.split(' - ')
      if (dateRange.hasOwnProperty(0)) queryParameters.start_date = dateRange[0]
      if (dateRange.hasOwnProperty(1)) queryParameters.end_date = dateRange[1]

      await store
        .dispatch('analytic/summaryCustomerTurnOver', queryParameters)
        .then((response) => {
          const responseData = response.data

          Object.assign(turnoverData, responseData)
        
          isSummaryLoading.value = false
        })
        .catch((e) => {
          isSummaryLoading.value = false
          console.log(e)
        })
    }

    const checkAccess = async (roleCodes) => {
      if (roleCodes.includes('ADMIN_STORE')) {
        setTimeout(() => {
          router.push({
            name: 'transaction',
            params: { slug: route.params.slug },
            query: { lang: route.query.lang },
          })
        }, 2000)
      } else if (roleCodes.includes('CASHIER')) {
        setTimeout(() => {
          router.push({
            name: 'point-of-sale',
            params: { slug: route.params.slug },
            query: { lang: route.query.lang },
          })
        }, 2000)
      } else if (roleCodes.includes('PICKER_PACKER')) {
        setTimeout(() => {
          router.push({
            name: 'transaction',
            params: { slug: route.params.slug },
            query: { lang: route.query.lang },
          })
        }, 2000)
      } else if (roleCodes.includes('DRIVER')) {
        setTimeout(() => {
          router.push({
            name: 'transaction',
            params: { slug: route.params.slug },
            query: { lang: route.query.lang },
          })
        }, 2000)
      } else {
        // getSummaryData()
        // getStockSummaryData()
      }
    }

    return {
      dayjs,
      t,
      n,
      store,
      route,
      router,

      roleAccess,

      // periodic,
      // periodicName,
      // periodicOptions,

      reportData,
      stockData,
      turnoverData,
      reportByDateChartLabel,
      reportByDateChartPrefix,
      reportByDateChartData,
      omzetByDateChartData,
      marginByDateChartData,

      isSummaryLoading,
      isStockSummaryLoading,
      timer,

      getSummaryData,
      getStockSummaryData,
      getTurnoverSummaryData,
      getTransactionByDateChartData,
      
      differenceDays,
      gapDate,

      checkAccess,

      filterDate,
      importantNotesRef,
      prevImportantNotes,
      nextImportantNotes,
    }
  },
}
</script>
