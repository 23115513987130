<template>
  <!-- BEGIN: Mobile Menu -->
  <div
    class="mobile-menu md:hidden"
    :class="{
      'mobile-menu--active': activeMobileMenu,
    }"
  >
    <div class="mobile-menu-bar justify-between">
      <a href="javascript:;" class="mobile-menu-toggler flex items-center">
        <BarChart2Icon
          class="h-8 w-8 -rotate-270 transform text-white"
          @click="toggleMobileMenu"
        />
      </a>
      <!-- <a v-if="'title' in $route.meta && $route.meta.title === 'Dashboard'" href="" class="flex"> -->
      <a href="" class="flex">
        <img
          alt="Gromura - SCM"
          class="h-10 ml-12"
          src="@/assets/images/logo.png"
        />
        <!-- <GromuraIcon class="w-8 text-white" /> -->
      </a>
      <!-- <a v-else href="" class="flex pl-4">
        <span class="text-md text-white font-bold">{{ 'title' in $route.meta ? $t($route.meta.title) : '' }}</span>
      </a> -->
      <div class="block md:hidden">
        <TopBar />
      </div>
    </div>
    <div class="scrollable">
      <a href="javascript:;" class="mobile-menu-toggler">
        <XCircleIcon
          class="h-8 w-8 -rotate-90 transform text-white"
          @click="toggleMobileMenu"
        />
      </a>
      <ul class="scrollable__content py-2">
        <li
          v-if="JSON.stringify(activeUser) !== '{}'"
          class="mt-6 mb-8 pl-6 pr-2"
        >
          <!-- <img alt="Gromura - SCM" class="h-10" src="@/assets/images/logo-white.png" /> -->
          <div
            class="flex w-full items-center"
            @click="
              $router.push({
                name: 'profile-user',
                params: {
                  slug: $route.params.slug,
                },
                query: {
                  lang: $route.query.lang,
                },
              }),
                (activeMobileMenu = false)
            "
          >
            <div class="flex h-10 w-12">
              <ImageLoader
                :image="activeUser.avatar"
                :alt="`${activeUser.first_name} ${activeUser.last_name}`"
                rounded-class="rounded-full"
                height="h-10"
                class="w-12"
              />
            </div>
            <div class="flex w-full flex-col pl-2 text-white">
              <div class="flex">
                <span class="text-sm font-bold">
                  {{ `${activeUser.first_name} ${activeUser.last_name}` }}
                </span>
              </div>
              <div class="flex">
                <span class="mt-0.5 text-xs">
                  {{ activeUser.role_names.toString() || null }}
                </span>
              </div>
            </div>
          </div>
        </li>
        <li
          v-if="JSON.stringify(activeUser) !== '{}'"
          :key="999"
          class="menu__devider my-2 md:my-4"
        ></li>
        <!-- BEGIN: First Child -->
        <template v-for="(menu, menuKey) in formattedMenu">
          <li
            v-if="menu == 'devider'"
            :key="menu + menuKey"
            class="menu__devider my-2 md:my-4"
          ></li>
          <li v-else :key="menu + menuKey">
            <a
              href="javascript:;"
              class="menu"
              :class="{
                'menu--active bg-primary/25 rounded-xl mx-4 !pl-3 !pr-2 font-bold': menu.active,
                'menu--open': menu.activeDropdown,
              }"
              @click="linkTo(menu, router)"
            >
              <div class="menu__icon" :class="{
                  'text-primary': menu.active,
                }">
                <template v-if="menu.svg === true">
                  <HomeMenuIcon v-if="menu.icon === 'HomeIcon'" class="w-6" />
                  <ReportMenuIcon
                    v-else-if="menu.icon === 'ReportIcon'"
                    class="w-6"
                  />
                  <AnalyticMenuIcon
                    v-if="menu.icon === 'AnalyticIcon'"
                    class="w-6"
                  />
                  <ProfileMenuIcon
                    v-if="menu.icon === 'ProfileIcon'"
                    class="w-5"
                  />
                  <StoreMenuIcon v-if="menu.icon === 'StoreIcon'" class="w-6" />
                  <WarehouseMenuIcon
                    v-if="menu.icon === 'WarehouseIcon'"
                    class="w-6"
                  />
                </template>
                <template v-else-if="menu.hero === true">
                    <BanknotesIcon class="w-6" v-if="menu.icon === 'BanknotesIcon'" />
                  </template>
                <template v-else>
                  <component :is="menu.icon" />
                </template>
              </div>
              <div class="menu__title">
                <span
                  :class="{
                    'text-primary': menu.active,
                  }"
                >
                  {{ $t(menu.title) }}
                </span>
                <div
                  v-if="menu.submenu"
                  class="menu__sub-icon"
                  :class="{ 'rotate-180 transform': menu.activeDropdown }"
                >
                  <ChevronDownIcon />
                </div>
              </div>
            </a>
            <!-- BEGIN: Second Child -->
            <transition @enter="enter" @leave="leave">
              <ul v-if="menu.submenu && menu.activeDropdown">
                <li
                  v-for="(submenu, subMenuKey) in menu.submenu"
                  :key="subMenuKey"
                >
                  <a
                    href="javascript:;"
                    class="menu"
                    :class="{ 'menu--active font-bold': submenu.active }"
                    @click="linkTo(submenu, router)"
                  >
                    <div class="menu__icon">
                      <!-- <ActivityIcon /> -->
                      <CircleIcon class="w-2" />
                    </div>
                    <div class="menu__title">
                      {{ $t(submenu.title) }}
                      <div
                        v-if="submenu.submenu"
                        class="menu__sub-icon"
                        :class="{
                          'rotate-180 transform': submenu.activeDropdown,
                        }"
                      >
                        <ChevronDownIcon />
                      </div>
                    </div>
                  </a>
                  <!-- BEGIN: Third Child -->
                  <transition @enter="enter" @leave="leave">
                    <ul v-if="submenu.submenu && submenu.activeDropdown">
                      <li
                        v-for="(lastSubMenu, lastSubMenuKey) in submenu.submenu"
                        :key="lastSubMenuKey"
                      >
                        <a
                          href="javascript:;"
                          class="menu"
                          :class="{ 'menu--active font-bold': lastSubMenu.active }"
                          @click="linkTo(lastSubMenu, router)"
                        >
                          <div class="menu__icon">
                            <!-- <ZapIcon /> -->
                            <CircleIcon class="w-2" />
                          </div>
                          <div class="menu__title">
                            {{ $t(lastSubMenu.title) }}
                          </div>
                        </a>
                      </li>
                    </ul>
                  </transition>
                  <!-- END: Third Child -->
                </li>
              </ul>
            </transition>
            <!-- END: Second Child -->
          </li>
        </template>
        <li :key="9999" class="menu__devider my-2 md:my-4"></li>
        <li>
          <a href="javascript:;" class="menu !text-red-500" @click="showLogoutModal">
            <div class="menu__icon">
              <LogOutIcon class="w-6" />
            </div>
            <div class="menu__title">
              {{ $t('action.logout') }}
            </div>
          </a>
        </li>
        <!-- END: First Child -->
      </ul>
    </div>
  </div>
  <Modal :show="logoutModal" @hidden="logoutModal = false">
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="my-6 text-xl">{{ $t('confirmation.logout') }}</div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          class="btn btn-outline-primary mr-2 w-24"
          @click="logoutModal = false"
        >
          {{ $t('button.cancel') }}
        </button>
        <button type="button" class="btn btn-danger w-24" @click="logout">
          {{ $t('button.logout') }}
        </button>
      </div>
    </ModalBody>
  </Modal>
  <!-- END: Mobile Menu -->
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helper as $h } from '@/utils/helper'
import {
  useSideMenuStore,
  useSideMenuCommissionerStore,
  // useSideMenuStaffStore,
  // useSideMenuHeadOperationStore,
  useSideMenuHeadStoreStore,
  useSideMenuAdminStoreStore,
  useSideMenuCashierStore,
  useSideMenuSalesStore,
  useSideMenuPickerPackerStore,
  useSideMenuDriverStore,
} from '@/stores/side-menu'
import {
  activeMobileMenu,
  toggleMobileMenu,
  linkTo,
  enter,
  leave,
} from './index'
import { nestedMenu } from '@/layouts/side-menu'
import dom from '@left4code/tw-starter/dist/js/dom'
import TopBar from '@/components/top-bar/Main.vue'
import SimpleBar from 'simplebar'
import ImageLoader from '@/components/image-loader/Main.vue'

import globalMixin from '@/mixins/global.js'

import GromuraIcon from '@/assets/svg/gromura-icon-dynamic.svg'

import HomeMenuIcon from '@/assets/svg/menu/home.svg'
import ReportMenuIcon from '@/assets/svg/menu/report.svg'
import AnalyticMenuIcon from '@/assets/svg/menu/analytic.svg'
import ProfileMenuIcon from '@/assets/svg/menu/user.svg'
import StoreMenuIcon from '@/assets/svg/menu/store.svg'
import WarehouseMenuIcon from '@/assets/svg/menu/warehouse.svg'
import { BanknotesIcon } from '@heroicons/vue/24/solid'

export default {
  components: {
    TopBar,
    SimpleBar,
    nestedMenu,
    ImageLoader,

    GromuraIcon,
    HomeMenuIcon,
    ReportMenuIcon,
    AnalyticMenuIcon,
    ProfileMenuIcon,
    StoreMenuIcon,
    WarehouseMenuIcon,

    BanknotesIcon,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const activeUser = ref({})
    const roleUser = ref({})
    const formattedMenu = ref([])

    const sideMenuStore = useSideMenuStore()
    const sideMenu = computed(() => nestedMenu(sideMenuStore.menu, route))

    const sideMenuCommissionerStore = useSideMenuCommissionerStore()
    const sideMenuCommissioner = computed(() =>
      nestedMenu(sideMenuCommissionerStore.menu, route)
    )

    // const sideMenuStaffStore = useSideMenuStaffStore()
    // const sideMenuStaff = computed(() =>
    //   nestedMenu(sideMenuStaffStore.menu, route)
    // )

    // const sideMenuHeadOperationStore = useSideMenuHeadOperationStore()
    // const sideMenuHeadOperation = computed(() =>
    //   nestedMenu(sideMenuHeadOperationStore.menu, route)
    // )

    const sideMenuHeadStoreStore = useSideMenuHeadStoreStore()
    const sideMenuHeadStore = computed(() =>
      nestedMenu(sideMenuHeadStoreStore.menu, route)
    )

    const sideMenuAdminStoreStore = useSideMenuAdminStoreStore()
    const sideMenuAdminStore = computed(() =>
      nestedMenu(sideMenuAdminStoreStore.menu, route)
    )

    const sideMenuCashierStore = useSideMenuCashierStore()
    const sideMenuCashier = computed(() =>
      nestedMenu(sideMenuCashierStore.menu, route)
    )

    const sideMenuSalesStore = useSideMenuSalesStore()
    const sideMenuSales = computed(() =>
      nestedMenu(sideMenuSalesStore.menu, route)
    )

    const sideMenuPickerPackerStore = useSideMenuPickerPackerStore()
    const sideMenuPickerPacker = computed(() =>
      nestedMenu(sideMenuPickerPackerStore.menu, route)
    )

    const sideMenuDriverStore = useSideMenuDriverStore()
    const sideMenuDriver = computed(() =>
      nestedMenu(sideMenuDriverStore.menu, route)
    )

    watch(
      computed(() => route.path),
      () => {
        // formattedMenu.value = $h.toRaw(mobileMenu.value)
        setActiveMenu()
      }
    )

    onMounted(async () => {
      if (dom('.mobile-menu .scrollable').length) {
        new SimpleBar(dom('.mobile-menu .scrollable')[0])
      }
      // formattedMenu.value = $h.toRaw(mobileMenu.value)
      setActiveMenu()
    })

    const setActiveMenu = () => {
      activeUser.value = store.getters['auth/activeUser']

      if (activeUser.value !== null) {
        if (activeUser.value.role_codes.length > 0) {
          roleUser.value = activeUser.value.role_codes
        }
      }

      // if (roleUser.value.code === 'SUPERADMIN') {
      //   formattedMenu.value = $h.toRaw(sideMenu.value)
      // } else {
      //   formattedMenu.value = $h.toRaw(sideMenuStaff.value)
      // }
      // if (roleUser.value.includes('HEAD_OPERATION')) {
      //   formattedMenu.value = $h.toRaw(sideMenuHeadOperation.value)
      // } else 
      if (roleUser.value.includes('HEAD_STORE')) {
        formattedMenu.value = $h.toRaw(sideMenuHeadStore.value)
      } else if (roleUser.value.includes('ADMIN_STORE')) {
        formattedMenu.value = $h.toRaw(sideMenuAdminStore.value)
      } else if (roleUser.value.includes('CASHIER')) {
        formattedMenu.value = $h.toRaw(sideMenuCashier.value)
      } else if (roleUser.value.includes('SALES')) {
        formattedMenu.value = $h.toRaw(sideMenuSales.value)
      } else if (roleUser.value.includes('PICKER_PACKER')) {
        formattedMenu.value = $h.toRaw(sideMenuPickerPacker.value)
      } else if (roleUser.value.includes('DRIVER')) {
        formattedMenu.value = $h.toRaw(sideMenuDriver.value)
      } else if (roleUser.value.includes('COMMISSIONER')) {
        formattedMenu.value = $h.toRaw(sideMenuCommissioner.value)
      } else {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
    }

    return {
      t,
      route,
      router,
      formattedMenu,
      sideMenuStore,
      sideMenu,
      activeUser,
      // sideMenuStaffStore,
      // sideMenuStaff,
      // sideMenuHeadOperationStore,
      // sideMenuHeadOperation,
      sideMenuHeadStoreStore,
      sideMenuHeadStore,
      sideMenuAdminStoreStore,
      sideMenuAdminStore,
      sideMenuCashierStore,
      sideMenuCashier,
      sideMenuSalesStore,
      sideMenuSales,
      sideMenuPickerPackerStore,
      sideMenuPickerPacker,
      sideMenuDriverStore,
      sideMenuDriver,
      setActiveMenu,
      activeMobileMenu,
      toggleMobileMenu,
      linkTo,
      enter,
      leave,
    }
  },
  data() {
    return {
      logoutModal: false,
      breadcumbName: '',
    }
  },
  methods: {
    showLogoutModal() {
      this.logoutModal = true
    },
    logout() {
      this.toggleMobileMenu()
      this.$store.dispatch('auth/logout').then((response) => {
        this.logoutModal = false
        this.isLoggedIn = false

        this.$router.push({
          name: 'login',
          params: {
            slug: this.$route.params.slug,
          },
          query: {
            lang: this.$route.query.lang,
          },
        })
      })
    },
    openURL(pageName) {
      this.$router.push({
        name: pageName,
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
  },
}
</script>
