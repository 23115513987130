<template>
  <div class="intro-y mt-8 flex items-center">
    <h2 class="mr-auto text-lg font-medium">Profile Layout</h2>
  </div>
  <div class="mt-5 grid grid-cols-12 gap-6">
    <!-- BEGIN: Profile Menu -->
    <div
      class="col-span-12 flex flex-col-reverse lg:col-span-4 lg:block 2xl:col-span-3"
    >
      <div class="intro-y box mt-5 lg:mt-0">
        <div class="relative flex items-center p-5">
          <div class="image-fit h-12 w-12">
            <img
              alt="Gromura - SCM"
              class="rounded-full"
              :src="$f()[0].users[0].image"
            />
          </div>
          <div class="ml-4 mr-auto">
            <div class="text-base font-medium">{{ $f()[0].users[0].name }}</div>
            <div class="text-slate-500">{{ $f()[0].jobs[0] }}</div>
          </div>
          <Dropdown>
            <DropdownToggle tag="a" class="block h-5 w-5" href="javascript:;">
              <MoreHorizontalIcon class="h-5 w-5 text-slate-500" />
            </DropdownToggle>
            <DropdownMenu class="w-56">
              <DropdownContent>
                <DropdownHeader>Export Options</DropdownHeader>
                <DropdownDivider />
                <DropdownItem>
                  <ActivityIcon class="mr-2 h-4 w-4" />
                  English
                </DropdownItem>
                <DropdownItem>
                  <BoxIcon class="mr-2 h-4 w-4" />
                  Indonesia
                  <div
                    class="ml-auto rounded-full bg-danger px-1 text-xs text-white"
                  >
                    10
                  </div>
                </DropdownItem>
                <DropdownItem>
                  <LayoutIcon class="mr-2 h-4 w-4" />
                  English
                </DropdownItem>
                <DropdownItem>
                  <SidebarIcon class="mr-2 h-4 w-4" />
                  Indonesia
                </DropdownItem>
                <DropdownDivider />
                <DropdownFooter>
                  <button type="button" class="btn bg-gradient-to-r from-teal-700 to-primary text-white py-1 px-2">
                    Settings
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary ml-auto py-1 px-2"
                  >
                    View Profile
                  </button>
                </DropdownFooter>
              </DropdownContent>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div class="border-t border-slate-200/60 p-5 dark:border-darkmode-400">
          <a class="flex items-center font-medium text-primary" href="">
            <ActivityIcon class="mr-2 h-4 w-4" /> Personal Information
          </a>
          <a class="mt-5 flex items-center" href="">
            <BoxIcon class="mr-2 h-4 w-4" /> Account Settings
          </a>
          <a class="mt-5 flex items-center" href="">
            <LockIcon class="mr-2 h-4 w-4" /> Change Password
          </a>
          <a class="mt-5 flex items-center" href="">
            <SettingsIcon class="mr-2 h-4 w-4" /> User Settings
          </a>
        </div>
        <div class="border-t border-slate-200/60 p-5 dark:border-darkmode-400">
          <a class="flex items-center" href="">
            <ActivityIcon class="mr-2 h-4 w-4" /> Email Settings
          </a>
          <a class="mt-5 flex items-center" href="">
            <BoxIcon class="mr-2 h-4 w-4" /> Saved Credit Cards
          </a>
          <a class="mt-5 flex items-center" href="">
            <LockIcon class="mr-2 h-4 w-4" /> Social Networks
          </a>
          <a class="mt-5 flex items-center" href="">
            <SettingsIcon class="mr-2 h-4 w-4" /> Tax Information
          </a>
        </div>
        <div
          class="flex border-t border-slate-200/60 p-5 dark:border-darkmode-400"
        >
          <button type="button" class="btn bg-gradient-to-r from-teal-700 to-primary text-white py-1 px-2">
            New Group
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary ml-auto py-1 px-2"
          >
            New Quick Link
          </button>
        </div>
      </div>
      <div class="intro-y box mt-5 bg-primary p-5 text-white">
        <div class="flex items-center">
          <div class="text-lg font-medium">Important Update</div>
          <div
            class="ml-auto rounded-md bg-white px-1 text-xs text-slate-700 dark:bg-primary dark:text-white"
          >
            New
          </div>
        </div>
        <div class="mt-4">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s.
        </div>
        <div class="mt-5 flex font-medium">
          <button
            type="button"
            class="btn border-white py-1 px-2 text-white dark:border-darkmode-400 dark:bg-darkmode-400 dark:text-slate-300"
          >
            Take Action
          </button>
          <button
            type="button"
            class="btn ml-auto border-transparent py-1 px-2 text-white dark:border-transparent"
          >
            Dismiss
          </button>
        </div>
      </div>
    </div>
    <!-- END: Profile Menu -->
    <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Daily Sales -->
        <div class="intro-y box col-span-12 2xl:col-span-6">
          <div
            class="flex items-center border-b border-slate-200/60 px-5 py-5 dark:border-darkmode-400 sm:py-3"
          >
            <h2 class="mr-auto text-base font-medium">Daily Sales</h2>
            <Dropdown class="ml-auto sm:hidden">
              <DropdownToggle tag="a" class="block h-5 w-5" href="javascript:;">
                <MoreHorizontalIcon class="h-5 w-5 text-slate-500" />
              </DropdownToggle>
              <DropdownMenu class="w-40">
                <DropdownContent>
                  <DropdownItem>
                    <FileIcon class="mr-2 h-4 w-4" /> Download Excel
                  </DropdownItem>
                </DropdownContent>
              </DropdownMenu>
            </Dropdown>
            <button class="btn btn-outline-secondary hidden sm:flex">
              <FileIcon class="mr-2 h-4 w-4" /> Download Excel
            </button>
          </div>
          <div class="p-5">
            <div class="relative flex items-center">
              <div class="image-fit h-12 w-12 flex-none">
                <img
                  alt="Gromura - SCM"
                  class="rounded-full"
                  :src="$f()[0].users[0].image"
                />
              </div>
              <div class="ml-4 mr-auto">
                <a href="" class="font-medium">{{ $f()[0].users[0].name }}</a>
                <div class="mr-5 text-slate-500 sm:mr-5">
                  CAP PANDA CINCAU SELASIH
                </div>
              </div>
              <div class="font-medium text-slate-600 dark:text-slate-500">
                +Rp19
              </div>
            </div>
            <div class="relative mt-5 flex items-center">
              <div class="image-fit h-12 w-12 flex-none">
                <img
                  alt="Gromura - SCM"
                  class="rounded-full"
                  :src="$f()[1].users[0].image"
                />
              </div>
              <div class="ml-4 mr-auto">
                <a href="" class="font-medium">{{ $f()[1].users[0].name }}</a>
                <div class="mr-5 text-slate-500 sm:mr-5">
                  OVALE NATURAL H2O MICELLAR WATER
                </div>
              </div>
              <div class="font-medium text-slate-600 dark:text-slate-500">
                +Rp25
              </div>
            </div>
            <div class="relative mt-5 flex items-center">
              <div class="image-fit h-12 w-12 flex-none">
                <img
                  alt="Gromura - SCM"
                  class="rounded-full"
                  :src="$f()[2].users[0].image"
                />
              </div>
              <div class="ml-4 mr-auto">
                <a href="" class="font-medium">{{ $f()[2].users[0].name }}</a>
                <div class="mr-5 text-slate-500 sm:mr-5">
                  ELLIPS 5IN1 HAIR VITAMIN MILKSHAKE
                </div>
              </div>
              <div class="font-medium text-slate-600 dark:text-slate-500">
                +Rp21
              </div>
            </div>
          </div>
        </div>
        <!-- END: Daily Sales -->
        <!-- BEGIN: Announcement -->
        <div class="intro-y box col-span-12 2xl:col-span-6">
          <div
            class="flex items-center border-b border-slate-200/60 px-5 py-3 dark:border-darkmode-400"
          >
            <h2 class="mr-auto text-base font-medium">Announcement</h2>
            <button
              class="tiny-slider-navigator btn btn-outline-secondary mr-2 px-2"
              @click="prevAnnouncement"
            >
              <ChevronLeftIcon class="h-4 w-4" />
            </button>
            <button
              class="tiny-slider-navigator btn btn-outline-secondary px-2"
              @click="nextAnnouncement"
            >
              <ChevronRightIcon class="h-4 w-4" />
            </button>
          </div>
          <TinySlider ref-key="announcementRef" class="py-5">
            <div class="px-5">
              <div class="text-lg font-medium">Gromura - SCM</div>
              <div class="mt-2 text-slate-600 dark:text-slate-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever. <br /><br />
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry since the 1500s.
              </div>
              <div class="mt-5 flex items-center">
                <div
                  class="rounded bg-primary/10 px-3 py-2 font-medium text-primary dark:bg-darkmode-400 dark:text-slate-300"
                >
                  02 June 2021
                </div>
                <button class="btn btn-secondary ml-auto">View Details</button>
              </div>
            </div>
            <div class="px-5">
              <div class="text-lg font-medium">Gromura - SCM</div>
              <div class="mt-2 text-slate-600 dark:text-slate-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever. <br /><br />
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry since the 1500s.
              </div>
              <div class="mt-5 flex items-center">
                <div
                  class="rounded bg-primary/10 px-3 py-2 font-medium text-primary dark:bg-darkmode-400 dark:text-slate-300"
                >
                  02 June 2021
                </div>
                <button class="btn btn-secondary ml-auto">View Details</button>
              </div>
            </div>
            <div class="px-5">
              <div class="text-lg font-medium">Gromura - SCM</div>
              <div class="mt-2 text-slate-600 dark:text-slate-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever. <br /><br />
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry since the 1500s.
              </div>
              <div class="mt-5 flex items-center">
                <div
                  class="rounded bg-primary/10 px-3 py-2 font-medium text-primary dark:bg-darkmode-400 dark:text-slate-300"
                >
                  02 June 2021
                </div>
                <button class="btn btn-secondary ml-auto">View Details</button>
              </div>
            </div>
          </TinySlider>
        </div>
        <!-- END: Announcement -->
        <!-- BEGIN: Projects -->
        <div class="intro-y box col-span-12 2xl:col-span-6">
          <div
            class="flex items-center border-b border-slate-200/60 px-5 py-3 dark:border-darkmode-400"
          >
            <h2 class="mr-auto text-base font-medium">Projects</h2>
            <button
              class="tiny-slider-navigator btn btn-outline-secondary mr-2 px-2"
              @click="prevNewProjects"
            >
              <ChevronLeftIcon class="h-4 w-4" />
            </button>
            <button
              class="tiny-slider-navigator btn btn-outline-secondary px-2"
              @click="nextNewProjects"
            >
              <ChevronRightIcon class="h-4 w-4" />
            </button>
          </div>
          <TinySlider ref-key="newProjectsRef" class="py-5">
            <div class="px-5">
              <div class="text-lg font-medium">Gromura - SCM</div>
              <div class="mt-2 text-slate-600 dark:text-slate-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </div>
              <div class="mt-5">
                <div class="flex text-slate-500">
                  <div class="mr-auto">Pending Tasks</div>
                  <div>20%</div>
                </div>
                <div class="progress mt-2 h-1">
                  <div
                    class="progress-bar w-1/2 bg-primary"
                    role="progressbar"
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <div class="px-5">
              <div class="text-lg font-medium">Gromura - SCM</div>
              <div class="mt-2 text-slate-600 dark:text-slate-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </div>
              <div class="mt-5">
                <div class="flex text-slate-500">
                  <div class="mr-auto">Pending Tasks</div>
                  <div>20%</div>
                </div>
                <div class="progress mt-2 h-1">
                  <div
                    class="progress-bar w-1/2 bg-primary"
                    role="progressbar"
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <div class="px-5">
              <div class="text-lg font-medium">Gromura - SCM</div>
              <div class="mt-2 text-slate-600 dark:text-slate-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s.
              </div>
              <div class="mt-5">
                <div class="flex text-slate-500">
                  <div class="mr-auto">Pending Tasks</div>
                  <div>20%</div>
                </div>
                <div class="progress mt-2 h-1">
                  <div
                    class="progress-bar w-1/2 bg-primary"
                    role="progressbar"
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </TinySlider>
        </div>
        <!-- END: Projects -->
        <!-- BEGIN: Today Schedules -->
        <div class="intro-y box col-span-12 2xl:col-span-6">
          <div
            class="flex items-center border-b border-slate-200/60 px-5 py-3 dark:border-darkmode-400"
          >
            <h2 class="mr-auto text-base font-medium">Today Schedules</h2>
            <button
              class="tiny-slider-navigator btn btn-outline-secondary mr-2 px-2"
              @click="prevTodaySchedules"
            >
              <ChevronLeftIcon class="h-4 w-4" />
            </button>
            <button
              class="tiny-slider-navigator btn btn-outline-secondary px-2"
              @click="nextTodaySchedules"
            >
              <ChevronRightIcon class="h-4 w-4" />
            </button>
          </div>
          <TinySlider ref-key="todaySchedulesRef" class="py-5">
            <div class="px-5 text-center sm:text-left">
              <div class="text-lg font-medium">Meeting Dengan Semua Sales</div>
              <div class="mt-2 text-slate-600 dark:text-slate-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry
              </div>
              <div class="mt-2">11:15AM - 12:30PM</div>
              <div class="mt-5 flex flex-col items-center sm:flex-row">
                <div class="flex items-center text-slate-500">
                  <MapPinIcon class="mr-2 hidden h-4 w-4 sm:block" />
                  1396 Pooh Bear Lane, New Market
                </div>
                <button
                  class="btn btn-secondary sm:mt-0sm:ml-auto mt-3 mt-3 py-1 px-2 sm:ml-auto sm:mt-0"
                >
                  View On Map
                </button>
              </div>
            </div>
            <div class="px-5 text-center sm:text-left">
              <div class="text-lg font-medium">Meeting Dengan Semua Sales</div>
              <div class="mt-2 text-slate-600 dark:text-slate-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry
              </div>
              <div class="mt-2">11:15AM - 12:30PM</div>
              <div class="mt-5 flex flex-col items-center sm:flex-row">
                <div class="flex items-center text-slate-500">
                  <MapPinIcon class="mr-2 hidden h-4 w-4 sm:block" />
                  1396 Pooh Bear Lane, New Market
                </div>
                <button
                  class="btn btn-secondary sm:mt-0sm:ml-auto mt-3 mt-3 py-1 px-2 sm:ml-auto sm:mt-0"
                >
                  View On Map
                </button>
              </div>
            </div>
            <div class="px-5 text-center sm:text-left">
              <div class="text-lg font-medium">Meeting Dengan Semua Sales</div>
              <div class="mt-2 text-slate-600 dark:text-slate-500">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry
              </div>
              <div class="mt-2">11:15AM - 12:30PM</div>
              <div class="mt-5 flex flex-col items-center sm:flex-row">
                <div class="flex items-center text-slate-500">
                  <MapPinIcon class="mr-2 hidden h-4 w-4 sm:block" />
                  1396 Pooh Bear Lane, New Market
                </div>
                <button
                  class="btn btn-secondary sm:mt-0sm:ml-auto mt-3 mt-3 py-1 px-2 sm:ml-auto sm:mt-0"
                >
                  View On Map
                </button>
              </div>
            </div>
          </TinySlider>
        </div>
        <!-- END: Today Schedules -->
        <!-- BEGIN: Top Products -->
        <TabGroup class="intro-y box col-span-12 2xl:col-span-6">
          <div
            class="flex items-center border-b border-slate-200/60 p-5 dark:border-darkmode-400"
          >
            <h2 class="mr-auto text-base font-medium">Top Products</h2>
            <Dropdown class="ml-auto">
              <DropdownToggle tag="a" class="block h-5 w-5" href="javascript:;">
                <MoreHorizontalIcon class="h-5 w-5 text-slate-500" />
              </DropdownToggle>
              <DropdownMenu class="w-40">
                <DropdownContent>
                  <DropdownItem>
                    <Edit2Icon class="mr-2 h-4 w-4" /> New Chat
                  </DropdownItem>
                  <DropdownItem>
                    <TrashIcon class="mr-2 h-4 w-4" /> Delete
                  </DropdownItem>
                </DropdownContent>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div class="p-5">
            <TabList class="nav-boxed-tabs flex-col justify-center sm:flex-row">
              <Tab
                :fullWidth="false"
                class="mb-2 w-full cursor-pointer py-2 px-0 text-center sm:mx-2 sm:mb-0 sm:w-20"
              >
                <BoxIcon class="mx-auto mb-2 block h-6 w-6" /> Tangerang
              </Tab>
              <Tab
                :fullWidth="false"
                class="mb-2 w-full cursor-pointer py-2 px-0 text-center sm:mx-2 sm:mb-0 sm:w-20"
              >
                <InboxIcon class="mx-auto mb-2 block h-6 w-6" />
                Bekasi
              </Tab>
              <Tab
                :fullWidth="false"
                class="mb-2 w-full cursor-pointer py-2 px-0 text-center sm:mx-2 sm:mb-0 sm:w-20"
              >
                <ActivityIcon class="mx-auto mb-2 block h-6 w-6" />
                Bogor
              </Tab>
            </TabList>
            <TabPanels class="mt-8">
              <TabPanel>
                <div class="flex flex-col items-center sm:flex-row">
                  <div class="mr-auto">
                    <a href="" class="font-medium"
                      >OVALE NATURAL H2O MICELLAR WATER</a
                    >
                    <div class="mt-1 text-slate-500">Kecantikan</div>
                  </div>
                  <div class="mt-3 flex w-full items-center sm:mt-0 sm:w-auto">
                    <div class="mr-5 rounded bg-success/20 px-2 text-success">
                      +20%
                    </div>
                    <div class="progress mt-2 h-1 sm:w-40">
                      <div
                        class="progress-bar w-1/2 bg-primary"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="mt-5 flex flex-col items-center sm:flex-row">
                  <div class="mr-auto">
                    <a href="" class="font-medium"
                      >ELLIPS 5IN1 HAIR VITAMIN MILKSHAKE</a
                    >
                    <div class="mt-1 text-slate-500">Kecantikan</div>
                  </div>
                  <div class="mt-3 flex w-full items-center sm:mt-0 sm:w-auto">
                    <div class="mr-5 rounded bg-success/20 px-2 text-success">
                      +55%
                    </div>
                    <div class="progress mt-2 h-1 sm:w-40">
                      <div
                        class="progress-bar w-2/3 bg-primary"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="mt-5 flex flex-col items-center sm:flex-row">
                  <div class="mr-auto">
                    <a href="" class="font-medium"
                      >Ovale Facial Mask Lemon Sachet</a
                    >
                    <div class="mt-1 text-slate-500">Kecantikan</div>
                  </div>
                  <div class="mt-3 flex w-full items-center sm:mt-0 sm:w-auto">
                    <div class="mr-5 rounded bg-success/20 px-2 text-success">
                      +40%
                    </div>
                    <div class="progress mt-2 h-1 sm:w-40">
                      <div
                        class="progress-bar w-3/4 bg-primary"
                        role="progressbar"
                        aria-valuenow="0"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabPanels>
          </div>
        </TabGroup>
        <!-- END: Top Products -->
        <!-- BEGIN: Work In Progress -->
        <TabGroup class="intro-y box col-span-12 2xl:col-span-6">
          <div
            class="flex items-center border-b border-slate-200/60 px-5 py-5 dark:border-darkmode-400 sm:py-0"
          >
            <h2 class="mr-auto text-base font-medium">Work In Progress</h2>
            <Dropdown class="ml-auto sm:hidden">
              <DropdownToggle tag="a" class="block h-5 w-5" href="javascript:;">
                <MoreHorizontalIcon class="h-5 w-5 text-slate-500" />
              </DropdownToggle>
              <DropdownMenu class="w-40">
                <DropdownContent tag="div">
                  <TabList class="block">
                    <Tab :fullWidth="false" class="dropdown-item cursor-pointer"
                      >New</Tab
                    >
                    <Tab :fullWidth="false" class="dropdown-item cursor-pointer"
                      >Last Week</Tab
                    >
                  </TabList>
                </DropdownContent>
              </DropdownMenu>
            </Dropdown>
            <TabList class="nav-link-tabs ml-auto hidden w-auto sm:flex">
              <Tab :fullWidth="false" class="cursor-pointer py-5">New</Tab>
              <Tab :fullWidth="false" class="cursor-pointer py-5"
                >Last Week</Tab
              >
            </TabList>
          </div>
          <div class="p-5">
            <TabPanels>
              <TabPanel>
                <div>
                  <div class="flex">
                    <div class="mr-auto">Pending Tasks</div>
                    <div>20%</div>
                  </div>
                  <div class="progress mt-2 h-1">
                    <div
                      class="progress-bar w-1/2 bg-primary"
                      role="progressbar"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div class="mt-5">
                  <div class="flex">
                    <div class="mr-auto">Completed Tasks</div>
                    <div>2 / 20</div>
                  </div>
                  <div class="progress mt-2 h-1">
                    <div
                      class="progress-bar w-1/4 bg-primary"
                      role="progressbar"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div class="mt-5">
                  <div class="flex">
                    <div class="mr-auto">Tasks In Progress</div>
                    <div>42</div>
                  </div>
                  <div class="progress mt-2 h-1">
                    <div
                      class="progress-bar w-3/4 bg-primary"
                      role="progressbar"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <div class="mt-5">
                  <div class="flex">
                    <div class="mr-auto">Tasks In Review</div>
                    <div>70%</div>
                  </div>
                  <div class="progress mt-2 h-1">
                    <div
                      class="progress-bar w-4/5 bg-primary"
                      role="progressbar"
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
                <a href="" class="btn btn-secondary mx-auto mt-5 block w-40"
                  >View More Details</a
                >
              </TabPanel>
            </TabPanels>
          </div>
        </TabGroup>
        <!-- END: Work In Progress -->
        <!-- BEGIN: Latest Tasks -->
        <TabGroup class="intro-y box col-span-12 2xl:col-span-6">
          <div
            class="flex items-center border-b border-slate-200/60 px-5 py-5 dark:border-darkmode-400 sm:py-0"
          >
            <h2 class="mr-auto text-base font-medium">Latest Tasks</h2>
            <Dropdown class="ml-auto sm:hidden">
              <DropdownToggle tag="a" class="block h-5 w-5" href="javascript:;">
                <MoreHorizontalIcon class="h-5 w-5 text-slate-500" />
              </DropdownToggle>
              <DropdownMenu class="w-40">
                <DropdownContent tag="div">
                  <TabList class="block">
                    <Tab :fullWidth="false" class="dropdown-item cursor-pointer"
                      >New</Tab
                    >
                    <Tab :fullWidth="false" class="dropdown-item cursor-pointer"
                      >Last Week</Tab
                    >
                  </TabList>
                </DropdownContent>
              </DropdownMenu>
            </Dropdown>
            <TabList class="nav-link-tabs ml-auto hidden w-auto sm:flex">
              <Tab :fullWidth="false" class="cursor-pointer py-5">New</Tab>
              <Tab :fullWidth="false" class="cursor-pointer py-5"
                >Last Week</Tab
              >
            </TabList>
          </div>
          <div class="p-5">
            <TabPanels>
              <TabPanel>
                <div class="flex items-center">
                  <div
                    class="border-l-2 border-primary pl-4 dark:border-primary"
                  >
                    <a href="" class="font-medium">Create New Campaign</a>
                    <div class="text-slate-500">10:00 AM</div>
                  </div>
                  <div class="form-check form-switch ml-auto">
                    <input class="form-check-input" type="checkbox" />
                  </div>
                </div>
                <div class="mt-5 flex items-center">
                  <div
                    class="border-l-2 border-primary pl-4 dark:border-primary"
                  >
                    <a href="" class="font-medium">Meeting With Client</a>
                    <div class="text-slate-500">02:00 PM</div>
                  </div>
                  <div class="form-check form-switch ml-auto">
                    <input class="form-check-input" type="checkbox" />
                  </div>
                </div>
                <div class="mt-5 flex items-center">
                  <div
                    class="border-l-2 border-primary pl-4 dark:border-primary"
                  >
                    <a href="" class="font-medium">Create New Repository</a>
                    <div class="text-slate-500">04:00 PM</div>
                  </div>
                  <div class="form-check form-switch ml-auto">
                    <input class="form-check-input" type="checkbox" />
                  </div>
                </div>
                <div class="mt-5 flex items-center">
                  <div
                    class="border-l-2 border-primary pl-4 dark:border-primary"
                  >
                    <a href="" class="font-medium">Meeting With Client</a>
                    <div class="text-slate-500">10:00 AM</div>
                  </div>
                  <div class="form-check form-switch ml-auto">
                    <input class="form-check-input" type="checkbox" />
                  </div>
                </div>
                <div class="mt-5 flex items-center">
                  <div
                    class="border-l-2 border-primary pl-4 dark:border-primary"
                  >
                    <a href="" class="font-medium">Create New Repository</a>
                    <div class="text-slate-500">11:00 PM</div>
                  </div>
                  <div class="form-check form-switch ml-auto">
                    <input class="form-check-input" type="checkbox" />
                  </div>
                </div>
              </TabPanel>
            </TabPanels>
          </div>
        </TabGroup>
        <!-- END: Latest Tasks -->
        <!-- BEGIN: General Statistics -->
        <div class="intro-y box col-span-12 2xl:col-span-6">
          <div
            class="flex items-center border-b border-slate-200/60 px-5 py-5 dark:border-darkmode-400 sm:py-3"
          >
            <h2 class="mr-auto text-base font-medium">General Statistics</h2>
            <Dropdown class="ml-auto">
              <DropdownToggle
                tag="a"
                class="block h-5 w-5 sm:hidden"
                href="javascript:;"
              >
                <MoreHorizontalIcon class="h-5 w-5 text-slate-500" />
              </DropdownToggle>
              <DropdownToggle
                class="btn btn-outline-secondary hidden font-normal sm:flex"
              >
                Export <ChevronDownIcon class="ml-2 h-4 w-4" />
              </DropdownToggle>
              <DropdownMenu class="w-40">
                <DropdownContent>
                  <DropdownHeader>Export Tools</DropdownHeader>
                  <DropdownDivider />
                  <DropdownItem>
                    <PrinterIcon class="mr-2 h-4 w-4" />
                    Print
                  </DropdownItem>
                  <DropdownItem>
                    <ExternalLinkIcon class="mr-2 h-4 w-4" />
                    Excel
                  </DropdownItem>
                  <DropdownItem>
                    <FileTextIcon class="mr-2 h-4 w-4" />
                    CSV
                  </DropdownItem>
                  <DropdownItem>
                    <ArchiveIcon class="mr-2 h-4 w-4" />
                    PDF
                  </DropdownItem>
                </DropdownContent>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div class="p-5">
            <div class="flex flex-col items-center sm:flex-row">
              <div class="mr-auto flex flex-wrap sm:flex-nowrap">
                <div class="mr-5 mb-1 flex items-center sm:mb-0">
                  <div class="mr-3 h-2 w-2 rounded-full bg-pending"></div>
                  <span>Author Sales</span>
                </div>
                <div class="mr-5 mb-1 flex items-center sm:mb-0">
                  <div class="mr-3 h-2 w-2 rounded-full bg-primary"></div>
                  <span>Product Profit</span>
                </div>
              </div>
              <Dropdown class="mt-3 mr-auto sm:mt-0 sm:mr-0">
                <DropdownToggle class="btn btn-outline-secondary font-normal">
                  Filter by Month
                  <ChevronDownIcon class="ml-2 h-4 w-4" />
                </DropdownToggle>
                <DropdownMenu class="w-40">
                  <DropdownContent class="h-32 overflow-y-auto">
                    <DropdownItem>January</DropdownItem>
                    <DropdownItem>February</DropdownItem>
                    <DropdownItem>March</DropdownItem>
                    <DropdownItem>June</DropdownItem>
                    <DropdownItem>July</DropdownItem>
                  </DropdownContent>
                </DropdownMenu>
              </Dropdown>
            </div>
            <div class="report-chart mt-8">
              <ReportLineChart :height="212" />
            </div>
          </div>
        </div>
        <!-- END: General Statistics -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, provide } from 'vue'
import ReportLineChart from '@/components/report-line-chart/Main.vue'

const announcementRef = ref()
const newProjectsRef = ref()
const todaySchedulesRef = ref()

provide('bind[announcementRef]', (el) => {
  announcementRef.value = el
})

provide('bind[newProjectsRef]', (el) => {
  newProjectsRef.value = el
})

provide('bind[todaySchedulesRef]', (el) => {
  todaySchedulesRef.value = el
})

const prevAnnouncement = () => {
  const el = announcementRef.value
  el.tns.goTo('prev')
}
const nextAnnouncement = () => {
  const el = announcementRef.value
  el.tns.goTo('next')
}
const prevNewProjects = () => {
  const el = newProjectsRef.value
  el.tns.goTo('prev')
}
const nextNewProjects = () => {
  const el = newProjectsRef.value
  el.tns.goTo('next')
}
const prevTodaySchedules = () => {
  const el = todaySchedulesRef.value
  el.tns.goTo('prev')
}
const nextTodaySchedules = () => {
  const el = todaySchedulesRef.value
  el.tns.goTo('next')
}
</script>
