<template>
  <div class="box p-5">
    <div class="flex w-full mb-4">
      <span class="text-sm font-semibold">{{  title }}</span>
    </div>
    <div class="flex flex-col md:flex-row items-center justify-between">
      <div class="relative md:-ml-12 lg:ml-0">
        <DonutChart
          :data="[Number(data.transaction_r1), Number(data.transaction_r2), Number(data.transaction_r3)]"
          :labels="['R1', 'R2', 'R3']"
          :colors="[colors.info(0.9), colors.success(0.9), colors.danger(0.9)]"
          :height="200"
        />
        <div
          class="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-center"
        >
          <div class="text-xl font-medium 2xl:text-2xl">{{ data.transaction_total }}</div>
          <div class="mt-0.5 text-slate-500">Total Semua</div>
        </div>
      </div>
      <div class="w-full mt-4 md:mt-0 md:-ml-6 lg:ml-0 justify-center">
        <div class="flex justify-between md:justify-start items-center">
          <div class="flex w-20 items-center">
            <div class="mr-3 h-2 w-2 rounded-full bg-sky-600"></div>
            <span class="truncate">R1</span>
          </div>
          <span class="font-semibold">{{ data.transaction_r1 }}</span>
        </div>
        <div class="mt-4 flex justify-between md:justify-start items-center">
          <div class="flex w-20 items-center">
            <div class="mr-3 h-2 w-2 rounded-full bg-green-600"></div>
            <span class="truncate">R2</span>
          </div>
          <span class="font-semibold">{{ data.transaction_r2 }}</span>
        </div>
        <div class="mt-4 flex justify-between md:justify-start items-center">
          <div class="flex w-20 items-center">
            <div class="mr-3 h-2 w-2 rounded-full bg-red-600"></div>
            <span class="truncate">R3</span>
          </div>
          <span class="font-semibold">{{ data.transaction_r3 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { helper as $h } from '@/utils/helper'
import DonutChart from '@/components/donut-chart/Main.vue'
import { colors } from '@/utils/colors'

export default {
  name: 'CardReportChartTransaction',
  components: {
    DonutChart
  },
  props: {
    title: {
      type: String,
      default: null,
      required: true,
    },
    data: {
      total: Array,
      default: [],
      required: true,
    },
  },
  data: () => ({
  }),
  setup(props, { emit }) {
    return {
      colors
    }
  }
}
</script>
