<template>
  <h2 class="intro-y mt-2 md:mt-6 text-lg font-medium">List Pembelian</h2>
  <ButtonAdd
    v-if="$h.roleCanManage($h.managementRoles(), roleAccess)"
    @click="
      $router.push({
        path: '/purchase/order',
        params: {
          slug: $route.params.slug,
        },
        query: {
          lang: $route.query.lang,
        },
      })
    "
  />
  <div class="mt-0 grid grid-cols-12 gap-6 md:mt-5">
    <div class="intro-y flex col-span-12 mt-2 flex-wrap items-center">
      <div class="flex w-full md:w-auto">
        <div class="relative w-full text-slate-500 md:w-48 h-9.5">
          <input
            v-model="filterData.search"
            type="text"
            class="form-control box w-full pl-9 pr-3 md:w-56 text-ellipsis"
            placeholder="Cari pembelian..."
            @input="searchFilter()"
          />
          <SearchIcon
            class="absolute inset-y-0 left-0 my-auto ml-3 h-4 w-4 md:-mr-4"
          />
        </div>
        <button class="btn box ml-2 shadow-sm md:ml-10" @click="openFilter">
          <FilterIcon class="h-4 w-4" />
        </button>
      </div>
      <div class="mx-auto hidden text-slate-500 md:block"></div>
      <div
        v-if="$h.roleCanManage($h.superRoles(), roleAccess)"
        class="flex mt-3 items-center md:mt-0 xl:mt-0 xl:w-auto"
      >
        <!-- <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white mr-2 text-white">
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button> -->
        <ExportCSVComponent @export="download" />
      </div>
    </div>

    <TableComponent
      :show-loading="isLoading"
      :show-title="false"
      :show-search="false"
      :header="tableHeader"
      :meta-data="metaData"
      :is-not-empty="tableData.length > 0"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="(purchase, index) in tableData" :key="purchase.id" class="intro-x">
        <td class="!py-3.5">
          <div class="bg-gray-50 font-bold p-2 text-emerald-700 rounded-xl w-12 text-center">
            {{ (metaData.page !== 1 ? (metaData.page - 1) * metaData.take : 0) + index + 1 }}
          </div>
        </td>
        <td class="!py-3.5">
          {{ purchase.code }}
        </td>
        <td>
          <div class="flex items-center justify-center">
            <div
              v-for="item in purchase.items.slice(0, 3)"
              :key="item.id"
              class="image-fit zoom-in -ml-5 h-10 w-10"
            >
              <ImageLoader
                :image="
                  item.product.images !== undefined &&
                  item.product.images.length > 0
                    ? item.product.images[0].image
                    : null
                "
                :alt="item.product.name"
                rounded-class="rounded-md"
              />
            </div>
            <!-- <span class="text-xs">
              {{ getProductNames(item.items.slice(0, 3)) }},
            </span> -->
          </div>
        </td>

        <td class="text-center">
          {{ purchase.items.length }}
        </td>
        <td class="text-teal-600 font-semibold">
          <Currency
            :total-in-string="
              (purchase.total).toString()
            "
          />
        </td>
        <td class="text-danger font-semibold">
          <Currency :total-in-string="purchase.total_discount.toString()" />
        </td>
        <td class="text-xs text-slate-600 dark:text-slate-500">
          {{ purchase.supplier !== undefined ? purchase.supplier.name : '-' }}
        </td>
        <td>
          <div class="flex justify-center text-center capitalize cursor-pointer" @click="detail(purchase)">
            <Status :status="purchase.status" />
          </div>
        </td>
        <!-- <td class="text-center">
          {{ item.quantity }}
        </td> -->
        <!-- <td v-if="purchase.received_at !== null" class="text-center">
          {{ formattedDate(purchase.received_at) }}
        </td>
        <td v-else class="text-center">-</td> -->
        <td class="table-report__action">
          <div class="flex items-center justify-center">
            <template
              v-if="
                ['COMPLETED', 'CANCELLED'].includes(purchase.status) ===
                  false && $h.roleCanManage($h.superRoles(), roleAccess)
              "
            >
              <a
                class="flex mx-2 items-center text-green-600"
                href="javascript:;"
                @click="changeStatusForm(purchase)"
              >
                <EditIcon class="mr-1 h-4 w-4" /> Ubah Status
              </a>
              |
            </template>
            <template v-else>
              -
            </template>
            <!-- <template v-if="purchase.is_payed !== true">
              <a
                class="mx-2 flex items-center text-green-600"
                href="javascript:;"
                @click="detail(item)"
              >
                <DollarSignIcon class="mr-1 h-4 w-4" /> Bayar
              </a>
              |
            </template> -->
            <!-- <a
              class="flex mx-2 items-center text-secondary"
              href="javascript:;"
              @click="detail(purchase)"
            >
              <EyeIcon class="mr-1 h-4 w-4" /> Detail
            </a> -->
          </div>
        </td>
      </tr>
    </TableComponent>
    <!-- END: Data List -->

    <!-- <DetailPurchase :show-modal="detailPurchaseModal" :can-edit="['PENDING', 'PROCESS'].includes(purchase.status) &&
                        roleCanManage($h.superRoles(), roleAccess)" :purchase="purchase" @on-close="detailPurchaseModal = false" @on-error="onError" /> -->
  <DetailPurchase :show-modal="detailPurchaseModal" :can-edit="false" :purchase="purchase" @on-close="detailPurchaseModal = false" @on-error="onError" />


    <!-- ADD & EDIT MODAL -->
    <Modal
      size="modal-lg"
      :show="changeStatusModal"
      @hidden="changeStatusModal = false"
    >
      <ModalHeader>
        <div class="flex w-full justify-between">
          <div class="flex">
            <h2 class="text-base font-medium">Ubah Status</h2>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="flex w-full flex-col">
          <div class="w-full flex-col">
            <label class="form-label">Status</label>
            <select
              v-model="validate.status.$model"
              class="box form-select mt-1 w-full"
              placeholder="Pilih Status"
            >
              <option value="" disabled selected>
                {{ placeholderStatus }}
              </option>
              <option
                v-for="item in statusOption"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </option>
            </select>
            <template v-if="validate.status.$error">
              <div
                v-for="(error, index) in validate.status.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
            <!-- <div v-if="statusError === true" class="mt-2 text-xs text-danger">
              Status harus diisi
            </div> -->
          </div>
          <div
            v-if="['PAID', 'RECEIVED'].includes(formData.status)"
            class="mt-4 w-full"
          >
            <label class="form-label">Upload Dokumen</label>
            <div
              class="rounded-md border-2 border-dashed dark:border-darkmode-400"
              :class="files.length === 0 ? 'py-6' : 'pt-4'"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <div class="flex flex-wrap px-4">
                <div
                  v-for="(file, i) in files"
                  :key="i"
                  class="image-fit zoom-in relative mb-5 mr-5 h-24 w-24 cursor-pointer"
                >
                  <img
                    v-if="file.image !== undefined"
                    class="rounded-md"
                    alt="Gromura - SCM"
                    :src="file.image"
                  />
                  <div
                    v-else-if="file.type === 'application/pdf'"
                    class="flex h-full w-full flex-col justify-center rounded-md bg-orange-100 px-2 text-center text-orange-600"
                  >
                    <span class="text-xs">{{ file.name }}</span>
                    <!-- <span class="text-sm">{{ file.name }}</span> -->
                  </div>
                  <img
                    v-else
                    class="rounded-md"
                    alt="Gromura - SCM"
                    :src="generateThumbnail(file)"
                  />
                  <Tippy
                    tag="div"
                    content="Hapus gambar ini?"
                    class="flex absolute right-0 top-0 -mr-2 -mt-2 h-5 w-5 items-center justify-center rounded-full bg-danger text-white"
                    @click="remove(file)"
                  >
                    <xIcon class="h-4 w-4" />
                  </Tippy>
                </div>
              </div>
              <div
                class="flex relative cursor-pointer flex-col px-4"
                :class="files.length === 0 ? 'py-6' : 'pb-4'"
              >
                <div class="flex items-center">
                  <ImageIcon class="mr-2 h-4 w-4" />
                  <div v-if="isDragging">Lepaskan file disini.</div>
                  <div v-else>
                    <span class="mr-1 text-primary">Unggah file</span>
                    <span class="text-gray-500">atau jatuhkan file disini</span>
                  </div>
                </div>
                <span
                  class="mt-2 block text-xs font-medium italic text-gray-400"
                >
                  Hanya mendukung pdf & gambar
                </span>
                <input
                  id="fileInput"
                  ref="file"
                  multiple
                  type="file"
                  name="file"
                  class="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
                  accept="application/pdf,image/*"
                  @change="onChange"
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn bg-gradient-to-r from-teal-700 to-primary px-8 text-white"
          @click="changeStatusData"
        >
          <template v-if="isFormLoading === true">
            <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
          </template>
          Simpan
        </button>
      </ModalFooter>
    </Modal>

    <!-- FILTER MODAL -->
    <Modal :show="filterModal" @hidden="filterModal = false">
      <ModalHeader>
        <h2 class="mr-auto text-lg font-bold">Filter</h2>
      </ModalHeader>
      <ModalBody>
        <div class="flex w-full flex-col">
          <span class="text-sm font-bold">Urutkan</span>
          <div class="mt-4 flex w-full flex-col">
            <span class="text-xs">Waktu Dibuat</span>
            <div
              v-for="sort in sortingOptions"
              :key="sort.id"
              class="form-check mt-2 flex justify-between border-b py-3 cursor-pointer"
              @click="checkFilter(sort.value, 'order')"
            >
              <div class="flex">
                <ArrowsUpDownIcon class="h-4" />
                <label class="form-check-label ml-2" :for="sort.id">{{
                  sort.label
                }}</label>
              </div>
              <input
                :id="sort.id"
                v-model="filterData.order"
                :value="sort.value"
                class="form-check-input"
                type="radio"
                name="filter-sorting"
              />
            </div>
          </div>

          <span class="mt-6 text-sm font-bold">Berdasarkan</span>
          <div class="mt-4 flex w-full flex-col">
            <span class="text-xs">Rentang Waktu</span>
            <div class="relative mt-1 w-full text-slate-500 sm:ml-auto">
              <CalendarIcon
                class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
              />
              <Litepicker
                v-model="filterData.filter_date"
                :options="{
                  lang: 'id',
                  autoApply: false,
                  singleMode: false,
                  showWeekNumbers: true,
                  dropdowns: {
                    minYear: 2020,
                    maxYear: null,
                    months: true,
                    years: true,
                  },
                  format: 'YYYY-MM-DD',
                  buttonText: { apply: 'Terapkan', cancel: 'Batal' },
                  plugins: ['ranges'],
                  ranges: {
                    position: 'bottom',
                    customRanges: $h.customRanges(),
                  },
                }"
                class="form-control box w-full border border-gray-300 pl-8"
              />
            </div>
          </div>
          <div class="mt-4 flex w-full flex-col">
            <span class="text-xs">Status</span>
            <div class="mt-1 flex w-full flex-wrap">
              <StatusOption
                v-for="item in statusOptions"
                :id="item.id"
                :key="item.id"
                :name="item.status"
                :selected="item.selected"
                class="mt-1"
                @click="selectStatus(item)"
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          title="Terapkan Filter"
          color="gradient"
          classes="w-full"
          @click="applyFilter"
        />
      </ModalFooter>
    </Modal>
  </div>

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">
        {{ message }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helpers, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import { resizeImg } from '@/plugins/pica'
import dayjs from 'dayjs'
import globalMixin from '@/mixins/global.js'
import useEmitter from '@/composables/useEmitter'
import Currency from '@/components/Currency.vue'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Status from '@/components/status/Main.vue'
import StatusOption from '@/components/status-option/Main.vue'
import ButtonAdd from '@/components/button/ButtonAdd.vue'
import ExportCSVComponent from '@/components/ExportCSVComponent.vue'
import DetailPurchase from '@/components/purchase/Detail.vue'
import { ArrowsUpDownIcon } from '@heroicons/vue/24/solid'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Status,
    StatusOption,
    Currency,
    ButtonAdd,
    ArrowsUpDownIcon,
    ExportCSVComponent,
    DetailPurchase,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const emitter = useEmitter()

    const activeUser = reactive({})
    const roleAccess = ref('')
    const superRoles = ref([
      'SUPERADMIN',
      'CEO',
      'HEAD_TECHNOLOGY',
      'HEAD_OPERATION',
      'HEAD_SALES',
      'HEAD_FINANCE',
    ])

    const filterDate = ref()

    watch(filterDate, (currentValue, oldValue) => {
      getSummaryData()
    })

    let purchase = reactive({})

    const isDragging = ref(false)
    let files = ref([])

    const isFormLoading = ref(false)
    const isLoading = ref(false)

    const timer = ref(undefined)
    const filterModal = ref(false)

    const tax = ref(11)
    const subTotal = ref(0)
    const total = ref(0)
    const totalOriginal = ref(0)

    const detailPurchaseModal = ref(false)
    const changeStatusModal = ref(false)

    const status = ref('')
    const message = ref('')

    const titleModal = ref('')

    const tableHeader = ref([
      {
        item: 'NO',
        customClass: '',
      },
      {
        item: 'KODE PO',
        customClass: 'w-32',
      },
      // {
      //   item: 'GUDANG',
      //   customClass: '',
      // },
      {
        item: 'PRODUK',
        customClass: '',
      },
      {
        item: 'TOTAL PRODUK',
        customClass: 'text-center',
      },
      // {
      //   item: 'TOTAL QUANTITY',
      //   customClass: '',
      // },
      {
        item: 'TOTAL HARGA',
        customClass: '',
      },
      {
        item: 'TOTAL DISCOUNT',
        customClass: '',
      },
      {
        item: 'SUPPLIER',
        customClass: '',
      },
      {
        item: 'STATUS',
        customClass: 'text-center',
      },
      // {
      //   item: 'TANGGAL DITERIMA',
      //   customClass: 'text-center',
      // },
      {
        item: 'AKSI',
        customClass: 'text-center',
      },
    ])

    let placeholderStatus = ref('')
    let statusOption = ref([])

    const formData = reactive({
      status: '',
    })

    const statusRequired = helpers.withMessage('Status harus dipilih', required)

    let rules = reactive({
      status: {
        statusRequired,
      },
    })

    const validate = useVuelidate(rules, toRefs(formData))

    const sortingOptions = ref([
      {
        id: 1,
        label: 'A-Z',
        value: 'ASC',
      },
      {
        id: 2,
        label: 'Z-A',
        value: 'DESC',
      },
    ])

    const statusOptions = ref([
      // {
      //   id: null,
      //   status: 'Semua',
      // },
      {
        id: 'PENDING',
        status: 'Tertunda',
        selected: false,
      },
      {
        id: 'PROCESS',
        status: 'Diproses',
        selected: false,
      },
      // {
      //   id: 'DELIVERY',
      //   status: 'Dikirim',
        // selected: false,
      // },
      {
        id: 'PAID',
        status: 'Dibayar',
        selected: false,
      },
      {
        id: 'RECEIVED',
        status: 'Diterima',
        selected: false,
      },
      {
        id: 'COMPLETED',
        status: 'Selesai',
        selected: false,
      },
      {
        id: 'CANCELLED',
        status: 'Dibatalkan',
        selected: false,
      },
    ])

    let tableData = ref([])

    let metaData = reactive({
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })
    
    const starDateOfMonth = dayjs().startOf('month').format("YYYY-MM-DD")
    const endDateOfMonth = dayjs().endOf('month').format("YYYY-MM-DD")

    let filterData = reactive({
      page: 1,
      take: 10,
      search: '',
      order: sortingOptions.value[1].value,
      status: null,
      filter_date: `${starDateOfMonth} - ${endDateOfMonth}`,
    })

    onMounted(async () => {
      // clearing all event
      emitter.all.clear()

      Object.assign(activeUser, store.getters['auth/activeUser'])

      roleAccess.value = activeUser.role_codes

      emitter.on('update-data', (store) => {

        getAllData(true)
      })

      const storeId = store.getters['store/storeId']

      if (storeId === null) {
        setTimeout(() => {
          getAllData()
        }, 2000)
      } else {
        getAllData()
      }
    })

    const getAllData = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        tableData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }

      const storeId = store.getters['store/storeId']
      const dateRange = filterData.filter_date.split(' - ')

      let queryParameters = {
        store_id: storeId,
        order: filterData.order,
        page: filterData.page,
        take: filterData.take,
      }

      if (filterData.status && filterData.status !== null)
        queryParameters.status = filterData.status
      if (dateRange.hasOwnProperty(0))
        queryParameters.start_date = dateRange[0]
      if (dateRange.hasOwnProperty(1)) queryParameters.end_date = dateRange[1]

      if (filterData.search) {
        queryParameters.q = filterData.search
        delete queryParameters.start_date
        delete queryParameters.end_date
      }
      
      await store
        .dispatch('purchase/getByStore', queryParameters)
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            tableData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const download = async (fileType) => {
      const storeId = store.getters['store/storeId']

      const dateRange = filterData.filter_date.split(' - ')

      let queryParameters = {
        store_id: storeId,
        file_type: fileType,
        order: filterData.order,
      }

      if (filterData.status && filterData.status !== null)
        queryParameters.status = filterData.status
      if (filterData.search) queryParameters.q = filterData.search
      if (dateRange.hasOwnProperty(0)) queryParameters.start_date = dateRange[0]
      if (dateRange.hasOwnProperty(1)) queryParameters.end_date = dateRange[1]

      await store
        .dispatch('purchase/download', queryParameters)
        .then((response) => {
          const filename = response.dispotition.split('"')[1]
          saveAs(response.data, filename)
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const openFilter = () => {
      filterModal.value = true
    }

    const checkFilter = (value, _target) => {
      if (_target === 'order') {
        filterData.order = value
      }
    }

    const applyFilter = () => {
      filterModal.value = false
      getAllData()
    }

    const changeFilter = (event) => {
      Object.assign(filterData, {
        ...filterData,
        page: Number(event.filterData.page),
        take: Number(event.filterData.take),
      })
      getAllData()
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getAllData(true)
      }, 500)
    }

    const selectStatus = (option) => {
      option.selected = !option.selected

      let statuses = []
      if (filterData.status !== null) {
        statuses = filterData.status.split(',')
      }

      if (option.selected === false && statuses.length > 0) {
        statuses = statuses.filter((e) => e !== option.id)
      } else {
        statuses.push(option.id)
      }

      if (statuses.length > 0) {
        filterData.status = statuses.toString()
      } else {
        filterData.status = null
      }
    }

    const formattedDate = (date) => {
      return dayjs(date).format('DD-MM-YYYY')
    }

    const getProductNames = (purchase) => {
      const productNames = []
      purchase.map((item) => {
        productNames.push(item.product.name)
      })
      return productNames.toString()
    }

    const onChange = (e) => {
      files.value = [...files.value, ...e.target.files]
    }
    const generateThumbnail = (file) => {
      let fileSrc = URL.createObjectURL(file)
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc)
      }, 1000)
      return fileSrc
    }
    const makeName = (name) => {
      return (
        name.split('.')[0].substring(0, 3) +
        '...' +
        name.split('.')[name.split('.').length - 1]
      )
    }
    const remove = (file) => {
      const index = files.value.indexOf(file)
      files.value.splice(index, 1)
    }
    const dragover = (e) => {
      e.preventDefault()
      isDragging.value = true
    }
    const dragleave = () => {
      isDragging.value = false
    }
    const drop = (e) => {
      e.preventDefault()

      files.value = [...files.value, ...e.dataTransfer.files]

      isDragging.value = false
    }

    const changeStatusForm = async (data) => {
      resetForm()

      if (data.status === 'PENDING') {
        placeholderStatus.value = 'Tertunda'
      } else if (data.status === 'PROCESS') {
        placeholderStatus.value = 'Diproses'
        // } else if (data.status === 'DELIVERY') {
        //   placeholderStatus.value = 'Dikirim'
      } else if (data.status === 'PAID') {
        placeholderStatus.value = 'Dibayar'
      } else if (data.status === 'RECEIVED') {
        placeholderStatus.value = 'Diterima'
      } else if (data.status === 'COMPLETED') {
        placeholderStatus.value = 'Selesai'
      } else if (data.status === 'CANCELLED') {
        placeholderStatus.value = 'Dibatalkan'
      }

      if (data.status === 'PENDING') {
        statusOption.value = [
          {
            id: 'PROCESS',
            name: 'Diproses',
          },
          {
            id: 'CANCELLED',
            name: 'Dibatalkan',
          },
        ]
      } else if (
        data.status === 'PROCESS' &&
        data.payment.payment_at === null
      ) {
        statusOption.value = [
          // {
          //   id: 'DELIVERY',
          //   name: 'Dikirim',
          // },
          {
            id: 'PAID',
            name: 'Dibayar',
          },
          {
            id: 'CANCELLED',
            name: 'Dibatalkan',
          },
        ]
        // } else if (
        //   data.status === 'DELIVERY' &&
        //   data.payment.payment_at === null
        // ) {
        //   statusOption.value = [
        //     {
        //       id: 'PAID',
        //       name: 'Dibayar',
        //     },
        //     {
        //       id: 'RECEIVED',
        //       name: 'Diterima',
        //     },
        //   ]
        // } else if (data.status === 'DELIVERY') {
        //   statusOption.value = [
        //     {
        //       id: 'RECEIVED',
        //       name: 'Diterima',
        //     },
        //   ]
        // } else if (data.status === 'PAID' && data.received_at === null) {
      } else if (data.status === 'PAID') {
        statusOption.value = [
          {
            id: 'RECEIVED',
            name: 'Diterima',
          },
        ]
      } else if (data.status === 'PAID' || data.status === 'RECEIVED') {
        statusOption.value = [
          {
            id: 'COMPLETED',
            name: 'Selesai',
          },
        ]
      }

      purchase.value = data

      changeStatusModal.value = true
    }

    const detail = async (trx) => {
      Object.assign(purchase, trx)

      detailPurchaseModal.value = true
    }

    const changeQuantity = (product, bonus, added) => {
      if (bonus === true) {
        if (added === true) {
          if (product.quantity_bonus === null) {
            product.quantity_bonus = 1
          } else {
            product.quantity_bonus = product.quantity_bonus + 1
          }
        } else {
          if (product.quantity_bonus !== 0) {
            product.quantity_bonus = product.quantity_bonus - 1
          }
        }
      } else {
        if (added === true) {
          if (product.quantity === null) {
            product.quantity = 1
          } else {
            product.quantity = product.quantity + 1
          }
        } else {
          if (product.quantity !== 0) {
            product.quantity = product.quantity - 1
          }
        }
      }

      calculateTotalPriceItem(product)
    }

    const calculateTotalPriceItem = (product) => {
      product.total_price =
        product.quantity * product.pay_price - product.price_discount
      product.total_price_original = product.quantity * product.pay_price

      calculateTotalPrice()
    }

    const calculateTotalPrice = () => {
      let totalPrice = 0
      let totalPriceOrg = 0
      purchase.items.forEach((item) => {
        totalPrice += item.total_price
        totalPriceOrg += item.total_price_original
      })

      if (tax.value.toString() !== '0') {
        subTotal.value = totalPrice - Math.round((totalPrice / 100) * tax.value)
      } else {
        subTotal.value = totalPrice
      }

      total.value = totalPrice
      totalOriginal.value = totalPriceOrg
    }

    const resetForm = () => {
      validate.value.$reset()

      purchase.value = {}

      formData.status = ''

      files.value = []
    }

    const changeStatusData = async () => {
      validate.value.$touch()

      if (validate.value.$invalid == false) {
        isFormLoading.value = true
        const payload = {
          purchase_id: purchase.value.id,
          status: formData.status,
        }

        await store
          .dispatch('purchase/changeStatus', payload)
          .then(async (response) => {
            if (response.statusCode === 200) {
              if (['PAID', 'RECEIVED'].includes(formData.status)) {
                files.value.map(async (file) => {
                  const payloadDoc = {
                    purchase_id: purchase.value.id,
                    type:
                      formData.status === 'RECEIVED' ? 'INVOICE' : 'PAYMENT', // INVOICE, PAYMENT, DOCUMENT, DRAFT
                    document: file,
                  }
                  await store
                    .dispatch('purchase/upload', payloadDoc)
                    .then((response) => {})
                    .catch((error) => {
                      const responseData = error.response.data
                      isFormLoading.value = false
                      status.value = responseData.message.status
                      message.value = responseData.message.detail
                      // show toast
                      setTimeout(() => {
                        showToast(false)
                      }, 200)
                    })
                })
              }

              if (formData.status === 'RECEIVED') {
                const products = []

                purchase.value.items.forEach((item) => {
                  const product = {
                    product_id: item.product.id,
                    quantity: item.quantity,
                    quantity_bonus: item.quantity_bonus,
                    price_discount: item.price_discount,
                  }

                  products.push(product)
                })

                const payload = {
                  purchase_id: purchase.value.id,
                  products,
                }

                await store
                  .dispatch('purchase/receive', payload)
                  .then((response) => {
                    if (response.statusCode === 200) {
                      status.value = response.status
                      message.value = response.message

                      isFormLoading.value = false
                      changeStatusModal.value = false

                      // show toast
                      setTimeout(() => {
                        showToast({
                          success: true,
                        })
                      }, 200)
                    }
                  })
                  .catch((e) => {
                    isFormLoading.value = false
                    console.log(e)
                  })
              } else if (formData.status === 'PAID') {
                const payload = {
                  purchase_id: purchase.value.id,
                }

                await store
                  .dispatch('purchase/paid', payload)
                  .then((response) => {
                    if (response.statusCode === 200) {
                      status.value = response.status
                      message.value = response.message

                      isFormLoading.value = false
                      changeStatusModal.value = false

                      // show toast
                      setTimeout(() => {
                        showToast({
                          success: true,
                        })
                      }, 200)
                    }
                  })
                  .catch((e) => {
                    isFormLoading.value = false
                    console.log(e)
                  })
              } else {
                status.value = response.status
                message.value = response.message

                isFormLoading.value = false
                changeStatusModal.value = false

                // show toast
                setTimeout(() => {
                  showToast({
                    success: true,
                  })
                }, 200)
              }

              validate.value.$reset()

              setTimeout(() => {
                getAllData()
              }, 1500)
            } else {
              status.value = response.message.status
              message.value = response.message.detail
              // show toast
              setTimeout(() => {
                showToast()
              }, 200)
            }
          })
          .catch((error) => {
            const responseData = error.response.data
            isFormLoading.value = false
            status.value = responseData.message.status
            message.value = responseData.message.detail
            // show toast
            setTimeout(() => {
              showToast(false)
            }, 200)
          })
      }
    }

    const onError = (msg) => {
      status.value = 'Gagal'
      message.value = msg
      // show toast
      setTimeout(() => {
        showToast()
      }, 200)
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    return {
      t,
      n,
      store,
      route,
      router,

      roleAccess,
      superRoles,

      detail,

      purchase,

      changeStatusForm,
      changeStatusData,

      filterModal,
      changeStatusModal,
      detailPurchaseModal,

      isFormLoading,
      isLoading,

      timer,

      changeQuantity,
      calculateTotalPriceItem,
      calculateTotalPrice,

      tax,
      subTotal,
      total,
      totalOriginal,

      resetForm,
      formData,
      validate,
      placeholderStatus,
      statusOption,

      status,
      message,

      titleModal,
      tableHeader,
      tableData,
      metaData,

      filterData,
      openFilter,
      checkFilter,
      applyFilter,
      searchFilter,
      changeFilter,
      selectStatus,
      
      sortingOptions,
      statusOptions,

      getAllData,
      download,

      formattedDate,

      getProductNames,

      isDragging,
      files,

      onChange,
      generateThumbnail,
      makeName,
      remove,
      dragover,
      dragleave,
      drop,

      onError,
      showToast,
    }
  },
}
</script>
