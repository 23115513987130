<template>
  <h2 class="intro-y mt-2 md:mt-6 text-lg font-medium">Supplier</h2>
  <ButtonAdd
    v-if="$h.roleCanManage($h.managementRoles(), roleAccess)"
    :is-loading="isButtonLoading" @click="addForm" />
  <div class="mt-0 grid grid-cols-12 gap-6 md:mt-5">
    <div class="intro-y col-span-12 mt-2 flex flex-wrap items-center">
      <div class="flex w-full md:w-auto">
        <div class="relative w-full text-slate-500 md:w-48 h-9.5">
          <input
            v-model="filterData.search"
            type="text"
            class="form-control box w-full pr-8 md:w-56 h-10"
            placeholder="Cari supplier..."
            @input="searchFilter()"
          />
          <SearchIcon
            class="absolute inset-y-0 right-0 my-auto mr-4 h-4 w-4 md:-mr-4"
          />
        </div>
        <button class="btn box ml-2 shadow-sm md:ml-10" @click="openFilter">
          <FilterIcon class="h-4 w-4" />
        </button>
      </div>
      <div class="mx-auto hidden text-slate-500 md:block"></div>
      <div class="mt-3 flex items-center md:mt-0 xl:mt-0 xl:w-auto">
        <!-- <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white mr-2 text-white">
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button> -->
        <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white  text-white">
          <FileTextIcon class="mr-2 h-4 w-4" /> Export ke Excel
        </button>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <TableComponent
      :show-loading="isLoading"
      :show-title="false"
      :show-search="false"
      :header="tableHeader"
      :meta-data="metaData"
      :is-not-empty="tableData.length > 0"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="(item, index) in tableData" :key="item.id" class="intro-x">
        <td class="!py-3.5">
          <div class="bg-gray-50 font-bold p-2 text-emerald-700 rounded-xl w-12 text-center">
            {{ (metaData.page !== 1 ? (metaData.page - 1) * metaData.take : 0) + index + 1 }}
          </div>
        </td>
        <td class="!py-3.5">
          <div class="flex items-center">
            <div class="image-fit zoom-in h-9 w-9">
              <ImageLoader
                :image="item.image"
                :alt="item.name"
                rounded-class="rounded-md"
                @click="imagePreview(item)"
              />
            </div>
          </div>
        </td>
        <td>
          {{ item.code }}
        </td>
        <td>
          {{ item.name }}
        </td>
        <td class="text-xs text-primary">
          {{ item.npwp }}
        </td>
        <td class="text-xs text-slate-600 dark:text-slate-500">
          {{ item.pic }}
        </td>
        <td class="table-report__action w-32">
          <div class="flex items-center justify-center">
            <a
              class="flex items-center text-secondary"
              href="javascript:;"
              @click="supplierPreview(item)"
            >
              <EyeIcon class="mr-1 h-4 w-4" /> Detail
            </a>
            <template v-if="$h.roleCanManage($h.superRoles(), roleAccess)">
              <!-- <template
                v-if="
                  isSubCategoryLoading === false &&
                  isBrandLoading === false &&
                  isUnitLoading === false
                "
              > -->
              <a
                class="ml-3 flex items-center text-green-500"
                href="javascript:;"
                @click="editForm(item)"
              >
                <CheckSquareIcon class="mr-1 h-4 w-4" /> Edit
              </a>
              <!-- </template> -->
              <!-- <template v-else>
                <a
                  class="ml-3 flex items-center text-green-300"
                  href="javascript:;"
                >
                  <CheckSquareIcon class="mr-1 h-4 w-4" /> Edit
                </a>
              </template> -->
              <a
                class="ml-3 flex items-center text-danger"
                href="javascript:;"
                @click="deleteForm(item)"
              >
                <Trash2Icon class="mr-1 h-4 w-4" /> Delete
              </a>
            </template>
          </div>
        </td>
      </tr>
    </TableComponent>
    <!-- END: Data List -->
  </div>

  <!-- ADD & EDIT MODAL -->
  <Modal size="modal-xl" :show="addEditModal" @hidden="addEditModal = false">
    <ModalHeader>
      <h2 class="mr-auto text-base font-medium">{{ titleModal }}</h2>
    </ModalHeader>
    <ModalBody>
      <div class="flex flex-col lg:flex-row w-full">
        <div class="w-full lg:w-1/2 lg:border-r-2 lg:pr-4">
          <div class="image-fit relative h-20 w-20 flex-none lg:h-24 lg:w-24">
            <template v-if="supplierImagePreview != ''">
              <ImageLoader
                :image="supplierImagePreview"
                :alt="formData.name"
                rounded-class="rounded-full"
              />
            </template>
            <template v-else>
              <ImageLoader
                :image="formData.image"
                :alt="formData.name"
                rounded-class="rounded-full"
              />
            </template>

            <div
              class="absolute bottom-0 right-0 mb-1 flex cursor-pointer items-center justify-center rounded-full bg-primary p-1 lg:p-2"
              @click="selectFile()"
            >
              <input
                id="hidden-input"
                ref="imageUploader"
                type="file"
                accept="image/*"
                class="hidden"
              />
              <CameraIcon class="h-3 w-3 text-white lg:h-4 lg:w-4" />
            </div>
          </div>
          <div class="input-form mt-5 w-full">
            <label
              for="form-code"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.code') }}
            </label>
            <input
              id="form-code"
              v-model.trim="validate.code.$model"
              type="text"
              name="code"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.code.$error }"
              :placeholder="$t('formInput.enterCode')"
            />
            <template v-if="validate.code.$error">
              <div
                v-for="(error, index) in validate.code.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form mt-3 w-full">
            <label
              for="form-name"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.name') }}
            </label>
            <input
              id="form-name"
              v-model.trim="validate.name.$model"
              type="text"
              name="name"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.name.$error }"
              :placeholder="$t('formInput.enterName')"
            />
            <template v-if="validate.name.$error">
              <div
                v-for="(error, index) in validate.name.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form mt-3 w-full">
            <label
              for="form-npwp"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.npwp') }}
            </label>
            <input
              id="form-npwp"
              v-model.trim="validate.npwp.$model"
              type="text"
              name="npwp"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.npwp.$error }"
              :placeholder="$t('formInput.enterNpwp')"
            />
            <template v-if="validate.npwp.$error">
              <div
                v-for="(error, index) in validate.npwp.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form mt-3 w-full">
            <label
              for="form-pic"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.pic') }}
            </label>
            <input
              id="form-pic"
              v-model.trim="validate.pic.$model"
              type="text"
              name="pic"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.pic.$error }"
              :placeholder="$t('formInput.enterPic')"
            />
            <template v-if="validate.pic.$error">
              <div
                v-for="(error, index) in validate.pic.$errors"
                :key="index"
                class="mt-2 text-xs text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form mt-3 w-full">
            <label
              for="form-phone"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.phone') }}
            </label>
            <input
              id="form-phone"
              v-model="validate.phone.$model"
              type="text"
              name="phone"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.phone.$error }"
              :placeholder="$t('formLabel.phone')"
            />
            <template v-if="validate.phone.$error">
              <div
                v-for="(error, index) in validate.phone.$errors"
                :key="index"
                class="mt-2 text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
          <div class="input-form mt-3 w-full">
            <label
              for="form-email"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.email') }}
            </label>
            <input
              id="form-email"
              v-model.trim="validate.email.$model"
              type="email"
              name="email"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.email.$error }"
              :placeholder="$t('formLabel.email')"
            />
            <template v-if="validate.email.$error">
              <div
                v-for="(error, index) in validate.email.$errors"
                :key="index"
                class="mt-2 text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
        </div>

        <div class="w-full lg:w-1/2 lg:pl-4">
          <div class="input-form mt-3 w-full">
            <label
              for="form-address-province"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.province') }}
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="province"
                value="code"
                label="name"
                track-by="name"
                :placeholder="$t('formLabel.select.province')"
                :options="provinceList"
                :searchable="true"
                :classes="multiSelectClasses"
                @change="changeAddress('province')"
              />
            </div>
          </div>
          <div class="input-form mt-3 w-full">
            <label
              for="form-address-city"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.city') }}
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="city"
                value="code"
                label="name"
                track-by="name"
                :placeholder="$t('formLabel.select.city')"
                :options="cityList"
                :searchable="true"
                :classes="multiSelectClasses"
                :disabled="cityDisabled === true"
                @change="changeAddress('city')"
              />
            </div>
          </div>

          <div class="input-form mt-3 w-full">
            <label
              for="form-address-district"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.district') }}
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="district"
                value="code"
                label="name"
                track-by="name"
                :placeholder="$t('formLabel.select.district')"
                :options="districtList"
                :searchable="true"
                :classes="multiSelectClasses"
                :disabled="districtDisabled === true"
                @change="changeAddress('district')"
              />
            </div>
          </div>
          <div class="input-form mt-3 w-full">
            <label
              for="form-address-village"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.village') }}
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="village"
                value="code"
                label="name"
                track-by="name"
                :placeholder="$t('formLabel.select.village')"
                :options="villageList"
                :searchable="true"
                :classes="multiSelectClasses"
                :disabled="villageDisabled === true"
                @change="changeAddress('village')"
              />
            </div>
          </div>

          <div class="flex w-full flex-col lg:flex-row">
            <div class="input-form mt-3 w-full">
              <label
                for="form-address-postal-code"
                class="form-label flex w-full flex-col sm:flex-row"
              >
                {{ $t('formLabel.postalCode') }}
              </label>
              <div class="mt-2">
                <input
                  id="form-postalCode"
                  v-model.trim="validate.postalCode.$model"
                  type="number"
                  name="postalCode"
                  class="intro-x login__input form-control block py-3 px-4"
                  :class="{ 'border-danger': validate.postalCode.$error }"
                  :placeholder="$t('formLabel.postalCode')"
                />
                <template v-if="validate.postalCode.$error">
                  <div
                    v-for="(error, index) in validate.postalCode.$errors"
                    :key="index"
                    class="mt-2 text-danger"
                  >
                    {{ $t(error.$message) }}
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div class="input-form mt-3 w-full">
            <label
              for="form-address"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              {{ $t('formLabel.address') }}
            </label>

            <textarea
              id="form-address"
              v-model.trim="validate.address.$model"
              type="text"
              name="address"
              class="intro-x login__input form-control block py-3 px-4"
              :class="{ 'border-danger': validate.address.$error }"
              :placeholder="$t('formLabel.address')"
            ></textarea>

            <template v-if="validate.address.$error">
              <div
                v-for="(error, index) in validate.address.$errors"
                :key="index"
                class="mt-2 text-danger"
              >
                {{ $t(error.$message) }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <button type="button" @click="addData" class="btn bg-gradient-to-r from-teal-700 to-primary text-white px-8">
        <template v-if="isFormLoading === true">
          <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
        </template>
        Simpan
      </button>
    </ModalFooter>
  </Modal>

  <!-- DELETE MODAL -->
  <Modal :show="deleteModal" @hidden="cancelDelete">
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5">Apakah anda yakin ingin menghapus data ini ?</div>
        <div class="mt-2 text-lg text-slate-600">{{ activeData.name }}</div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          @click="cancelDelete"
          class="btn btn-outline-secondary mr-4 w-24"
        >
          Batal
        </button>
        <button type="button" class="btn btn-danger w-32" @click="deleteData">
          <template v-if="isFormLoading === true">
            <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
          </template>
          Hapus
        </button>
      </div>
    </ModalBody>
  </Modal>

  <!-- DETAIL MODAL -->
  <Modal :show="supplierPreviewModal" @hidden="supplierPreviewModal = false">
    <ModalBody class="p-0">
      <div
        class="relative block flex-none before:block before:w-full before:pt-[100%]"
      >
        <div class="image-fit absolute top-0 left-0 h-full w-full">
          <ImageLoader
            :image="activeSupplier.image"
            :alt="activeSupplier.name"
            rounded-class="rounded-tr-md rounded-tl-md"
          />
        </div>
      </div>
      <div class="p-4">
        <div class="flex w-full truncate text-lg font-medium">
          {{ activeSupplier.name }}
        </div>
        <span class="mt-3 text-xs text-green-600">{{
          activeSupplier.code
        }}</span>

        <div class="mt-4 flex w-full border-t-2 pt-4">
          <div class="flex w-1/4">
            <span>NPWP</span>
          </div>
          <div class="flex w-3/4">
            <span>: {{ activeSupplier.npwp }}</span>
          </div>
        </div>
        <div class="mt-2 flex w-full">
          <div class="flex w-1/4">
            <span>PIC</span>
          </div>
          <div class="flex w-3/4">
            <span>: {{ activeSupplier.pic }}</span>
          </div>
        </div>
        <div class="mt-2 flex w-full">
          <div class="flex w-1/4">
            <span>No Telpon</span>
          </div>
          <div class="flex w-3/4">
            <span>: {{ activeSupplier.phone }}</span>
          </div>
        </div>
        <div class="mt-2 flex w-full">
          <div class="flex w-1/4">
            <span>Email</span>
          </div>
          <div class="flex w-3/4">
            <span>: {{ activeSupplier.email }}</span>
          </div>
        </div>

        <template
          v-if="
            activeSupplier.address !== undefined &&
            activeSupplier.address !== null
          "
        >
          <div class="mt-4 flex w-full border-t-2 pt-4">
            <div class="flex w-1/4">
              <span class="text-md font-bold">Alamat</span>
            </div>
          </div>
          <template
            v-if="
              activeSupplier.address.province !== undefined &&
              activeSupplier.address.province !== null
            "
          >
            <div class="mt-2 flex w-full">
              <div class="flex w-1/4">
                <span>Provinsi</span>
              </div>
              <div class="flex w-3/4">
                <span
                  >: {{ activeSupplier.address.province.name }} ({{
                    activeSupplier.address.province.code
                  }})</span
                >
              </div>
            </div>
          </template>
          <template
            v-if="
              activeSupplier.address.city !== undefined &&
              activeSupplier.address.city !== null
            "
          >
            <div class="mt-2 flex w-full">
              <div class="flex w-1/4">
                <span>Kota</span>
              </div>
              <div class="flex w-3/4">
                <span
                  >: {{ activeSupplier.address.city.name }} ({{
                    activeSupplier.address.city.code
                  }})</span
                >
              </div>
            </div>
          </template>
          <template
            v-if="
              activeSupplier.address.district !== undefined &&
              activeSupplier.address.district !== null
            "
          >
            <div class="mt-2 flex w-full">
              <div class="flex w-1/4">
                <span>Kecamatan</span>
              </div>
              <div class="flex w-3/4">
                <span
                  >: {{ activeSupplier.address.district.name }} ({{
                    activeSupplier.address.district.code
                  }})</span
                >
              </div>
            </div>
          </template>
          <template
            v-if="
              activeSupplier.address.village !== undefined &&
              activeSupplier.address.village !== null
            "
          >
            <div class="mt-2 flex w-full">
              <div class="flex w-1/4">
                <span>Kelurahan</span>
              </div>
              <div class="flex w-3/4">
                <span
                  >: {{ activeSupplier.address.village.name }} ({{
                    activeSupplier.address.village.code
                  }})</span
                >
              </div>
            </div>
          </template>
          <div class="mt-2 flex w-full">
            <div class="flex w-1/4">
              <span>Kode Pos</span>
            </div>
            <div class="flex w-3/4">
              <span>: {{ activeSupplier.address.postal_code }}</span>
            </div>
          </div>
          <div class="mt-2 flex w-full">
            <div class="flex w-1/4">
              <span>Alamat</span>
            </div>
            <div class="flex w-3/4">
              <span>: {{ activeSupplier.address.address }}</span>
            </div>
          </div>
        </template>
      </div>
    </ModalBody>
  </Modal>

  <!-- DETAIL IMAGE -->
  <Modal :show="imagePreviewModal" @hidden="imagePreviewModal = false">
    <ModalBody class="p-0">
      <div
        class="relative block flex-none before:block before:w-full before:pt-[100%]"
      >
        <div class="image-fit absolute top-0 left-0 h-full w-full">
          <ImageLoader
            :image="activeSupplier.image"
            :alt="activeSupplier.name"
            rounded-class="rounded-md"
          />
        </div>
      </div>
    </ModalBody>
  </Modal>

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">
        {{ message }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
  helpers,
  required,
  minLength,
  maxLength,
  email,
  integer,
  url,
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import { resizeImg } from '@/plugins/pica'
import globalMixin from '@/mixins/global.js'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import Multiselect from '@vueform/multiselect'
import ButtonAdd from '@/components/button/ButtonAdd.vue'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Currency,
    Multiselect,
    ButtonAdd
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const activeUser = reactive({})
    const roleAccess = ref('')
    const superRoles = ref([
      'SUPERADMIN',
      'CEO',
      'HEAD_TECHNOLOGY',
      'HEAD_OPERATION',
      'HEAD_SALES',
      'HEAD_FINANCE',
    ])

    const activeSupplier = reactive({})

    const supplierImagePreview = ref('')
    const imageFile = ref(null)

    const vendor = ref('')
    const paymentDateline = ref('')

    const provincePage = ref(1)
    const cityPage = ref(1)
    const districtPage = ref(1)
    const villagePage = ref(1)

    const province = ref('')
    const city = ref('')
    const district = ref('')
    const village = ref('')

    const cityDisabled = ref(true)
    const districtDisabled = ref(true)
    const villageDisabled = ref(true)

    const provinceList = ref([])
    const cityList = ref([])
    const districtList = ref([])
    const villageList = ref([])

    const isFormLoading = ref(false)
    const isLoading = ref(false)
    const isButtonLoading = ref(false)

    const timer = ref(undefined)

    const supplierPreviewModal = ref(false)
    const imagePreviewModal = ref(false)
    const addEditModal = ref(false)
    const deleteModal = ref(false)

    const status = ref('')
    const message = ref('')

    const titleModal = ref('')

    const tableHeader = ref([
      {
        item: 'NO',
        customClass: '',
      },
      {
        item: 'GAMBAR',
        customClass: '',
      },
      {
        item: 'KODE',
        customClass: '',
      },
      {
        item: 'NAMA',
        customClass: '',
      },
      {
        item: 'NPWP',
        customClass: '',
      },
      {
        item: 'PIC',
        customClass: '',
      },
      {
        item: 'AKSI',
        customClass: 'text-center',
      },
    ])

    let activeDataId = ref(null)

    let tableData = ref([])

    let metaData = reactive({
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 10,
      search: '',
      order: 'ASC',
    })

    const formData = reactive({
      image: null,
      code: '',
      name: '',
      npwp: '',
      pic: '',
      email: '',
      phone: '',
      address: '',
      postalCode: '',
    })

    const activeData = reactive({
      image: null,
      code: '',
      name: '',
      npwp: '',
      pic: '',
      email: '',
      phone: '',
      address: '',
      postalCode: '',
    })

    const codeRequired = helpers.withMessage(
      'formError.code.required',
      required
    )
    const nameRequired = helpers.withMessage(
      'formError.name.required',
      required
    )
    const emailValid = helpers.withMessage('formError.email.validEmail', email)
    const phoneValid = helpers.withMessage(
      'formError.phone.validPhone',
      integer
    )
    const phoneMinLength = helpers.withMessage(
      'formError.phone.minLength',
      minLength(9)
    )
    const phoneMaxLength = helpers.withMessage(
      'formError.phone.maxLength',
      maxLength(13)
    )

    const rules = {
      code: {
        codeRequired,
      },
      name: {
        nameRequired,
      },
      npwp: {},
      pic: {},
      email: {
        emailValid,
      },
      phone: {
        phoneMinLength,
        phoneMaxLength,
        phoneValid,
      },
      address: {},
      postalCode: {},
    }

    const validate = useVuelidate(rules, toRefs(formData))

    onMounted(async () => {
      Object.assign(activeUser, store.getters['auth/activeUser'])
      roleAccess.value = activeUser.role_codes

      getAllData()
      await getAddressProvince()
    })

    const addData = async () => {
      validate.value.$touch()

      if (validate.value.$invalid == false) {
        isFormLoading.value = true

        let payload = {
          image: imageFile.value,
          code: formData.code,
          name: formData.name,
          npwp: formData.npwp,
          pic: formData.pic,
          email: formData.email,
          phone: formData.phone,
          province_id: province.value,
          city_id: city.value,
          district_id: district.value,
          village_id: village.value,
          address: formData.address,
          postal_code: formData.postalCode,
        }

        let endpoint = 'supplier/create'

        if (activeDataId.value !== null && activeDataId.value !== '') {
          payload.id = activeDataId.value

          endpoint = 'supplier/update'
        }

        await store
          .dispatch(endpoint, payload)
          .then((response) => {
            if (response.statusCode === 200) {
              status.value = response.status
              message.value = response.message

              addEditModal.value = false

              // show toast
              setTimeout(() => {
                showToast({
                  success: true,
                })
              }, 200)

              validate.value.$reset()

              getAllData()
            } else {
              status.value = response.message.status
              message.value = response.message.detail
              // show toast
              setTimeout(() => {
                showToast()
              }, 200)
            }
            isFormLoading.value = false
          })
          .catch((error) => {
            const responseData = error.response.data
            isFormLoading.value = false
            status.value = responseData.message.status
            message.value = responseData.message.detail
            // show toast
            setTimeout(() => {
              showToast(false)
            }, 200)
          })
      }
    }

    const deleteData = async () => {
      isFormLoading.value = true
      await store
        .dispatch('supplier/delete', activeDataId.value)
        .then((response) => {
          if (response.statusCode === 200) {
            status.value = response.status
            message.value = response.message

            // show toast
            setTimeout(() => {
              showToast({
                success: true,
              })
            }, 200)

            validate.value.$reset()

            getAllData()
          } else {
            status.value = response.message.status
            message.value = response.message.detail
            // show toast
            setTimeout(() => {
              showToast()
            }, 200)
          }
          isFormLoading.value = false
          deleteModal.value = false
        })
        .catch((error) => {
          const responseData = error.response.data
          deleteModal.value = false
          isFormLoading.value = false
          status.value = responseData.message.status
          message.value = responseData.message.detail
          // show toast
          setTimeout(() => {
            showToast(false)
          }, 200)
        })
    }

    const resetForm = () => {
      province.value = ''
      city.value = ''
      district.value = ''
      village.value = ''

      validate.value.$reset()

      supplierImagePreview.value = ''
      imageFile.value = null

      formData.image = null
      formData.code = ''
      formData.name = ''
      formData.npwp = ''
      formData.pic = ''
      formData.phone = ''
      formData.email = ''
      formData.address = ''
      formData.postalCode = ''

      cityDisabled.value = true
      districtDisabled.value = true
      villageDisabled.value = true

      activeDataId.value = null
    }

    const addForm = () => {
      isButtonLoading.value = true;

      resetForm()

      titleModal.value = 'Tambah Supplier'
      addEditModal.value = true
      isButtonLoading.value = false;
    }

    const editForm = async (data) => {
      resetForm()

      activeDataId.value = data.id

      formData.image = data.image
      formData.code = data.code
      formData.name = data.name
      formData.npwp = data.npwp
      formData.pic = data.pic
      formData.phone = data.phone
      formData.email = data.email

      if (data.address !== undefined && data.address !== null) {
        formData.address = data.address.address
        formData.postalCode = data.address.postal_code

        province.value = data.address.province.code
        city.value = data.address.city.code
        district.value = data.address.district.code
        village.value = data.address.village.code

        await getAddressCity()
        await getAddressDistrict()
        await getAddressVillage()

        cityDisabled.value = false
        districtDisabled.value = false
        villageDisabled.value = false
      }

      titleModal.value = 'Ubah Supplier'
      addEditModal.value = true
    }

    const deleteForm = (data) => {
      activeData.code = data.code
      activeData.name = data.name

      activeDataId.value = data.id

      deleteModal.value = true
    }

    const cancelDelete = (data) => {
      activeData.code = ''
      activeData.name = ''

      activeDataId.value = null

      deleteModal.value = false
    }

    const previewImage = (file) => {
      // no need resize
      if (file.size < 1024) {
        supplierImagePreview.value = window.URL.createObjectURL(file)
        imageFile.value = file
      }
      const targetWidth = 1000
      resizeImg(file, targetWidth, (resized) => {
        supplierImagePreview.value = window.URL.createObjectURL(resized)
        imageFile.value = resized
      })
    }

    const selectFile = () => {
      const hidden = document.getElementById('hidden-input')
      hidden.click()
      hidden.onchange = (e) => {
        previewImage(e.target.files[0])
      }
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const getAllData = async (success) => {
      if (filterData.page === 1) {
        isLoading.value = true
      }
      await store
        .dispatch('supplier/getAll', {
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          q: filterData.search,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            tableData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const getAddressProvince = async () => {
      const queryParameters = {
        order: 'ASC',
        page: provincePage.value,
        take: 20,
        q: '',
      }
      await store
        .dispatch('address/province', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const provinceTemporary = []
            response.data.data.forEach((item) => {
              provinceTemporary.push({
                value: item.code,
                ...item,
              })
            })
            provinceList.value = [...provinceList.value, ...provinceTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('province')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressCity = async () => {
      const queryParameters = {
        order: 'ASC',
        page: cityPage.value,
        take: 50,
        q: '',
        province_code: province.value,
      }
      await store
        .dispatch('address/city', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const cityTemporary = []
            response.data.data.forEach((item) => {
              cityTemporary.push({
                value: item.code,
                ...item,
              })
            })
            if (cityPage.value === 1) {
              cityList.value = []
              cityList.value = [...cityTemporary]
            } else {
              cityList.value = [...cityList.value, ...cityTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('city')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressDistrict = async () => {
      const queryParameters = {
        order: 'ASC',
        page: 1,
        take: 50,
        q: '',
        city_code: city.value,
      }
      await store
        .dispatch('address/district', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const districtTemporary = []
            response.data.data.forEach((item) => {
              districtTemporary.push({
                value: item.code,
                ...item,
              })
            })
            if (districtPage.value === 1) {
              districtList.value = []
              districtList.value = [...districtTemporary]
            } else {
              districtList.value = [...districtList.value, ...districtTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('district')
            }
          }
        })
        .catch((error) => {})
    }

    const getAddressVillage = async () => {
      const queryParameters = {
        order: 'ASC',
        page: 1,
        take: 50,
        q: '',
        district_code: district.value,
      }
      await store
        .dispatch('address/village', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const villageTemporary = []
            response.data.data.forEach((item) => {
              villageTemporary.push({
                value: item.code,
                ...item,
              })
            })
            if (villagePage.value === 1) {
              villageList.value = []
              villageList.value = [...villageTemporary]
            } else {
              villageList.value = [...villageList.value, ...villageTemporary]
            }

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('village')
            }
          }
        })
        .catch((error) => {})
    }

    const loadMores = async (type) => {
      if (type === 'province') {
        provincePage.value += 1
        getAddressProvince()
      } else if (type === 'city') {
        cityPage.value += 1
        getAddressCity()
      } else if (type === 'district') {
        districtPage.value += 1
        getAddressDistrict()
      } else if (type === 'village') {
        villagePage.value += 1
        getAddressVillage()
      }
    }

    const changeAddress = async (type) => {
      setTimeout(() => {
        if (type === 'province') {
          cityPage.value = 1
          city.value = ''
          cityList.value = []
          cityDisabled.value = false

          district.value = ''
          districtList.value = []
          districtDisabled.value = true

          village.value = ''
          villageList.value = []
          villageDisabled.value = true

          if (province.value !== '') {
            getAddressCity()
          }
        } else if (type === 'city') {
          districtPage.value = 1
          district.value = ''
          districtList.value = []
          districtDisabled.value = false

          village.value = ''
          villageList.value = []
          villageDisabled.value = true

          if (city.value !== '') {
            getAddressDistrict()
          }
        } else if (type === 'district') {
          villagePage.value = 1
          village.value = ''
          villageList.value = []
          villageDisabled.value = false

          if (district.value !== '') {
            getAddressVillage()
          }
        }
      }, 200)
    }

    const changeFilter = (event) => {
      Object.assign(filterData, {
        ...filterData,
        page: Number(event.filterData.page),
        take: Number(event.filterData.take),
      })
      getAllData()
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getAllData()
      }, 500)
    }

    const supplierPreview = async (supplier) => {
      console.log('-supplier ', supplier)
      Object.assign(activeSupplier, supplier)
      supplierPreviewModal.value = true
    }

    const imagePreview = async (supplier) => {
      Object.assign(activeSupplier, supplier)
      imagePreviewModal.value = true
    }

    return {
      t,
      n,
      store,
      route,
      router,

      activeSupplier,

      roleAccess,
      superRoles,

      supplierImagePreview,
      imageFile,
      selectFile,
      previewImage,

      addEditModal,
      deleteModal,
      supplierPreviewModal,
      imagePreviewModal,

      supplierPreview,
      imagePreview,

      province,
      city,
      district,
      village,

      cityDisabled,
      districtDisabled,
      villageDisabled,

      provinceList,
      cityList,
      districtList,
      villageList,

      changeAddress,

      isFormLoading,
      isLoading,
      isButtonLoading,

      timer,

      status,
      message,

      titleModal,
      tableHeader,
      tableData,
      metaData,
      filterData,

      formData,
      validate,
      addData,
      resetForm,
      addForm,
      editForm,
      deleteForm,
      cancelDelete,
      activeData,
      deleteData,
      // editData,

      activeDataId,
      getAllData,

      showToast,
      changeFilter,
      searchFilter,
    }
  },
}
</script>
