<template>
  <div class="flex flex-col">
    <DetailImage
      v-if="activeProduct !== undefined && activeProduct.images !== undefined && activeProduct.images !== null"
      :images="activeProduct.images"
      :name="activeProduct.name"
      rounded-class="rounded-tr-xl rounded-tl-xl"
    />

    <div class="p-4">
      <div class="flex w-full truncate text-lg md:text-xl font-medium">
        {{ activeProduct.name }}
      </div>
      <div
        class="mt-2 flex w-full flex-wrap"
      >
        <Brand v-if="activeProduct.brand !== undefined" :name="activeProduct.brand.name" />
        <Category v-if="activeProduct.sub_category !== undefined && activeProduct.sub_category.category !== undefined" :name="activeProduct.sub_category.category.name" />
        <SubCategory v-if="activeProduct.sub_category !== undefined" :name="activeProduct.sub_category.name" />
      </div>

      <div
        v-if="basePrice !== null && salePrice !== null"
        class="mt-2 flex w-full flex-row border-dashed border-t-2 py-4"
      >
        <div v-if="showBasePrice" class="flex w-full flex-col">
          <div class="flex">
            <span class="text-xs text-gray-600"> Harga Beli </span>
          </div>
          <div class="mt-1 text-teal-600 font-semibold">
            <Currency :total-in-string="basePrice" class="text-lg" />
          </div>
        </div>
        <div v-if="salePrice !== undefined && salePrice !== null" class="flex w-full flex-col">
          <div class="flex">
            <span class="text-xs text-gray-600"> Harga Jual </span>
          </div>
          <div class="mt-1 font-bold text-red-600">
            <Currency :total-in-string="salePrice" class="text-lg" :class="showBasePrice ? 'text-red-600' : 'text-green-600'" />
          </div>
        </div>
      </div>
      <!-- <div v-else class="mt-4">
        <div class="flex font-bold text-red-600">
          <Currency :total-in-string="activeProduct.price" class="text-lg" />
        </div>
      </div> -->

      <div
        v-if="activeProduct.product_converters !== undefined"
        class="mt-2 flex w-full border-dashed border-t-2 pt-4"
      >
        <div
          v-for="(item, key) in activeProduct.product_converters"
          :key="item.id"
          class="flex w-1/2 flex-col p-3"
          :class="
            activeProduct.product_converters.length === 1 ? 'bg-gray-50 rounded-lg'
            : key === 0 ? 'bg-green-50/75 rounded-tl-lg rounded-bl-lg'
            : 'bg-amber-50/75 rounded-tr-lg rounded-br-lg'"
        >
          <div class="flex">
            <template v-if="item.base === true">
              <span class="text-xs">Satuan Besar</span>
            </template>
            <template v-else>
              <span class="text-xs">Satuan Kecil</span>
            </template>
          </div>
          <div class="flex flex-col">
            <div class="flex">
              <BarcodeGenerator
                :value="item.barcode"
                :line-color="'#000'"
                :width="1"
                :height="40"
                :font-size="14"
                :element-tag="'svg'"
                class="rounded-lg"
              />
            </div>
            <div class="flex">
              <div class="block truncate text-base font-medium">
                {{ item.product_unit.name.toUpperCase() }}
              </div>
              <template v-if="activeProduct.is_accessories === true">
                <span class="ml-2 mt-1 text-xs text-red-600">{{
                  item.converter
                }}</span>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="activeProduct.product_converters !== undefined && stock !== null"
        class="mt-2 flex w-full border-dashed border-t-2 pt-4"
      >
        <template v-if="activeProduct.product_converters.length === 2">
          <div class="flex w-1/2">
            <div class="py-1 px-3 flex items-center bg-green-50 border border-green-400 rounded-full text-green-700">
              <LayersIcon class="mr-1 h-4 w-4" /> Stok:
              {{ bigStock }}
            </div>
            <!-- Stock: &nbsp<span class="text-green-700 font-bold">{{ bigStock }}</span> -->
          </div>
          <div class="flex w-1/2">
            <div class="py-1 px-3 flex items-center bg-yellow-50 border border-yellow-400 rounded-full text-yellow-700">
              <LayersIcon class="mr-1 h-4 w-4" /> Stok:
              {{ smallStock }}
            </div>
            <!-- Stock: &nbsp<span class="text-green-700 font-bold">{{ smallStock }}</span> -->
          </div>
        </template>
        <template v-else>
          <div class="flex w-1/2">
            <div class="py-1 px-3 flex items-center bg-green-50 border border-green-400 rounded-full text-green-700">
              <LayersIcon class="mr-1 h-4 w-4" /> Stok:
              {{ bigStock }}
            </div>
            <!-- Stock: &nbsp<span class="text-green-700 font-bold">{{ bigStock }}</span> -->
          </div>
        </template>
      </div>
      <!-- <div
        v-else-if="activeProduct.product_converters !== undefined"
        class="mt-2 flex w-full border-dashed border-t-2 pt-4"
      >
        <UomConverter :converters="activeProduct.product_converters" />
      </div> -->
      <div class="mt-4 flex w-full border-dashed border-t-2 pt-4">
        <span class="text-gray-700" v-html="activeProduct.description" />
      </div>
    </div>
  </div>
</template>

<script>
import { Pagination } from 'swiper/modules'
import BarcodeGenerator from '@/components/barcode/Generator.vue'
import DetailImage from '@/components/detail-image/Main.vue'
import Currency from '@/components/Currency.vue'
import Brand from '@/components/detail-product/Brand.vue'
import Category from '@/components/detail-product/Category.vue'
import SubCategory from '@/components/detail-product/SubCategory.vue'
import UomConverter from '@/components/uom/Main.vue'

export default {
  name: 'DetailProduct',
  components: {
    Pagination,
    BarcodeGenerator,
    DetailImage,
    Currency,
    Brand,
    Category,
    SubCategory,
    UomConverter
  },
  props: {
    activeProduct: {
      type: Object,
      default: null,
      required: true,
    },
    basePrice: {
      type: Number,
      default: null,
      required: false,
    },
    salePrice: {
      type: Number,
      default: null,
      required: false,
    },
    showBasePrice: {
      type: Boolean,
      default: true,
      required: false,
    },
    stock: {
      type: Number,
      default: null,
      required: false,
    },
  },
  data: () => ({
    modulesImage: [Pagination],
    bigStock: 0,
    smallStock: 0,
  }),
  mounted() {
    if (this.activeProduct !== undefined && this.activeProduct.product_converters !== undefined && this.activeProduct.product_converters.length > 1) {
      this.activeProduct.product_converters.sort(function(x, y) {
          return (x === y)? 0 : x ? -1 : 1;
      });
    }

    this.convertStock()
  },
  methods: {
    convertStock() {
      const product = this.activeProduct
      const converters = product.product_converters

      if (converters !== undefined) {
        if (converters.length === 2) {
          let unitBig = {}
          let unitSmall = {}
          let converter = 0

          converters.map((item) => {
            if (item.base === true) {
              unitBig = item.product_unit
            } else {
              unitSmall = item.product_unit
              converter = item.converter
            }
          })

          const divideNumber = this.stock / converter

          if (converter > this.stock) {
            return `${this.stock} ${unitSmall.name}`
          } else if (divideNumber.toString().split('.').length > 0) {
            const roundNumber = Math.floor(divideNumber.toString().split('.')[0])
            const leftNumber = this.stock - roundNumber * converter

            this.bigStock = `${roundNumber} ${unitBig.name}`
            this.smallStock = `${leftNumber} ${unitSmall.name}`
          } else {
            this.bigStock = `${this.stock} ${unitBig.name}`
          }
        } else {
          if (converters.length > 0) {
            // default for unit product
            const unit = converters[0].product_unit
            this.bigStock = `${this.stock} ${unit.name}`
          }
        }
      }
    },
  },
}
</script>
