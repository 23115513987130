<template>
  <div
    class="flex group fixed bottom-6 right-0 z-50 h-24 w-24 items-end p-2 md:bottom-8"
    :class="{ 'animate-bounce': pingSignal }"
    @click="cartPreview = true"
  >
    <div
      class="flex absolute z-50 cursor-pointer items-center justify-center rounded-full bg-gradient-to-r from-teal-700 to-primary p-4 text-white shadow-xl"
    >
      <ShoppingBagIcon
        class="h-6 w-6 transition transition-all duration-[0.6s]"
      />
    </div>
    <div
      class="flex absolute z-50 -ml-2 mb-8 h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-red-500 p-3 text-white"
    >
      <span>{{ productDataSelected.length }}</span>
    </div>
  </div>

  <div class="intro-y flex mt-8 flex-col items-center sm:flex-row">
    <h2
      class="mr-auto text-lg font-medium"
    >
      Buat Penjualan
    </h2>
  </div>
  <div class="intro-y flex mt-4 w-full flex-col">
    <div class="w-full lg:flex">
      <div class="flex w-full md:w-auto">
        <div class="relative w-full">
          <input
            v-model="filterData.search"
            type="text"
            class="form-control box w-full py-3 px-4 pr-10 lg:w-80"
            placeholder="Cari produk..."
            @input="searchFilter()"
          />
          <SearchIcon
            class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4 text-slate-500"
          />
        </div>

        <!-- <select
            v-model="filterData.type"
            placeholder="Semua"
            class="box form-select ml-4 w-28"
            @change="getProduct(true)"
          >
            <option
              v-for="method in productTypeOptions"
              :key="method.id"
              :value="method.id"
            >
              {{ method.name }}
            </option>
          </select> -->
      </div>
      <!-- <select
            class="box form-select mt-3 ml-auto w-full py-3 px-4 lg:mt-0 lg:w-auto"
          >
            <option>Sort By</option>
            <option>A to Z</option>
            <option>Z to A</option>
            <option>Harga Terendah</option>
            <option>Harga Tertinggi</option>
            <option>Stok Terendah</option>
            <option>Stok Tertinggi</option>
            <option>Minimum Stok</option>
            <option>Maksimum Stok</option>
          </select> -->
    </div>
    <div
      v-if="productData.length === 0"
      class="mt-5 grid grid-cols-12 gap-5 border-b pb-8"
    >
      <div
        class="flex relative col-span-12 h-24 items-center justify-center rounded-md border border-dashed border-gray-300 py-5 text-center sm:col-span-4 2xl:col-span-3"
      >
        <span class="mb-2 block text-xs font-medium text-gray-400">
          Belum ada produk yang dicari
        </span>
      </div>
    </div>
    <div v-else class="flex mt-5 w-full flex-col pb-8">
      <div class="flex w-full">
        <span
          v-if="filterData.search === ''"
          class="text-sm italic text-gray-600"
          >Rekomendasi Produk</span
        >
        <span v-else class="text-sm italic text-gray-600">Hasil Pencarian</span>
      </div>
      <div
        class="mt-4 grid grid-cols-2 gap-3 md:gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6"
      >
        <div
          v-for="item in productData"
          :key="item.product.id"
          class="box flex h-full w-full flex-col"
        >
          <div class="flex w-full">
            <ImageLoader
              :image="
                item.product.images !== undefined && item.product.images.length > 0
                  ? item.product.images[0].image
                  : null
              "
              :alt="item.product.name"
              rounded-class="rounded-tr-md rounded-tl-md w-full cursor-pointer"
              @click="productPreview(item)"
            />
          </div>
          <div class="flex h-full w-full flex-col justify-between">
            <div class="flex w-full flex-col px-4 py-4">
              <div class="text-md font-medium truncate">
                {{ item.product.name }}
              </div>
              <div class="flex w-full text-sm font-semibold">
                <Currency :total-in-string="item.product.price.toString()" />
              </div>
              <div class="mt-2 flex">
                <div class="py-0.5 px-3 flex items-center bg-green-50 border border-green-300 rounded-full text-green-600">
                  <LayersIcon class="mr-1 h-4 w-4" /> Stok:
                  {{ item.stock }}
                </div>
              </div>
            </div>
            <div
              v-if="item.stock !== 0"
              class="flex cursor-pointer text-xs lg:text-sm items-center justify-center rounded-br-xl rounded-bl-xl bg-teal-600 py-3 px-2 text-center text-white hover:bg-teal-500"
              @click="addItem(item)"
            >
              <PlusCircleIcon class="mr-1 h-4 w-4 text-white" />
              <span class="flex font-semibold"> Tambahkan </span>
              <!-- <span class="flex lg:hidden"> Tambahkan </span> -->
            </div>
            <div
                v-else
                class="flex items-center justify-center rounded-br-xl rounded-bl-xl bg-gray-500 py-3 md:py-2 lg:py-3 px-2 text-center text-white"
              >
                <AlertCircleIcon class="mr-1 h-4 w-4 text-white" />
                <span class="flex"> Stok Habis </span>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- CART MODAL -->
  <Modal
    backdrop="static"
    :slide-over="true"
    :show="cartPreview"
    class="modal-cart"
    @hidden="cartPreview = false"
  >
    <a
      class="top-0 right-0 right-auto mt-4 -ml-12 hidden md:fixed md:flex"
      href="javascript:;"
      @click="cartPreview = false"
    >
      <XIcon class="h-8 w-8 text-white" />
    </a>
    <ModalHeader class="flex justify-between p-5">
      <h2 class="mr-auto text-base font-medium">Keranjang Sales Order</h2>
      <a
        class="flex md:hidden"
        href="javascript:;"
        @click="cartPreview = false"
      >
        <XIcon class="h-6 w-6 text-red-500" />
      </a>
    </ModalHeader>
    <ModalBody class="p-0">
      <div class="input-form my-4 px-4 w-full">
        <label
          for="form-payment-method"
          class="form-label flex w-full text-xs flex-col sm:flex-row"
        >
          {{ $t('formLabel.paymentMethod') }}
        </label>
        <div class="mt-2">
          <Multiselect
            v-model="paymentMethod"
            value="id"
            value-prop="id"
            label="name"
            track-by="name"
            :placeholder="
              isPaymentMethodLoading
                ? 'Memuat data....'
                : $t('formLabel.select.paymentMethod')
            "
            :options="paymentMethodData"
            :classes="multiSelectClasses"
            :class="isPaymentMethodError ? 'border-danger' : ''"
            :searchable="true"
            :loading="isPaymentMethodLoading"
          />

          <template v-if="isPaymentMethodError">
            <div class="mt-2 text-xs text-danger">
              Metode Pembayaran harus diisi
            </div>
          </template>
        </div>
      </div>

      <div class="input-form mb-4 w-full px-4">
        <label
          for="form-warung"
          class="form-label flex w-full text-xs flex-col sm:flex-row"
        >
          Warung
        </label>
        <div class="mt-2">
          <Multiselect
            v-model="customer"
            value="id"
            value-prop="id"
            label="full_name"
            track-by="full_name"
            :placeholder="
              isCustomerLoading ? 'Memuat data....' : 'Pilih Warung'
            "
            :options="customerData"
            :classes="multiSelectClasses"
            :class="isCustomerError ? 'border-danger' : ''"
            :internal-search="false"
            :searchable="true"
            :loading="isCustomerLoading"
            @select="setActiveCustomer($event)"
            @search-change="findCustomer"
          />

          <div v-if="activeCustomer !== undefined && activeCustomer.full_name !== undefined" class="border border-gray-200 px-3 py-4 rounded-bl-lg rounded-br-lg -mt-1 flex flex-col">
            <span class="font-semibold text-xs">{{  activeCustomer.full_name }} - ({{  activeCustomer.type }})</span>
            <span class="text-xs text-green-700">{{  activeCustomer.phone }}</span>
            <span class="text-xs text-gray-600">{{ activeCustomer.address.detail_address }}</span>
          </div>

          <template v-if="isCustomerError">
            <div class="mt-2 text-xs text-danger">Warung harus diisi</div>
          </template>
        </div>
      </div>

      <div
        v-if="productDataSelected.length === 0"
        class="grid grid-cols-12 pb-8"
      >
        <div class="h-4 col-span-12 bg-gray-100"></div>
        <div
          class="flex mx-4 mt-4 relative col-span-12 h-72 items-center justify-center rounded-md border border-dashed border-gray-300 py-5 text-center"
        >
          <span class="mb-2 block text-xs font-medium text-gray-400">
            Belum ada produk yang dipilih
          </span>
        </div>
      </div>
      <div v-else class="grid grid-cols-12">
        <div
          v-for="item in productDataSelected"
          :key="item.product.id"
          class="relative col-span-12"
        >
          <div class="h-4 w-full bg-gray-100"></div>
          <div class="flex flex-col">
            <div class="flex w-full">
              <div class="flex w-20 pl-4 pt-4 pb-2">
                <div class="w-full">
                  <ImageLoader
                    :image="
                      item.product.images !== undefined && item.product.images.length > 0
                        ? item.product.images[0].image
                        : null
                    "
                    :alt="item.product.name"
                    class="w-16"
                    rounded-class="rounded-md cursor-pointer"
                    @click="productPreview(item)"
                  />
                </div>
              </div>
              <div class="flex w-full flex-col pl-4 md:px-4 pt-4 pb-2">
                <div class="flex text-md w-full font-medium">
                  {{ item.product.name }}
                </div>
                <div class="flex mt-1 w-full">
                  <span
                    class="flex items-center text-xs text-green-500"
                  >
                    {{ item.product.brand.name }}
                  </span>
                </div>
                <div class="flex w-full mt-2 pt-1 border-t text-xs font-medium text-red-500">
                  <Currency :total-in-string="item.product.price.toString()" />
                </div>

                <div class="flex mt-4 mb-2">
                  <div class="flex">
                    <button
                      type="button"
                      class="btn mr-1 h-8 w-8 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                      @click="changeQuantity(item, false, false)"
                    >
                      -
                    </button>
                    <input
                      id="pos-form-4"
                      v-model="item.quantity"
                      type="text"
                      class="form-control w-16 text-center text-xs"
                      placeholder="0"
                      @input="calculateTotalPriceItem(item)"
                    />
                    <button
                      type="button"
                      class="btn ml-1 h-8 w-8 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                      @click="changeQuantity(item, false, true)"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <div class="flex w-12 justify-end py-2 pr-4">
                <a
                  class="flex mt-2 text-danger"
                  href="javascript:;"
                  @click="removeItem(item)"
                >
                  <Trash2Icon class="h-5 w-5" />
                </a>
              </div>

            </div>
            <div class="flex w-full flex-col border-t p-2 py-3 pl-4">
              <div class="flex w-full justify-between text-xs">
                <div class="flex items-center">Total</div>
                <div class="flex items-end pr-2">
                  <Currency :total-in-string="item.total_price.toString()" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h-4 w-full bg-gray-100"></div>
      <div class="flex flex-col pb-24 px-4">
        <div class="border mt-4 rounded-lg bg-teal-50 border-teal-200 p-4">
          <div class="flex">
            <div class="mr-auto text-gray-600">Subtotal</div>
            <div class="font-medium">
              <Currency
                :total-in-string="
                  subTotal.toString()
                "
              />
            </div>
          </div>
          <div class="mt-3 flex">
            <div class="mr-auto text-gray-600">Tax ({{ tax }}%)</div>
            <div class="font-medium">
              <Currency
                :total-in-string="(total - subTotal).toString()"
              />
            </div>
          </div>
        </div>
      </div>
      
    </ModalBody>
    <ModalFooter class="w-470 fixed bottom-0 z-20 bg-white p-0 px-4 py-4">
      <div class="flex justify-between">
        <div class="flex flex-col">
          <div class="mr-auto text-gray-600 text-xs font-medium">Total Harga</div>
          <div class="flex text-base font-medium">
            <Currency :total-in-string="total.toString()" />
          </div>
        </div>
        <div class="flex">
          <button
            type="button"
            class="btn rounded-xl flex w-40 bg-gradient-to-r from-teal-700 to-primary text-white"
            @click="createTransaction"
          >
            <LoadingIcon
              v-if="isFormLoading"
              icon="oval"
              color="white"
              class="mr-2 h-5 w-5"
            />
            Buat Pesanan
          </button>
        </div>
      </div>
    </ModalFooter>
  </Modal>

  <!-- DETAIL MODAL -->
  <Modal :show="productPreviewModal" @hidden="productPreviewModal = false">
    <ModalBody class="p-0">
      <DetailProduct
        v-if="activeProductStock.product !== undefined"
        :active-product="activeProductStock.product"
        :base-price="activeProductStock.base_price"
        :sale-price="activeProductStock.sale_price"
        :stock="activeProductStock.stock"
        :show-base-price="$h.roleCanManage($h.superRoles(), roleAccess)"
      />
    </ModalBody>
  </Modal>

  <DetailTransaction
    :show-modal="detailTrxModal" 
    :can-edit="false"
    :transaction="transaction"
    :auto-print="true"
    @on-close="detailTrxModal = false"
    @on-error="onError"
  />

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">
        {{ message }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helper as $h } from '@/utils/helper'
import Toastify from 'toastify-js'
import dayjs from 'dayjs'
import globalMixin from '@/mixins/global.js'
import useEmitter from '@/composables/useEmitter'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import Multiselect from '@vueform/multiselect'
import ShimmerCard from '@/components/shimmer/ShimmerCard.vue'
import DetailImage from '@/components/detail-image/Main.vue'
import DetailProduct from '@/components/detail-product/Main.vue'
import DetailTransaction from '@/components/transaction/Detail.vue'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Currency,
    Multiselect,
    ShimmerCard,
    DetailImage,
    DetailProduct,
    DetailTransaction,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const emitter = useEmitter()

    const activeUser = reactive({})
    const roleAccess = ref('')

    let transaction = reactive({})
    const activeProductStock = reactive({})

    const activeCustomer = reactive({})

    let custKeyword = ref('')
    const customer = ref('')
    const paymentMethod = ref('')

    const tax = ref(11)
    const subTotal = ref(0)
    const total = ref(0)

    const customerPage = ref(1)
    const paymentMethodPage = ref(1)

    const customerData = ref([])
    const paymentMethodData = ref([])

    const isFormLoading = ref(false)
    const isCustomerLoading = ref(false)
    const isPaymentMethodLoading = ref(false)
    const isLoading = ref(false)

    const pingSignal = ref(false)
    const timer = ref(undefined)

    const isCustomerError = ref(false)
    const isPaymentMethodError = ref(false)

    const productPreviewModal = ref(false)
    const detailTrxModal = ref(false)
    const cartPreview = ref(false)

    const status = ref('')
    const message = ref('')

    let productData = ref([])
    let productDataSelected = ref([])

    let metaData = reactive({
      page: 1,
      take: 6,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 12,
      search: '',
      order: 'ASC',
      // type: null,
    })

    onMounted(async () => {
      // clearing all event
      emitter.all.clear()

      Object.assign(activeUser, store.getters['auth/activeUser'])
      roleAccess.value = activeUser.role_codes

      emitter.on('update-data', (store) => {

        getProduct()
      })

      const storeId = store.getters['store/storeId']

      if (storeId === null) {
        setTimeout(() => {
          getProduct()
        }, 2000)
      } else {
        getProduct()
      }

      await getPaymentMethod()
    })

    const findCustomer = async (keyword) => {
      if (keyword !== undefined && keyword !== '' && keyword !== null) {
        custKeyword.value = keyword
      } else if (keyword === '' || keyword === null) {
        return
      }

      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getCustomer(true)
      }, 500)
    }

    const getCustomer = async (reset) => {
      if (reset === true) {
        customerPage.value = 1
        customerData.value = []
      }

      isCustomerLoading.value = true
      const storeId = store.getters['store/storeId']
      const queryParameters = {
        order: 'ASC',
        page: customerPage.value,
        take: 10,
        store_id: storeId,
        q: custKeyword.value,
      }
      await store
        .dispatch('storeCustomer/getAll', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const customerTemporary = []
            response.data.data.forEach((item) => {
              customerTemporary.push({
                value: item.code,
                ...item,
              })
            })
            customerData.value = [...customerData.value, ...customerTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('customer')
            } else {
              isCustomerLoading.value = false
            }
          }
        })
        .catch((error) => {})
    }

    const getPaymentMethod = async () => {
      const queryParameters = {
        order_rank: 'ASC',
        page: paymentMethodPage.value,
        take: 50,
        q: '',
      }
      await store
        .dispatch('master/paymentMethod', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const paymentMethodTemporary = []
            response.data.data.forEach((item) => {
              paymentMethodTemporary.push({
                value: item.code,
                ...item,
              })
            })
            paymentMethodData.value = [
              ...paymentMethodData.value,
              ...paymentMethodTemporary,
            ]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('paymentMethod')
            }
          }
        })
        .catch((error) => {})
    }

    const loadMores = async (type) => {
      if (type === 'customer') {
        customerPage.value += 1
        getCustomer()
      } else if (type === 'paymentMethod') {
        paymentMethodPage.value += 1
        getPaymentMethod()
      }
    }

    const getProduct = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        productData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }

      const storeId = store.getters['store/storeId']

      await store
        .dispatch('productStock/getByStore', {
          store_id: storeId,
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          q: filterData.search,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            productData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getProduct(true)
      }, 500)
    }

    const productPreview = (data) => {
      Object.assign(activeProductStock, data)
      productPreviewModal.value = true
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const resetForm = () => {
      customer.value = ''
      paymentMethod.value = ''
      subTotal.value = 0
      total.value = 0

      isCustomerError.value = false
      isPaymentMethodError.value = false

      productData.value = []
      productDataSelected.value = []
    }

    const addItem = (product) => {
      pingSignal.value = true
      if (productDataSelected.value.length === 0) {
        product.quantity = null
        product.price = null
        // product.quantity_bonus = null
        product.price_discount = null
        product.total_price = 0
        productDataSelected.value = [product]
      } else {
        let isDuplicate = false
        productDataSelected.value.forEach((item) => {
          if (item.id === product.id) {
            isDuplicate = true
          }
        })
        if (isDuplicate === false) {
          product.quantity = null
          product.price = null
          // product.quantity_bonus = null
          product.price_discount = null
          product.total_price = 0
          productDataSelected.value = [...productDataSelected.value, product]
        }
      }

      setTimeout(() => {
        pingSignal.value = false
      }, 1500)
    }

    const removeItem = (product) => {
      productDataSelected.value = productDataSelected.value.filter(
        (item) => item.id != product.id
      )
    }

    const changeQuantity = (product, bonus, added) => {
      // if (bonus === true) {
      //   if (added === true) {
      //     if (product.quantity_bonus === null) {
      //       product.quantity_bonus = 1
      //     } else {
      //       product.quantity_bonus = product.quantity_bonus + 1
      //     }
      //   } else {
      //     if (product.quantity_bonus !== 0) {
      //       product.quantity_bonus = product.quantity_bonus - 1
      //     }
      //   }
      // } else {
      if (added === true) {
        if (product.quantity === null) {
          product.quantity = 1
        } else {
          product.quantity = product.quantity + 1
        }
      } else {
        if (product.quantity !== 0) {
          product.quantity = product.quantity - 1
        }
      }
      // }

      calculateTotalPriceItem(product)
    }

    const calculateTotalPriceItem = (productStock) => {
      productStock.total_price =
        productStock.quantity * productStock.product.price -
        productStock.price_discount

      calculateTotalPrice()
    }

    const calculateTotalPrice = () => {
      let totalPrice = 0
      productDataSelected.value.forEach((item) => {
        totalPrice += item.total_price
      })

      if (tax.value.toString() !== '0') {
        subTotal.value = totalPrice - Math.round((totalPrice / 100) * tax.value)
      } else {
        subTotal.value = totalPrice
      }

      total.value = totalPrice
    }

    const createTransaction = async () => {
      if (productDataSelected.value.length === 0) {
        status.value = 'Gagal'
        message.value = 'Mohon untuk menambahkan item terlebih dahulu'
        setTimeout(() => {
          showToast()
        }, 200)
        return
      }

      let dataNotValid = false
      productDataSelected.value.forEach((item) => {
        if (item.quantity === 0 || item.quantity === null) {
          dataNotValid = true
        }
        //  else if (item.price * item.quantity <= item.price_discount) {
        //   status.value = 'Gagal'
        //   message.value = 'Harga Diskon tidak boleh melebihi harga beli'
        //   setTimeout(() => {
        //     showToast()
        //   }, 200)
        //   dataNotValid = true
        // }
      })

      if (dataNotValid === true) {
        status.value = 'Gagal'
        message.value = 'Quantity tidak boleh kosong'
        setTimeout(() => {
          showToast()
        }, 200)
        return
      }

      if (customer.value === '') {
        isCustomerError.value = true
      } else if (paymentMethod.value === '') {
        isPaymentMethodError.value = true
      }

      if (
        productDataSelected.value.length === 0 ||
        customer.value === '' ||
        paymentMethod.value === ''
      ) {
        return
      }

      isFormLoading.value = true

      const products = []

      productDataSelected.value.forEach((item) => {
        const product = {
          product_stock_id: item.id,
          quantity: item.quantity,
          // price: item.price,
          // quantity_bonus: item.quantity_bonus,
          // price_discount: item.price_discount,
        }

        products.push(product)
      })

      const storeId = store.getters['store/storeId']

      let payload = {
        store_id: storeId,
        user_id: activeUser.id,
        customer_id: customer.value,
        payment_method_id: paymentMethod.value,
        channel: "agent-web",
        tax_id: Number(tax.value),
        products,
      }

      await store
        .dispatch('transaction/create', payload)
        .then((response) => {
          if (response.statusCode === 200) {
            status.value = response.status
            message.value = response.message

            // show toast
            setTimeout(() => {
              showToast({
                success: true,
              })
            }, 200)

            setTimeout(() => {
              cartPreview.value = false
              
              resetForm()
              getProduct(true)

              Object.assign(transaction, response.data)
              detailTrxModal.value = true
            }, 500)

            // getProduct()
            // setTimeout(() => {
            //   router.push({
            //     name: 'transaction-detail',
            //     params: {
            //       id: response.data.id,
            //       slug: route.params.slug,
            //     },
            //     query: {
            //       lang: route.query.lang,
            //       print: 'true',
            //     },
            //   })
            // }, 500)
            
          } else {
            status.value = response.message.status
            message.value = response.message.detail
            // show toast
            setTimeout(() => {
              showToast()
            }, 200)
          }
          isFormLoading.value = false
        })
        .catch((error) => {
          console.log('error ', error)
          console.log('error.response ', error.response)
          const responseData = error.response.data
          isFormLoading.value = false
          status.value = responseData.message.status
          message.value = responseData.message.detail
          // show toast
          setTimeout(() => {
            showToast(false)
          }, 200)
        })
    }

    const onError = (msg) => {
      status.value = 'Gagal'
      message.value = msg
      // show toast
      setTimeout(() => {
        showToast()
      }, 200)
    }

    const setActiveCustomer = () => {
      isCustomerError.value = false;
      const filterData = customerData.value.filter(
        (item) => item.id === customer.value
      )

      if (filterData.length > 0) {
        Object.assign(activeCustomer, filterData[0])
      }
    }

    return {
      t,
      n,
      store,
      route,
      router,

      transaction,
      activeProductStock,
      activeCustomer,

      roleAccess,
      
      productPreviewModal,
      productPreview,
      detailTrxModal,
      cartPreview,

      customer,
      paymentMethod,
      tax,
      subTotal,
      total,

      customerData,
      paymentMethodData,

      isFormLoading,
      isCustomerLoading,
      isPaymentMethodLoading,
      isLoading,

      isCustomerError,
      isPaymentMethodError,

      status,
      message,

      metaData,
      filterData,

      productData,
      productDataSelected,

      addItem,
      removeItem,
      changeQuantity,
      calculateTotalPriceItem,
      calculateTotalPrice,

      createTransaction,
      resetForm,

      findCustomer,
      getCustomer,
      getPaymentMethod,
      getProduct,

      searchFilter,
      showToast,

      setActiveCustomer,

      pingSignal,
      timer,
      onError,
    }
  },
}
</script>
