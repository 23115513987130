<template>
  <div class="flex w-full">
    <div class="flex">
      <div
        class="flex h-14 w-14 flex-col justify-center rounded-md text-center"
        :class="
          document.extension === 'pdf'
            ? 'bg-orange-100 text-orange-600'
            : 'bg-purple-100 text-purple-600'
        "
      >
        <span class="text-xs">{{ document.extension.toUpperCase() }}</span>
      </div>
    </div>
    <div class="flex flex-col pl-2">
      <div class="flex">
        <span class="text-sm md:text-md text-gray-700">{{ document.name }}</span>
      </div>
      <div class="flex">
        <span class="text-xs text-gray-400">{{
          formatBytes(document.size)
        }}</span>
      </div>
      <div class="mt-1 flex cursor-pointer" @click="openURL">
        <span class="text-xs text-green-600">Buka Dokumen</span>
      </div>
    </div>
    <!-- <div
      class="flex py-4"
    >
      <div v-if="document.type === 'PAYMENT'" class="py-1 px-4 rotate-45 rounded-sm bg-sky-100 text-sky-500">
        <span class="text-xs">Pembayaran</span>
      </div>
      <div v-else-if="document.type === 'INVOICE'" class="py-1 px-4 rotate-45 rounded-sm bg-green-100 text-green-500">
        <span class="text-xs">Invoice</span>
      </div>
      <div v-else-if="document.type === 'DOCUMENT'" class="py-1 px-4 rotate-45 rounded-sm bg-purple-100 text-purple-500">
        <span class="text-xs">Dokumen</span>
      </div>
      <div v-else class="py-1 px-4 rotate-45 rounded-sm bg-gray-100 text-gray-500">
        <span class="text-xs">Draft</span>
      </div>
    </div> -->
  </div>
  <!-- <div class="image-fit top-0 left-0 h-full w-full">
    <ImageLoader :image="document.file" />
  </div> -->
</template>

<script>
import ImageLoader from '@/components/image-loader/Main.vue'

export default {
  name: 'DocumentFile',
  components: {
    ImageLoader,
  },
  props: {
    document: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data: () => ({}),
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    openURL() {
      window.open(this.document.file, '_blank')
    },
  },
}
</script>
