<template>
  <h2 class="intro-y mt-2 md:mt-6 text-lg font-medium">Merk Produk</h2>
  <ButtonAdd
    v-if="$h.roleCanManage($h.managementRoles(), roleAccess)"
    :is-loading="isButtonLoading"
    @click="addForm"
  />
  <div class="mt-0 grid grid-cols-12 gap-6 md:mt-5">
    <div class="intro-y col-span-12 mt-2 flex flex-wrap items-center">
      <div class="flex w-full md:w-auto">
        <div class="relative w-full text-slate-500 md:w-48 h-9.5">
          <input
            v-model="filterData.search"
            type="text"
            class="form-control box w-full pr-8 md:w-56 h-10"
            placeholder="Cari merk..."
            @input="searchFilter()"
          />
          <SearchIcon
            class="absolute inset-y-0 right-0 my-auto mr-4 h-4 w-4 md:-mr-4"
          />
        </div>
        <button
          class="btn box ml-2 shadow-sm md:ml-10"
          @click="filterModal = true"
        >
          <FilterIcon class="h-4 w-4" />
        </button>
      </div>
      <div class="mx-auto hidden text-slate-500 md:block"></div>
      <div class="mt-3 flex items-center md:mt-0 xl:mt-0 xl:w-auto">
        <!-- <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white mr-2 text-white">
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button> -->
        <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white  text-white" disabled @click="download">
          <!-- <template v-if="isDownloadLoading === true">
            <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
          </template> -->
          <FileTextIcon class="mr-2 h-4 w-4" /> Export ke Excel
        </button>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <TableComponent
      :show-loading="isLoading"
      :show-title="false"
      :show-search="false"
      :header="tableHeader"
      :meta-data="metaData"
      :is-not-empty="tableData.length > 0"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="(item, index) in tableData" :key="item.id" class="intro-x">
        <td class="!py-3.5">
          <div class="bg-gray-50 font-bold p-2 text-emerald-700 rounded-xl w-12 text-center">
            {{ (metaData.page !== 1 ? (metaData.page - 1) * metaData.take : 0) + index + 1 }}
          </div>
        </td>
        <td class="!py-3.5">
          <div class="flex items-center">
            <div class="image-fit zoom-in h-9 w-9">
              <ImageLoader
                :image="item.image"
                :alt="item.name"
                rounded-class="rounded-md"
                @click="imagePreview(item)"
              />
            </div>
          </div>
        </td>
        <td class="capitalize">
          {{ item.code }}
        </td>
        <td>
          {{ item.name }}
        </td>
        <!-- <td v-if="filterData.type === null" class="text-center capitalize">
          <div class="flex justify-center">
            <Alert
              v-if="item.is_accessories === true"
              className="alert-secondary text-white rounded-xl text-xs py-1 px-4"
              >AKSESORIS</Alert
            >
            <Alert
              v-else
              className="alert-danger text-white rounded-xl text-xs py-1 px-4"
              >UNIT</Alert
            >
          </div>
        </td> -->
        <!-- <td class="text-xs text-slate-600 dark:text-slate-500">
          {{ item.description }}
        </td> -->
        <td class="table-report__action w-32">
          <div
            v-if="$h.roleCanManage($h.managementRoles(), roleAccess)"
            class="flex items-center justify-center text-success">
            <a
              class="mr-3 flex items-center"
              href="javascript:;"
              @click="editForm(item)"
            >
              <CheckSquareIcon class="mr-1 h-4 w-4" /> Edit
            </a>
            <a
              class="flex items-center text-danger"
              href="javascript:;"
              @click="deleteForm(item)"
            >
              <Trash2Icon class="mr-1 h-4 w-4" /> Delete
            </a>
          </div>
          <div v-else class="flex items-center justify-center text-success">
            -
          </div>
        </td>
      </tr>
    </TableComponent>
    <!-- END: Data List -->
  </div>

  <!-- ADD & EDIT MODAL -->
  <Modal :show="addEditModal" @hidden="addEditModal = false">
    <ModalHeader>
      <h2 class="mr-auto text-base font-medium">{{ titleModal }}</h2>
    </ModalHeader>
    <ModalBody>
      <div class="flex w-full flex-col">
        <!-- <div v-if="activeDataId === null" class="w-full flex items-center">
          <input
            v-model="validate.is_accessories.$model"
            id="is-accessories"
            type="checkbox"
            class="form-check-input mr-2 border"
          />
          <label class="cursor-pointer select-none" for="is-accessories"
            >Untuk aksesoris</label
          >
        </div> -->
        <div class="input-form w-full">
          <label
            for="form-code"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.code') }}
          </label>
          <input
            id="form-code"
            v-model.trim="validate.code.$model"
            type="text"
            name="code"
            class="intro-x login__input form-control block py-3 px-4"
            :class="{ 'border-danger': validate.code.$error }"
            :placeholder="$t('formInput.enterCode')"
          />
          <template v-if="validate.code.$error">
            <div
              v-for="(error, index) in validate.code.$errors"
              :key="index"
              class="mt-2 text-xs text-danger"
            >
              {{ $t(error.$message) }}
            </div>
          </template>
        </div>
        <div class="input-form mt-3 w-full">
          <label
            for="form-name"
            class="form-label flex w-full flex-col sm:flex-row"
          >
            {{ $t('formLabel.name') }}
          </label>
          <input
            id="form-name"
            v-model.trim="validate.name.$model"
            type="text"
            name="name"
            class="intro-x login__input form-control block py-3 px-4"
            :class="{ 'border-danger': validate.name.$error }"
            :placeholder="$t('formInput.enterName')"
          />
          <template v-if="validate.name.$error">
            <div
              v-for="(error, index) in validate.name.$errors"
              :key="index"
              class="mt-2 text-xs text-danger"
            >
              {{ $t(error.$message) }}
            </div>
          </template>
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <button type="button" class="btn bg-gradient-to-r from-teal-700 to-primary text-white" @click="addData">
        <template v-if="isFormLoading === true">
          <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
        </template>
        Simpan
      </button>
    </ModalFooter>
  </Modal>

  <!-- DELETE MODAL -->
  <Modal :show="deleteModal" @hidden="cancelDelete">
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-xs text-danger" />
        <div class="mt-5">Apakah anda yakin ingin menghapus data ini ?</div>
        <div class="mt-2 text-lg text-slate-600">{{ activeData.name }}</div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          class="btn btn-outline-secondary mr-4 w-24"
          @click="cancelDelete"
        >
          Batal
        </button>
        <button type="button" class="btn btn-danger w-32" @click="deleteData">
          <template v-if="isFormLoading === true">
            <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
          </template>
          Hapus
        </button>
      </div>
    </ModalBody>
  </Modal>

  <!-- DETAIL IMAGE -->
  <Modal :show="imagePreviewModal" @hidden="imagePreviewModal = false">
    <ModalBody class="p-0">
      <div
        class="relative block flex-none before:block before:w-full before:pt-[100%]"
      >
        <div class="image-fit absolute top-0 left-0 h-full w-full">
          <ImageLoader
            :image="activeBrand.image"
            :alt="activeBrand.name"
            rounded-class="rounded-md"
          />
        </div>
      </div>
    </ModalBody>
  </Modal>

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">
        {{ message }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {
  helpers,
  required,
  minLength,
  maxLength,
  email,
  integer,
  url,
} from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Toastify from 'toastify-js'
import { helper as $h } from '@/utils/helper'
import globalMixin from '@/mixins/global.js'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import ButtonAdd from '@/components/button/ButtonAdd.vue'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Currency,
    ButtonAdd,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const activeUser = reactive({})
    const roleAccess = ref('')
    const superRoles = ref([
      'SUPERADMIN',
      'CEO',
      'HEAD_TECHNOLOGY',
      'HEAD_OPERATION',
      'HEAD_SALES',
      'HEAD_FINANCE',
    ])

    const addEditModal = ref(false)
    const deleteModal = ref(false)
    const imagePreviewModal = ref(false)

    const activeBrand = reactive({})

    const isFormLoading = ref(false)
    const isLoading = ref(false)
    const isButtonLoading = ref(false)

    const timer = ref(undefined)

    const status = ref('')
    const message = ref('')

    const titleModal = ref('')

    const tableHeader = ref([
      {
        item: 'NO',
        customClass: '',
      },
      {
        item: 'IMAGE',
        customClass: '',
      },
      {
        item: 'KODE',
        customClass: '',
      },
      {
        item: 'NAMA',
        customClass: '',
      },
      // {
      //   item: 'DESKRIPSI',
      //   customClass: '',
      // },
      {
        item: 'AKSI',
        customClass: 'text-center',
      },
    ])

    let activeDataId = ref(null)

    let tableData = ref([])

    let metaData = reactive({
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 10,
      search: '',
      order: 'ASC',
      type: null,
    })

    const productTypeOptions = ref([
      {
        id: null,
        name: 'Semua',
      },
      {
        id: 'UNITS',
        name: 'Unit',
      },
      {
        id: 'ACCESSORIES',
        name: 'Aksesoris',
      },
    ])

    const formData = reactive({
      code: '',
      name: '',
      // is_accessories: false
    })

    const activeData = reactive({
      code: '',
      name: '',
    })

    const codeRequired = helpers.withMessage(
      'formError.code.required',
      required
    )
    const nameRequired = helpers.withMessage(
      'formError.name.required',
      required
    )

    const rules = {
      code: {
        codeRequired,
      },
      name: {
        nameRequired,
      },
      // is_accessories: {}
    }

    const validate = useVuelidate(rules, toRefs(formData))

    onMounted(() => {
      Object.assign(activeUser, store.getters['auth/activeUser'])
      roleAccess.value = activeUser.role_codes

      getAllData()
    })

    const addData = async () => {
      validate.value.$touch()
      if (validate.value.$invalid == false) {
        isFormLoading.value = true

        let payload = {
          // id: null,
          code: formData.code,
          name: formData.name,
          // is_accessories: formData.is_accessories,
        }

        let endpoint = 'productBrand/create'

        if (activeDataId.value !== null && activeDataId.value !== '') {
          payload.id = activeDataId.value

          endpoint = 'productBrand/update'
        }

        await store
          .dispatch(endpoint, payload)
          .then((response) => {
            if (response.statusCode === 200) {
              status.value = response.status
              message.value = response.message

              addEditModal.value = false

              // show toast
              setTimeout(() => {
                showToast({
                  success: true,
                })
              }, 200)

              validate.value.$reset()

              getAllData()
            } else {
              status.value = response.message.status
              message.value = response.message.detail
              // show toast
              setTimeout(() => {
                showToast()
              }, 200)
            }
            isFormLoading.value = false
          })
          .catch((error) => {
            const responseData = error.response.data
            isFormLoading.value = false
            status.value = responseData.message.status
            message.value = responseData.message.detail
            // show toast
            setTimeout(() => {
              showToast(false)
            }, 200)
          })
      }
    }

    const deleteData = async () => {
      isFormLoading.value = true
      await store
        .dispatch('productBrand/delete', activeDataId.value)
        .then((response) => {
          if (response.statusCode === 200) {
            status.value = response.status
            message.value = response.message

            // show toast
            setTimeout(() => {
              showToast({
                success: true,
              })
            }, 200)

            validate.value.$reset()

            getAllData()
          } else {
            status.value = response.message.status
            message.value = response.message.detail
            // show toast
            setTimeout(() => {
              showToast()
            }, 200)
          }
          isFormLoading.value = false
          deleteModal.value = false
        })
        .catch((error) => {
          const responseData = error.response.data
          deleteModal.value = false
          isFormLoading.value = false
          status.value = responseData.message.status
          message.value = responseData.message.detail
          // show toast
          setTimeout(() => {
            showToast(false)
          }, 200)
        })
    }

    const resetForm = () => {
      validate.value.$reset()
      formData.code = ''
      formData.name = ''
      // formData.is_accessories = false
    }

    const addForm = () => {
      isButtonLoading.value = true

      resetForm()

      activeDataId.value = null

      titleModal.value = 'Tambah Merk Produk'
      addEditModal.value = true
      isButtonLoading.value = false
    }

    const editForm = (data) => {
      validate.value.$reset()

      activeDataId.value = data.id

      formData.code = data.code
      formData.name = data.name
      // formData.is_accessories = data.is_accessories

      titleModal.value = 'Ubah Merk Produk'
      addEditModal.value = true
    }

    const deleteForm = (data) => {
      validate.value.$reset()
      activeData.code = data.code
      activeData.name = data.name

      activeDataId.value = data.id

      deleteModal.value = true
    }

    const cancelDelete = (data) => {
      activeData.code = ''
      activeData.name = ''

      activeDataId.value = null

      deleteModal.value = false
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const getAllData = async (success) => {
      if (filterData.page === 1) {
        isLoading.value = true
      }
      await store
        .dispatch('productBrand/getAll', {
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          q: filterData.search,
          // is_accessories: filterData.type === 'ACCESSORIES' ? true : filterData.type === 'UNITS' ? false : null
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            tableData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const changeFilter = (event) => {
      Object.assign(filterData, {
        ...filterData,
        page: Number(event.filterData.page),
        take: Number(event.filterData.take),
      })
      getAllData()
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getAllData()
      }, 500)
    }

    const imagePreview = async (brand) => {
      Object.assign(activeBrand, brand)
      imagePreviewModal.value = true
    }

    return {
      t,
      n,
      store,
      route,
      router,

      roleAccess,
      superRoles,

      addEditModal,
      deleteModal,
      imagePreviewModal,

      activeBrand,

      imagePreview,

      isFormLoading,
      isLoading,
      isButtonLoading,

      timer,

      status,
      message,

      titleModal,
      tableHeader,
      tableData,
      metaData,
      filterData,
      productTypeOptions,

      showToast,

      formData,
      validate,
      addData,
      resetForm,
      addForm,
      editForm,
      deleteForm,
      cancelDelete,
      activeData,
      deleteData,
      // editData,

      activeDataId,
      getAllData,
      changeFilter,
      searchFilter,
    }
  },
}
</script>
