import axios from 'axios'

const tokenType = localStorage.getItem('gromura.tokenType') || 'Bearer'
const accessToken = localStorage.getItem('gromura.accessToken') || null
const refreshToken = localStorage.getItem('gromura.refreshToken') || null

if (accessToken !== null) {
  axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
} else {
  delete axios.defaults.headers.common['Authorization']
}

// if (
//   import.meta.env.MODE === 'production' ||
//   import.meta.env.MODE === 'staging'
// ) {
//   axios.defaults.baseURL = import.meta.env.VITE_API_URL
// } else {
//   axios.defaults.baseURL = 'https://gromura-api.kiraket.com'
// }
axios.defaults.baseURL = import.meta.env.VITE_API_URL

export function routePathAPI() {
  return '/v1'

  // if (
  //   import.meta.env.MODE === 'production' ||
  //   import.meta.env.MODE === 'staging'
  // ) {
  //   return '/v1'
  // } else {
  //   // return '/api/v1'
  // }
}

const platform = 'Web'

const axiosInstance = axios.create({
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-key': import.meta.env.VITE_API_KEY ?? '1ab2c3d4e5f61ab2c3d4e5f6',
    'x-language-code': import.meta.env.VITE_LANGUAGE_CODE ?? 'id_ID',
    'token-type': platform,
  },
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response, config } = error

    if (response.status === 401) {
      return axios
        .post(
          `${routePathAPI()}/auth/token/refresh`,
          { refresh_token: refreshToken },
          {
            ...config,
          }
        )
        .then((response) => {
          const statusCode = response.data.statusCode
          if (statusCode === 200) {
            const token = response.data.data

            // SET NEW TOKEN
            localStorage.setItem('gromura.accessToken', token.access_token)
            // APPEND TOKEN TO CURRENT HEADER BEARER
            axiosInstance.defaults.headers.common[
              'Authorization'
            ] = `${tokenType} ${token.access_token}`

            // RETRYING REQUEST
            return axiosInstance(config)
          } else {
            return Promise.reject(response)
          }
        })
        .catch(() => {
          return Promise.reject(error)
        })
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
