import i18n from '../plugins/i18n.js'
import { createRouter, createWebHistory } from 'vue-router'
import { LANGUAGES } from '../constants'

import SideMenu from '../layouts/side-menu/Main.vue'
import SimpleMenu from '../layouts/simple-menu/Main.vue'
import TopMenu from '../layouts/top-menu/Main.vue'

import Blank from '../views/Blank.vue'

import VersionPage from '../views/Version.vue'
import ErrorPage from '../views/Error.vue'

import Login from '../views/Login.vue'
import Register from '../views/Register.vue'

import Dashboard from '../views/Dashboard.vue'

import Profile from '../views/profile/Index.vue'
import Bussiness from '../views/business/Index.vue'
import UserManager from '../views/user/Index.vue'
import UserStaff from '../views/user/Staff.vue'

import UpdateProfile from '../views/sample/update-profile/Main.vue'
import ChangePassword from '../views/sample/change-password/Main.vue'

import AddressProvince from '../views/address/Province.vue'
import AddressCity from '../views/address/City.vue'
import AddressDistrict from '../views/address/District.vue'
import AddressVillage from '../views/address/Village.vue'

import PaymentMethod from '../views/payment-method/Index.vue'
import PaymentTerm from '../views/payment-term/Index.vue'
import Supplier from '../views/supplier/Index.vue'

import StoreList from '../views/store/Index.vue'
import StoreProfile from '../views/store/Profile.vue'
import StoreStaff from '../views/store/Staff.vue'

import Agent from '../views/agent/Index.vue'
import Customer from '../views/customer/Index.vue'

import ProductCategory from '../views/product/Category.vue'
import ProductSubCategory from '../views/product/SubCategory.vue'
import ProductBrand from '../views/product/Brand.vue'
import ProductUoM from '../views/product/UoM.vue'
import Product from '../views/product/Index.vue'
import AddProduct from '../views/product/Add.vue'
import ProductStock from '../views/product/ProductStock.vue'
// import BadStock from '../views/product/BadStock.vue'
import PosStock from '../views/product/PosStock.vue'
import SalePrice from '../views/product/SalePrice.vue'

import PurchaseOrder from '../views/purchase-order/Index.vue'
import PurchaseOrderDetail from '../views/purchase-order/Detail.vue'
import AddPurchaseOrder from '../views/purchase-order/Add.vue'
import ReceivePurchaseOrder from '../views/purchase-order/Receive.vue'
import ReceiptList from '../views/purchase-order/Receipt.vue'
import SalesOrder from '../views/SalesOrder.vue'
import PointOfSale from '../views/PointOfSale.vue'

import StockOpname from '../views/stock-opname/Index.vue'
import AddStockOpname from '../views/stock-opname/Add.vue'

import ReturStock from '../views/retur-stock/Index.vue'
import AddReturStock from '../views/retur-stock/Add.vue'

// import AllTransactionList from '../views/transaction/Main.vue'
import TransactionList from '../views/transaction/Index.vue'
import TransactionDetail from '../views/transaction/Detail.vue'

import WhatsappTemplate from '../views/whatsapp/Template.vue'
import WhatsappBlast from '../views/whatsapp/Index.vue'

import ReportSummary from '../views/report/Summary.vue'
import ReportDailySales from '../views/report/DailySales.vue'
import ReportProductSales from '../views/report/ProductSales.vue'
import ReportPaymentMethod from '../views/report/PaymentMethod.vue'
import ReportSalesPerDay from '../views/report/SalesPerDay.vue'
import ReportSalesPerProduct from '../views/report/SalesPerProduct.vue'

import AnalyticCustomerTurnOver from '../views/analytic/CustomerTurnOver.vue'
import AnalyticStockPrediction from '../views/analytic/StockPrediction.vue'
import AnalyticGrossProfit from '../views/analytic/GrossProfit.vue'
import AnalyticGrowthPercentage from '../views/analytic/GrowthPercentage.vue'
import AnalyticTransactionAverage from '../views/analytic/TransactionAverage.vue'
import AnalyticStockTurnOver from '../views/analytic/StockTurnOver.vue'

import DashboardOverview1 from '../views/sample/dashboard-overview-1/Main.vue'
import DashboardOverview2 from '../views/sample/dashboard-overview-2/Main.vue'
import DashboardOverview3 from '../views/sample/dashboard-overview-3/Main.vue'
import DashboardOverview4 from '../views/sample/dashboard-overview-4/Main.vue'
import Categories from '../views/sample/categories/Main.vue'

import ProductList from '../views/sample/product-list/Main.vue'
import ProductGrid from '../views/sample/product-grid/Main.vue'
import SellerList from '../views/sample/seller-list/Main.vue'
import SellerDetail from '../views/sample/seller-detail/Main.vue'
import Reviews from '../views/sample/reviews/Main.vue'
import Inbox from '../views/sample/inbox/Main.vue'
import FileManager from '../views/sample/file-manager/Main.vue'
import Chat from '../views/sample/chat/Main.vue'
import Post from '../views/sample/post/Main.vue'
import Calendar from '../views/sample/calendar/Main.vue'
import CrudDataList from '../views/sample/crud-data-list/Main.vue'
import CrudForm from '../views/sample/crud-form/Main.vue'
import UsersLayout1 from '../views/sample/users-layout-1/Main.vue'
import UsersLayout2 from '../views/sample/users-layout-2/Main.vue'
import UsersLayout3 from '../views/sample/users-layout-3/Main.vue'
import ProfileOverview1 from '../views/sample/profile-overview-1/Main.vue'
import ProfileOverview2 from '../views/sample/profile-overview-2/Main.vue'
import ProfileOverview3 from '../views/sample/profile-overview-3/Main.vue'
import WizardLayout1 from '../views/sample/wizard-layout-1/Main.vue'
import WizardLayout2 from '../views/sample/wizard-layout-2/Main.vue'
import WizardLayout3 from '../views/sample/wizard-layout-3/Main.vue'
import BlogLayout1 from '../views/sample/blog-layout-1/Main.vue'
import BlogLayout2 from '../views/sample/blog-layout-2/Main.vue'
import BlogLayout3 from '../views/sample/blog-layout-3/Main.vue'
import PricingLayout1 from '../views/sample/pricing-layout-1/Main.vue'
import PricingLayout2 from '../views/sample/pricing-layout-2/Main.vue'
import InvoiceLayout1 from '../views/sample/invoice-layout-1/Main.vue'
import InvoiceLayout2 from '../views/sample/invoice-layout-2/Main.vue'
import FaqLayout1 from '../views/sample/faq-layout-1/Main.vue'
import FaqLayout2 from '../views/sample/faq-layout-2/Main.vue'
import FaqLayout3 from '../views/sample/faq-layout-3/Main.vue'
import RegularTable from '../views/sample/regular-table/Main.vue'
import Tabulator from '../views/sample/tabulator/Main.vue'
import Modal from '../views/sample/modal/Main.vue'
import SlideOver from '../views/sample/slide-over/Main.vue'
import Notification from '../views/sample/notification/Main.vue'
import Tab from '../views/sample/tab/Main.vue'
import Accordion from '../views/sample/accordion/Main.vue'
import Button from '../views/sample/button/Main.vue'
import Alert from '../views/sample/alert/Main.vue'
import ProgressBar from '../views/sample/progress-bar/Main.vue'
import Tooltip from '../views/sample/tooltip/Main.vue'
import Dropdown from '../views/sample/dropdown/Main.vue'
import Typography from '../views/sample/typography/Main.vue'
import Icon from '../views/sample/icon/Main.vue'
import LoadingIcon from '../views/sample/loading-icon/Main.vue'
import RegularForm from '../views/sample/regular-form/Main.vue'
import Datepicker from '../views/sample/datepicker/Main.vue'
import TomSelect from '../views/sample/tom-select/Main.vue'
import FileUpload from '../views/sample/file-upload/Main.vue'
import WysiwygEditor from '../views/sample/wysiwg-editor/Main.vue'
import Validation from '../views/sample/validation/Main.vue'
import Chart from '../views/sample/chart/Main.vue'
import Slider from '../views/sample/slider/Main.vue'
import ImageZoom from '../views/sample/image-zoom/Main.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/version',
    name: 'version',
    component: VersionPage,
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage,
  },
  {
    path: '/',
    component: SideMenu,
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          title: 'Dashboard',
          general: true,
          store: true,
        },
      },
      {
        path: 'address/province',
        name: 'address-province',
        component: AddressProvince,
        meta: {
          title: 'Provinsi',
        },
      },
      {
        path: 'address/city',
        name: 'address-city',
        component: AddressCity,
        meta: {
          title: 'Kota',
        },
      },
      {
        path: 'address/district',
        name: 'address-district',
        component: AddressDistrict,
        meta: {
          title: 'Kecamatan',
        },
      },
      {
        path: 'address/village',
        name: 'address-village',
        component: AddressVillage,
        meta: {
          title: 'Kelurahan',
        },
      },
      {
        path: 'profile',
        name: 'profile-user',
        component: Profile,
        meta: {
          title: 'menu.profile.account',
          general: true,
        },
      },
      {
        path: 'business/profile',
        name: 'profile-business',
        component: Bussiness,
        meta: {
          title: 'menu.profile.business',
          general: true,
        },
      },
      {
        path: 'store/profile',
        name: 'profile-store',
        component: StoreProfile,
        meta: {
          title: 'menu.profile.store',
          store: true,
          general: true,
        },
      },
      {
        path: 'store',
        name: 'store-list',
        component: StoreList,
        meta: {
          title: 'List Outlet',
          general: true,
        },
      },
      {
        path: 'store/staff',
        name: 'employee',
        component: StoreStaff,
        meta: {
          title: 'Staff Outlet',
          store: true,
          // general: true,
        },
      },
      {
        path: 'user',
        name: 'user-manager',
        component: UserManager,
        meta: {
          title: 'Direksi',
          general: true,
        },
      },
      {
        path: 'user/staff',
        name: 'user-staff',
        component: UserStaff,
        meta: {
          title: 'Karyawan',
          store: true,
          // general: true,
        },
      },
      {
        path: 'agent',
        name: 'agent-list',
        component: Agent,
        meta: {
          title: 'Agen Sales',
          store: true,
        },
      },
      {
        path: 'customer',
        name: 'customer-list',
        component: Customer,
        meta: {
          title: 'Data Warung',
          store: true,
          // general: true,
        },
      },
      {
        path: 'payment/method',
        name: 'payment-method',
        component: PaymentMethod,
        meta: {
          title: 'Metode Pembayaran',
        },
      },
      {
        path: 'payment/term',
        name: 'payment-term',
        component: PaymentTerm,
        meta: {
          title: 'Term Pembayaran',
        },
      },
      {
        path: 'supplier',
        name: 'supplier',
        component: Supplier,
        meta: {
          title: 'Supplier',
        },
      },
      {
        path: 'product/category',
        name: 'product-category',
        component: ProductCategory,
        meta: {
          title: 'Kategori Produk',
          general: true,
        },
      },
      {
        path: 'product/category/sub',
        name: 'product-sub-category',
        component: ProductSubCategory,
        meta: {
          title: 'Sub Kategori Produk',
          general: true,
        },
      },
      {
        path: 'product/principle',
        name: 'product-principle',
        component: ProductBrand,
        meta: {
          title: 'Merk Produk',
          general: true,
        },
      },
      {
        path: 'product/uom',
        name: 'product-uom',
        component: ProductUoM,
        meta: {
          title: 'UoM Produk',
          general: true,
        },
      },
      {
        path: 'product/add',
        name: 'add-product',
        component: AddProduct,
        meta: {
          title: 'Tambah Produk',
          general: true,
        },
      },
      {
        path: 'product',
        name: 'product',
        component: Product,
        meta: {
          title: 'List Produk',
          general: true,
        },
      },
      {
        path: 'product/stock',
        name: 'product-stock',
        component: ProductStock,
        meta: {
          title: 'Stock',
          store: true,
          // general: true,
        },
      },
      // {
      //   path: 'product/stock/good',
      //   name: 'product-good-stock',
      //   component: GoodStock,
      //   meta: {
      //     title: 'Good Stock',
      //     store: true,
      //     // general: true,
      //   },
      // },
      // {
      //   path: 'product/stock/bad',
      //   name: 'product-bad-stock',
      //   component: BadStock,
      //   meta: {
      //     title: 'Bad Stock',
      //     store: true,
      //     // general: true,
      //   },
      // },
      // {
      //   path: 'product/stock/pos',
      //   name: 'product-pos-stock',
      //   component: PosStock,
      //   meta: {
      //     title: 'PoS Stock',
      //     store: true,
      //     // general: true,
      //   },
      // },
      // {
      //   path: 'product/grid',
      //   name: 'product-grid',
      //   component: ProductGrid,
      //   meta: {
      //     title: 'general.hello',
      //     store: true,
      //     // general: true,
      //   },
      // },
      {
        path: 'sale/price',
        name: 'sale-price',
        component: SalePrice,
        meta: {
          title: 'Harga Jual',
          store: true,
          // general: true,
        },
      },
      {
        path: 'stock/opname',
        name: 'stock-opname',
        component: StockOpname,
        meta: {
          title: 'Stock Opname',
          store: true,
          // general: true,
        },
      },
      // {
      //   path: 'stock/opname/add',
      //   name: 'add-stock-opname',
      //   component: AddStockOpname,
      //   meta: {
      //     title: 'Buat Stock Opname',
      //     store: true,
      //     // general: true,
      //   },
      // },
      {
        path: 'stock/retur',
        name: 'retur-stock',
        component: ReturStock,
        meta: {
          title: 'Retur Stock',
          store: true,
          // general: true,
        },
      },
      // {
      //   path: 'stock/retur/add',
      //   name: 'add-retur-stock',
      //   component: AddReturStock,
      //   meta: {
      //     title: 'Buat Retur Stock',
      //     store: true,
      //     // general: true,
      //   },
      // },
      {
        path: 'purchase',
        name: 'purchase',
        component: PurchaseOrder,
        meta: {
          title: 'Pembelian',
          store: true,
        },
      },
      // {
      //   path: 'purchase/:id',
      //   name: 'purchase-detail',
      //   component: PurchaseOrderDetail,
      //   meta: {
      //     title: 'Detail Pembelian',
      //   },
      // },
      {
        path: 'purchase/order',
        name: 'purchase-order',
        component: AddPurchaseOrder,
        meta: {
          title: 'Pembelian',
          store: true,
        },
      },
      // {
      //   path: 'purchase/receive',
      //   name: 'purchase-receive',
      //   component: ReceivePurchaseOrder,
      //   meta: {
      //     title: 'Terima Pembelian',
      //   },
      // },
      // {
      //   path: 'receipt',
      //   name: 'receipt',
      //   component: ReceiptList,
      //   meta: {
      //     title: 'List Receipt',
      //     store: true,
      //   },
      // },
      {
        path: 'sales/order',
        name: 'sales-order',
        component: SalesOrder,
        meta: {
          title: 'Sales Order',
          store: true,
        },
      },
      {
        path: 'pos',
        name: 'point-of-sale',
        component: PointOfSale,
        meta: {
          title: 'Point of Sale',
          store: true,
        },
      },
      // {
      //   path: 'transaction/all',
      //   name: 'all-transaction-list',
      //   component: AllTransactionList,
      //   meta: {
      //     title: 'Transaksi',
      //     store: true,
      //   },
      // },
      {
        path: 'transaction',
        name: 'transaction',
        component: TransactionList,
        meta: {
          title: 'Transaksi',
          store: true,
        },
      },
      // {
      //   path: 'transaction/:id',
      //   name: 'transaction-detail',
      //   component: TransactionDetail,
      //   meta: {
      //     title: 'Detail Transaksi',
      //   },
      // },
      {
        path: 'whatsapp-template',
        name: 'whatsapp-template',
        component: WhatsappTemplate,
        meta: {
          title: 'Whatsapp Template',
        },
      },
      {
        path: 'whatsapp-blast',
        name: 'whatsapp-blast',
        component: WhatsappBlast,
        meta: {
          title: 'Whatsapp Blast',
        },
      },
      // {
      //   path: 'transaction/detail',
      //   name: 'transaction-detail',
      //   component: TransactionDetail,
      //   meta: {
      //     title: 'Detail Transaksi',
      //   },
      // },

      // REPORT MENU
      {
        path: 'report/summary',
        name: 'report-summary',
        component: ReportSummary,
        meta: {
          title: 'menu.report.summary',
          store: true,
        },
      },
      {
        path: 'report/day',
        name: 'report-day',
        component: ReportSalesPerDay,
        meta: {
          title: 'menu.report.dailySales',
          store: true,
        },
      },
      {
        path: 'report/product',
        name: 'report-product',
        component: ReportSalesPerProduct,
        meta: {
          title: 'menu.report.productSales',
          store: true,
        },
      },
      {
        path: 'report/order-detail',
        name: 'report-order-detail',
        component: Blank,
        meta: {
          title: 'menu.report.orderDetail',
          store: true,
        },
      },
      // {
      //   path: 'report/tenant-sales',
      //   name: 'report-tenant-sales',
      //   component: Blank,
      //   meta: {
      //     title: 'menu.report.tenantSales',
      //   },
      // },
      {
        path: 'report/daily-sales',
        name: 'report-daily-sales',
        component: ReportDailySales,
        meta: {
          title: 'menu.report.dailySales',
          store: true,
        },
      },
      {
        path: 'report/product-sales',
        name: 'report-product-sales',
        component: ReportProductSales,
        meta: {
          title: 'menu.report.dailySales',
          store: true,
        },
      },
      {
        path: 'report/payment-method',
        name: 'report-payment-method',
        component: ReportPaymentMethod,
        meta: {
          title: 'menu.report.paymentMethod',
          store: true,
        },
      },
      // {
      //   path: 'report/withdrawal',
      //   name: 'report-withdrawal',
      //   component: Blank,
      //   meta: {
      //     title: 'menu.report.withdrawal',
      //     store: true,
      //   },
      // },
      // {
      //   path: 'report/promotion',
      //   name: 'report-promotion',
      //   component: Blank,
      //   meta: {
      //     title: 'menu.report.promotion',
      //     store: true,
      //   },
      // },
      // ANALYTIC MENU
      
      {
        path: 'analytic/turnover',
        name: 'analytic-turnover',
        component: AnalyticCustomerTurnOver,
        meta: {
          title: 'Customer Turnover',
          store: true,
        },
      },
      {
        path: 'analytic/sales-prediction',
        name: 'analytic-sales-prediction',
        component: AnalyticStockPrediction,
        meta: {
          title: 'Sales Prediction',
          store: true,
        },
      },
      {
        path: 'analytic/gross-profit',
        name: 'analytic-gross-profit',
        component: AnalyticGrossProfit,
        meta: {
          title: 'Gross & Profit',
          store: true,
        },
      },
      {
        path: 'analytic/transaction-average',
        name: 'analytic-transaction-average',
        component: AnalyticTransactionAverage,
        meta: {
          title: 'menu.analytic.averageTransaction',
          store: true,
        },
      },
      {
        path: 'analytic/growth-percentage',
        name: 'analytic-growth-percentage',
        component: AnalyticGrowthPercentage,
        meta: {
          title: 'menu.analytic.growthPercentage',
          store: true,
        },
      },
      {
        path: 'analytic/stock-turnover',
        name: 'analytic-stock-turnover',
        component: AnalyticStockTurnOver,
        meta: {
          title: 'menu.analytic.stockTurnOver',
          store: true,
        },
      },
      // {
      //   path: 'analytic/ecommerce-index',
      //   name: 'analytic-ecommerce-index',
      //   component: Blank,
      //   meta: {
      //     title: 'menu.analytic.ecommerceIndex',
      //     store: true,
      //   },
      // },
      // {
      //   path: 'analytic/satisfaction-index',
      //   name: 'analytic-satisfaction-index',
      //   component: Blank,
      //   meta: {
      //     title: 'menu.analytic.satisfactionIndex',
      //     store: true,
      //   },
      // },

      // SAMPLE PAGES
      {
        path: 'dashboard-overview-1',
        name: 'side-menu-dashboard-overview-1',
        component: DashboardOverview1,
        meta: {
          title: 'menu.dashboard',
          general: true,
        },
      },
      {
        path: 'dashboard-overview-2',
        name: 'side-menu-dashboard-overview-2',
        component: DashboardOverview2,
        meta: {
          title: 'menu.dashboard',
          general: true,
        },
      },
      {
        path: 'dashboard-overview-3',
        name: 'side-menu-dashboard-overview-3',
        component: DashboardOverview3,
        meta: {
          title: 'menu.dashboard',
          general: true,
        },
      },
      {
        path: 'dashboard-overview-4',
        name: 'side-menu-dashboard-overview-4',
        component: DashboardOverview4,
        meta: {
          title: 'menu.dashboard',
          general: true,
        },
      },
      {
        path: 'profile-overview-1',
        name: 'side-menu-profile-overview-1',
        component: ProfileOverview1,
        meta: {
          title: 'page.profile.index',
          general: true,
        },
      },
      {
        path: 'profile-overview-2',
        name: 'side-menu-profile-overview-2',
        component: ProfileOverview2,
        meta: {
          title: 'page.profile.index',
          general: true,
        },
      },
      {
        path: 'profile-overview-3',
        name: 'side-menu-profile-overview-3',
        component: ProfileOverview3,
        meta: {
          title: 'page.profile.index',
          general: true,
        },
      },
      {
        path: 'categories',
        name: 'side-menu-categories',
        component: Categories,
        meta: {
          title: 'general.hello',
          // general: true,
        },
      },
      {
        path: 'seller-list',
        name: 'side-menu-seller-list',
        component: SellerList,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'seller-detail',
        name: 'side-menu-seller-detail',
        component: SellerDetail,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'reviews',
        name: 'side-menu-reviews',
        component: Reviews,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'inbox',
        name: 'side-menu-inbox',
        component: Inbox,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'file-manager',
        name: 'side-menu-file-manager',
        component: FileManager,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },

      {
        path: 'chat',
        name: 'side-menu-chat',
        component: Chat,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'post',
        name: 'side-menu-post',
        component: Post,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'calendar',
        name: 'side-menu-calendar',
        component: Calendar,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'crud-data-list',
        name: 'side-menu-crud-data-list',
        component: CrudDataList,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'crud-form',
        name: 'side-menu-crud-form',
        component: CrudForm,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'users-layout-1',
        name: 'side-menu-users-layout-1',
        component: UsersLayout1,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'users-layout-2',
        name: 'side-menu-users-layout-2',
        component: UsersLayout2,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'users-layout-3',
        name: 'side-menu-users-layout-3',
        component: UsersLayout3,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },

      {
        path: 'wizard-layout-1',
        name: 'side-menu-wizard-layout-1',
        component: WizardLayout1,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'wizard-layout-2',
        name: 'side-menu-wizard-layout-2',
        component: WizardLayout2,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'wizard-layout-3',
        name: 'side-menu-wizard-layout-3',
        component: WizardLayout3,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'blog-layout-1',
        name: 'side-menu-blog-layout-1',
        component: BlogLayout1,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'blog-layout-2',
        name: 'side-menu-blog-layout-2',
        component: BlogLayout2,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'blog-layout-3',
        name: 'side-menu-blog-layout-3',
        component: BlogLayout3,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'pricing-layout-1',
        name: 'side-menu-pricing-layout-1',
        component: PricingLayout1,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'pricing-layout-2',
        name: 'side-menu-pricing-layout-2',
        component: PricingLayout2,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'invoice-layout-1',
        name: 'side-menu-invoice-layout-1',
        component: InvoiceLayout1,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'invoice-layout-2',
        name: 'side-menu-invoice-layout-2',
        component: InvoiceLayout2,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'faq-layout-1',
        name: 'side-menu-faq-layout-1',
        component: FaqLayout1,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'faq-layout-2',
        name: 'side-menu-faq-layout-2',
        component: FaqLayout2,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'faq-layout-3',
        name: 'side-menu-faq-layout-3',
        component: FaqLayout3,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'update-profile',
        name: 'side-menu-update-profile',
        component: UpdateProfile,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'change-password',
        name: 'side-menu-change-password',
        component: ChangePassword,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'regular-table',
        name: 'side-menu-regular-table',
        component: RegularTable,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'tabulator',
        name: 'side-menu-tabulator',
        component: Tabulator,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'modal',
        name: 'side-menu-modal',
        component: Modal,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'slide-over',
        name: 'side-menu-slide-over',
        component: SlideOver,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'notification',
        name: 'side-menu-notification',
        component: Notification,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'tab',
        name: 'side-menu-tab',
        component: Tab,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'accordion',
        name: 'side-menu-accordion',
        component: Accordion,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'button',
        name: 'side-menu-button',
        component: Button,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'alert',
        name: 'side-menu-alert',
        component: Alert,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'progress-bar',
        name: 'side-menu-progress-bar',
        component: ProgressBar,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'tooltip',
        name: 'side-menu-tooltip',
        component: Tooltip,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'dropdown',
        name: 'side-menu-dropdown',
        component: Dropdown,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'typography',
        name: 'side-menu-typography',
        component: Typography,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'icon',
        name: 'side-menu-icon',
        component: Icon,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'loading-icon',
        name: 'side-menu-loading-icon',
        component: LoadingIcon,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'regular-form',
        name: 'side-menu-regular-form',
        component: RegularForm,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'datepicker',
        name: 'side-menu-datepicker',
        component: Datepicker,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'tom-select',
        name: 'side-menu-tom-select',
        component: TomSelect,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'file-upload',
        name: 'side-menu-file-upload',
        component: FileUpload,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'wysiwyg-editor',
        name: 'side-menu-wysiwyg-editor',
        component: WysiwygEditor,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'validation',
        name: 'side-menu-validation',
        component: Validation,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'chart',
        name: 'side-menu-chart',
        component: Chart,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'slider',
        name: 'side-menu-slider',
        component: Slider,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
      {
        path: 'image-zoom',
        name: 'side-menu-image-zoom',
        component: ImageZoom,
        meta: {
          title: 'general.hello',
          general: true,
        },
      },
    ],
  },
  // {
  //   path: '/simple-menu',
  //   component: SimpleMenu,
  //   children: [
  //     {
  //       path: 'dashboard-overview-1',
  //       name: 'simple-menu-dashboard-overview-1',
  //       component: DashboardOverview1,
  //     },
  //     {
  //       path: 'dashboard-overview-2',
  //       name: 'simple-menu-dashboard-overview-2',
  //       component: DashboardOverview2,
  //     },
  //     {
  //       path: 'dashboard-overview-3',
  //       name: 'simple-menu-dashboard-overview-3',
  //       component: DashboardOverview3,
  //     },
  //     {
  //       path: 'dashboard-overview-4',
  //       name: 'simple-menu-dashboard-overview-4',
  //       component: DashboardOverview4,
  //     },
  //     {
  //       path: 'categories',
  //       name: 'simple-menu-categories',
  //       component: Categories,
  //     },
  //     {
  //       path: 'add-product',
  //       name: 'simple-menu-add-product',
  //       component: AddProduct,
  //     },
  //     {
  //       path: 'product-list',
  //       name: 'simple-menu-product-list',
  //       component: ProductList,
  //     },
  //     {
  //       path: 'product-grid',
  //       name: 'simple-menu-product-grid',
  //       component: ProductGrid,
  //     },
  //     {
  //       path: 'transaction-list',
  //       name: 'simple-menu-transaction-list',
  //       component: TransactionList,
  //     },
  //     {
  //       path: 'transaction-detail',
  //       name: 'simple-menu-transaction-detail',
  //       component: TransactionDetail,
  //     },
  //     {
  //       path: 'seller-list',
  //       name: 'simple-menu-seller-list',
  //       component: SellerList,
  //     },
  //     {
  //       path: 'seller-detail',
  //       name: 'simple-menu-seller-detail',
  //       component: SellerDetail,
  //     },
  //     {
  //       path: 'reviews',
  //       name: 'simple-menu-reviews',
  //       component: Reviews,
  //     },
  //     {
  //       path: 'inbox',
  //       name: 'simple-menu-inbox',
  //       component: Inbox,
  //     },
  //     {
  //       path: 'file-manager',
  //       name: 'simple-menu-file-manager',
  //       component: FileManager,
  //     },
  //     {
  //       path: 'point-of-sale',
  //       name: 'simple-menu-point-of-sale',
  //       component: PointOfSale,
  //     },
  //     {
  //       path: 'chat',
  //       name: 'simple-menu-chat',
  //       component: Chat,
  //     },
  //     {
  //       path: 'post',
  //       name: 'simple-menu-post',
  //       component: Post,
  //     },
  //     {
  //       path: 'calendar',
  //       name: 'simple-menu-calendar',
  //       component: Calendar,
  //     },
  //     {
  //       path: 'crud-data-list',
  //       name: 'simple-menu-crud-data-list',
  //       component: CrudDataList,
  //     },
  //     {
  //       path: 'crud-form',
  //       name: 'simple-menu-crud-form',
  //       component: CrudForm,
  //     },
  //     {
  //       path: 'users-layout-1',
  //       name: 'simple-menu-users-layout-1',
  //       component: UsersLayout1,
  //     },
  //     {
  //       path: 'users-layout-2',
  //       name: 'simple-menu-users-layout-2',
  //       component: UsersLayout2,
  //     },
  //     {
  //       path: 'users-layout-3',
  //       name: 'simple-menu-users-layout-3',
  //       component: UsersLayout3,
  //     },
  //     {
  //       path: 'profile-overview-1',
  //       name: 'simple-menu-profile-overview-1',
  //       component: ProfileOverview1,
  //     },
  //     {
  //       path: 'profile-overview-2',
  //       name: 'simple-menu-profile-overview-2',
  //       component: ProfileOverview2,
  //     },
  //     {
  //       path: 'profile-overview-3',
  //       name: 'simple-menu-profile-overview-3',
  //       component: ProfileOverview3,
  //     },
  //     {
  //       path: 'wizard-layout-1',
  //       name: 'simple-menu-wizard-layout-1',
  //       component: WizardLayout1,
  //     },
  //     {
  //       path: 'wizard-layout-2',
  //       name: 'simple-menu-wizard-layout-2',
  //       component: WizardLayout2,
  //     },
  //     {
  //       path: 'wizard-layout-3',
  //       name: 'simple-menu-wizard-layout-3',
  //       component: WizardLayout3,
  //     },
  //     {
  //       path: 'blog-layout-1',
  //       name: 'simple-menu-blog-layout-1',
  //       component: BlogLayout1,
  //     },
  //     {
  //       path: 'blog-layout-2',
  //       name: 'simple-menu-blog-layout-2',
  //       component: BlogLayout2,
  //     },
  //     {
  //       path: 'blog-layout-3',
  //       name: 'simple-menu-blog-layout-3',
  //       component: BlogLayout3,
  //     },
  //     {
  //       path: 'pricing-layout-1',
  //       name: 'simple-menu-pricing-layout-1',
  //       component: PricingLayout1,
  //     },
  //     {
  //       path: 'pricing-layout-2',
  //       name: 'simple-menu-pricing-layout-2',
  //       component: PricingLayout2,
  //     },
  //     {
  //       path: 'invoice-layout-1',
  //       name: 'simple-menu-invoice-layout-1',
  //       component: InvoiceLayout1,
  //     },
  //     {
  //       path: 'invoice-layout-2',
  //       name: 'simple-menu-invoice-layout-2',
  //       component: InvoiceLayout2,
  //     },
  //     {
  //       path: 'faq-layout-1',
  //       name: 'simple-menu-faq-layout-1',
  //       component: FaqLayout1,
  //     },
  //     {
  //       path: 'faq-layout-2',
  //       name: 'simple-menu-faq-layout-2',
  //       component: FaqLayout2,
  //     },
  //     {
  //       path: 'faq-layout-3',
  //       name: 'simple-menu-faq-layout-3',
  //       component: FaqLayout3,
  //     },
  //     {
  //       path: 'update-profile',
  //       name: 'simple-menu-update-profile',
  //       component: UpdateProfile,
  //     },
  //     {
  //       path: 'change-password',
  //       name: 'simple-menu-change-password',
  //       component: ChangePassword,
  //     },
  //     {
  //       path: 'regular-table',
  //       name: 'simple-menu-regular-table',
  //       component: RegularTable,
  //     },
  //     {
  //       path: 'tabulator',
  //       name: 'simple-menu-tabulator',
  //       component: Tabulator,
  //     },
  //     {
  //       path: 'modal',
  //       name: 'simple-menu-modal',
  //       component: Modal,
  //     },
  //     {
  //       path: 'slide-over',
  //       name: 'simple-menu-slide-over',
  //       component: SlideOver,
  //     },
  //     {
  //       path: 'notification',
  //       name: 'simple-menu-notification',
  //       component: Notification,
  //     },
  //     {
  //       path: 'tab',
  //       name: 'simple-menu-tab',
  //       component: Tab,
  //     },
  //     {
  //       path: 'accordion',
  //       name: 'simple-menu-accordion',
  //       component: Accordion,
  //     },
  //     {
  //       path: 'button',
  //       name: 'simple-menu-button',
  //       component: Button,
  //     },
  //     {
  //       path: 'alert',
  //       name: 'simple-menu-alert',
  //       component: Alert,
  //     },
  //     {
  //       path: 'progress-bar',
  //       name: 'simple-menu-progress-bar',
  //       component: ProgressBar,
  //     },
  //     {
  //       path: 'tooltip',
  //       name: 'simple-menu-tooltip',
  //       component: Tooltip,
  //     },
  //     {
  //       path: 'dropdown',
  //       name: 'simple-menu-dropdown',
  //       component: Dropdown,
  //     },
  //     {
  //       path: 'typography',
  //       name: 'simple-menu-typography',
  //       component: Typography,
  //     },
  //     {
  //       path: 'icon',
  //       name: 'simple-menu-icon',
  //       component: Icon,
  //     },
  //     {
  //       path: 'loading-icon',
  //       name: 'simple-menu-loading-icon',
  //       component: LoadingIcon,
  //     },
  //     {
  //       path: 'regular-form',
  //       name: 'simple-menu-regular-form',
  //       component: RegularForm,
  //     },
  //     {
  //       path: 'datepicker',
  //       name: 'simple-menu-datepicker',
  //       component: Datepicker,
  //     },
  //     {
  //       path: 'tom-select',
  //       name: 'simple-menu-tom-select',
  //       component: TomSelect,
  //     },
  //     {
  //       path: 'file-upload',
  //       name: 'simple-menu-file-upload',
  //       component: FileUpload,
  //     },
  //     {
  //       path: 'wysiwyg-editor',
  //       name: 'simple-menu-wysiwyg-editor',
  //       component: WysiwygEditor,
  //     },
  //     {
  //       path: 'validation',
  //       name: 'simple-menu-validation',
  //       component: Validation,
  //     },
  //     {
  //       path: 'chart',
  //       name: 'simple-menu-chart',
  //       component: Chart,
  //     },
  //     {
  //       path: 'slider',
  //       name: 'simple-menu-slider',
  //       component: Slider,
  //     },
  //     {
  //       path: 'image-zoom',
  //       name: 'simple-menu-image-zoom',
  //       component: ImageZoom,
  //     },
  //   ],
  // },
  // {
  //   path: '/top-menu',
  //   component: TopMenu,
  //   children: [
  //     {
  //       path: 'dashboard-overview-1',
  //       name: 'top-menu-dashboard-overview-1',
  //       component: DashboardOverview1,
  //     },
  //     {
  //       path: 'dashboard-overview-2',
  //       name: 'top-menu-dashboard-overview-2',
  //       component: DashboardOverview2,
  //     },
  //     {
  //       path: 'dashboard-overview-3',
  //       name: 'top-menu-dashboard-overview-3',
  //       component: DashboardOverview3,
  //     },
  //     {
  //       path: 'dashboard-overview-4',
  //       name: 'top-menu-dashboard-overview-4',
  //       component: DashboardOverview4,
  //     },
  //     {
  //       path: 'categories',
  //       name: 'top-menu-categories',
  //       component: Categories,
  //     },
  //     {
  //       path: 'add-product',
  //       name: 'top-menu-add-product',
  //       component: AddProduct,
  //     },
  //     {
  //       path: 'product-list',
  //       name: 'top-menu-product-list',
  //       component: ProductList,
  //     },
  //     {
  //       path: 'product-grid',
  //       name: 'top-menu-product-grid',
  //       component: ProductGrid,
  //     },
  //     {
  //       path: 'transaction-list',
  //       name: 'top-menu-transaction-list',
  //       component: TransactionList,
  //     },
  //     {
  //       path: 'transaction-detail',
  //       name: 'top-menu-transaction-detail',
  //       component: TransactionDetail,
  //     },
  //     {
  //       path: 'seller-list',
  //       name: 'top-menu-seller-list',
  //       component: SellerList,
  //     },
  //     {
  //       path: 'seller-detail',
  //       name: 'top-menu-seller-detail',
  //       component: SellerDetail,
  //     },
  //     {
  //       path: 'reviews',
  //       name: 'top-menu-reviews',
  //       component: Reviews,
  //     },
  //     {
  //       path: 'inbox',
  //       name: 'top-menu-inbox',
  //       component: Inbox,
  //     },
  //     {
  //       path: 'file-manager',
  //       name: 'top-menu-file-manager',
  //       component: FileManager,
  //     },
  //     {
  //       path: 'point-of-sale',
  //       name: 'top-menu-point-of-sale',
  //       component: PointOfSale,
  //     },
  //     {
  //       path: 'chat',
  //       name: 'top-menu-chat',
  //       component: Chat,
  //     },
  //     {
  //       path: 'post',
  //       name: 'top-menu-post',
  //       component: Post,
  //     },
  //     {
  //       path: 'calendar',
  //       name: 'top-menu-calendar',
  //       component: Calendar,
  //     },
  //     {
  //       path: 'crud-data-list',
  //       name: 'top-menu-crud-data-list',
  //       component: CrudDataList,
  //     },
  //     {
  //       path: 'crud-form',
  //       name: 'top-menu-crud-form',
  //       component: CrudForm,
  //     },
  //     {
  //       path: 'users-layout-1',
  //       name: 'top-menu-users-layout-1',
  //       component: UsersLayout1,
  //     },
  //     {
  //       path: 'users-layout-2',
  //       name: 'top-menu-users-layout-2',
  //       component: UsersLayout2,
  //     },
  //     {
  //       path: 'users-layout-3',
  //       name: 'top-menu-users-layout-3',
  //       component: UsersLayout3,
  //     },
  //     {
  //       path: 'profile-overview-1',
  //       name: 'top-menu-profile-overview-1',
  //       component: ProfileOverview1,
  //     },
  //     {
  //       path: 'profile-overview-2',
  //       name: 'top-menu-profile-overview-2',
  //       component: ProfileOverview2,
  //     },
  //     {
  //       path: 'profile-overview-3',
  //       name: 'top-menu-profile-overview-3',
  //       component: ProfileOverview3,
  //     },
  //     {
  //       path: 'wizard-layout-1',
  //       name: 'top-menu-wizard-layout-1',
  //       component: WizardLayout1,
  //     },
  //     {
  //       path: 'wizard-layout-2',
  //       name: 'top-menu-wizard-layout-2',
  //       component: WizardLayout2,
  //     },
  //     {
  //       path: 'wizard-layout-3',
  //       name: 'top-menu-wizard-layout-3',
  //       component: WizardLayout3,
  //     },
  //     {
  //       path: 'blog-layout-1',
  //       name: 'top-menu-blog-layout-1',
  //       component: BlogLayout1,
  //     },
  //     {
  //       path: 'blog-layout-2',
  //       name: 'top-menu-blog-layout-2',
  //       component: BlogLayout2,
  //     },
  //     {
  //       path: 'blog-layout-3',
  //       name: 'top-menu-blog-layout-3',
  //       component: BlogLayout3,
  //     },
  //     {
  //       path: 'pricing-layout-1',
  //       name: 'top-menu-pricing-layout-1',
  //       component: PricingLayout1,
  //     },
  //     {
  //       path: 'pricing-layout-2',
  //       name: 'top-menu-pricing-layout-2',
  //       component: PricingLayout2,
  //     },
  //     {
  //       path: 'invoice-layout-1',
  //       name: 'top-menu-invoice-layout-1',
  //       component: InvoiceLayout1,
  //     },
  //     {
  //       path: 'invoice-layout-2',
  //       name: 'top-menu-invoice-layout-2',
  //       component: InvoiceLayout2,
  //     },
  //     {
  //       path: 'faq-layout-1',
  //       name: 'top-menu-faq-layout-1',
  //       component: FaqLayout1,
  //     },
  //     {
  //       path: 'faq-layout-2',
  //       name: 'top-menu-faq-layout-2',
  //       component: FaqLayout2,
  //     },
  //     {
  //       path: 'faq-layout-3',
  //       name: 'top-menu-faq-layout-3',
  //       component: FaqLayout3,
  //     },
  //     {
  //       path: 'update-profile',
  //       name: 'top-menu-update-profile',
  //       component: UpdateProfile,
  //     },
  //     {
  //       path: 'change-password',
  //       name: 'top-menu-change-password',
  //       component: ChangePassword,
  //     },
  //     {
  //       path: 'regular-table',
  //       name: 'top-menu-regular-table',
  //       component: RegularTable,
  //     },
  //     {
  //       path: 'tabulator',
  //       name: 'top-menu-tabulator',
  //       component: Tabulator,
  //     },
  //     {
  //       path: 'modal',
  //       name: 'top-menu-modal',
  //       component: Modal,
  //     },
  //     {
  //       path: 'slide-over',
  //       name: 'top-menu-slide-over',
  //       component: SlideOver,
  //     },
  //     {
  //       path: 'notification',
  //       name: 'top-menu-notification',
  //       component: Notification,
  //     },
  //     {
  //       path: 'tab',
  //       name: 'top-menu-tab',
  //       component: Tab,
  //     },
  //     {
  //       path: 'accordion',
  //       name: 'top-menu-accordion',
  //       component: Accordion,
  //     },
  //     {
  //       path: 'button',
  //       name: 'top-menu-button',
  //       component: Button,
  //     },
  //     {
  //       path: 'alert',
  //       name: 'top-menu-alert',
  //       component: Alert,
  //     },
  //     {
  //       path: 'progress-bar',
  //       name: 'top-menu-progress-bar',
  //       component: ProgressBar,
  //     },
  //     {
  //       path: 'tooltip',
  //       name: 'top-menu-tooltip',
  //       component: Tooltip,
  //     },
  //     {
  //       path: 'dropdown',
  //       name: 'top-menu-dropdown',
  //       component: Dropdown,
  //     },
  //     {
  //       path: 'typography',
  //       name: 'top-menu-typography',
  //       component: Typography,
  //     },
  //     {
  //       path: 'icon',
  //       name: 'top-menu-icon',
  //       component: Icon,
  //     },
  //     {
  //       path: 'loading-icon',
  //       name: 'top-menu-loading-icon',
  //       component: LoadingIcon,
  //     },
  //     {
  //       path: 'regular-form',
  //       name: 'top-menu-regular-form',
  //       component: RegularForm,
  //     },
  //     {
  //       path: 'datepicker',
  //       name: 'top-menu-datepicker',
  //       component: Datepicker,
  //     },
  //     {
  //       path: 'tom-select',
  //       name: 'top-menu-tom-select',
  //       component: TomSelect,
  //     },
  //     {
  //       path: 'file-upload',
  //       name: 'top-menu-file-upload',
  //       component: FileUpload,
  //     },
  //     {
  //       path: 'wysiwyg-editor',
  //       name: 'top-menu-wysiwyg-editor',
  //       component: WysiwygEditor,
  //     },
  //     {
  //       path: 'validation',
  //       name: 'top-menu-validation',
  //       component: Validation,
  //     },
  //     {
  //       path: 'chart',
  //       name: 'top-menu-chart',
  //       component: Chart,
  //     },
  //     {
  //       path: 'slider',
  //       name: 'top-menu-slider',
  //       component: Slider,
  //     },
  //     {
  //       path: 'image-zoom',
  //       name: 'top-menu-image-zoom',
  //       component: ImageZoom,
  //     },
  //   ],
  // },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  // use the language from the routing param or default language
  let language = to.query.lang
  if (!LANGUAGES.includes(language)) {
    language = LANGUAGES[0]
  }
  const { locale } = i18n.global
  locale.value = language

  // let requiresAuth = to.meta.hasOwnProperty('requiresAuth')
  //   ? to.meta.requiresAuth
  //   : true

  // // checking if the user is authenticated
  // if (!store.getters.isAuthenticated() && requiresAuth) {
  //   next({ name: 'login', params: { ...{ redirect: to.name }, ...to.params } })
  //   return
  // } else if (
  //   store.getters.isAuthenticated() &&
  //   !store.getters.isDataPreloaded() &&
  //   to.name !== 'preloading'
  // ) {
  //   //... comment
  //   next({
  //     name: 'preloading',
  //     params: { ...{ redirect: to.name }, ...to.params },
  //   })
  //   return
  // }

  next()
})

export default router
