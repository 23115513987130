<template>
  <div class="report-box rounded-tl-xl rounded-tr-lg rounded-bl-xl rounded-br-3xl shadow-sm" :class="{'md:transform md:duration-200 md:hover:scale-110 md:hover:transform-gpu md:hover:cursor-pointer' : total !== null}">
    <ShimmerCardReport v-if="loading" />
    <div
      v-else
      class="rounded-tl-xl rounded-tr-lg rounded-bl-xl rounded-br-3xl" :class="boxClasses"
    >
      <div class="flex items-center rounded-tl-xl rounded-tr-lg py-1.5 px-3 md:px-4" :class="boxTitleClasses">
        <ActivityIcon
          v-if="color === 'info'"
          class="h-4 w-4 mr-1"
          :class="titleClasses"
        />
        <CheckCircleIcon
          v-else-if="color === 'success'"
          class="h-4 w-4 mr-1"
          :class="titleClasses"
        />
        <XCircleIcon
          v-else-if="color === 'danger'"
          class="h-4 w-4 mr-1"
          :class="titleClasses"
        />
        <HashIcon
          v-else
          class="h-4 w-4 mr-1"
          :class="titleClasses"
        />
        <div class="text-xs md:text-sm font-semibold" :class="titleClasses">
          {{ title }}
        </div>
      </div>
      <div class="text-lg font-medium leading-8 md:text-2xl py-1 px-3 md:px-4 md:py-3">
        <Currency
          :total-in-string="total.toString()"
          :with-prefix="withPrefix"
          class="text-lg font-medium leading-8 md:text-2xl"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Currency from '@/components/Currency.vue'
import ShimmerCardReport from '@/components/shimmer/ShimmerCardReport.vue'

export default {
  name: 'CardReport',
  components: {
    Currency,
    ShimmerCardReport,
  },
  props: {
    title: {
      type: String,
      default: null,
      required: true,
    },
    total: {
      total: Number,
      default: "",
      required: true,
    },
    loading: {
      total: Boolean,
      default: false,
      required: false,
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
    withPrefix: {
      total: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    boxClasses: 'bg-white',
    boxTitleClasses: '',
    titleClasses: 'text-light',
  }),
  watch: {
    color(val) {
      this.getClassCard(val)
    },
  },
  mounted() {
    this.getClassCard(this.color)
  },
  methods: {
    getClassCard(color) {
      switch (color) {
        case 'info':
          // this.boxClasses =
          //   'bg-cyan-50'
          this.boxTitleClasses = 'bg-gradient-to-r from-cyan-50/50 to-cyan-200/70'
          this.titleClasses = 'text-cyan-500'
          break
        case 'success':
          // this.boxClasses =
          //   'bg-green-50'
          this.boxTitleClasses = 'bg-gradient-to-r from-green-50/50 to-green-200/70'
          this.titleClasses = 'text-green-500'
          break 
        case 'danger':
          // this.boxClasses =
          //   'bg-red-50'
          this.boxTitleClasses = 'bg-gradient-to-r from-red-50/50 to-red-200/70'
          this.titleClasses = 'text-red-500'
          break
        default:
          // this.boxClasses =
          //   'bg-white'
          this.boxTitleClasses = 'bg-gradient-to-r from-gray-50/50 to-gray-200/70'
          this.titleClasses = 'text-gray-500'
          break
      }
    },
  },
}
</script>
