<template>
  <Chart
    type="doughnut"
    :width="width"
    :height="height"
    :data="chartData"
    :options="options"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useDarkModeStore } from '@/stores/dark-mode'
import { useColorSchemeStore } from '@/stores/color-scheme'
import { colors } from '@/utils/colors'

const props = defineProps({
  labels: {
    type: Array,
    default: () => {
      return []
    },
  },
  data: {
    type: Array,
    default: () => {
      return []
    },
  },
  colors: {
    type: Array,
    default: () => {
      return []
    },
  },
  width: {
    type: [Number, String],
    default: 'auto',
  },
  height: {
    type: [Number, String],
    default: 'auto',
  },
  cutout: {
    type: String,
    default: '80',
  },
})

const darkMode = computed(() => useDarkModeStore().darkMode)
const colorScheme = computed(() => useColorSchemeStore().colorScheme)

const data = [60, 20, 110, 10]
const chartColors = () => [
  // colors.secondary(0.9),
  colors.warning(0.9),
  colors.success(0.9),
  colors.danger(0.9),
]

const chartData = computed(() => {
  return {
    labels: props.labels,
    datasets: [
      {
        data: props.data,
        // backgroundColor: props.colors ? chartColors() : '',
        backgroundColor: props.colors,
        hoverBackgroundColor: props.colors,
        borderWidth: 5,
        borderColor: darkMode.value ? colors.darkmode[700]() : colors.white,
      },
    ],
  }
})

const options = computed(() => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: `${props.cutout}%`,
  }
})
</script>
