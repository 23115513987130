<template>
  <Dropdown>
    <DropdownToggle
      class="btn hover:btn-gray-600 rounded-xl bg-gray-800 font-normal text-white dark:bg-white dark:text-gray-800 sm:ml-auto sm:mt-0"
      @click="showDropdown = true"
    >
      <FileTextIcon class="mr-2 hidden h-4 w-4 sm:block" />
      <span>{{ $t('action.export') }}</span>
    </DropdownToggle>
    <DropdownMenu class="w-40 rounded-2xl">
      <DropdownContent v-if="showDropdown" class="overflow-y-auto">
        <DropdownItem @click="doExport('.csv')"> CSV (.csv) </DropdownItem>
        <DropdownItem @click="doExport('.xlsx')"> Excel (.xlsx) </DropdownItem>
      </DropdownContent>
    </DropdownMenu>
  </Dropdown>
</template>
<script>
export default {
  name: 'ExportCSVComponent',
  emits: ['export'],
  data: () => ({
    showDropdown: false,
    isDownloadCsvLoading: false,
    isDownloadXlsxLoading: false,
  }),
  methods: {
    doExport(type) {
      this.showDropdown = false
      this.$emit('export', type)
    },
  },
}
</script>
