<template>
  <h2 class="intro-y mt-2 md:mt-6 text-lg font-medium">WhatsApp Blast</h2>
  <ButtonAdd
    v-if="$h.roleCanManage($h.superRoles(), roleAccess)"
    :is-loading="isButtonLoading"
    @click="addForm"
  />
  <div class="mt-0 grid grid-cols-12 gap-6 md:mt-5">
    <div class="intro-y col-span-12 mt-2 flex flex-wrap items-center">
      <div class="flex w-full md:w-auto">
        <div class="relative w-full text-slate-500 md:w-48 h-9.5">
          <input
            v-model="filterData.search"
            type="text"
            class="form-control box w-full pr-8 md:w-56 h-10"
            placeholder="Cari produk..."
            @input="searchFilter()"
          />
          <SearchIcon
            class="absolute inset-y-0 right-0 my-auto mr-4 h-4 w-4 md:-mr-4"
          />
        </div>
        <button class="btn box ml-2 shadow-sm md:ml-10" @click="openFilter">
          <FilterIcon class="h-4 w-4" />
        </button>
      </div>
      <div class="mx-auto hidden text-slate-500 md:block"></div>
      <div class="mt-3 flex items-center md:mt-0 xl:mt-0 xl:w-auto">
        <!-- <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white mr-2 text-white">
          <PrinterIcon class="mr-2 h-4 w-4" /> Print
        </button> -->
        <button class="btn bg-gradient-to-r from-teal-700 to-primary text-white  text-white" disabled>
          <FileTextIcon class="mr-2 h-4 w-4" /> Export ke Excel
        </button>
      </div>
    </div>
    <!-- BEGIN: Data List -->
    <TableComponent
      :show-loading="isLoading"
      :show-title="false"
      :show-search="false"
      :header="tableHeader"
      :meta-data="metaData"
      :is-not-empty="tableData.length > 0"
      @change-filter="changeFilter($event)"
    >
      <tr v-for="item in tableData" :key="item.id" class="intro-x">
        <td class="text-xs text-slate-600 dark:text-slate-500">
          {{ $h.formatDate(item.created, 'YYYY MMMM DD, hh:mm:ss') }}
        </td>
        <td>
          <template v-if="item.created_by !== undefined">
            {{ item.created_by.full_name }}
          </template>
          <template v-else> - </template>
        </td>
        <td class="text-xs text-slate-600 dark:text-slate-500">
          <template v-if="item.template !== undefined">
            {{ item.template.name }}
          </template>
          <template v-else> - </template>
        </td>
        <td class="text-center text-xs font-bold text-sky-500">
          <template v-if="item.template !== undefined">
            {{ item.template.category }}
          </template>
          <template v-else> - </template>
        </td>
        <td>
          {{ item.file_name }}
        </td>
        <td>
          <a :href="item.file" class="font-medium text-primary" target="blank"
            >Download File</a
          >
        </td>
        <td>
          <div class="flex items-center justify-center">
            <button
              class="btn btn-danger rounded-lg"
              @click="blastMessage(item)"
            >
              <LoadingIcon
                v-if="item.loading"
                icon="spinning-circles"
                color="white"
                class="mr-2 h-4 w-4"
              />
              <SendIcon v-else class="mr-2 h-4 w-4" />
              Blast Message
            </button>
          </div>
        </td>
      </tr>
    </TableComponent>
    <!-- END: Data List -->
  </div>

  <!-- ADD & EDIT MODAL -->
  <Modal size="modal-lg" :show="addEditModal" @hidden="addEditModal = false">
    <ModalHeader>
      <div class="flex w-full justify-between">
        <div class="flex">
          <h2 class="text-base font-medium">{{ titleModal }}</h2>
        </div>
      </div>
    </ModalHeader>
    <ModalBody>
      <div class="flex w-full flex-col">
        <div class="w-full">
          <label class="form-label">List Phone File</label>
          <div
            class="rounded-md border-2 border-dashed"
            :class="[
              isAttachmentError ? 'border-red-400' : '',
              files === null ? '' : 'pt-4',
            ]"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <div class="flex flex-wrap px-4">
              <div
                v-if="files !== null"
                class="relative h-16 w-full cursor-pointer"
              >
                <div
                  class="flex items-center justify-center rounded-md bg-green-50 p-8 text-center font-bold text-green-600"
                >
                  <span>{{ files.name }}</span>
                </div>
              </div>
            </div>
            <div
              class="relative flex cursor-pointer flex-col px-4"
              :class="files === null ? 'py-6' : 'mt-8 pb-4'"
            >
              <div class="flex items-center">
                <ImageIcon class="mr-2 h-4 w-4" />
                <div v-if="isDragging">Lepaskan file disini.</div>
                <div v-else>
                  <span class="mr-1 text-primary">Unggah file</span>
                  <span class="text-gray-500">atau jatuhkan file disini</span>
                </div>
              </div>
              <span class="mt-2 block text-xs font-medium italic text-gray-400">
                Hanya mendukung .xlsx
              </span>
              <input
                id="fileInput"
                ref="file"
                type="file"
                name="file"
                class="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                @change="onChange"
              />
            </div>
          </div>
        </div>
        <div class="w-full">
          <div class="input-form mt-3 w-full">
            <label
              for="form-whatsapp template"
              class="form-label flex w-full flex-col sm:flex-row"
            >
              Template ID
            </label>
            <div class="mt-2">
              <Multiselect
                v-model="whatsappTemplateId"
                value="id"
                value-prop="id"
                label="name"
                track-by="name"
                :placeholder="
                  isWhatsappTemplateLoading
                    ? 'Memuat data....'
                    : 'Pilih Template'
                "
                :options="whatsappTemplateData"
                :classes="multiSelectClasses"
                :class="isWhatsappTemplateError ? 'border-danger' : ''"
                :searchable="true"
                :loading="isWhatsappTemplateLoading"
                @select="isWhatsappTemplateError = false"
              />

              <template v-if="isWhatsappTemplateError">
                <div class="mt-2 text-xs text-danger">
                  Template ID harus diisi
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <button type="button" class="btn bg-gradient-to-r from-teal-700 to-primary text-white px-8" @click="addData">
        <template v-if="isFormLoading === true">
          <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
        </template>
        Simpan
      </button>
    </ModalFooter>
  </Modal>

  <!-- DELETE MODAL -->
  <Modal :show="deleteModal" @hidden="cancelDelete">
    <ModalBody class="p-0">
      <div class="p-5 text-center">
        <XCircleIcon class="mx-auto mt-3 h-16 w-16 text-danger" />
        <div class="mt-5">Apakah anda yakin ingin menghapus data ini ?</div>
        <div class="mt-2 text-lg text-slate-600">{{ activeData.name }}</div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          type="button"
          class="btn btn-outline-secondary mr-4 w-24"
          @click="cancelDelete"
        >
          Batal
        </button>
        <button type="button" class="btn btn-danger w-32" @click="deleteData">
          <template v-if="isFormLoading === true">
            <LoadingIcon icon="spinning-circles" color="white" class="" />&nbsp
          </template>
          Hapus
        </button>
      </div>
    </ModalBody>
  </Modal>

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">
        {{ message }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Toastify from 'toastify-js'
import globalMixin from '@/mixins/global.js'
import TableComponent from '@/components/TableComponent.vue'
import Multiselect from '@vueform/multiselect'
import ButtonAdd from '@/components/button/ButtonAdd.vue'
import { helper as $h } from '@/utils/helper'

export default {
  components: {
    TableComponent,
    Multiselect,
    ButtonAdd,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const activeUser = reactive({})
    const roleAccess = ref('')
    const superRoles = ref([
      'SUPERADMIN',
      'CEO',
      'HEAD_TECHNOLOGY',
      'HEAD_OPERATION',
      'HEAD_SALES',
      'HEAD_FINANCE',
    ])

    const activeBlasting = reactive({})

    const isEditForm = ref(false)

    const isDragging = ref(false)
    let files = ref(null)
    let filesDeleted = ref([])

    const isAttachmentError = ref(false)

    const isWhatsappTemplateError = ref(false)
    const pageWhatsappTemplate = ref(1)
    const whatsappTemplateId = ref('')
    const whatsappTemplateData = ref([])

    const isWhatsappTemplateLoading = ref(false)
    const isFormLoading = ref(false)
    const isLoading = ref(false)
    const isButtonLoading = ref(false)

    const timer = ref(undefined)

    const addEditModal = ref(false)
    const deleteModal = ref(false)

    const status = ref('')
    const message = ref('')

    const titleModal = ref('')

    const tableHeader = ref([
      {
        item: 'DIBUAT',
        customClass: '',
      },
      {
        item: 'OLEH',
        customClass: '',
      },
      {
        item: 'TEMPLATE',
        customClass: '',
      },
      {
        item: 'KATEGORI',
        customClass: 'text-center',
      },
      {
        item: 'FILE NAME',
        customClass: '',
      },
      {
        item: 'ATTACHMENT',
        customClass: 'w-40',
      },
      {
        item: 'AKSI',
        customClass: 'w-52 text-center',
      },
    ])

    let activeDataId = ref(null)

    let tableData = ref([])

    let metaData = reactive({
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 10,
      order: 'ASC',
      type: null,
      search: '',
    })

    const activeData = reactive({
      template_id: null,
      file: '',
    })

    const formData = reactive({
      template_id: null,
      file: '',
    })

    onMounted(async () => {
      Object.assign(activeUser, store.getters['auth/activeUser'])
      roleAccess.value = activeUser.role_codes

      getWhatsappTemplateData()
      getAllData()
    })

    const onChange = (e) => {
      files.value = e.target.files[0]
      isAttachmentError.value = false
    }
    const generateThumbnail = (file) => {
      let fileSrc = URL.createObjectURL(file)
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc)
      }, 1000)
      return fileSrc
    }

    const makeName = (name) => {
      return (
        name.split('.')[0].substring(0, 3) +
        '...' +
        name.split('.')[name.split('.').length - 1]
      )
    }
    const remove = (file) => {
      const index = files.value.indexOf(file)
      files.value.splice(index, 1)

      if (file.id !== undefined && file.image !== undefined) {
        filesDeleted.value.push(file.id)
      }
    }
    const dragover = (e) => {
      e.preventDefault()
      isDragging.value = true
    }
    const dragleave = () => {
      isDragging.value = false
    }
    const drop = (e) => {
      e.preventDefault()

      files.value = e.dataTransfer.files[0]

      isDragging.value = false

      isAttachmentError.value = false
    }

    const addData = async () => {
      console.log('--- files.value ', files.value)
      console.log('--- whatsappTemplateId.value ', whatsappTemplateId.value)
      if (files.value === null) {
        isAttachmentError.value = true
      }

      if (whatsappTemplateId.value === '') {
        isWhatsappTemplateError.value = true
      }

      if (files.value === null || whatsappTemplateId.value === '') {
        return
      }

      isFormLoading.value = true

      const formData = new FormData()
      formData.append('phone_file', files.value)
      formData.append('template_id', whatsappTemplateId.value)

      let endpoint = 'whatsappBlast/create'

      if (activeDataId.value !== null && activeDataId.value !== '') {
        endpoint = 'whatsappBlast/update/' + activeDataId.value
      }

      await store
        .dispatch(endpoint, formData)
        .then((response) => {
          if (response.statusCode === 200) {
            status.value = response.status
            message.value = response.message

            addEditModal.value = false

            // show toast
            setTimeout(() => {
              showToast({
                success: true,
              })
            }, 200)

            setTimeout(() => {
              getAllData()
            }, 500)
          } else {
            status.value = response.message.status
            message.value = response.message.detail
            // show toast
            setTimeout(() => {
              showToast()
            }, 200)
          }
          isFormLoading.value = false
        })
        .catch((error) => {
          const responseData = error.response.data
          isFormLoading.value = false
          status.value = responseData.message.status
          message.value = responseData.message.detail
          // show toast
          setTimeout(() => {
            showToast(false)
          }, 200)
        })
    }

    const blastMessage = async (data) => {
      data.loading = true
      await store
        .dispatch('whatsappBlast/send', { id: data.id })
        .then((response) => {
          if (response.statusCode === 200) {
            status.value = response.status
            message.value = response.message

            data.loading = false
            // show toast
            setTimeout(() => {
              showToast({
                success: true,
              })
            }, 200)
          } else {
            status.value = response.message.status
            message.value = response.message.detail
            data.loading = false

            // show toast
            setTimeout(() => {
              showToast()
            }, 200)
          }
          isFormLoading.value = false
        })
        .catch((error) => {
          const responseData = error.response.data
          isFormLoading.value = false
          status.value = responseData.message.status
          message.value = responseData.message.detail
          // show toast
          setTimeout(() => {
            showToast(false)
          }, 200)
        })
    }

    const deleteData = async () => {
      isFormLoading.value = true
      await store
        .dispatch('whatsappBlast/delete', activeDataId.value)
        .then((response) => {
          if (response.statusCode === 200) {
            status.value = response.status
            message.value = response.message

            // show toast
            setTimeout(() => {
              showToast({
                success: true,
              })
            }, 200)

            getAllData(true)
          } else {
            status.value = response.message.status
            message.value = response.message.detail
            // show toast
            setTimeout(() => {
              showToast()
            }, 200)
          }
          isFormLoading.value = false
          deleteModal.value = false
        })
        .catch((error) => {
          const responseData = error.response.data
          deleteModal.value = false
          isFormLoading.value = false
          status.value = responseData.message.status
          message.value = responseData.message.detail
          // show toast
          setTimeout(() => {
            showToast(false)
          }, 200)
        })
    }

    const resetForm = () => {
      isEditForm.value = false

      whatsappTemplateId.value = ''
      isAttachmentError.value = false
      isWhatsappTemplateError.value = false

      files.value = null
      filesDeleted.value = []

      activeDataId.value = null
    }

    const addForm = async () => {
      isButtonLoading.value = true
      resetForm()

      await getWhatsappTemplateData(true)

      titleModal.value = 'Buat Blasting Message'
      addEditModal.value = true

      isButtonLoading.value = false
    }

    const editForm = async (data) => {
      resetForm()

      // isAccessories.value = data.is_accessories === true ? true : false

      await getWhatsappTemplateData(true)

      isEditForm.value = true

      activeDataId.value = data.id

      whatsappTemplateId.value = data.template.id

      titleModal.value = 'Ubah Blasting Message'
      addEditModal.value = true
    }

    const deleteForm = (data) => {
      activeData.name = data.name
      activeData.price = data.price

      activeDataId.value = data.id

      deleteModal.value = true
    }

    const cancelDelete = (data) => {
      activeData.name = ''
      activeData.price = ''

      activeDataId.value = null

      deleteModal.value = false
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const getAllData = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        tableData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }
      await store
        .dispatch('whatsappBlast/getAll', {
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          q: filterData.search,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            tableData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const getWhatsappTemplateData = async (reset) => {
      if (reset === true) {
        pageWhatsappTemplate.value = 1
        whatsappTemplateData.value = []
      }

      isWhatsappTemplateLoading.value = true
      await store
        .dispatch('whatsappTemplate/getAll', {
          order: 'ASC',
          page: pageWhatsappTemplate.value,
          take: 50,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            Object.assign(whatsappTemplateData.value, [
              ...whatsappTemplateData.value,
              ...responseData.data,
            ])
          }

          if (responseData.data.length > 0) {
            pageWhatsappTemplate.value += 1
            getWhatsappTemplateData()
          } else {
            isWhatsappTemplateLoading.value = false
          }
        })
        .catch((e) => {
          // if (filterData.page === 1) {
          //   isLoading.value = false
          // }
          console.log(e)
          isWhatsappTemplateLoading.value = false
        })
    }

    const changeFilter = (event) => {
      Object.assign(filterData, {
        ...filterData,
        page: Number(event.filterData.page),
        take: Number(event.filterData.take),
      })
      getAllData()
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getAllData(true)
      }, 500)
    }

    return {
      $h,
      t,
      n,
      store,
      route,
      router,

      roleAccess,
      superRoles,

      activeBlasting,

      isEditForm,

      isDragging,
      files,
      filesDeleted,

      onChange,
      generateThumbnail,
      makeName,
      remove,
      dragover,
      dragleave,
      drop,

      addEditModal,
      deleteModal,

      isAttachmentError,
      isWhatsappTemplateError,
      isWhatsappTemplateLoading,
      isFormLoading,
      isLoading,
      isButtonLoading,
      timer,

      status,
      message,

      titleModal,
      tableHeader,
      tableData,
      metaData,
      filterData,

      formData,
      addData,
      resetForm,
      addForm,
      editForm,
      deleteForm,
      cancelDelete,
      activeData,
      deleteData,

      activeDataId,
      getAllData,
      blastMessage,

      showToast,
      changeFilter,
      searchFilter,

      pageWhatsappTemplate,
      whatsappTemplateId,
      whatsappTemplateData,

      getWhatsappTemplateData,
    }
  },
}
</script>
