<template>
  <div
    class="rounded-tl-xl rounded-tr-lg rounded-bl-xl rounded-br-3xl bg-white"
  >
    <div class="flex rounded-tl-xl rounded-tr-lg py-1.5 px-3 md:px-4 bg-gray-50">
      <ShimmerParagraph height="h-5" width="60%" class="hidden md:flex" />
      <ShimmerParagraph height="h-4" width="60%" class="flex md:hidden" />
    </div>
    <div class="py-1 px-3 md:px-4 md:py-5">
      <ShimmerParagraph height="h-7" width="80%" class="hidden md:flex" />
      <ShimmerParagraph height="h-5" width="80%" class="flex md:hidden" />
    </div>
  </div>
</template>

<script>
import ShimmerParagraph from '@/components/shimmer/ShimmerParagraph.vue'

export default {
  name: 'ShimmerCardReport',
  components: {
    ShimmerParagraph,
  },
}
</script>
