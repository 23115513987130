<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 2xl:col-span-9">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: Notification -->
        <div class="intro-y col-span-12 mt-6 -mb-6">
          <Alert
            class="box mb-6 flex items-center bg-primary text-white"
            v-slot="{ dismiss }"
          >
            <span
              >Introducing new dashboard! Download now at
              <a
                href="https://themeforest.net/item/midone-jquery-tailwindcss-html-admin-template/26366820"
                class="ml-1 underline"
                target="blank"
                >themeforest.net</a
              >.</span
            >
            <button
              type="button"
              class="btn-close text-white"
              @click="dismiss"
              aria-label="Close"
            >
              <XIcon class="h-4 w-4" />
            </button>
          </Alert>
        </div>
        <!-- BEGIN: Notification -->
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-2 lg:col-span-8 xl:col-span-6">
          <div class="intro-y block h-10 items-center sm:flex">
            <h2 class="mr-5 truncate text-lg font-medium">General Report</h2>
            <select class="box form-select mt-3 sm:ml-auto sm:mt-0 sm:w-auto">
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
              <option value="custom-date">Custom Date</option>
            </select>
          </div>
          <div class="report-box-2 intro-y mt-12 sm:mt-5">
            <div class="box sm:flex">
              <div class="flex flex-1 flex-col justify-center px-8 py-12">
                <ShoppingBagIcon class="h-10 w-10 text-warning" />
                <div class="relative mt-12 ml-0.5 pl-4 text-3xl font-medium">
                  <span
                    class="absolute top-0 left-0 -ml-0.5 text-2xl font-medium"
                    >$</span
                  >
                  54.143
                </div>
                <Tippy
                  tag="div"
                  class="report-box-2__indicator cursor-pointer bg-success"
                  content="47% Higher than last month"
                >
                  47% <ChevronUpIcon class="ml-0.5 h-4 w-4" />
                </Tippy>
                <div class="mt-4 text-slate-500">
                  Sales earnings this month after associated author fees, &
                  before taxes.
                </div>
                <button
                  class="btn btn-outline-secondary relative mt-12 justify-start rounded-full"
                >
                  Download Reports
                  <span
                    class="absolute right-0 top-0 bottom-0 my-auto ml-auto mr-0.5 flex h-8 w-8 items-center justify-center rounded-full bg-primary text-white"
                  >
                    <ArrowRightIcon class="h-4 w-4" />
                  </span>
                </button>
              </div>
              <div
                class="flex flex-1 flex-col justify-center border-t border-dashed border-slate-200 px-8 py-12 dark:border-darkmode-300 sm:border-t-0 sm:border-l"
              >
                <div class="text-xs text-slate-500">TOTAL TRANSACTION</div>
                <div class="mt-1.5 flex items-center">
                  <div class="text-base">4.501</div>
                  <Tippy
                    tag="div"
                    class="ml-2 flex cursor-pointer text-xs font-medium text-danger"
                    content="2% Lower than last month"
                  >
                    2%
                    <ChevronDownIcon class="ml-0.5 h-4 w-4" />
                  </Tippy>
                </div>
                <div class="mt-5 text-xs text-slate-500">CANCELATION CASE</div>
                <div class="mt-1.5 flex items-center">
                  <div class="text-base">2</div>
                  <Tippy
                    tag="div"
                    class="ml-2 flex cursor-pointer text-xs font-medium text-danger"
                    content="0.1% Lower than last month"
                  >
                    0.1%
                    <ChevronDownIcon class="ml-0.5 h-4 w-4" />
                  </Tippy>
                </div>
                <div class="mt-5 text-xs text-slate-500">
                  GROSS RENTAL VALUE
                </div>
                <div class="mt-1.5 flex items-center">
                  <div class="text-base">$72.000</div>
                  <Tippy
                    tag="div"
                    class="ml-2 flex cursor-pointer text-xs font-medium text-success"
                    content="49% Higher than last month"
                  >
                    49% <ChevronUpIcon class="ml-0.5 h-4 w-4" />
                  </Tippy>
                </div>
                <div class="mt-5 text-xs text-slate-500">
                  GROSS RENTAL PROFIT
                </div>
                <div class="mt-1.5 flex items-center">
                  <div class="text-base">$54.000</div>
                  <Tippy
                    tag="div"
                    class="ml-2 flex cursor-pointer text-xs font-medium text-success"
                    content="52% Higher than last month"
                  >
                    52% <ChevronUpIcon class="ml-0.5 h-4 w-4" />
                  </Tippy>
                </div>
                <div class="mt-5 text-xs text-slate-500">NEW USERS</div>
                <div class="mt-1.5 flex items-center">
                  <div class="text-base">2.500</div>
                  <Tippy
                    tag="div"
                    class="tooltip ml-2 flex cursor-pointer text-xs font-medium text-success"
                    content="52% Higher than last month"
                  >
                    52% <ChevronUpIcon class="ml-0.5 h-4 w-4" />
                  </Tippy>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
        <!-- BEGIN: Visitors -->
        <div class="col-span-12 mt-2 sm:col-span-6 lg:col-span-4 xl:col-span-3">
          <div class="intro-y flex h-10 items-center">
            <h2 class="mr-5 truncate text-lg font-medium">Visitors</h2>
            <a href="" class="ml-auto truncate text-primary">View on Map</a>
          </div>
          <div class="report-box-2 intro-y mt-5">
            <div class="box p-5">
              <div class="flex items-center">
                Realtime active users
                <Dropdown class="ml-auto">
                  <DropdownToggle
                    tag="a"
                    class="-mr-2 block h-5 w-5"
                    href="javascript:;"
                  >
                    <MoreVerticalIcon class="h-5 w-5 text-slate-500" />
                  </DropdownToggle>
                  <DropdownMenu class="w-40">
                    <DropdownContent>
                      <DropdownItem>
                        <FileTextIcon class="mr-2 h-4 w-4" />
                        Export
                      </DropdownItem>
                      <DropdownItem>
                        <SettingsIcon class="mr-2 h-4 w-4" />
                        Settings
                      </DropdownItem>
                    </DropdownContent>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div class="mt-2 text-2xl font-medium">214</div>
              <div class="mt-4 flex border-b border-slate-200 pb-2">
                <div class="text-xs text-slate-500">Page views per second</div>
                <Tippy
                  tag="div"
                  class="ml-auto flex cursor-pointer text-xs font-medium text-success"
                  content="49% Lower than last month"
                >
                  49% <ChevronUpIcon class="ml-0.5 h-4 w-4" />
                </Tippy>
              </div>
              <div class="broder-slate-200 mt-2 border-b">
                <div class="-mb-1.5 -ml-2.5">
                  <ReportBarChart :height="79" />
                </div>
              </div>
              <div
                class="mb-2 mt-4 flex border-b border-slate-200 pb-2 text-xs text-slate-500"
              >
                <div>Top Active Pages</div>
                <div class="ml-auto">Active Users</div>
              </div>
              <div class="flex">
                <div>/letz-lara…review/2653</div>
                <div class="ml-auto">472</div>
              </div>
              <div class="mt-1.5 flex">
                <div>/midone…review/1674</div>
                <div class="ml-auto">294</div>
              </div>
              <div class="mt-1.5 flex">
                <div>/profile…review/46789</div>
                <div class="ml-auto">83</div>
              </div>
              <div class="mt-1.5 flex">
                <div>/profile…review/24357</div>
                <div class="ml-auto">21</div>
              </div>
              <button
                class="btn btn-outline-secondary mt-4 w-full border-dashed py-1 px-2"
              >
                Real-Time Report
              </button>
            </div>
          </div>
        </div>
        <!-- END: Visitors -->
        <!-- BEGIN: Users By Age -->
        <div
          class="col-span-12 mt-2 sm:col-span-6 lg:col-span-4 lg:mt-6 xl:col-span-3 xl:mt-2"
        >
          <div class="intro-y flex h-10 items-center">
            <h2 class="mr-5 truncate text-lg font-medium">Users By Age</h2>
            <a href="" class="ml-auto truncate text-primary">Show More</a>
          </div>
          <div class="report-box-2 intro-y mt-5">
            <TabGroup class="box p-5">
              <TabList
                class="nav-pills mx-auto w-4/5 rounded-md bg-slate-100 dark:bg-black/20"
              >
                <Tab class="w-full py-1.5 px-2" tag="button">Active</Tab>
                <Tab class="w-full py-1.5 px-2" tag="button">Inactive</Tab>
              </TabList>
              <TabPanels class="mt-6">
                <TabPanel>
                  <div class="relative">
                    <ReportDonutChart :height="208" class="mt-3" />
                    <div
                      class="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-center"
                    >
                      <div class="text-2xl font-medium">2.501</div>
                      <div class="mt-0.5 text-slate-500">Active Users</div>
                    </div>
                  </div>
                  <div class="mx-auto mt-5 w-52 sm:w-auto">
                    <div class="flex items-center">
                      <div class="mr-3 h-2 w-2 rounded-full bg-primary"></div>
                      <span class="truncate">17 - 30 Years old</span>
                      <span class="ml-auto font-medium">62%</span>
                    </div>
                    <div class="mt-4 flex items-center">
                      <div class="mr-3 h-2 w-2 rounded-full bg-pending"></div>
                      <span class="truncate">31 - 50 Years old</span>
                      <span class="ml-auto font-medium">33%</span>
                    </div>
                    <div class="mt-4 flex items-center">
                      <div class="mr-3 h-2 w-2 rounded-full bg-warning"></div>
                      <span class="truncate">>= 50 Years old</span>
                      <span class="ml-auto font-medium">10%</span>
                    </div>
                  </div>
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
        </div>
        <!-- END: Users By Age -->
        <!-- BEGIN: Official Store -->
        <div class="col-span-12 mt-6 lg:col-span-8">
          <div class="intro-y block h-10 items-center sm:flex">
            <h2 class="mr-5 truncate text-lg font-medium">Official Store</h2>
            <div class="relative mt-3 text-slate-500 sm:ml-auto sm:mt-0">
              <MapPinIcon
                class="absolute inset-y-0 left-0 z-10 my-auto ml-3 h-4 w-4"
              />
              <input
                type="text"
                class="form-control box pl-10 sm:w-56"
                placeholder="Filter by city"
              />
            </div>
          </div>
          <div class="intro-y box mt-12 p-5 sm:mt-5">
            <div>
              250 Official stores in 21 countries, click the marker to see
              location details.
            </div>
            <ReportMap class="report-maps mt-5 rounded-md bg-slate-200" />
          </div>
        </div>
        <!-- END: Official Store -->
        <!-- BEGIN: Weekly Best Sellers -->
        <div class="col-span-12 mt-6 xl:col-span-4">
          <div class="intro-y flex h-10 items-center">
            <h2 class="mr-5 truncate text-lg font-medium">
              Weekly Best Sellers
            </h2>
          </div>
          <div class="mt-5">
            <div
              v-for="(faker, fakerKey) in $_.take($f(), 4)"
              :key="fakerKey"
              class="intro-y"
            >
              <div class="box zoom-in mb-3 flex items-center px-4 py-4">
                <div
                  class="image-fit h-10 w-10 flex-none overflow-hidden rounded-md"
                >
                  <img alt="Gromura - SCM" :src="faker.users[0].image" />
                </div>
                <div class="ml-4 mr-auto">
                  <div class="font-medium">
                    {{ faker.users[0].name }}
                  </div>
                  <div class="mt-0.5 text-xs text-slate-500">
                    {{ faker.dates[0] }}
                  </div>
                </div>
                <div
                  class="cursor-pointer rounded-full bg-success py-1 px-2 text-xs font-medium text-white"
                >
                  137 Sales
                </div>
              </div>
            </div>
            <a
              href=""
              class="intro-y block w-full rounded-md border border-dotted border-slate-400 py-4 text-center text-slate-500 dark:border-darkmode-300"
              >View More</a
            >
          </div>
        </div>
        <!-- END: Weekly Best Sellers -->
        <!-- BEGIN: Ads 1 -->
        <div class="col-span-12 mt-6 lg:col-span-6">
          <div class="box intro-y relative overflow-hidden bg-primary p-8">
            <div
              class="-mt-3 w-full text-xl leading-[2.15rem] text-white sm:w-72"
            >
              Transact safely with Lender’s Fund Account (RDL)
            </div>
            <div
              class="mt-3 w-full leading-relaxed text-white/70 dark:text-slate-500 sm:w-72"
            >
              Apply now, quick registration.
            </div>
            <button
              class="btn mt-6 w-32 bg-white dark:bg-darkmode-800 dark:text-white sm:mt-10"
            >
              Start Now
            </button>
            <img
              class="absolute top-0 right-0 -mt-3 mr-2 hidden w-2/5 sm:block"
              alt="Gromura - SCM"
              src="@/assets/images/woman-illustration.svg"
            />
          </div>
        </div>
        <!-- END: Ads 1 -->
        <!-- BEGIN: Ads 2 -->
        <div class="col-span-12 mt-6 lg:col-span-6">
          <div class="box intro-y relative overflow-hidden p-8">
            <div
              class="-mt-3 w-full text-xl leading-[2.15rem] text-primary dark:text-white sm:w-52"
            >
              Invite friends to get
              <span class="font-medium">FREE</span> bonuses!
            </div>
            <div class="mt-2 w-full leading-relaxed text-slate-500 sm:w-60">
              Get a IDR 100,000 voucher by inviting your friends to fund
              #BecomeMember
            </div>
            <Tippy
              tag="div"
              class="relative mt-6 w-48 cursor-pointer"
              content="Copy referral link"
            >
              <input
                type="text"
                class="form-control"
                value="https://dashboard.in"
              />
              <CopyIcon
                class="absolute right-0 top-0 bottom-0 my-auto mr-4 h-4 w-4"
              />
            </Tippy>
            <img
              class="absolute top-0 right-0 mt-1 -mr-12 hidden w-1/2 sm:block"
              alt="Gromura - SCM"
              src="@/assets/images/phone-illustration.svg"
            />
          </div>
        </div>
        <!-- END: Ads 2 -->
        <!-- BEGIN: Weekly Top Products -->
        <div class="col-span-12 mt-6">
          <div class="intro-y block h-10 items-center sm:flex">
            <h2 class="mr-5 truncate text-lg font-medium">
              Weekly Top Products
            </h2>
            <div class="mt-3 flex items-center sm:ml-auto sm:mt-0">
              <button
                class="btn box flex items-center text-slate-600 dark:text-slate-300"
              >
                <FileTextIcon class="mr-2 hidden h-4 w-4 sm:block" />
                Export to Excel
              </button>
              <button
                class="btn box ml-3 flex items-center text-slate-600 dark:text-slate-300"
              >
                <FileTextIcon class="mr-2 hidden h-4 w-4 sm:block" />
                Export to PDF
              </button>
            </div>
          </div>
          <div class="intro-y mt-8 overflow-auto sm:mt-0 lg:overflow-visible">
            <table class="table-report table sm:mt-2">
              <thead>
                <tr>
                  <th class="whitespace-nowrap">IMAGES</th>
                  <th class="whitespace-nowrap">PRODUCT NAME</th>
                  <th class="whitespace-nowrap text-center">STOCK</th>
                  <th class="whitespace-nowrap text-center">STATUS</th>
                  <th class="whitespace-nowrap text-center">ACTIONS</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(faker, fakerKey) in $_.take($f(), 4)"
                  :key="fakerKey"
                  class="intro-x"
                >
                  <td class="w-40">
                    <div class="flex">
                      <div class="image-fit zoom-in h-10 w-10">
                        <Tippy
                          tag="img"
                          alt="Gromura - SCM"
                          class="rounded-full"
                          :src="faker.products[0].images[0]"
                          :content="`Uploaded at ${faker.dates[0]}`"
                        />
                      </div>
                      <div class="image-fit zoom-in -ml-5 h-10 w-10">
                        <Tippy
                          tag="img"
                          alt="Gromura - SCM"
                          class="rounded-full"
                          :src="faker.products[0].images[1]"
                          :content="`Uploaded at ${faker.dates[1]}`"
                        />
                      </div>
                      <!-- <div class="image-fit zoom-in -ml-5 h-10 w-10">
                        <Tippy
                          tag="img"
                          alt="Gromura - SCM"
                          class="rounded-full"
                          :src="faker.images[2]"
                          :content="`Uploaded at ${faker.dates[2]}`"
                        />
                      </div> -->
                    </div>
                  </td>
                  <td>
                    <a href="" class="whitespace-nowrap font-medium">{{
                      faker.products[0].name
                    }}</a>
                    <div
                      class="mt-0.5 whitespace-nowrap text-xs text-slate-500"
                    >
                      {{ faker.products[0].category }}
                    </div>
                  </td>
                  <td class="text-center">{{ faker.stocks[0] }}</td>
                  <td class="w-40">
                    <div
                      class="flex items-center justify-center"
                      :class="{
                        'text-success': faker.trueFalse[0],
                        'text-danger': !faker.trueFalse[0],
                      }"
                    >
                      <CheckSquareIcon class="mr-2 h-4 w-4" />
                      {{ faker.trueFalse[0] ? 'Active' : 'Inactive' }}
                    </div>
                  </td>
                  <td class="table-report__action w-56">
                    <div class="flex items-center justify-center">
                      <a class="mr-3 flex items-center" href="">
                        <CheckSquareIcon class="mr-1 h-4 w-4" />
                        Edit
                      </a>
                      <a class="flex items-center text-danger" href="">
                        <Trash2Icon class="mr-1 h-4 w-4" /> Delete
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="intro-y mt-3 flex flex-wrap items-center sm:flex-row sm:flex-nowrap"
          >
            <nav class="w-full sm:mr-auto sm:w-auto">
              <ul class="pagination">
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ChevronsLeftIcon class="h-4 w-4" />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ChevronLeftIcon class="h-4 w-4" />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">...</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">1</a>
                </li>
                <li class="page-item active">
                  <a class="page-link" href="#">2</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">3</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">...</a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ChevronRightIcon class="h-4 w-4" />
                  </a>
                </li>
                <li class="page-item">
                  <a class="page-link" href="#">
                    <ChevronsRightIcon class="h-4 w-4" />
                  </a>
                </li>
              </ul>
            </nav>
            <select class="box form-select mt-3 w-20 sm:mt-0">
              <option>10</option>
              <option>25</option>
              <option>35</option>
              <option>50</option>
            </select>
          </div>
        </div>
        <!-- END: Weekly Top Products -->
      </div>
    </div>
    <div class="col-span-12 2xl:col-span-3">
      <div class="-mb-10 pb-10 2xl:border-l">
        <div class="grid grid-cols-12 gap-x-6 gap-y-6 2xl:gap-x-0 2xl:pl-6">
          <!-- BEGIN: Important Notes -->
          <div class="col-span-12 mt-3 md:col-span-6 xl:col-span-12 2xl:mt-8">
            <div class="intro-x flex h-10 items-center">
              <h2 class="mr-auto truncate text-lg font-medium">
                Important Notes
              </h2>
              <button
                data-carousel="important-notes"
                data-target="prev"
                class="tiny-slider-navigator btn mr-2 border-slate-300 px-2 text-slate-600 dark:text-slate-300"
                @click="prevImportantNotes"
              >
                <ChevronLeftIcon class="h-4 w-4" />
              </button>
              <button
                data-carousel="important-notes"
                data-target="next"
                class="tiny-slider-navigator btn mr-2 border-slate-300 px-2 text-slate-600 dark:text-slate-300"
                @click="nextImportantNotes"
              >
                <ChevronRightIcon class="h-4 w-4" />
              </button>
            </div>
            <div class="intro-x mt-5">
              <div class="box zoom-in">
                <TinySlider ref-key="importantNotesRef">
                  <div class="p-5">
                    <div class="truncate text-base font-medium">
                      Lorem Ipsum is simply dummy text
                    </div>
                    <div class="mt-1 text-slate-400">20 Hours ago</div>
                    <div class="mt-1 text-justify text-slate-500">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </div>
                    <div class="mt-5 flex font-medium">
                      <button type="button" class="btn btn-secondary py-1 px-2">
                        View Notes
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary ml-auto ml-auto py-1 px-2"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                  <div class="p-5">
                    <div class="truncate text-base font-medium">
                      Lorem Ipsum is simply dummy text
                    </div>
                    <div class="mt-1 text-slate-400">20 Hours ago</div>
                    <div class="mt-1 text-justify text-slate-500">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </div>
                    <div class="mt-5 flex font-medium">
                      <button type="button" class="btn btn-secondary py-1 px-2">
                        View Notes
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary ml-auto ml-auto py-1 px-2"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                  <div class="p-5">
                    <div class="truncate text-base font-medium">
                      Lorem Ipsum is simply dummy text
                    </div>
                    <div class="mt-1 text-slate-400">20 Hours ago</div>
                    <div class="mt-1 text-justify text-slate-500">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s.
                    </div>
                    <div class="mt-5 flex font-medium">
                      <button type="button" class="btn btn-secondary py-1 px-2">
                        View Notes
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-secondary ml-auto ml-auto py-1 px-2"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                </TinySlider>
              </div>
            </div>
          </div>
          <!-- END: Important Notes -->
          <!-- BEGIN: Recent Activities -->
          <div
            class="col-span-12 mt-3 md:col-span-6 xl:col-span-4 2xl:col-span-12"
          >
            <div class="intro-x flex h-10 items-center">
              <h2 class="mr-5 truncate text-lg font-medium">
                Recent Activities
              </h2>
              <a href="" class="ml-auto truncate text-primary">Show More</a>
            </div>
            <div
              class="relative mt-5 before:absolute before:ml-5 before:mt-5 before:block before:h-[85%] before:w-px before:bg-slate-200 before:dark:bg-darkmode-400"
            >
              <div class="intro-x relative mb-3 flex items-center">
                <div
                  class="before:absolute before:mt-5 before:ml-5 before:block before:h-px before:w-20 before:bg-slate-200 before:dark:bg-darkmode-400"
                >
                  <div
                    class="image-fit h-10 w-10 flex-none overflow-hidden rounded-full"
                  >
                    <img alt="Gromura - SCM" :src="$f()[9].users[0].image" />
                  </div>
                </div>
                <div class="box zoom-in ml-4 flex-1 px-5 py-3">
                  <div class="flex items-center">
                    <div class="font-medium">
                      {{ $f()[9].users[0].name }}
                    </div>
                    <div class="ml-auto text-xs text-slate-500">07:00 PM</div>
                  </div>
                  <div class="mt-1 text-slate-500">Has joined the team</div>
                </div>
              </div>
              <div class="intro-x relative mb-3 flex items-center">
                <div
                  class="before:absolute before:mt-5 before:ml-5 before:block before:h-px before:w-20 before:bg-slate-200 before:dark:bg-darkmode-400"
                >
                  <div
                    class="image-fit h-10 w-10 flex-none overflow-hidden rounded-full"
                  >
                    <img alt="Gromura - SCM" :src="$f()[8].users[0].image" />
                  </div>
                </div>
                <div class="box zoom-in ml-4 flex-1 px-5 py-3">
                  <div class="flex items-center">
                    <div class="font-medium">
                      {{ $f()[8].users[0].name }}
                    </div>
                    <div class="ml-auto text-xs text-slate-500">07:00 PM</div>
                  </div>
                  <div class="text-slate-500">
                    <div class="mt-1">Added 3 new photos</div>
                    <div class="mt-2 flex">
                      <Tippy
                        tag="div"
                        class="image-fit zoom-in mr-1 h-8 w-8"
                        :content="$f()[0].products[0].name"
                      >
                        <img
                          alt="Gromura - SCM"
                          class="rounded-md border border-white"
                          :src="$f()[8].images[0]"
                        />
                      </Tippy>
                      <Tippy
                        tag="div"
                        class="image-fit zoom-in mr-1 h-8 w-8"
                        :content="$f()[1].products[0].name"
                      >
                        <img
                          alt="Gromura - SCM"
                          class="rounded-md border border-white"
                          :src="$f()[8].images[1]"
                        />
                      </Tippy>
                      <Tippy
                        tag="div"
                        class="image-fit zoom-in mr-1 h-8 w-8"
                        :content="$f()[2].products[0].name"
                      >
                        <img
                          alt="Gromura - SCM"
                          class="rounded-md border border-white"
                          :src="$f()[8].images[2]"
                        />
                      </Tippy>
                    </div>
                  </div>
                </div>
              </div>
              <div class="intro-x my-4 text-center text-xs text-slate-500">
                12 November
              </div>
              <div class="intro-x relative mb-3 flex items-center">
                <div
                  class="before:absolute before:mt-5 before:ml-5 before:block before:h-px before:w-20 before:bg-slate-200 before:dark:bg-darkmode-400"
                >
                  <div
                    class="image-fit h-10 w-10 flex-none overflow-hidden rounded-full"
                  >
                    <img alt="Gromura - SCM" :src="$f()[7].users[0].image" />
                  </div>
                </div>
                <div class="box zoom-in ml-4 flex-1 px-5 py-3">
                  <div class="flex items-center">
                    <div class="font-medium">
                      {{ $f()[7].users[0].name }}
                    </div>
                    <div class="ml-auto text-xs text-slate-500">07:00 PM</div>
                  </div>
                  <div class="mt-1 text-slate-500">
                    Has changed
                    <a class="text-primary" href="">{{
                      $f()[7].products[0].name
                    }}</a>
                    price and description
                  </div>
                </div>
              </div>
              <div class="intro-x relative mb-3 flex items-center">
                <div
                  class="before:absolute before:mt-5 before:ml-5 before:block before:h-px before:w-20 before:bg-slate-200 before:dark:bg-darkmode-400"
                >
                  <div
                    class="image-fit h-10 w-10 flex-none overflow-hidden rounded-full"
                  >
                    <img alt="Gromura - SCM" :src="$f()[6].users[0].image" />
                  </div>
                </div>
                <div class="box zoom-in ml-4 flex-1 px-5 py-3">
                  <div class="flex items-center">
                    <div class="font-medium">
                      {{ $f()[6].users[0].name }}
                    </div>
                    <div class="ml-auto text-xs text-slate-500">07:00 PM</div>
                  </div>
                  <div class="mt-1 text-slate-500">
                    Has changed
                    <a class="text-primary" href="">{{
                      $f()[6].products[0].name
                    }}</a>
                    description
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Recent Activities -->
          <!-- BEGIN: Transactions -->
          <div
            class="col-span-12 mt-3 md:col-span-6 xl:col-span-4 2xl:col-span-12"
          >
            <div class="intro-x flex h-10 items-center">
              <h2 class="mr-5 truncate text-lg font-medium">Transactions</h2>
            </div>
            <div class="mt-5">
              <div
                v-for="(faker, fakerKey) in $_.take($f(), 5)"
                :key="fakerKey"
                class="intro-x"
              >
                <div class="box zoom-in mb-3 flex items-center px-5 py-3">
                  <div
                    class="image-fit h-10 w-10 flex-none overflow-hidden rounded-full"
                  >
                    <img alt="Gromura - SCM" :src="faker.users[0].image" />
                  </div>
                  <div class="ml-4 mr-auto">
                    <div class="font-medium">
                      {{ faker.users[0].name }}
                    </div>
                    <div class="mt-0.5 text-xs text-slate-500">
                      {{ faker.dates[0] }}
                    </div>
                  </div>
                  <div
                    :class="{
                      'text-success': faker.trueFalse[0],
                      'text-danger': !faker.trueFalse[0],
                    }"
                  >
                    {{ faker.trueFalse[0] ? '+' : '-' }}Rp{{ faker.totals[0] }}
                  </div>
                </div>
              </div>
              <a
                href=""
                class="intro-x block w-full rounded-md border border-dotted border-slate-400 py-3 text-center text-slate-500 dark:border-darkmode-300"
                >View More</a
              >
            </div>
          </div>
          <!-- END: Transactions -->
          <!-- BEGIN: Schedules -->
          <div
            class="col-span-12 mt-3 md:col-span-6 xl:col-span-4 xl:col-start-1 xl:row-start-2 2xl:col-span-12 2xl:col-start-auto 2xl:row-start-auto"
          >
            <div class="intro-x flex h-10 items-center">
              <h2 class="mr-5 truncate text-lg font-medium">Schedules</h2>
              <a
                href=""
                class="ml-auto flex items-center truncate text-primary"
              >
                <PlusIcon class="mr-1 h-4 w-4" /> Add New Schedules
              </a>
            </div>
            <div class="mt-5">
              <div class="intro-x box">
                <div class="p-5">
                  <div class="flex">
                    <ChevronLeftIcon class="h-5 w-5 text-slate-500" />
                    <div class="mx-auto text-base font-medium">April</div>
                    <ChevronRightIcon class="h-5 w-5 text-slate-500" />
                  </div>
                  <div class="mt-5 grid grid-cols-7 gap-4 text-center">
                    <div class="font-medium">Su</div>
                    <div class="font-medium">Mo</div>
                    <div class="font-medium">Tu</div>
                    <div class="font-medium">We</div>
                    <div class="font-medium">Th</div>
                    <div class="font-medium">Fr</div>
                    <div class="font-medium">Sa</div>
                    <div class="relative rounded py-0.5 text-slate-500">29</div>
                    <div class="relative rounded py-0.5 text-slate-500">30</div>
                    <div class="relative rounded py-0.5 text-slate-500">31</div>
                    <div class="relative rounded py-0.5">1</div>
                    <div class="relative rounded py-0.5">2</div>
                    <div class="relative rounded py-0.5">3</div>
                    <div class="relative rounded py-0.5">4</div>
                    <div class="relative rounded py-0.5">5</div>
                    <div
                      class="relative rounded bg-success/20 py-0.5 dark:bg-success/30"
                    >
                      6
                    </div>
                    <div class="relative rounded py-0.5">7</div>
                    <div class="relative rounded bg-primary py-0.5 text-white">
                      8
                    </div>
                    <div class="relative rounded py-0.5">9</div>
                    <div class="relative rounded py-0.5">10</div>
                    <div class="relative rounded py-0.5">11</div>
                    <div class="relative rounded py-0.5">12</div>
                    <div class="relative rounded py-0.5">13</div>
                    <div class="relative rounded py-0.5">14</div>
                    <div class="relative rounded py-0.5">15</div>
                    <div class="relative rounded py-0.5">16</div>
                    <div class="relative rounded py-0.5">17</div>
                    <div class="relative rounded py-0.5">18</div>
                    <div class="relative rounded py-0.5">19</div>
                    <div class="relative rounded py-0.5">20</div>
                    <div class="relative rounded py-0.5">21</div>
                    <div class="relative rounded py-0.5">22</div>
                    <div
                      class="relative rounded bg-pending/20 py-0.5 dark:bg-pending/30"
                    >
                      23
                    </div>
                    <div class="relative rounded py-0.5">24</div>
                    <div class="relative rounded py-0.5">25</div>
                    <div class="relative rounded py-0.5">26</div>
                    <div
                      class="relative rounded bg-primary/10 py-0.5 dark:bg-primary/50"
                    >
                      27
                    </div>
                    <div class="relative rounded py-0.5">28</div>
                    <div class="relative rounded py-0.5">29</div>
                    <div class="relative rounded py-0.5">30</div>
                    <div class="relative rounded py-0.5 text-slate-500">1</div>
                    <div class="relative rounded py-0.5 text-slate-500">2</div>
                    <div class="relative rounded py-0.5 text-slate-500">3</div>
                    <div class="relative rounded py-0.5 text-slate-500">4</div>
                    <div class="relative rounded py-0.5 text-slate-500">5</div>
                    <div class="relative rounded py-0.5 text-slate-500">6</div>
                    <div class="relative rounded py-0.5 text-slate-500">7</div>
                    <div class="relative rounded py-0.5 text-slate-500">8</div>
                    <div class="relative rounded py-0.5 text-slate-500">9</div>
                  </div>
                </div>
                <div class="border-t border-slate-200/60 p-5">
                  <div class="flex items-center">
                    <div class="mr-3 h-2 w-2 rounded-full bg-pending"></div>
                    <span class="truncate">Sales Workshop</span>
                    <span class="font-medium xl:ml-auto">23th</span>
                  </div>
                  <div class="mt-4 flex items-center">
                    <div class="mr-3 h-2 w-2 rounded-full bg-primary"></div>
                    <span class="truncate">Launching Toko Baru</span>
                    <span class="font-medium xl:ml-auto">10th</span>
                  </div>
                  <div class="mt-4 flex items-center">
                    <div class="mr-3 h-2 w-2 rounded-full bg-warning"></div>
                    <span class="truncate">Perayaan 1 Tahun Gromura</span>
                    <span class="font-medium xl:ml-auto">31th</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Schedules -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, provide, inject } from 'vue'
import ReportDonutChart from '@/components/report-donut-chart/Main.vue'
import ReportBarChart from '@/components/report-bar-chart/Main.vue'
import ReportMap from '@/components/report-map/Main.vue'

const importantNotesRef = ref()

provide('bind[importantNotesRef]', (el) => {
  importantNotesRef.value = el
})

const prevImportantNotes = () => {
  const el = importantNotesRef.value
  el.tns.goTo('prev')
}
const nextImportantNotes = () => {
  const el = importantNotesRef.value
  el.tns.goTo('next')
}
</script>
