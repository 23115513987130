<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <div class="container sm:px-10">
      <div class="block grid-cols-2 gap-4 xl:grid">
        <!-- BEGIN: Register Info -->
        <div class="hidden min-h-screen flex-col xl:flex">
          <a href="" class="-intro-x flex items-center pt-5">
            <img alt="Gromura - SCM" class="w-6" src="@/assets/images/logo.png" />
            <span class="ml-3 text-lg text-white"> Gromura </span>
          </a>
          <div class="my-auto">
            <img
              alt="Gromura - SCM"
              class="-intro-x -mt-16 w-1/2"
              src="@/assets/svg/illustration.svg"
            />
            <div
              class="-intro-x mt-10 text-4xl font-medium leading-tight text-white"
            >
              A few more clicks to <br />
              sign up to your account.
            </div>
            <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-slate-400"
            >
              Manage all your accounts in one place
            </div>
          </div>
        </div>
        <!-- END: Register Info -->
        <!-- BEGIN: Register Form -->
        <div class="my-10 flex h-screen py-5 xl:my-0 xl:h-auto xl:py-0">
          <div
            class="my-auto mx-auto w-full rounded-md bg-white px-5 py-8 shadow-md dark:bg-darkmode-600 sm:w-3/4 sm:px-8 lg:w-2/4 xl:ml-20 xl:w-auto xl:bg-transparent xl:p-0 xl:shadow-none"
          >
            <h2
              class="intro-x text-center text-2xl font-bold xl:text-left xl:text-3xl"
            >
              Sign Up
            </h2>
            <div
              class="intro-x mt-2 text-center text-slate-400 dark:text-slate-400 xl:hidden"
            >
              A few more clicks to sign in to your account. Manage all your
              accounts in one place
            </div>
            <div class="intro-x mt-8">
              <input
                type="text"
                class="intro-x login__input form-control block py-3 px-4"
                placeholder="First Name"
              />
              <input
                type="text"
                class="intro-x login__input form-control mt-4 block py-3 px-4"
                placeholder="Last Name"
              />
              <input
                type="text"
                class="intro-x login__input form-control mt-4 block py-3 px-4"
                placeholder="Email"
              />
              <input
                type="text"
                class="intro-x login__input form-control mt-4 block py-3 px-4"
                placeholder="Password"
              />
              <div class="intro-x mt-3 grid h-1 w-full grid-cols-12 gap-4">
                <div class="col-span-3 h-full rounded bg-success"></div>
                <div class="col-span-3 h-full rounded bg-success"></div>
                <div class="col-span-3 h-full rounded bg-success"></div>
                <div
                  class="col-span-3 h-full rounded bg-slate-100 dark:bg-darkmode-800"
                ></div>
              </div>
              <a
                href=""
                class="intro-x mt-2 block text-xs text-slate-500 sm:text-sm"
                >What is a secure password?</a
              >
              <input
                type="text"
                class="intro-x login__input form-control mt-4 block py-3 px-4"
                placeholder="Password Confirmation"
              />
            </div>
            <div
              class="intro-x mt-4 flex items-center text-xs text-slate-600 dark:text-slate-500 sm:text-sm"
            >
              <input
                id="remember-me"
                type="checkbox"
                class="form-check-input mr-2 border"
              />
              <label class="cursor-pointer select-none" for="remember-me"
                >I agree to the Envato</label
              >
              <a class="ml-1 text-primary dark:text-slate-200" href=""
                >Privacy Policy</a
              >.
            </div>
            <div class="intro-x mt-5 text-center xl:mt-8 xl:text-left">
              <button
                class="btn bg-gradient-to-r from-teal-700 to-primary text-white w-full rounded-xl py-3 px-4 align-top xl:mr-3 xl:w-32"
              >
                Register
              </button>
              <button
                class="btn btn-outline-secondary mt-3 w-full py-3 px-4 align-top xl:mt-0 xl:w-32"
              >
                Sign in
              </button>
            </div>
          </div>
        </div>
        <!-- END: Register Form -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import dom from '@left4code/tw-starter/dist/js/dom'

onMounted(() => {
  dom('body').removeClass('main').removeClass('error-page').addClass('login')
})
</script>
