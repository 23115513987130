<template>
  <div
    class="flex group fixed bottom-6 right-0 z-50 h-24 w-24 items-end p-2 md:bottom-8"
    :class="{ 'animate-bounce': pingSignal }"
    @click="cartPreview = true"
  >
    <div
      class="flex absolute z-50 cursor-pointer items-center justify-center rounded-full bg-gradient-to-r from-teal-700 to-primary p-4 text-white shadow-xl"
    >
      <ShoppingBagIcon
        class="h-6 w-6 transition transition-all duration-[0.6s]"
      />
    </div>
    <div
      class="flex absolute z-50 -ml-2 mb-8 h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-red-500 p-3 text-white"
    >
      <span>{{ productDataSelected.length }}</span>
    </div>
  </div>

  <div class="intro-y flex mt-8 flex-col items-center sm:flex-row">
    <h2
      v-if="$h.roleCanManage($h.superRoles(), roleAccess)"
      class="mr-auto text-lg font-medium"
    >
      Buat Pembelian
    </h2>
    <h2 v-else class="mr-auto text-lg font-medium">Request Pembelian</h2>
  </div>
  <div class="intro-y flex mt-4 w-full flex-col">
    <div class="w-full lg:flex">
      <div class="flex w-full md:w-auto">
        <div class="relative w-full">
          <input
            v-model="filterData.search"
            type="text"
            class="form-control box w-full py-3 px-4 pr-10 lg:w-80"
            placeholder="Cari produk..."
            @input="searchFilter()"
          />
          <SearchIcon
            class="absolute inset-y-0 right-0 my-auto mr-3 h-4 w-4 text-slate-500"
          />
        </div>

        <!-- <select
            v-model="filterData.type"
            placeholder="Semua"
            class="box form-select ml-4 w-28"
            @change="getProduct(true)"
          >
            <option
              v-for="method in productTypeOptions"
              :key="method.id"
              :value="method.id"
            >
              {{ method.name }}
            </option>
          </select> -->
      </div>
      <!-- <select
            class="box form-select mt-3 ml-auto w-full py-3 px-4 lg:mt-0 lg:w-auto"
          >
            <option>Sort By</option>
            <option>A to Z</option>
            <option>Z to A</option>
            <option>Harga Terendah</option>
            <option>Harga Tertinggi</option>
            <option>Stok Terendah</option>
            <option>Stok Tertinggi</option>
            <option>Minimum Stok</option>
            <option>Maksimum Stok</option>
          </select> -->
    </div>
    <div
      v-if="productData.length === 0"
      class="mt-5 grid grid-cols-12 gap-5 border-b pb-8"
    >
      <div
        class="flex relative col-span-12 h-24 items-center justify-center rounded-md border border-dashed border-gray-300 py-5 text-center sm:col-span-4 2xl:col-span-3"
      >
        <span class="mb-2 block text-xs font-medium text-gray-400">
          Belum ada produk yang dicari
        </span>
      </div>
    </div>
    <div v-else class="flex mt-5 w-full flex-col pb-8">
      <div class="flex w-full">
        <span
          v-if="filterData.search === ''"
          class="text-sm italic text-gray-600"
          >Rekomendasi Produk</span
        >
        <span v-else class="text-sm italic text-gray-600">Hasil Pencarian</span>
      </div>
      <div
        class="mt-4 grid grid-cols-2 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6"
      >
        <div
          v-for="item in productData"
          :key="item.key"
          class="box flex h-full w-full flex-col"
        >
          <div class="flex w-full">
            <ImageLoader
              :image="
                item.images !== undefined && item.images.length > 0
                  ? item.images[0].image
                  : null
              "
              :alt="item.name"
              rounded-class="rounded-tr-md rounded-tl-md w-full cursor-pointer"
              @click="productPreview(item)"
            />
          </div>
          <div class="flex h-full w-full flex-col justify-between">
            <div class="flex w-full flex-col px-4 py-4">
              <div class="flex text-md w-full font-medium">
                {{ item.name }}
              </div>
              <div class="flex mt-1 w-full">
                <span class="flex items-center text-xs text-green-500">
                  {{ item.brand.name }}
                </span>
              </div>
            </div>
            <div
              class="flex cursor-pointer items-center justify-center rounded-br-xl rounded-bl-xl bg-teal-500 py-3 px-2 text-center text-white hover:bg-teal-600"
              @click="addItem(item)"
            >
              <PlusCircleIcon class="mr-1 h-4 w-4 text-white" />
              <span class="hidden lg:flex"> Tambahkan ke Keranjang </span>
              <span class="flex lg:hidden"> Tambahkan </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- CART MODAL -->
  <Modal
    backdrop="static"
    :slide-over="true"
    :show="cartPreview"
    class="modal-cart"
    @hidden="cartPreview = false"
  >
    <a
      class="top-0 right-0 right-auto mt-4 -ml-12 hidden md:fixed md:flex"
      href="javascript:;"
      @click="cartPreview = false"
    >
      <XIcon class="h-8 w-8 text-white" />
    </a>
    <ModalHeader class="flex justify-between p-5">
      <h2 class="mr-auto text-base font-medium">Keranjang Purchase Order</h2>
      <a
        class="flex md:hidden"
        href="javascript:;"
        @click="cartPreview = false"
      >
        <XIcon class="h-6 w-6 text-red-500" />
      </a>
    </ModalHeader>
    <ModalBody class="p-0">
      <div class="input-form my-4 w-full px-4">
        <label
          for="form-product-supplier"
          class="form-label flex w-full text-xs flex-col sm:flex-row"
        >
          {{ $t('formLabel.supplier') }}
        </label>
        <div class="mt-2">
          <Multiselect
            v-model="supplier"
            value="id"
            value-prop="id"
            label="name"
            track-by="name"
            :placeholder="
              isSupplierLoading
                ? 'Memuat data....'
                : $t('formLabel.select.supplier')
            "
            :options="supplierData"
            :classes="multiSelectClasses"
            :class="isSupplierError ? 'border-danger' : ''"
            :searchable="true"
            :loading="isSupplierLoading"
            @select="isSupplierError = false"
          />

          <template v-if="isSupplierError">
            <div class="mt-2 text-xs text-danger">Supplier harus diisi</div>
          </template>
        </div>
      </div>

      <div class="flex flex-col px-4 pb-6">
        <div class="input-form mb-3 w-full">
          <label
            for="form-product-paymentTerm"
            class="form-label flex w-full text-xs flex-col sm:flex-row"
          >
            {{ $t('formLabel.paymentTerm') }}
          </label>
          <div class="mt-2">
            <Multiselect
              v-model="paymentTerm"
              value="id"
              value-prop="id"
              label="name"
              track-by="name"
              :placeholder="
                isPaymentTermLoading
                  ? 'Memuat data....'
                  : $t('formLabel.select.paymentTerm')
              "
              :options="paymentTermData"
              :classes="multiSelectClasses"
              :class="isPaymentTermError ? 'border-danger' : ''"
              :searchable="true"
              :loading="isPaymentTermLoading"
              @select="setPaymentDateline"
            />

            <template v-if="isPaymentTermError">
              <div class="mt-2 text-xs text-danger">
                Term Pembayaran harus diisi
              </div>
            </template>
          </div>
        </div>
        <div class="relative mx-auto mb-8 h-10 w-full md:mb-5">
          <div class="mb-2 text-xs"><p>Payment Dateline</p></div>
          <div
            class="flex absolute h-full w-10 items-center justify-center rounded-l border bg-white bg-slate-100 text-slate-500 dark:border-darkmode-800 dark:bg-darkmode-700 dark:text-slate-400"
          >
            <CalendarIcon class="h-4 w-4" />
          </div>

          <input
            v-model="paymentDateline"
            type="text"
            class="form-control h-full pl-12 read-only:bg-white"
            readonly
          />
        </div>
      </div>

      <div
        v-if="productDataSelected.length === 0"
        class="grid grid-cols-12 pb-8"
      >
        <div class="h-4 col-span-12 bg-gray-100"></div>
          <div
            class="flex mx-4 mt-4 relative col-span-12 h-72 items-center justify-center rounded-md border border-dashed border-gray-300 py-5 text-center"
          >
            <span class="mb-2 block text-xs font-medium text-gray-400">
              Belum ada produk yang dipilih
            </span>
          </div>
        </div>
      <div v-else class="grid grid-cols-12 pb-24">
        <div
          v-for="item in productDataSelected"
          :key="item.key"
          class="relative col-span-12"
        >
          <div class="h-4 w-full bg-gray-100"></div>
          <div class="flex flex-col">
            <div class="flex w-full">
              <div class="flex w-20 pl-4 pt-4 pb-2">
                <div class="w-full">
                  <ImageLoader
                    :image="
                      item.images !== undefined && item.images.length > 0
                        ? item.images[0].image
                        : null
                    "
                    :alt="item.name"
                    class="w-16"
                    rounded-class="rounded-md cursor-pointer"
                    @click="productPreview(item)"
                  />
                </div>
              </div>
              <div class="flex w-full flex-col pl-4 md:px-4 pt-4 pb-2">
                <div class="flex text-md w-full font-medium">
                  {{ item.name }}
                </div>
                <div class="flex mt-1 w-full">
                  <a
                    class="flex items-center text-xs text-green-500"
                    href="javascript:;"
                  >
                    {{ item.brand.name }}
                  </a>
                </div>

                <div class="flex mt-4">
                  <div class="flex">
                    <button
                      type="button"
                      class="btn mr-1 h-8 w-8 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                      @click="changeQuantity(item, false, false)"
                    >
                      -
                    </button>
                    <input
                      id="pos-form-4"
                      v-model="item.quantity"
                      type="text"
                      class="form-control w-16 text-center text-xs"
                      placeholder="0"
                      @input="calculateTotalPriceItem(item)"
                      @keypress="$h.isNumber($event)"
                    />
                    <button
                      type="button"
                      class="btn ml-1 h-8 w-8 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                      @click="changeQuantity(item, false, true)"
                    >
                      +
                    </button>
                  </div>

                  <div class="flex pl-2 md:pl-6">
                    <input
                      id="pos-form-4"
                      v-model="item.pay_price"
                      type="text"
                      class="form-control w-32 md:w-36 text-center text-xs"
                      placeholder="Harga Beli"
                      @input="calculateTotalPriceItem(item)"
                      @keypress="$h.isNumber($event)"
                    />
                  </div>
                </div>

                <div
                  v-if="item.price_discount !== null || item.quantity_bonus !== null || item.other_fees !== null"
                  class="flex mt-4 text-xs"
                >
                  HPP akan menjadi:
                  <Currency
                    class="ml-2 font-bold text-sky-500 italic"
                    :total-in-string="
                      Math.round(Number(item.total_price) / (Number(item.quantity) + Number(item.quantity_bonus))).toString()
                    "
                  />
                </div>
              </div>

              <div class="flex w-12 justify-end py-2 pr-4">
                <a
                  class="flex mt-2 text-danger"
                  href="javascript:;"
                  @click="removeItem(item)"
                >
                  <Trash2Icon class="h-5 w-5" />
                </a>
              </div>

            </div>
            <div class="flex mt-4 w-full flex-col border-t pt-2 pb-1 pr-2 pl-4">
              <div class="flex w-full justify-between">
                <div class="flex items-center text-xs text-slate-500">
                  Bonus Item
                </div>
                <div class="flex items-end pr-2">
                  <button
                    type="button"
                    class="btn mr-1 h-8 w-9 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                    @click="changeQuantity(item, true, false)"
                  >
                    -
                  </button>
                  <input
                    id="pos-form-4"
                    v-model="item.quantity_bonus"
                    type="text"
                    class="form-control w-16 text-center text-xs"
                    placeholder="0"
                    @input="calculateTotalPriceItem(item)"
                    @keypress="$h.isNumber($event)"
                  />
                  <button
                    type="button"
                    class="btn ml-1 h-8 w-9 border-slate-200 bg-slate-100 text-slate-500 dark:border-darkmode-500 dark:bg-darkmode-700"
                    @click="changeQuantity(item, true, true)"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div class="flex mb-2 w-full flex-col pt-1 pr-2 pl-4">
              <div class="flex w-full justify-between">
                <div class="flex items-center text-xs text-slate-500">
                  Discount Harga
                </div>
                <div class="flex items-end pr-2">
                  <input
                    id="pos-form-4"
                    v-model="item.price_discount"
                    type="text"
                    class="form-control w-36 text-center text-xs"
                    placeholder="0"
                    @input="calculateTotalPriceItem(item)"
                    @keypress="$h.isNumber($event)"
                  />
                </div>
              </div>
            </div>
            <div class="flex mb-2 w-full flex-col pt-1 pr-2 pl-4">
              <div class="flex w-full justify-between">
                <div class="flex items-center text-xs text-slate-500">
                  Biaya Lainnya
                </div>
                <div class="flex items-end pr-2">
                  <input
                    id="pos-form-4"
                    v-model="item.other_fees"
                    type="text"
                    class="form-control w-36 text-center text-xs"
                    placeholder="0"
                    @input="calculateTotalPriceItem(item)"
                    @keypress="$h.isNumber($event)"
                  />
                </div>
              </div>
            </div>
            <div class="flex w-full flex-col border-t p-2 py-3 pl-4">
              <div class="flex w-full justify-between text-xs">
                <div class="flex items-center">Total</div>
                <div class="flex items-end pr-2">
                  <Currency
                    v-if="item.total_price !== item.total_price_original"
                    class="mr-2 text-red-500 line-through"
                    :total-in-string="item.total_price_original.toString()"
                  />
                  <Currency :total-in-string="item.total_price.toString()" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </ModalBody>
    <ModalFooter class="w-470 fixed bottom-0 z-20 bg-white p-0 px-4 py-4">
      <div class="flex justify-between">
        <div class="flex flex-col">
          <div class="mr-auto text-xs font-medium">Total Harga</div>
          <div class="flex text-base font-medium">
            <Currency :total-in-string="total.toString()" />
            <Currency
              v-if="total !== totalOriginal"
              class="ml-2 text-red-500 line-through"
              :total-in-string="totalOriginal.toString()"
            />
          </div>
        </div>
        <div class="flex">
          <button
            type="button"
            class="btn flex w-32 bg-gradient-to-r from-teal-700 to-primary text-white"
            @click="createPurchase"
          >
            <LoadingIcon
              v-if="isFormLoading"
              icon="oval"
              color="white"
              class="mr-2 h-5 w-5"
            />

            <template v-if="$h.roleCanManage($h.superRoles(), roleAccess)">
              Buat PO
            </template>
            <template v-else> Request PO </template>
          </button>
        </div>
      </div>
    </ModalFooter>
  </Modal>

  <!-- DETAIL MODAL -->
  <Modal :show="productPreviewModal" @hidden="productPreviewModal = false">
    <ModalBody class="p-0">
      <DetailProduct :active-product="activeProduct" />
    </ModalBody>
  </Modal>

  <!-- DETAIL IMAGE -->
  <Modal :show="imagePreviewModal" @hidden="imagePreviewModal = false">
    <ModalBody class="p-0">
      <DetailImage
        v-if="activeProduct !== undefined"
        :images="activeProduct.images ?? []"
        :name="activeProduct.name"
      />
    </ModalBody>
  </Modal>

  <div id="success-notification" class="toastify-content flex hidden">
    <CheckCircleIcon class="text-success" />
    <div class="ml-4 mr-4">
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">
        {{ message }}
      </div>
    </div>
  </div>
  <div id="failed-notification" class="toastify-content flex hidden">
    <XCircleIcon class="text-danger" />
    <div class="ml-4 mr-4">
      <!-- <div class="font-medium">{{ $t('formInfo.loginFailed') }}</div> -->
      <div class="font-medium">{{ status }}</div>
      <div class="mt-1 text-slate-500">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { helper as $h } from '@/utils/helper'
import Toastify from 'toastify-js'
import dayjs from 'dayjs'
import globalMixin from '@/mixins/global.js'
import useEmitter from '@/composables/useEmitter'
import ImageLoader from '@/components/image-loader/Main.vue'
import TableComponent from '@/components/TableComponent.vue'
import Currency from '@/components/Currency.vue'
import Multiselect from '@vueform/multiselect'
import BarcodeGenerator from '@/components/barcode/Generator.vue'
import ShimmerCard from '@/components/shimmer/ShimmerCard.vue'
import DetailImage from '@/components/detail-image/Main.vue'
import DetailProduct from '@/components/detail-product/Main.vue'
import ButtonAdd from '@/components/button/ButtonAdd.vue'

export default {
  components: {
    ImageLoader,
    TableComponent,
    Currency,
    Multiselect,
    BarcodeGenerator,
    ShimmerCard,
    DetailImage,
    DetailProduct,
    ButtonAdd,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const emitter = useEmitter()

    const activeUser = reactive({})
    const roleAccess = ref('')

    const activeProduct = reactive({})

    const supplierImagePreview = ref('')
    const cartPreview = ref(false)
    const imageFile = ref(null)

    const shop = ref('')
    const supplier = ref('')
    const paymentTerm = ref('')
    const paymentDateline = ref('')

    const tax = ref(11)
    const subTotal = ref(0)
    const total = ref(0)
    const totalOriginal = ref(0)

    const storePage = ref(1)
    const supplierPage = ref(1)
    const paymentTermPage = ref(1)

    const storeData = ref([])
    const supplierData = ref([])
    const paymentTermData = ref([])

    const isFormLoading = ref(false)
    const isStoreLoading = ref(false)
    const isSupplierLoading = ref(false)
    const isPaymentTermLoading = ref(false)
    const isLoading = ref(false)

    const pingSignal = ref(false)
    const timer = ref(undefined)

    const isStoreError = ref(false)
    const isSupplierError = ref(false)
    const isPaymentTermError = ref(false)

    const productPreviewModal = ref(false)
    const imagePreviewModal = ref(false)

    const status = ref('')
    const message = ref('')

    let productData = ref([])
    // let productRecommendedData = ref([])
    let productDataSelected = ref([])

    let metaData = reactive({
      page: 1,
      take: 6,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    })

    let filterData = reactive({
      page: 1,
      take: 12,
      search: '',
      order: 'ASC',
      type: null,
    })

    const productTypeOptions = ref([
      {
        id: null,
        name: 'Semua',
      },
      {
        id: 'UNITS',
        name: 'Unit',
      },
      {
        id: 'ACCESSORIES',
        name: 'Aksesoris',
      },
    ])

    onMounted(async () => {
      // clearing all event
      emitter.all.clear()

      Object.assign(activeUser, store.getters['auth/activeUser'])
      roleAccess.value = activeUser.role_codes

      emitter.on('update-data', (store) => {

        // getProduct(true)
      })

      // const storeId = store.getters['store/storeId']

      // if (storeId === null) {
      //   setTimeout(() => {
      //     getProduct()
      //   }, 2000)
      // } else {
      //   getProduct()
      // }

      getProduct()

      // await getStore()
      await getSupplier()
      await getPaymentTerm()
    })

    const getStore = async () => {
      const queryParameters = {
        order: 'ASC',
        page: storePage.value,
        take: 50,
        q: '',
      }
      await store
        .dispatch('store/getAll', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const storeTemporary = []
            response.data.data.forEach((item) => {
              storeTemporary.push({
                value: item.code,
                ...item,
              })
            })
            storeData.value = [...storeData.value, ...storeTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('store')
            }
          }
        })
        .catch((error) => {})
    }

    const getSupplier = async () => {
      const queryParameters = {
        order: 'ASC',
        page: supplierPage.value,
        take: 50,
        q: '',
      }
      await store
        .dispatch('supplier/getAll', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const supplierTemporary = []
            response.data.data.forEach((item) => {
              supplierTemporary.push({
                value: item.code,
                ...item,
              })
            })
            supplierData.value = [...supplierData.value, ...supplierTemporary]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('supplier')
            }
          }
        })
        .catch((error) => {})
    }

    const getPaymentTerm = async () => {
      const queryParameters = {
        order: 'ASC',
        page: paymentTermPage.value,
        take: 50,
        q: '',
      }
      await store
        .dispatch('master/paymentTerm', queryParameters)
        .then((response) => {
          if ('data' in response.data) {
            const paymentTermTemporary = []
            response.data.data.forEach((item) => {
              paymentTermTemporary.push({
                value: item.code,
                ...item,
              })
            })
            paymentTermData.value = [
              ...paymentTermData.value,
              ...paymentTermTemporary,
            ]

            const meta = response.data.meta
            if (meta.hasNextPage === true) {
              loadMores('paymentTerm')
            }
          }
        })
        .catch((error) => {})
    }

    const loadMores = async (type) => {
      if (type === 'supplier') {
        supplierPage.value += 1
        getSupplier()
      } else if (type === 'paymentTerm') {
        paymentTermPage.value += 1
        getPaymentTerm()
      } else if (type === 'store') {
        storePage.value += 1
        getStore()
      }
    }

    const getProduct = async (reset) => {
      if (reset === true) {
        filterData.page = 1
        productData.value = []
      }

      if (filterData.page === 1) {
        isLoading.value = true
      }

      await store
        .dispatch('product/getAll', {
          order: filterData.order,
          page: filterData.page,
          take: filterData.take,
          q: filterData.search,
          // is_accessories:
          //   filterData.type === 'ACCESSORIES'
          //     ? true
          //     : filterData.type === 'UNITS'
          //     ? false
          //     : null,
        })
        .then((response) => {
          const responseData = response.data

          if (responseData.data) {
            productData.value = responseData.data
          }
          if (responseData.meta) {
            // metaData = responseData.meta
            Object.assign(metaData, responseData.meta)
          }
          if (filterData.page === 1) {
            isLoading.value = false
          }
        })
        .catch((e) => {
          if (filterData.page === 1) {
            isLoading.value = false
          }
          console.log(e)
        })
    }

    const searchFilter = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getProduct(true)
      }, 500)
    }

    const productPreview = (data) => {
      Object.assign(activeProduct, data)
      productPreviewModal.value = true
    }

    const imagePreview = (data) => {
      Object.assign(activeProduct, data)
      imagePreviewModal.value = true
    }

    const showToast = (success) => {
      let toastId = '#failed-notification'
      if (success) {
        toastId = '#success-notification'
      }
      Toastify({
        node: dom(toastId).clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true,
      }).showToast()
    }

    const resetForm = () => {
      shop.value = ''
      supplier.value = ''
      paymentTerm.value = ''
      paymentDateline.value = ''
      tax.value = 0
      subTotal.value = 0
      total.value = 0
      totalOriginal.value = 0

      isStoreError.value = false
      isSupplierError.value = false
      isPaymentTermError.value = false

      productData.value = []
      productDataSelected.value = []
    }

    const addItem = (product) => {
      pingSignal.value = true
      if (productDataSelected.value.length === 0) {
        product.quantity = null
        product.pay_price = null
        product.quantity_bonus = null
        product.price_discount = null
        product.other_fees = null
        product.total_price = 0
        product.total_price_original = 0
        productDataSelected.value = [product]
      } else {
        let isDuplicate = false
        productDataSelected.value.forEach((item) => {
          if (item.id === product.id) {
            isDuplicate = true
          }
        })
        if (isDuplicate === false) {
          product.quantity = null
          product.pay_price = null
          product.quantity_bonus = null
          product.price_discount = null
          product.other_fees = null
          product.total_price = 0
          product.total_price_original = 0
          productDataSelected.value = [...productDataSelected.value, product]
        }
      }

      setTimeout(() => {
        pingSignal.value = false
      }, 1500)
    }

    const removeItem = (product) => {
      productDataSelected.value = productDataSelected.value.filter(
        (item) => item.id != product.id
      )
    }

    const changeQuantity = (product, bonus, added) => {
      if (bonus === true) {
        if (added === true) {
          if (product.quantity_bonus === null) {
            product.quantity_bonus = 1
          } else {
            product.quantity_bonus = product.quantity_bonus + 1
          }
        } else {
          if (product.quantity_bonus !== 0) {
            product.quantity_bonus = product.quantity_bonus - 1
          }
        }
      } else {
        if (added === true) {
          if (product.quantity === null) {
            product.quantity = 1
          } else {
            product.quantity = product.quantity + 1
          }
        } else {
          if (product.quantity !== 0) {
            product.quantity = product.quantity - 1
          }
        }
      }

      calculateTotalPriceItem(product)
    }

    const calculateTotalPriceItem = (product) => {
      product.total_price =
        (Number(product.quantity) * Number(product.pay_price) + Number(product.other_fees)) - Number(product.price_discount)
      product.total_price_original = (Number(product.quantity) * Number(product.pay_price) + Number(product.other_fees))

      calculateTotalPrice()
    }

    const calculateTotalPrice = () => {
      let totalPrice = 0
      let totalPriceOrg = 0
      productDataSelected.value.forEach((item) => {
        totalPrice += item.total_price
        totalPriceOrg += item.total_price_original
      })

      if (tax.value.toString() !== '0') {
        subTotal.value = totalPrice - Math.round((totalPrice / 100) * tax.value)
      } else {
        subTotal.value = totalPrice
      }

      total.value = totalPrice
      totalOriginal.value = totalPriceOrg
    }

    const setPaymentDateline = () => {
      isPaymentTermError.value = false

      let paymentTermCode = ''
      paymentTermData.value.forEach((item) => {
        if (item.id === paymentTerm.value) {
          paymentTermCode = item.code
        }
      })

      const formattedDate = 'DD-MM-YYYY'

      if (paymentTermCode === 'CASH') {
        paymentDateline.value = dayjs().format(formattedDate)
      } else if (paymentTermCode === 'DUE_DATE_3') {
        paymentDateline.value = dayjs().add(2, 'day').format(formattedDate)
      } else if (paymentTermCode === 'DUE_DATE_5') {
        paymentDateline.value = dayjs().add(4, 'day').format(formattedDate)
      } else if (paymentTermCode === 'DUE_DATE_7') {
        paymentDateline.value = dayjs().add(6, 'day').format(formattedDate)
      } else if (paymentTermCode === 'DUE_DATE_14') {
        paymentDateline.value = dayjs().add(13, 'day').format(formattedDate)
      } else if (paymentTermCode === 'DUE_DATE_30') {
        paymentDateline.value = dayjs().add(29, 'day').format(formattedDate)
      }
    }

    const createPurchase = async () => {
      const storeId = store.getters['store/storeId']

      if (productDataSelected.value.length === 0) {
        status.value = 'Gagal'
        message.value = 'Mohon untuk menambahkan item terlebih dahulu'
        setTimeout(() => {
          showToast()
        }, 200)
        return
      }

      let dataNotValid = false
      console.log('--productDataSelected ', productDataSelected)
      productDataSelected.value.forEach((item) => {
        if (
          item.quantity === 0 ||
          item.quantity === null
        ) {
          status.value = 'Gagal'
          message.value = 'Quantity tidak boleh kosong'
          setTimeout(() => {
            showToast()
          }, 200)
          dataNotValid = true
        } else if (item.pay_price !== "0" && item.pay_price !== null) {
          if (item.pay_price * item.quantity <= item.price_discount) {
            status.value = 'Gagal'
            message.value = 'Harga Diskon tidak boleh melebihi harga beli'
            setTimeout(() => {
              showToast()
            }, 200)
            dataNotValid = true
          }
        }
      })

      if (dataNotValid === true) {
        return
      }

      // if (shop.value === '') {
      //   isStoreError.value = true
      // } else
      if (supplier.value === '') {
        isSupplierError.value = true
      } else if (paymentTerm.value === '') {
        isPaymentTermError.value = true
      }

      if (
        productDataSelected.value.length === 0 ||
        // shop.value === '' ||
        supplier.value === '' ||
        paymentTerm.value === ''
      ) {
        return
      }

      isFormLoading.value = true

      const products = []

      productDataSelected.value.forEach((item) => {
        const product = {
          product_id: item.id,
          quantity: item.quantity,
          price: item.pay_price ?? 0,
          quantity_bonus: item.quantity_bonus,
          price_discount: item.price_discount,
          other_fees: item.other_fees,
        }

        products.push(product)
      })

      // const storeId = store.getters['store/storeId']

      let payload = {
        store_id: storeId,
        supplier_id: supplier.value,
        payment_term_id: paymentTerm.value,
        tax_id: Number(tax.value),
        products,
      }

      await store
        .dispatch('purchase/create', payload)
        .then((response) => {
          isFormLoading.value = false
          if (response.statusCode === 200) {
            status.value = response.status
            message.value = response.message

            cartPreview.value = false

            // show toast
            setTimeout(() => {
              showToast({
                success: true,
              })
            }, 200)

            // getProduct()
            setTimeout(() => {
              router.push({
                name: 'purchase',
                params: {
                  slug: route.params.slug,
                },
                query: {
                  lang: route.query.lang,
                },
              })
            }, 500)
          } else {
            status.value = response.message.status
            message.value = response.message.detail
            // show toast
            setTimeout(() => {
              showToast()
            }, 200)
          }
        })
        .catch((error) => {
          const responseData = error.response.data
          isFormLoading.value = false
          cartPreview.value = false
          status.value = responseData.message.status
          message.value = responseData.message.detail
          // show toast
          setTimeout(() => {
            showToast(false)
          }, 200)
        })
    }

    return {
      t,
      n,
      store,
      route,
      router,

      activeProduct,

      roleAccess,

      supplierImagePreview,
      cartPreview,

      productPreviewModal,
      imagePreviewModal,
      productPreview,
      imagePreview,

      shop,
      supplier,
      paymentTerm,
      paymentDateline,
      tax,
      subTotal,
      total,
      totalOriginal,

      setPaymentDateline,

      storeData,
      supplierData,
      paymentTermData,

      isFormLoading,
      isStoreLoading,
      isSupplierLoading,
      isPaymentTermLoading,
      isLoading,

      pingSignal,
      timer,

      isStoreError,
      isSupplierError,
      isPaymentTermError,

      status,
      message,

      metaData,
      filterData,
      productTypeOptions,

      productData,
      productDataSelected,

      addItem,
      removeItem,
      changeQuantity,
      calculateTotalPriceItem,
      calculateTotalPrice,

      createPurchase,
      resetForm,

      getStore,
      getSupplier,
      getPaymentTerm,
      getProduct,

      searchFilter,
      showToast,
    }
  },
}
</script>
