<template>
  <span
    v-if="selected === false"
    class="mr-1 cursor-pointer rounded-full border border-gray-300 px-3 py-1 text-gray-600"
  >
    {{ name }}
  </span>
  <span
    v-else-if="id === 'PENDING' && selected === true"
    class="mr-1 cursor-pointer rounded-full border border-amber-400 text-amber-600 px-3 py-1 bg-amber-50"
  >
    {{ name }}
  </span>
  <span
    v-else-if="id === 'PROCESS' && selected === true"
    class="mr-1 cursor-pointer rounded-full border border-lime-400 text-lime-600 px-3 py-1 bg-lime-50"
  >
    {{ name }}
  </span>
  <span
    v-else-if="id === 'DELIVERY' && selected === true"
    class="mr-1 cursor-pointer rounded-full border border-purple-400 text-purple-600 px-3 py-1 bg-purple-50"
  >
    {{ name }}
  </span>
  <span
    v-else-if="id === 'PAID' && selected === true"
    class="mr-1 cursor-pointer rounded-full border border-teal-400 text-teal-600 px-3 py-1 bg-teal-50"
  >
    {{ name }}
  </span>
  <span
    v-else-if="id === 'RECEIVED' && selected === true"
    class="mr-1 cursor-pointer rounded-full border border-sky-400 text-sky-600 px-3 py-1 bg-sky-50"
  >
    {{ name }}
  </span>
  <span
    v-else-if="id === 'COMPLETED' && selected === true"
    class="mr-1 cursor-pointer rounded-full border border-green-400 text-green-600 px-3 py-1 bg-green-50"
  >
    {{ name }}
  </span>
  <span
    v-else-if="id === 'CANCELLED' && selected === true"
    class="mr-1 cursor-pointer rounded-full border border-red-400 text-red-600 px-3 py-1 bg-red-50"
  >
    {{ name }}
  </span>
  <span
    v-else-if="selected === true"
    class="mr-1 cursor-pointer rounded-full border border-primary bg-primary/10 px-3 py-1 text-primary"
  >
    {{ name }}
  </span>
</template>

<script>
export default {
  name: 'StatusOption',
  props: {
    id: {
      type: String,
      default: '',
      required: true,
      // validator: function (value) {
      //   return [
      //     '',
      //     'PENDING',
      //     'PROCESS',
      //     'DELIVERY',
      //     'PAID',
      //     'RECEIVED',
      //     'COMPLETED',
      //     'CANCELLED',
      //   ].includes(value)
      // },
    },
    name: {
      type: String,
      default: null,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
}
</script>
